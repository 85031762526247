import {  axios } from '../utils/axios';
import { saveAs } from 'file-saver';
import {
    TRANSACTIONS,
    TRANSACTIONS_ERROR,
  SHOW_LOADING_API,
  HIDE_LOADING_API,
  UPDATE_USERS,
  ALL_TRANSACTIONS,
  ALL_TRANSACTIONS_FAIL,
  LOGOUT
} from './types';






// Get transactions
export const getTransactions = (id, isAdmin) => async dispatch => {
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify({ id : id });
    
    try {
      const res = await axios.post('/api/transactions/get_transactions/', body, config);
  
      dispatch({
        type: TRANSACTIONS,
        payload: res.data
      });
  
      if (isAdmin) {
        const res1 = await axios.post('/api/users/get_user/', body, config);
        dispatch({
          type: UPDATE_USERS,
          payload: res1.data.item
        });
      }
      
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: TRANSACTIONS_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };


  // Get all transactions
export const getAllTransactions = (id) => async dispatch => {
    dispatch({
        type: SHOW_LOADING_API
      });
    
      const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        const body = JSON.stringify({ id : id });
      
      try {
        const res = await axios.post('/api/transactions/all_transactions/', body, config);
        dispatch({
          type: ALL_TRANSACTIONS,
          payload: res.data.transactions
        });
        dispatch({
          type: HIDE_LOADING_API
        });
      } catch (err) {
        dispatch({
          type: ALL_TRANSACTIONS_FAIL,
        //  payload: { msg: err.response.statusText, status: err.response.status }
        });
        dispatch({
          type: HIDE_LOADING_API
        });
        dispatch({ type: LOGOUT });
      }

  };




// Get invoice
export const getInvoice = (id, username, invoice, language, country) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob'
      };
      const body = JSON.stringify({ id , invoice_id : invoice._id, language, country });
    
    try {
      const res = await axios.post('/api/users/create_invoice/', body, config);
      if (res) {
      const res1 = await axios.post('/api/users/get_invoice/', body, config);
      if (res1) {
        const pdfBlob = new Blob([res1.data], { type: 'application/pdf' });
        if (invoice.transaction_type === 0 && language === "Français") {
          saveAs(pdfBlob, `Provision d'adhésion de ${username}.pdf`);
        }
        else if(invoice.transaction_type === 0 && language === "Italiano") {
          saveAs(pdfBlob, `Fornitura di membri di ${username}.pdf`);
        }
        else if(invoice.transaction_type === 0 && language === "English") {
          saveAs(pdfBlob, `Membership provision of ${username}.pdf`);
        }
        else if (invoice.transaction_type === 1 && language === "Français") {
          saveAs(pdfBlob, `Revenu participation de ${username} pour le mois de ${
            (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "Décembre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "Janvier" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "Février" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "Mars" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "Avril" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "Mai" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "Juin" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "Juillet" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "Août" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "Septembre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "Octobre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "Novembre" : "") + ' '
            + invoice.date_creation.substring(0, 10).split("-")[0]}.pdf`);
        }
        else if (invoice.transaction_type === 1 && language === "Italiano") {
          saveAs(pdfBlob, `Partecipazione al reddito di ${username} per il mese di ${
            (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "Dicembre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "Gennaio" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "Febbraio" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "Marzo" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "Aprile" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "Maggio" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "Giugno" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "Luglio" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "Agosto" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "Settembre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "Ottobre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "Novembre" : "") + ' '
            + invoice.date_creation.substring(0, 10).split("-")[0]}.pdf`);
        }
        else if(invoice.transaction_type === 1 && language === "English") {
          saveAs(pdfBlob, `${
            (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "December" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "January" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "February" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "March" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "April" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "May" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "June" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "July" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "August" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "September" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "October" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "November" : "") + ' '
                                + invoice.date_creation.substring(0, 10).split("-")[0]} participation income of ${username}.pdf`);
        }
        else if (invoice.transaction_type === 2 && language === "Français") {
          saveAs(pdfBlob, `Abonnement mensuel de ${username} pour le mois de ${
            (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "Janvier" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "Février" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "Mars" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "Avril" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "Mai" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "Juin" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "Juillet" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "Août" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "Septembre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "Octobre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "Novembre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "Décembre" : "") + ' '
            + invoice.date_creation.substring(0, 10).split("-")[0]}.pdf`);
        }
        else if (invoice.transaction_type === 2 && language === "Italiano") {
          saveAs(pdfBlob, `Abbonamento mensile di ${username} per il mese di ${
            (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "Gennaio" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "Febbraio" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "Marzo" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "Aprile" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "Maggio" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "Giugno" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "Luglio" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "Agosto" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "Settembre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "Ottobre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "Novembre" :
            invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "Dicembre" : "") + ' '
            + invoice.date_creation.substring(0, 10).split("-")[0]}.pdf`);
        }
        else if(invoice.transaction_type === 2 && language === "English") {
          saveAs(pdfBlob, `Monthly subscription of ${username} for the month of ${
            (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "January" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "February" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "March" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "April" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "May" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "June" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "July" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "August" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "September" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "October" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "November" :
                                invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "December" : "") + ' '
                                + invoice.date_creation.substring(0, 10).split("-")[0]}.pdf`);
        }
        else if (invoice.transaction_type === 3 && language === "Français") {
          saveAs(pdfBlob, `Cotisation annuelle de ${username} jusqu'au ${(invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "Décembre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "Janvier" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "Février" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "Mars" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "Avril" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "Mai" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "Juin" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "Juillet" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "Août" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "Septembre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "Octobre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "Novembre" : "") + ' '
             + (parseInt(invoice.date_creation.substring(0, 10).split("-")[0]) + 1) + ' inclut'}.pdf`);
        }
        else if (invoice.transaction_type === 3 && language === "Italiano") {
          saveAs(pdfBlob, `Contributo annuale di ${username} fino a ${(invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "Dicembre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "Gennaio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "Febbraio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "Marzo" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "Aprile" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "Maggio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "Giugno" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "Luglio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "Agosto" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "Settembre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "Ottobre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "Novembre" : "") + ' '
             + (parseInt(invoice.date_creation.substring(0, 10).split("-")[0]) + 1) + ' inclut'}.pdf`);
        }
        else if(invoice.transaction_type === 3 && language === "English") {
          saveAs(pdfBlob, `Annual contribution of ${username} until ${(invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "December" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "January" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "February" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "March" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "April" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "May" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "June" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "July" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "August" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "September" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "October" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "November" : "") + ' '
          + (parseInt(invoice.date_creation.substring(0, 10).split("-")[0]) + 1) + ' included'}.pdf`);
        }

        else if (invoice.transaction_type === 8 && language === "Français") {
          saveAs(pdfBlob, `Reçu de ${username} du ${invoice.date_creation.substring(0, 10).split("-")[2] + ' ' + (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "Janvier" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "Février" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "Mars" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "Avril" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "Mai" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "Juin" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "Juillet" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "Août" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "Septembre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "Octobre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "November" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "Décembre" : "") + ' '
             + (invoice.date_creation.substring(0, 10).split("-")[0])}.pdf`);
        }
        else if (invoice.transaction_type === 8 && language === "Italiano") {
          saveAs(pdfBlob, `Ricevuto da ${username} di ${invoice.date_creation.substring(0, 10).split("-")[2] + ' ' + (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "Gennaio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "Febbraio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "Marzo" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "Aprile" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "Maggio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "Giugno" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "Luglio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "Agosto" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "Settembre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "Ottobre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "Novembre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "Dicembre" : "") + ' '
             + (invoice.date_creation.substring(0, 10).split("-")[0])}.pdf`);
        }
        else if(invoice.transaction_type === 8 && language === "English") {
          saveAs(pdfBlob, `Receipt of ${username} for ${invoice.date_creation.substring(0, 10).split("-")[2] + ' ' + (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "January" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "February" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "March" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "April" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "May" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "June" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "July" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "August" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "September" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "November" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "October" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "December" : "") + ' '
          + (invoice.date_creation.substring(0, 10).split("-")[0])}.pdf`);
        }
        else if (invoice.transaction_type === 13 && language === "Français") {
          saveAs(pdfBlob, `Frais de repositionnement de ${username} du ${invoice.date_creation.substring(0, 10).split("-")[2] + ' ' + (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "Janvier" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "Février" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "Mars" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "Avril" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "Mai" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "Juin" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "Juillet" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "Août" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "Septembre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "Octobre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "November" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "Décembre" : "") + ' '
             + (invoice.date_creation.substring(0, 10).split("-")[0])}.pdf`);
        }
        else if (invoice.transaction_type === 13 && language === "Italiano") {
          saveAs(pdfBlob, `Costi di riposizionamento da ${username} di ${invoice.date_creation.substring(0, 10).split("-")[2] + ' ' + (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "Gennaio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "Febbraio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "Marzo" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "Aprile" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "Maggio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "Giugno" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "Luglio" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "Agosto" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "Settembre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "Ottobre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "Novembre" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "Dicembre" : "") + ' '
             + (invoice.date_creation.substring(0, 10).split("-")[0])}.pdf`);
        }
        else if(invoice.transaction_type === 13 && language === "English") {
          saveAs(pdfBlob, `Repositioning costs of ${username} for ${invoice.date_creation.substring(0, 10).split("-")[2] + ' ' + (invoice.date_creation.substring(0, 10).split("-")[1] === "01" ? "January" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "02" ? "February" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "03" ? "March" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "04" ? "April" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "05" ? "May" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "06" ? "June" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "07" ? "July" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "08" ? "August" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "09" ? "September" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "10" ? "November" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "11" ? "October" :
          invoice.date_creation.substring(0, 10).split("-")[1] === "12" ? "December" : "") + ' '
          + (invoice.date_creation.substring(0, 10).split("-")[0])}.pdf`);
        }
      //  saveAs(pdfBlob, `${username}.pdf`);
      }
      }
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };