import React/*, { useState }*/ from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//import { Button } from 'react-bootstrap';

const PostActions = 
({ auth: { user }, sponsorship: { sponsorships }}) => {
  const { t } = useTranslation();


  return (
    <div className='dash-buttons'>

      <Link to='/community' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Post.Community')}
      </Link>
      <Link to='/profile' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Post.Profile')}
      </Link>
      <br /><br /><br />
    </div>

  );
};

PostActions.propTypes = {
    sponsorship: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
  };
  
  const mapStateToProps = state => ({
    sponsorship: state.sponsorship,
    auth: state.auth
  });
  
  export default connect(
    mapStateToProps,
    { }
  )(PostActions);
  