import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import SponsorshipActions from './SponsorshipActions';
import { getSponsorships, updateSponsorship, getRibs, registerUserMember } from '../../actions/sponsorship';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useTranslation } from 'react-i18next';
import { Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';



const MyPartners = ({ getSponsorships, getRibs, registerUserMember, auth: { user, theme }, sponsorship: { sponsorships, ribs, loading } }) => {


  const [memberModalsOpen, setMemberModalIsOpen] = useState(false);
  const [memberModalTitle, setMemberModalTitle] = useState("");
  const [memberModalBody, setMemberModalBody] = useState("");
  const [userMember, setUserMember] = useState("");
  const [userUpdatedLoading, setUserUpdatedLoading] = useState(false);


  const [reverseData, setReverseData] = useState([]);

  
  let history = useHistory();

  const reverseSponsorships = useCallback(() => {
    let newSponsorships = [...sponsorships];
    newSponsorships.reverse();
    setReverseData(newSponsorships);
    setUserUpdatedLoading(false);
  }, [sponsorships]);

  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);



  // Utilisez reverseSponsorships uniquement lorsque sponsorships change
  useEffect(() => {
    reverseSponsorships();
  }, [sponsorships, reverseSponsorships]);


  
  useEffect(() => {
    getRibs(user._id);
  }, [getRibs, user._id]);
  
  let memberCandidateNumber = sponsorships.filter(sponsorship => sponsorship.status === "A" || sponsorship.status === "B").length;

  const { t } = useTranslation();

  if (user && (user.status === "C" || user.status === "A")) {
    return <Redirect to="/dashboard" />;
  }

      const showTable =
      
      
      <Fragment>
      
      
<h1 className='large text-primary'>{t('Sponsorship.My_partners')}</h1>

 
  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 


<table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className= {theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      <th scope="col">{t('Sponsorship.Number')}</th>
      <th scope="col">{t('Sponsorship.Registration_date')}</th>
      <th scope="col">{t('Sponsorship.Username')}</th>
      <th scope="col">{t('Sponsorship.Status')}</th>
      <th scope="col">{t('Sponsorship.Affiliation_program')}</th>
    </tr>
  </thead>
  <tbody>
    { 
    reverseData.map(( sponsorship, index ) => {
      if (sponsorship.status === "A") {
        memberCandidateNumber-=1;
      }

          var dateCreation = JSON.parse(JSON.stringify(sponsorship.date_creation))
          var finalDateCreation = ""
          if (dateCreation !== "") {
            dateCreation = JSON.parse(JSON.stringify(sponsorship.date_creation)).substring(0, 10).split("-")
            finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
          
            if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
              finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
            }
          }
      return(
      <tr key={index}>
      <th scope="row">{sponsorships.length - index/*sponsorship.new_id*/}</th>
      <td>{finalDateCreation}</td>
      <td>{sponsorship.name}</td>
      <td> {(sponsorship.status === "C" ?  t('Sponsorship.Candidate') :
      sponsorship.status === "J" ?  t('Sponsorship.Joker') :
      sponsorship.status === "A" ?  t('Sponsorship.Member') :
      sponsorship.status === "B" ?  t('Sponsorship.Candidate_member') :
      sponsorship.status === "D" ?  t('Sponsorship.Deleted') :
            sponsorship.status.charAt(0) === "M" ? t('Sponsorship.Actif_member') + sponsorship.status.substring(1) : "")}</td>
      
     
      <td>{sponsorship.status === "D"? 
             t('Sponsorship.Desactivated') :
             sponsorship.status === "J" ? 
             t('Sponsorship.Desactivated') :
             sponsorship.status === "C" ? 
             t('Sponsorship.Desactivated') :
             sponsorship.status === "A" && sponsorship.payed_status === 5 ? 
             t('Sponsorship.Excluded') :
             sponsorship.status === "A" && memberCandidateNumber >= 3 ?
             <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
             {t('Sponsorship.Number_participants')}</Tooltip>}>
      <span className="d-inline-block">
        <Button disabled style={{ pointerEvents: 'none' }}>
        {t('Sponsorship.Register')}
        </Button>
      </span>
    </OverlayTrigger> :
                 ((sponsorship.status === "A" && sponsorship.payed_status === 4) || sponsorship.status === "B") ?
                 t('Sponsorship.Registered') :
             sponsorship.status === "A" ?
             <button disabled={userUpdatedLoading} type="button" className="btn btn-primary" onClick={() => onClickMemberItem(sponsorship)}>{t('Sponsorship.Register')}</button> :
             sponsorship.status.charAt(0) === "M" ? 
             t('Sponsorship.Registered') :
              ""}</td>
      </tr>
      );
    }
    )}

    {sponsorships.length === 0 ? 
          <tr>
          <th colSpan={5}>{t('Sponsorship.No_actif_partners')}</th>
        </tr>
      : null}

  
  </tbody>
</table>
</div>
</div>
   
  </Fragment>;

const showMemberModal = 
<Modal className="my-modal" show={memberModalsOpen} onHide={() => setMemberModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{memberModalTitle}
</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>
 {memberModalBody}</ModalBody>

    <ModalFooter as="footer">
<>
<button className="btn btn-primary" onClick={() =>  validateCandidateMemberButtonClicked()}>{t('Sponsorship.Yes')}</button>

<button className="btn btn-secondary" onClick={() => setMemberModalIsOpen(false)}>{t('Sponsorship.No')}</button>
</>

</ModalFooter>
</Modal>



      const table =
      
      <Fragment>
       <SponsorshipActions />
      
      
    {sponsorships !== null ? (
 

<div>
{showTable}



</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;


function onClickMemberItem(item) {
    setUserMember(item);
    setMemberModalTitle(t('Language.Lang') === "English" ? item.name + t('Sponsorship.Registration_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('Sponsorship.Registration_of')+ " " + item.name : "");
    setMemberModalBody(
    t('Sponsorship.Continue_registration') + " " + item.name + "?"
    );
   setMemberModalIsOpen(true);
   }

   function validateCandidateMemberButtonClicked() {
         registerUserMember(userMember.id);
         setMemberModalIsOpen(false);
         setUserUpdatedLoading(true);
   }

return loading && sponsorships && ribs === null ? (
  <Spinner />
) : (
  <Fragment>

  {showMemberModal}
  {table}


</Fragment>
);
};


MyPartners.propTypes = {
  getSponsorships: PropTypes.func.isRequired,
  getRibs: PropTypes.func.isRequired,
  registerUserMember: PropTypes.func.isRequired,
  updateSponsorship: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getSponsorships, getRibs, registerUserMember, updateSponsorship }
)(MyPartners);


