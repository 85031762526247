import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { playMusic } from '../../actions/auth';
import styled from 'styled-components';

let Unmute, Mute;

Unmute = Mute = styled.svg`
position: absolute;
top: 7rem;
right: 4rem;
transition: all .5s linear;
`;



const Speaker = ({ auth: { mute }, playMusic }) => {
//export const Toggle = ({ auth: { user, isAuthenticated, theme, loading }, logout, changeTheme }) => {
 // console.log(theme);
  return (
    <div style={{ cursor: 'pointer' }}onClick={() => playMusic(!mute)}>
      { mute ? <Unmute xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#fff" /*stroke="#212121"*/ strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-volume-2"><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path></Unmute>
      : 
      <Mute xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-sun"><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><line x1="23" y1="9" x2="17" y2="15"></line><line x1="17" y1="9" x2="23" y2="15"></line></Mute> }
    </div>
  )
}

Speaker.propTypes = {
    playMusic: PropTypes.func.isRequired
  };
  
  const mapStateToProps = state => ({
    auth: state.auth
  });
  
  export default connect(
    mapStateToProps,
    { playMusic }
  )(Speaker);
  