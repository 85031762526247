import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
.my-primary{
    color: ${({ theme }) => theme.primary} !important ;
    background-color: red;
  }
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: 'Roboto', sans-serif;
    transition: all .5s linear;
  }
  p {
    line-height: 1.4rem;
  }
  .btn-primary {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.body};
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    border: none;
    transition: all .5s linear;
  }
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color:  ${({ theme }) => theme.body};
    background-color: ${({ theme }) => theme.primary};
}
  .text-primary {
    color: ${({ theme }) => theme.primary} !important;
    transition: all .5s linear;
  }

  details summary {
    width: 100%;
    padding: 0.5rem 0;
    border-top: 1px solid ${({ theme }) => theme.primary};
    position: relative;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 300;
    list-style: none;
    transition: all .5s linear;
  }
  
  details[open] summary {
    color : #EB5E69;
  }
  
  details summary:after {
    content: "+";
    color: ${({ theme }) => theme.primary};
    position: absolute;
    font-size: 1.75rem;
    line-height: 0;
    margin-top: 0.75rem;
    right: 0;
    font-weight: 200;
    transform-origin: center;
    transition: 200ms linear;
  }

.color-nav {
    background-color: ${({ theme }) => theme.nav};
  }

  .color-nav-transparent {
    background-color: "transparent";
  }
  
  .nav-link, .nav {
    color: ${({ theme }) => theme.nav_text} !important;
  }
  
  .nav-link:hover {
    color: ${({ theme }) => theme.primary} !important;
  }

  .navbar .dropdown-menu {
    background-color: ${({ theme }) => theme.body};
    border-color: ${({ theme }) => theme.text};
  }

 
  .dropdown-menu a{
    color: ${({ theme }) => theme.primary};
   }
   .dropdown-menu a:hover{
    background: #bc9f51;
    background: linear-gradient(left, #bc9f51, #edd99a);
    background: -moz-linear-gradient(left, #bc9f51, #edd99a);
    background: -webkit-gradient(linear, 100% 100%, 0% 0%, from(#bc9f51), to(#edd99a));
   color: ${({ theme }) => theme.dark} !important;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
   }

   .my-modal .modal-content {
    background-color: ${({ theme }) => theme.body};
    border-color: ${({ theme }) => theme.text};
  }

  .my-card {
    background-color: ${({ theme }) => theme.body};
    border-color: ${({ theme }) => theme.text};
  }

  .close {
    color: ${({ theme }) => theme.text} !important
 }

 .date-button {
  border: 2px solid ${({ theme }) => theme.body};
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.primary};
  border-color: ${({ theme }) => theme.primary};
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
}
.date-button:hover {
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.text_bar};
}

 input[type="text"], input[type="email"], input[type="tel"], input[type="password"], textarea, select {    
  background-color :${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text} !important;
  border-color: ${({ theme }) => theme.primary} !important;
  border:0px;
}


input[type="text"]:focus, input[type="email"]:focus, input[type="tel"]:focus, input[type="password"]:focus, textarea:focus { 
  outline: none !important;
  border-color: ${({ theme }) => theme.primary};
  box-shadow: 0 0 10px ${({ theme }) => theme.primary};
}

`;

export const lightTheme = {
  body: '#faf7f2',
  /*nav: '#fff',
  nav_text: '#daa520',*/
  nav: '#000',
  nav_text: '#bc9f51',
  text: '#000',
  text_bar: '#fff',
  primary: '#bc9f51',
  primary_hover: '#edd99a',
  dark: '#000'
};

export const darkTheme = {
  body: '#121212',
  nav: '#000',
  nav_text: '#bc9f51',
  text: '#fff',
  text_bar: '#000',
  primary: '#edd99a',
  primary_hover: '#bc9f51',
  dark: '#000'
};

/*
input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: ${({ theme }) => theme.body};
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid grey;
  cursor: pointer;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: ${({ theme }) => theme.primary};
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid ${({ theme }) => theme.text};
}
input[type='checkbox']{
  width: 14px !important;
  height: 14px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 1px solid gray;
  box-shadow: none;
  font-size: 0.8em;
  text-align: center;
  background:  ${({ theme }) => theme.body};
}

input[type='checkbox']:checked:after {
  content: '✔';
  color: ${({ theme }) => theme.primary};
}
*/