import {
    GET_DEALS,
    DEAL_ERROR,
    DELETE_DEAL,
    ADD_DEAL,
    GET_DEAL
  } from '../actions/types';
  
  const initialState = {
    deals: [],
    deal: null,
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_DEALS:
        return {
          ...state,
          deals: payload,
          loading: false
        };
      case GET_DEAL:
        return {
          ...state,
          deal: payload,
          loading: false
        };
      case ADD_DEAL:
        return {
          ...state,
          deals: [payload, ...state.deals],
          loading: false
        };
      case DELETE_DEAL:
        return {
          ...state,
          deals: state.posts.filter(deal => deal._id !== payload),
          loading: false
        };
      case DEAL_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
      default:
        return state;
    }
  }
  