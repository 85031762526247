import {
  SHOW_LOADING_API,
  HIDE_LOADING_API,
  REGISTER_SUCCESS,
  //REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  UPDATE_STATUS,
  UPDATE_TOKEN,
  //LOGIN_FAIL,
  LOGOUT,
  ACCOUNT_DELETED,
  CHANGE_THEME,
  PLAY_MUSIC
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  loading_api: false,
  user: null,
  theme: null,
  mute: true
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case SHOW_LOADING_API:
      return {
          ...state,
          loading_api: true
      };
      case HIDE_LOADING_API:
          return {
              ...state,
              loading_api: false
          };
          
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload
      };
      case UPDATE_STATUS:
        /*  var newUser = state.user
          newUser.status = payload */
          return {
              ...state,
              user: state.user._id === payload._id? payload : state.user,
              loading: false
          }; 
          case UPDATE_TOKEN:
              return {
                  ...state,
                  token: payload,
                  loading: false
              };   
    case REGISTER_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        user: payload
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };
    case ACCOUNT_DELETED:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null
      };
    case AUTH_ERROR:
      return {
          ...state,
          error: payload,
          loading: false
      };
    case LOGOUT:
        localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        full_matrix: null,
        old_user: null,
        bonus_genealogy: 0,
        candidate_warning: null,
        first_articles: null,
        articles: null
      };
    case CHANGE_THEME:
        return {
            ...state,
            theme: payload
        };
        case PLAY_MUSIC:
          return {
              ...state,
              mute: payload
          };
    default:
      return state;
  }
}
