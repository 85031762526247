import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile, getCurrentProfile } from '../../actions/profile';
import { useTranslation } from 'react-i18next';

const initialState = {
  company: '',
  website: '',
  location: '',
  status: '',
  skills: '',
  bio: '',
  twitter: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  instagram: ''
};
const ProfileForm = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  history
}) => {

  const { t } = useTranslation();

  const [formData, setFormData] = useState(initialState);

  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  useEffect(() => {
    if (!profile) getCurrentProfile();
    if (!loading && profile) {
      const profileData = { ...initialState };
      for (const key in profile) {
        if (key in profileData) profileData[key] = profile[key];
      }
      for (const key in profile.social) {
        if (key in profileData) profileData[key] = profile.social[key];
      }
      setFormData(profileData);
    }
  }, [loading, getCurrentProfile, profile]);

  const {
    company,
    website,
    location,
    status,
    skills,
    bio,
    twitter,
    facebook,
    linkedin,
    youtube,
    instagram
  } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    createProfile(formData, history, true);
  };

  return (
    <Fragment>
      <h1 className="large text-primary">{t('Profile.Edit_profile')}</h1>
      <p className="lead">
        <i className="fas fa-user" /> {t('Profile.Add_changes')}
      </p>
      <small>* = {t('Profile.Required_field')}</small>
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <select className="form-control" name="status" value={status} onChange={onChange}>
            <option value="">* {t('Profile.Select_status')}</option>
            <option value={t('Profile.Developer')}>{t('Profile.Developer')}</option>
            <option value={t('Profile.Junior_developer')}>{t('Profile.Junior_developer')}</option>
            <option value={t('Profile.Senior_developer')}>{t('Profile.Senior_developer')}</option>
            <option value={t('Profile.Manager')}>{t('Profile.Manager')}</option>
            <option value={t('Profile.Student_learning')}>{t('Profile.Student_learning')}</option>
            <option value={t('Profile.Instructor_teacher')}>{t('Profile.Instructor_teacher')}</option>
            <option value={t('Profile.Intern')}>{t('Profile.Intern')}</option>
            <option value={t('Profile.Other')}>{t('Profile.Other')}</option>
          </select>
          <small className="form-text">
          {t('Profile.Give_idea')}
          </small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder={t('Profile.Company')}
            name="company"
            value={company}
            onChange={onChange}
          />
          <small className="form-text">
          {t('Profile.Company_work')}
          </small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder={t('Profile.Website')}
            name="website"
            value={website}
            onChange={onChange}
          />
          <small className="form-text">
          {t('Profile.Company_website')}
          </small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder={t('Profile.Location')}
            name="location"
            value={location}
            onChange={onChange}
          />
          <small className="form-text">
          {t('Profile.City_suggested')}
          </small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder={"* "+t('Profile.Skills')}
            name="skills"
            value={skills}
            onChange={onChange}
          />
          <small className="form-text">
          {t('Profile.Use_comma')}
          </small>
        </div>
        <div className="form-group">
          <textarea
            placeholder={t('Profile.Short_bio')}
            name="bio"
            value={bio}
            onChange={onChange}
          />
          <small className="form-text">{t('Profile.About_yourself')}</small>
        </div>

        <div className="my-2">
          <button
            onClick={() => toggleSocialInputs(!displaySocialInputs)}
            type="button"
            className="btn btn-light"
          >
            {t('Profile.Social_links')}
          </button>
          <span>{t('Profile.Optional')}</span>
        </div>

        {displaySocialInputs && (
          <Fragment>
            <div className="form-group social-input">
              <i className="fab fa-twitter fa-2x" />
              <input
                type="text"
                placeholder={t('Profile.Twitter_url')}
                name="twitter"
                value={twitter}
                onChange={onChange}
              />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-facebook fa-2x" />
              <input
                type="text"
                placeholder={t('Profile.Facebook_url')}
                name="facebook"
                value={facebook}
                onChange={onChange}
              />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-youtube fa-2x" />
              <input
                type="text"
                placeholder={t('Profile.Youtube_url')}
                name="youtube"
                value={youtube}
                onChange={onChange}
              />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-linkedin fa-2x" />
              <input
                type="text"
                placeholder={t('Profile.Linkedin_url')}
                name="linkedin"
                value={linkedin}
                onChange={onChange}
              />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-instagram fa-2x" />
              <input
                type="text"
                placeholder={t('Profile.Instagram_url')}
                name="instagram"
                value={instagram}
                onChange={onChange}
              />
            </div>
          </Fragment>
        )}

        <input type="submit" value={t('Profile.Validate')} className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/dashboard">
          {t('Profile.Go_back')}
        </Link>
      </form>
    </Fragment>
  );
};

ProfileForm.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  withRouter(ProfileForm)
);
