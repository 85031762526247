import {
    TRANSACTIONS,
    TRANSACTIONS_ERROR,
    ALL_TRANSACTIONS,
    ALL_TRANSACTIONS_FAIL
} from '../actions/types';



const initialState = {
    loading: true,
    transaction: null,
 /*   transactions: [],
    all_transactions: [], */
    error: {}
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case TRANSACTIONS:
            return {
                ...state,
                transactions: payload,
                loading: false
            };

        case TRANSACTIONS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };

            case ALL_TRANSACTIONS:
                return {
                    ...state,
                    all_transactions: payload,
                    loading: false
                };
    
            case ALL_TRANSACTIONS_FAIL:
                return {
                    ...state,
                    error: payload,
                    loading: false
                };

        default:
            return state;
    }
}