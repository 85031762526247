import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SponsorshipActionsAdmin from './SponsorshipActionsAdmin';
import { Redirect } from 'react-router-dom';


const MessageHistory = ({ auth: { user, theme } }) => {

    const { t } = useTranslation();

    if ( user.role !== 0 ) {
        return <Redirect to="/dashboard" />;
      }

  return (
    <Fragment>
    <SponsorshipActionsAdmin />
    <section className="container">
      <h1 className='x-large text-primary'>
        <i className='fas fa-exclamation-triangle' /> {t('AdminSponsorship.Message_history')}
      </h1>
      <p className='large'>{t('NavBar.Wip')}</p>
      <div class="progress">
  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: "50%"}}></div>
</div>
      </section>
    </Fragment>
    ) ;
}


MessageHistory.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(MessageHistory);
