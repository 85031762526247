import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { repositioningUser } from '../../actions/sponsorship';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
//import { Button } from 'react-bootstrap';

const SponsorshipActions = 
({ repositioningUser, auth: { user }, sponsorship: { sponsorships, deleted_affiliation, full_matrix, old_user }}) => {
  const { t } = useTranslation();
  let history = useHistory();
  var count = 0;
  var countMember = 0;
  const starCounter = user.status ? user.status.substring(1).length : 0
for(var i = 0; i < sponsorships.length; ++i){
    if(sponsorships[i].status.charAt(0) !== "M" && sponsorships[i].status !== "J")
        count++;
        if(sponsorships[i].status.charAt(0) === "M") {
          countMember++;
        }
}

const [modalsOpen, setModalIsOpen] = useState(false);

function repositioning() {
  setModalIsOpen(false);
  if (full_matrix && !old_user && countMember >= 4) {
    repositioningUser(user._id)
  }
}



const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{full_matrix ? t('Sponsorship.Repositioning_conditions') : t('Sponsorship.No_candidates')}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{ full_matrix ? (countMember < 4 ? t('Sponsorship.Member_partners1') + " " + 4 + " " + t('Sponsorship.Member_partners2') : t('Sponsorship.Condition1') + "\n\n" + t('Notification.Condition2') + "\n\n" + t('Notification.Condition3') + "\n\n") : deleted_affiliation <= 3 ? t('Sponsorship.Add_members') : "" }
 {!full_matrix && deleted_affiliation > 0 ? "\n" + t('Sponsorship.Ps1') + " " + deleted_affiliation + " " + t('Sponsorship.Ps2') : ""}</ModalBody>
<ModalFooter as="footer">
{
full_matrix && countMember >= 4 ?
<div>
<button className="btn btn-primary" onClick={() => repositioning()}>{t('Sponsorship.Accept')}</button>
</div>
 : <button className="btn btn-primary" onClick={() => repositioning()}>{t('Sponsorship.Ok')}</button>
           }
</ModalFooter>
</Modal>
  return (
    <div className='dash-buttons'>

      <Link to='/affiliations' onClick={() => window.location.pathname === '/affiliations' ? history.go() : console.log("")} className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Affiliation_tree')}
      </Link>
      <Link to='/matrix' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Matrix')}
      </Link>
      <Link to='/payment-table' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Payment_table')}
      </Link>
      <Link to='/digital-wallet' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Digital_wallet')}
      </Link>


     {/* (user.payed_status === 3 && (!old_user || (old_user && !full_matrix))) ? <Link  to={(count + deleted_affiliation >= 3 || full_matrix) ? `${window.location.pathname}` : '/add-subscriber'} onClick={() => (count + deleted_affiliation >= 3 || full_matrix) ? 
      setModalIsOpen(true) : history.push({
    pathname: '/add-subscriber'
  })} className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Co-opt')} { !isNaN(deleted_affiliation) ? ("("+ (3 - (count + deleted_affiliation)) + ")") : "" }
</Link> : null */} 

           <Link to='/add-subscriber' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Co-opt')}
      </Link>
           

{(starCounter > 0 && (user && user.username && user.date_repositioning !== "" /*&& (user.username.slice(user.username.length - 1) === "*")*/)) ? <Link to='/full-matrices' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Full_matrices')}
      </Link> : null}

      <Link to='/my-partners' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.My_partners')}
      </Link>

      <br /><br /><br />
      {showModal}
    </div>

  );
};

SponsorshipActions.propTypes = {
    sponsorship: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    repositioningUser: PropTypes.func.isRequired
  };
  
  const mapStateToProps = state => ({
    sponsorship: state.sponsorship,
    auth: state.auth
  });
  
  export default connect(
    mapStateToProps,
    { repositioningUser }
  )(SponsorshipActions);
  