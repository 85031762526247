import React, { Fragment, useState, useEffect, useRef } from 'react';
//import { Link, Redirect } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, forgotPassword, playMusic, changeTheme } from '../../actions/auth';
import i18next from 'i18next';
import { setAlert } from '../../actions/alert';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useTranslation } from 'react-i18next';

import { useSpring, animated } from 'react-spring';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Globe from '../../video/atrakxion_globe.mp4';

import Relaxation from '../../audio/relaxation.mp3';


import Logo from '../../img/icon_dark.png';
import useWindowDimensions from '../layout/UseWindowDimensions';


import Speaker from '../layout/Speaker';
//import './Login.css';
  

const Login = ({ auth: { mute, theme },setAlert, login, forgotPassword, changeTheme, playMusic, isAuthenticated, match, alerts }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });


  const { /*height,*/ width } = useWindowDimensions();
  const [isClicked, setIsClicked] = useState(false);
  const fade = useSpring({
    /*from: {
      transform: 'translate3d(0, -500px, 0)'
    },
    to: {
      transform: 'translate3d(0, 0px, 0)'
    }*/

    transform: isClicked ? 'translate3d(0, -50, 0)' : 'translate3d(10px, -700px, 0)'
  })

  const fadePage = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    },
    delay: 250
  })

  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");
  const [modalSecondButton, setModalSecondButton] = useState("");

  

  const { email, password } = formData;

  const [emailForgot, setEmailForgot] = useState("");

  const [passwordInputType, setPasswordInputType] = useState(true);


  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  };

  const { t } = useTranslation();
  
  function handleClick(lang) {
    i18next.changeLanguage(lang)
  }


  
  useEffect(() => {
    handleClick('fr');
  }, []);

  useEffect(() => {
    if(theme !== "light") {
      changeTheme("light")
    }
  }, [theme, changeTheme]);

  
  //Only once
  const isInitialMount = useRef(true);


  const audioRef = useRef()

  useEffect(() => {
    if (isAuthenticated) {
      document.title = t('Login.Atrakxion');
    }
    else {
    document.title = t('Login.Home');
    }
    if (isInitialMount.current) {
       isInitialMount.current = false;

      if (match.params.confirmation === "link-expired") {
        setAlert(t('server_24_user'), 'danger');
      }
      else if (match.params.confirmation === "confirmation-done") {
        setAlert(t('server_25_user'), 'success');
      }
      else if (match.params.confirmation === "reset-done") {
        setAlert(t('server_27_user'), 'success');
      }

      playMusic(false);
    //  handleClick.bind(this)
    }
   /* window.addEventListener("DOMContentLoaded", event => {
      const audio = document.querySelector("audio");
      audio.volume = 0.2;
      audio.play();
    });*/
  });

 /* const [mouseClick, setMouseClick] = useState(false);

const updateMouseClick = () => {
      audioRef.current.play();
      setMouseClick(true)
};

  useEffect(() => {
    if (!mouseClick) {
      window.addEventListener("click", updateMouseClick);
      return () => window.removeEventListener("click", updateMouseClick);
    }
  }, [mouseClick]);*/
  
  useEffect(() => {
    if (!isAuthenticated && mute && audioRef.current) {
      audioRef.current.play();
    }
    else if (audioRef.current) {
      audioRef.current.pause();
    }
   /* return () => {
      if (audioRef.current) {
      audioRef.current.pause();
      }
    }*/
  }, [mute, isAuthenticated]);

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  

  function emailChanged(value) {
    if (value !== "") {
    setModalBody("");
    }
    else {
      setModalBody(t('Login.Email_required'));
    }
        setEmailForgot(value);
  }
  

  function forgottenPassword() {
      setEmailForgot(email)
      setModalTitle(t('Login.Get_reset_link'));
      setModalBody("");
      setModalFirstButton(t('Login.Validate'));
      setModalSecondButton("");
      setModalIsOpen(true);
  }

  function vaildateButtonClicked() {

    if (emailForgot === "") {
      setModalBody(t('Login.Email_required'));
  }
  else {
    forgotPassword(emailForgot);
    setModalIsOpen(false);
  }
//     window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
}

  const showModal = 
  <Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
  <ModalHeader closeButton as="span">
    <ModalTitle as="h4">{modalTitle}</ModalTitle>
  </ModalHeader>
  <ModalBody as="section" 
   style={{
    color: "red",
  whiteSpace: "pre-line"
   }}>{modalBody}
    <form className="form">
          <div className="form-group">
            <input
              type="email"
              placeholder={t('Login.Email_address')}
              name="email"
              value={emailForgot}
              onChange={e => emailChanged(e.target.value)}
              minLength="1"
            />
          </div>
        </form>
        </ModalBody>
  <ModalFooter as="footer">
  
    {modalFirstButton !== "" ? 
    <>
  <button className="btn btn-primary" onClick={() => vaildateButtonClicked()}>{modalFirstButton}</button>
  </> :
  null
    }
      {modalSecondButton !== "" ? 
      
  <button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalSecondButton}</button>
  
   :
   null
    }
  
  </ModalFooter>
  </Modal>


  return (
    <Fragment>
      
    <section >

{    /* <iframe src={Silence} type="audio/mp3" allow="autoplay" id="audio" loop = "loop"/*style="display:none"*//*></iframe>}
   {/* <embed src={Silence} type="audio/mp3" autostart="true" allow="autoplay" hidden="true"/>*/} 

  <audio ref={audioRef} /*autoPlay = "autoPlay"*/ loop = "loop"><source src={Relaxation} /*allow="autoplay"*/ type="audio/mp3"/></audio>
      <video
      autoPlay = "autoPlay"
      loop = "loop"
      muted = "muted"
      style={{
        position: "absolute",
        width: "100%",
        left: "50%",
        top: "50%",
        height: "100%",
        objectFit: "cover",
        transform: "translate(-50%, -50%)",
        zIndex: "-1"
      }}>
        <source src={Globe} type="video/mp4" />
        </video>
<animated.div className='dark-overlay' style={fadePage}>
        <div className='landing-inner' style={{position: "relative"}}>
{/* <div className="wrapper fadeInDown login-component">
  <div id="formContent">
    
    <div className="fadeIn first">
      <img src={Logo} id="icon" alt="Icon" />
    </div>


    <form onSubmit={e => onSubmit(e)}>
      <input type="email" id="email" name="email" className="fadeIn second" value={email} placeholder={t('Login.Email_address')} onChange={e => onChange(e)}
            required/>
      <input type="password" name="password" id="password" className="fadeIn third" value={password} placeholder={t('Login.Password')} onChange={e => onChange(e)}
            minLength="3"/>
      <input type="submit" className="fadeIn fourth" value={t('Login.Login')} />
    </form>


    <div id="formFooter">
      <button className="underlineHover button-forgot-password" onClick={() => forgottenPassword()}>{t('Login.Forgot_password') + "?"}</button>
    </div>

  </div>
</div>*/}


















<div style={{position: "absolute", display: isClicked ? "" : "none"}}>
<animated.div style={fade}>
<aside className="col-sm-4">

<div className="card" style={{ height: alerts !== null &&
alerts.length > 0 ? "490px" : "430px", width: width > 540 ? 500 : width - 40 }}>
<article className="card-body">
  <div className="d-flex justify-content-center">
<div style={{width: 200, height: 'auto'}}>
<img src={Logo} alt="Atrakxion icon"  className="img-fluid"/>
</div></div>
	<hr/>
	<p className="text-center">
    {
alerts !== null &&
alerts.length > 0 &&
  <div key={alerts[alerts.length - 1].id} className={`alert alert-${alerts[alerts.length - 1].alertType}`}>
    {t(`Server.${alerts[alerts.length - 1].msg}`)}
  </div>
    }
  </p>
	<form className="form" onSubmit={e => onSubmit(e)}>
	<div className="form-group">
	<div className="input-group">
		<div className="input-group-prepend">
		    <span className="input-group-text"> 
        <FontAwesomeIcon icon="user" /></span>
		 </div>
    <input 
      style={{width:"90%"}}
                type="email"
                placeholder={t('Login.Email_address')}
                name="email"
                className="form-control"
                value={email}
                onChange={e => onChange(e)}
                required/>
	</div>
	</div> 
	<div className="form-group">
	<div className="input-group">
		<div className="input-group-prepend">
		    <span className="input-group-text"> <FontAwesomeIcon icon="lock" /> </span>
		 </div>
      <input className="form-control" 
      style={{width:"90%"}}
                  type={passwordInputType ? "password" : "text"}
                  placeholder={t('Login.Password')}
                  name="password"
                  value={password}
                  onChange={e => onChange(e)}
                  minLength="3"/>
                  <span className="password-toogle-icon" onClick={() => setPasswordInputType(!passwordInputType)}>
                  <FontAwesomeIcon icon={passwordInputType ? "eye" : "eye-slash"}  />
                                    </span>
	</div> 
	</div> 
	<div className="form-group">
  <input type="submit" className="btn btn-block btn-dark" value={t('Login.Login')} />
	</div> 
  <button type="button" className="btn btn-block btn-outline-dark" onClick={() => forgottenPassword()}>{t('Login.Forgot_password') + "?"}</button>
	</form>
</article>
</div>

	</aside>

{/*   <h1 className="large text-primary-atrakxion">{t('Login.Sign_in')}</h1>
      <p className="lead">
        <i className="fas fa-user" /> {t('Login.Subtitle')}
      </p>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <input
            type="email"
            placeholder={t('Login.Email_address')}
            name="email"
            value={email}
            onChange={e => onChange(e)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder={t('Login.Password')}
            name="password"
            value={password}
            onChange={e => onChange(e)}
            minLength="3"
          />
        </div>
        <input type="submit" className="btn btn-block btn-primary" value={t('Login.Login')} />
       
      <button type="button" className="btn btn-block btn-outline-primary" onClick={() => forgottenPassword()}>{t('Login.Forgot_password') + "?"}</button>
  </form> */}  
  </animated.div>
  </div>
  <div style={{position: "absolute", display: isClicked ? "none" : ""}} zindex={1}>
	<p className="text-center">
    {
alerts !== null &&
alerts.length > 0 &&
<div>
  <div key={alerts[alerts.length - 1].id} className={`alert alert-${alerts[alerts.length - 1].alertType}`}>
    {t(`Server.${alerts[alerts.length - 1].msg}`)}
  </div>
<hr/></div>
    }
  </p>
          <div type="button" className="text-button-color text-dark mb-2" style={{paddingRight: "20px"}} onClick={() => setIsClicked(true)}>{t('Login.Login')}</div>
          </div>
  </div>
     {showModal}
     <Speaker/>
     </animated.div></section>

    </Fragment>
  );
};
/*      <p className="my-1">
        Don't have an account? <Link to="/register">Sign Up</Link>
      </p>
      */
Login.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  changeTheme: PropTypes.func.isRequired,
  playMusic: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  alerts: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  alerts: state.alert,
  auth: state.auth
});

export default connect(mapStateToProps, { setAlert, login, forgotPassword, changeTheme, playMusic })(Login);
