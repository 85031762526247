import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout, loadUser } from '../../actions/auth';
import Overlay from 'react-bootstrap/Overlay';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const Footbar = ({ auth: { user, isAuthenticated, loading, theme }, logout, loadUser }) => {


 const { t } = useTranslation();
 let history = useHistory();







useEffect(() => {
}, [isAuthenticated, t]);




  
  return (
    <Fragment>
<CookieConsent
  location="bottom"
  cookieName="Atrakxion"
  disableButtonStyles
  buttonClasses="btn btn-primary"
  style={{ opacity: '0.97' }}
  containerClasses="alert alert-warning col-lg-12"
  buttonText={t('Footer.I_understand')}
>
{t('Footer.Rgpd')}
</CookieConsent>
      {
  /*location="bottom"
  style={{ background: "#edd99a", color: "black" }}
  disableButtonStyles
  buttonClasses="btn btn-primary"
  containerClasses="alert alert-warning col-lg-12"
  buttonText={t('Footer.I_understand')}*/
      !loading && (
        <div className="footer-basic">
        <footer>
            <ul className="list-inline">
                <li className="list-inline-item" style={{borderBottom: isAuthenticated ? "none" : "1px solid #b0f2b6"}}><Link to='/about-us'> {t('Footer.About_us')}</Link></li>
                <li className="list-inline-item" style={{borderBottom: isAuthenticated ? "none" : "1px solid #b0f2b6"}}><Link to='/legal-notices'> {t('Footer.Legal_notice')}</Link></li>
                <li className="list-inline-item" style={{borderBottom: isAuthenticated ? "none" : "1px solid #b0f2b6"}}><Link to='/privacy-policy'> {t('Footer.Privacy_policy')}</Link></li>
       {/*  <li className="list-inline-item"><Link to= {isAuthenticated ? '/terms-conditions' : '#'} onClick={() => isAuthenticated ? null : setShowSecondClicked(!showSecond)} ref={targetSecond}> {t('Footer.Terms_conditions')}</Link>
              <Overlay target={targetSecond.current} show={showSecond} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}
          style={{
            backgroundColor: '#edd99a',
            padding: '2px',
            color: 'white',
            borderRadius: 3,
            cursor: 'pointer',
            ...props.style,
          }} onClick={() => setShowAllClicked()}> 
            {t('Footer.Login_access')}
          </Tooltip>
        )}
      </Overlay></li>*/}
      <OverlayTrigger placement="top" overlay={isAuthenticated ? <div></div> : <Tooltip id="tooltip-disabled">
             {t('Footer.Login_access')}</Tooltip>}>
      <li className="list-inline-item"><Link to= {isAuthenticated ? '/terms-conditions' : '#'} onClick={() => isAuthenticated ? null : history.push({
    pathname: '/login'
  })}> {t('Footer.Terms_conditions')}</Link>
  </li>
      </OverlayTrigger>
            </ul>


          {/*   <p className="copyright">Atrakxion © {new Date().getFullYear()}</p>*/}
        </footer>
    </div>
      )}
    

    </Fragment>
  );
  
};

Footbar.propTypes = {
  logout: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logout, loadUser }
)(Footbar);
