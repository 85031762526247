import {
    ADD_TRANSFER_SUCCESS,
    ADD_TRANSFER_FAIL,
    UPDATE_TRANSFER,
    UPDATE_TRANSFER_ERROR,
    TRANSFERS_SUCCESS,
    TRANSFERS_FAIL
} from '../actions/types';



const initialState = {
    loading: true,
    transfer: null,
  //  transfers: [],
    error: {}
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_TRANSFER_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                add_transfer: payload
            };
            case ADD_TRANSFER_FAIL:
            return {
                ...state,
                error: payload,
                loading: false
            };

            case UPDATE_TRANSFER:
                return {
                    ...state,
                    ...payload,
                    loading: false,
                    updated_transfer: payload
                };
                case UPDATE_TRANSFER_ERROR:
                return {
                    ...state,
                    error: payload,
                    loading: false
                };

            case TRANSFERS_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                transfers: payload
            };
            case TRANSFERS_FAIL:
            return {
                ...state,
                error: payload,
                loading: false
            };

        default:
            return state;
    }
}