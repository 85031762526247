import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import SponsorshipActionsDemo from './SponsorshipActionsDemo';
import { updateMonth } from '../../actions/sponsorship';
import Tree, { withStyles } from 'react-vertical-tree';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useTranslation } from 'react-i18next';



const SponsorshipTreeDemo = ({ updateMonth, auth: { user, theme }, sponsorship: { month } }) => {

  const [newIndex, setNewIndex] = useState(-1);


  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");




  useEffect(() => {
    if (theme === 'light') {
    document.body.style = 'background: #b9d7f7';
    }
    else {
    document.body.style = 'background: #398DE7';
    }
   // document.body.style = 'background: #b9d7f7'; //#DCDCDC

    // returned function will be called on component unmount 
    return () => {
      // document.body.style = 'background: #D3D3D3'; //#DCDCDC
      document.body.style = null;
    }
  }, [theme])


 /* const styles = {
    lines: {
      color: '#1890ff',
      height: '90px',
    },
    node: {
      backgroundColor: '#1890ff',
      border: '1px solid #1890ff',
    },
    text: {
      color: '#fff',
    }
  } */
  const styles = {
    lines: {
      color: theme === "light" ? '#bc9f51' : '#edd99a',
      height: '90px',
    },
    node: {
      backgroundColor: theme === "light" ? '#bc9f51' : '#edd99a',
      border: '1px solid',
      borderColor: theme === "light" ? '#bc9f51' : '#edd99a',
    },
    text: {
      color: theme === "light" ? '#fff' : '#000',
      fontWeight: 'bold'
    }
  }
  const StyledTree = withStyles(styles)(Tree)



  const { t } = useTranslation();

    var partners = [];
    var sponsorships = [];


   if (month === 1) {
    sponsorships = [{"id":"5f2a900b6ec416001719a351",
    "new_id":1798,
    "parent":1,
    "name":"Okdar",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Barbara",
    "last_name":"CURTIS",
    "country":"GB",
    "gender":"F",
    "date_of_birth":"2002-07-29T10:52:45.000Z",
    "status":"C",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":1,
    "date_creation":"2020-08-20",
    "date_member":"",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]},
    {"id":"5f2a90a16ec416001719a354",
    "new_id":1799,
    "parent":1,
    "name":"Drevon",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Ethan",
    "last_name":"CLARK",
    "country":"GB",
    "gender":"M",
    "date_of_birth":"2000-08-03T10:55:49.000Z",
    "status":"C",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":2,
    "date_creation":"2020-08-25",
    "date_member":"",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]}]

    if (newIndex === -1) {
    partners = [{"_id":"5f2a900b6ec416001719a352",
   "id":"5f2a900b6ec416001719a351",
   "new_id":1798,
   "name":"Okdar",
   "first_name":"Barbara",
   "last_name":"CURTIS",
   "parent":1,
   "parent_id":"5f2a89d86ec416001719a34e",
   "parent_username":"Golden",
   "country":"GB",
   "email":"Okdar@gmail.com",
   "gender":"F",
   "date_of_birth":"2002-07-29T10:52:45.000Z",
   "date_creation":"2020-08-20",
   "date_member":"",
   "date_repositioning":"",
   "confirmed":false,
   "role":1,
   "status":"C",
   "verticalLevel":user.verticalLevel + 1,
   "horizontalLevel":1,
   "children":[]},
       {"_id":"5f2a90a16ec416001719a355",
       "id":"5f2a90a16ec416001719a354",
       "new_id":1799,
       "name":"Drevon",
       "first_name":"Ethan",
       "last_name":"CLARK",
       "parent":1,
       "parent_id":"5f2a89d86ec416001719a34e",
       "parent_username":"Golden",
       "country":"GB",
       "email":"Drevon@gmail.com",
       "gender":"M",
       "date_of_birth":"2000-08-03T10:55:49.000Z",
       "date_creation":"2020-08-25",
       "date_member":"",
       "date_repositioning":"",
       "confirmed":false,
       "role":1,
       "status":"C",
       "verticalLevel":user.verticalLevel + 1,
       "horizontalLevel":2,
       "children":[]}]
    }

    else if (newIndex === 0 || newIndex === 1) {
      partners = []
    }
   }
  else if (month === 2) {

    sponsorships = [{"id":"5f2a900b6ec416001719a351",
    "new_id":1798,
    "parent":1,
    "name":"Okdar",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Barbara",
    "last_name":"CURTIS",
    "country":"GB",
    "gender":"F",
    "date_of_birth":"2002-07-29T10:52:45.000Z",
    "status":"C",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":1,
    "date_creation":"2020-08-20",
    "date_member":"",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]},
    {"id":"5f2a90a16ec416001719a354",
    "new_id":1799,
    "parent":1,
    "name":"Drevon",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Ethan",
    "last_name":"CLARK",
    "country":"GB",
    "gender":"M",
    "date_of_birth":"2000-08-03T10:55:49.000Z",
    "status":"C",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":2,
    "date_creation":"2020-08-25",
    "date_member":"",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]}]

    if (newIndex === -1) {
   partners = [{"_id":"5f2a900b6ec416001719a352",
  "id":"5f2a900b6ec416001719a351",
  "new_id":1798,
  "name":"Okdar",
  "first_name":"Barbara",
  "last_name":"CURTIS",
  "parent":1,
  "parent_id":"5f2a89d86ec416001719a34e",
  "parent_username":"Golden",
  "country":"GB",
  "email":"Okdar@gmail.com",
  "gender":"F",
  "date_of_birth":"2002-07-29T10:52:45.000Z",
  "date_creation":"2020-08-20",
  "date_member":"",
  "date_repositioning":"",
  "confirmed":false,
  "role":1,
  "status":"C",
  "verticalLevel":user.verticalLevel + 1,
  "horizontalLevel":1,
  "children":[{"_id":"5f2a918d6ec416001719a358",
              "id":"5f2a918d6ec416001719a357",
              "new_id":1800,
              "name":"Nagval",
              "first_name":"Diana",
              "last_name":"BROWN",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Nagval@gmail.com",
              "gender":"F",
              "date_of_birth":"1995-08-09T10:59:48.000Z",
              "date_creation":"2020-09-20",
              "date_member":"",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"C",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":1,
              "children":[]},
              {"_id":"5f2a92086ec416001719a35b",
              "id":"5f2a92086ec416001719a35a",
              "new_id":1801,
              "name":"Tarek",
              "first_name":"John",
              "last_name":"DAVIES",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Tarek@gmail.com",
              "gender":"M",
              "date_of_birth":"2002-07-31T11:02:06.000Z",
              "date_creation":"2020-09-25",
              "date_member":"",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"C",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":2,
              "children":[]}]},
      {"_id":"5f2a90a16ec416001719a355",
      "id":"5f2a90a16ec416001719a354",
      "new_id":1799,
      "name":"Drevon",
      "first_name":"Ethan",
      "last_name":"CLARK",
      "parent":1,
      "parent_id":"5f2a89d86ec416001719a34e",
      "parent_username":"Golden",
      "country":"GB",
      "email":"Drevon@gmail.com",
      "gender":"M",
      "date_of_birth":"2000-08-03T10:55:49.000Z",
      "date_creation":"2020-08-25",
      "date_member":"",
      "date_repositioning":"",
      "confirmed":false,
      "role":1,
      "status":"C",
      "verticalLevel":user.verticalLevel + 1,
      "horizontalLevel":2,
      "children":[{"_id":"5f2a92a46ec416001719a35e",
                "id":"5f2a92a36ec416001719a35d",
                "new_id":1802,
                "name":"Zakar",
                "first_name":"Sarah",
                "last_name":"COOPER",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Zakar@gmail.com",
                "gender":"F",
                "date_of_birth":"1998-07-29T11:04:56.000Z",
                "date_creation":"2020-09-20",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"C",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":3,
                "children":[]},
                {"_id":"5f2a930d6ec416001719a361",
                "id":"5f2a930d6ec416001719a360",
                "new_id":1803,
                "name":"Trolek",
                "first_name":"Thomas",
                "last_name":"JACKSON",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Trolek@gmail.com",
                "gender":"M",
                "date_of_birth":"1993-08-23T11:06:20.000Z",
                "date_creation":"2020-09-25",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"C",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":4,
                "children":[]}]}]
              }

              else if (newIndex === 0) {
                partners = [
                  {
                    "_id":"5f2a918d6ec416001719a358",
                    "id":"5f2a918d6ec416001719a357",
                    "new_id":1800,
                    "name":"Nagval",
                    "first_name":"Diana",
                    "last_name":"BROWN",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Nagval@gmail.com",
                    "gender":"F",
                    "date_of_birth":"1995-08-09T10:59:48.000Z",
                    "date_creation":"2020-09-20",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"C",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":1,
                    "children":[]},
                    {"_id":"5f2a92086ec416001719a35b",
                    "id":"5f2a92086ec416001719a35a",
                    "new_id":1801,
                    "name":"Tarek",
                    "first_name":"John",
                    "last_name":"DAVIES",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Tarek@gmail.com",
                    "gender":"M",
                    "date_of_birth":"2002-07-31T11:02:06.000Z",
                    "date_creation":"2020-09-25",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"C",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":2,
                    "children":[]}]
              }

              else if (newIndex === 1) {
                partners = [{
                  "_id":"5f2a92a46ec416001719a35e",
                  "id":"5f2a92a36ec416001719a35d",
                  "new_id":1802,
                  "name":"Zakar",
                  "first_name":"Sarah",
                  "last_name":"COOPER",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Zakar@gmail.com",
                  "gender":"F",
                  "date_of_birth":"1998-07-29T11:04:56.000Z",
                  "date_creation":"2020-09-20",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"C",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":131367,
                  "children":[]},
                  {"_id":"5f2a930d6ec416001719a361",
                  "id":"5f2a930d6ec416001719a360",
                  "new_id":1803,
                  "name":"Trolek",
                  "first_name":"Thomas",
                  "last_name":"JACKSON",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Trolek@gmail.com",
                  "gender":"M",
                  "date_of_birth":"1993-08-23T11:06:20.000Z",
                  "date_creation":"2020-09-25",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"C",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":131368,
                  "children":[]}]
              }
  }
  else if (month === 3) {

    sponsorships = [{"id":"5f2a900b6ec416001719a351",
    "new_id":1798,
    "parent":1,
    "name":"Okdar",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Barbara",
    "last_name":"CURTIS",
    "country":"GB",
    "gender":"F",
    "date_of_birth":"2002-07-29T10:52:45.000Z",
    "status":"M",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":1,
    "date_creation":"2020-08-20",
    "date_member":"2020-10-25",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]},
    {"id":"5f2a90a16ec416001719a354",
    "new_id":1799,
    "parent":1,
    "name":"Drevon",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Ethan",
    "last_name":"CLARK",
    "country":"GB",
    "gender":"M",
    "date_of_birth":"2000-08-03T10:55:49.000Z",
    "status":"M",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":2,
    "date_creation":"2020-08-25",
    "date_member":"2020-10-25",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]}]

    if (newIndex === -1) {
   partners = [{"_id":"5f2a900b6ec416001719a352",
  "id":"5f2a900b6ec416001719a351",
  "new_id":1798,
  "name":"Okdar",
  "first_name":"Barbara",
  "last_name":"CURTIS",
  "parent":1,
  "parent_id":"5f2a89d86ec416001719a34e",
  "parent_username":"Golden",
  "country":"GB",
  "email":"Okdar@gmail.com",
  "gender":"F",
  "date_of_birth":"2002-07-29T10:52:45.000Z",
  "date_creation":"2020-08-20",
  "date_member":"2020-10-25",
  "date_repositioning":"",
  "confirmed":false,
  "role":1,
  "status":"M",
  "verticalLevel":user.verticalLevel + 1,
  "horizontalLevel":1,
  "children":[{"_id":"5f2a918d6ec416001719a358",
              "id":"5f2a918d6ec416001719a357",
              "new_id":1800,
              "name":"Nagval",
              "first_name":"Diana",
              "last_name":"BROWN",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Nagval@gmail.com",
              "gender":"F",
              "date_of_birth":"1995-08-09T10:59:48.000Z",
              "date_creation":"2020-09-20",
              "date_member":"",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"C",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":1,
              "children":[]},
              {"_id":"5f2a92086ec416001719a35b",
              "id":"5f2a92086ec416001719a35a",
              "new_id":1801,
              "name":"Tarek",
              "first_name":"John",
              "last_name":"DAVIES",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Tarek@gmail.com",
              "gender":"M",
              "date_of_birth":"2002-07-31T11:02:06.000Z",
              "date_creation":"2020-09-25",
              "date_member":"",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"C",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":2,
              "children":[]}]},
      {"_id":"5f2a90a16ec416001719a355",
      "id":"5f2a90a16ec416001719a354",
      "new_id":1799,
      "name":"Drevon",
      "first_name":"Ethan",
      "last_name":"CLARK",
      "parent":1,
      "parent_id":"5f2a89d86ec416001719a34e",
      "parent_username":"Golden",
      "country":"GB",
      "email":"Drevon@gmail.com",
      "gender":"M",
      "date_of_birth":"2000-08-03T10:55:49.000Z",
      "date_creation":"2020-08-25",
      "date_member":"2020-10-25",
      "date_repositioning":"",
      "confirmed":false,
      "role":1,
      "status":"M",
      "verticalLevel":user.verticalLevel + 1,
      "horizontalLevel":2,
      "children":[{"_id":"5f2a92a46ec416001719a35e",
                "id":"5f2a92a36ec416001719a35d",
                "new_id":1802,
                "name":"Zakar",
                "first_name":"Sarah",
                "last_name":"COOPER",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Zakar@gmail.com",
                "gender":"F",
                "date_of_birth":"1998-07-29T11:04:56.000Z",
                "date_creation":"2020-09-20",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"C",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":3,
                "children":[]},
                {"_id":"5f2a930d6ec416001719a361",
                "id":"5f2a930d6ec416001719a360",
                "new_id":1803,
                "name":"Trolek",
                "first_name":"Thomas",
                "last_name":"JACKSON",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Trolek@gmail.com",
                "gender":"M",
                "date_of_birth":"1993-08-23T11:06:20.000Z",
                "date_creation":"2020-09-25",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"C",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":4,
                "children":[]}]}]
              }

              else if (newIndex === 0) {
                partners = [
                  {
                    "_id":"5f2a918d6ec416001719a358",
                    "id":"5f2a918d6ec416001719a357",
                    "new_id":1800,
                    "name":"Nagval",
                    "first_name":"Diana",
                    "last_name":"BROWN",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Nagval@gmail.com",
                    "gender":"F",
                    "date_of_birth":"1995-08-09T10:59:48.000Z",
                    "date_creation":"2020-09-20",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"C",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":1,
                    "children":[
                      {"_id":"5f2a946e6ec416001719a364",
                                  "id":"5f2a946e6ec416001719a363",
                                  "new_id":1804,
                                  "name":"Thaok",
                                  "first_name":"Tracy",
                                  "last_name":"EVANS",
                                  "parent":1,
                                  "parent_id":"5f2a918d6ec416001719a357",
                                  "parent_username":"Nagval",
                                  "country":"GB",
                                  "email":"Thaok@gmail.com",
                                  "gender":"F",
                                  "date_of_birth":"1998-08-11T11:12:33.000Z",
                                  "date_creation":"2020-10-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":false,
                                  "role":1,
                                  "status":"C",
                                  "verticalLevel":user.verticalLevel + 3,
                                  "horizontalLevel":1,
                                  "children":[]},
                                  {"_id":"5f2a94c06ec416001719a367",
                                  "id":"5f2a94c06ec416001719a366",
                                  "new_id":1805,
                                  "name":"Nagza",
                                  "first_name":"Alexander",
                                  "last_name":"Miller",
                                  "parent":1,
                                  "parent_id":"5f2a918d6ec416001719a357",
                                  "parent_username":"Nagval",
                                  "country":"GB",
                                  "email":"Nagza@gmail.com",
                                  "gender":"M",
                                  "date_of_birth":"1996-08-14T11:13:55.000Z",
                                  "date_creation":"2020-10-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":false,
                                  "role":1,
                                  "status":"C",
                                  "verticalLevel":user.verticalLevel + 3,
                                  "horizontalLevel":2,
                                  "children":[]}
                    ]},
                    {"_id":"5f2a92086ec416001719a35b",
                    "id":"5f2a92086ec416001719a35a",
                    "new_id":1801,
                    "name":"Tarek",
                    "first_name":"John",
                    "last_name":"DAVIES",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Tarek@gmail.com",
                    "gender":"M",
                    "date_of_birth":"2002-07-31T11:02:06.000Z",
                    "date_creation":"2020-09-25",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"C",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":2,
                    "children":[
                      {
                        "id":"5f2a95676ec416001719a369",
                        "new_id":1806,
                        "name":"Lokuz",
                        "first_name":"Emily",
                        "last_name":"LEWIS",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"F",
                        "date_of_birth":"1995-08-16T11:15:51.000Z",
                        "date_creation":"2020-10-20",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"Lokuz@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":3,
                        "children":[]
                     },
                     {
                        "id":"5f2a95b06ec416001719a36c",
                        "new_id":1807,
                        "name":"Gringo",
                        "first_name":"James",
                        "last_name":"ROBINSON",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"M",
                        "date_of_birth":"1990-08-13T11:18:08.000Z",
                        "date_creation":"2020-10-25",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"Gringo@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":4,
                        "children":[]
                     }
                    ]}]
              }

              else if (newIndex === 1) {
                partners = [{
                  "_id":"5f2a92a46ec416001719a35e",
                  "id":"5f2a92a36ec416001719a35d",
                  "new_id":1802,
                  "name":"Zakar",
                  "first_name":"Sarah",
                  "last_name":"COOPER",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Zakar@gmail.com",
                  "gender":"F",
                  "date_of_birth":"1998-07-29T11:04:56.000Z",
                  "date_creation":"2020-09-20",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"C",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":3,
                  "children":[
                    {
                      "id":"5f2a96a16ec416001719a36f",
                      "new_id":1808,
                      "name":"Stobar",
                      "first_name":"Margaret",
                      "last_name":"MURPHY",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1995-08-09T11:19:43.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"Stobar@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":5,
                      "children":[]
                   },
                   {
                      "id":"5f2a98637fbfdd001758266a",
                      "new_id":1809,
                      "name":"Kronos",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"1990-08-21T11:28:15.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"Kronos@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":6,
                      "children":[]
                   }
                  ]},
                  {"_id":"5f2a930d6ec416001719a361",
                  "id":"5f2a930d6ec416001719a360",
                  "new_id":1803,
                  "name":"Trolek",
                  "first_name":"Thomas",
                  "last_name":"JACKSON",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Trolek@gmail.com",
                  "gender":"M",
                  "date_of_birth":"1993-08-23T11:06:20.000Z",
                  "date_creation":"2020-09-25",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"C",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":4,
                  "children":[
                    {
                      "id":"5f2ab39f7fbfdd0017582673",
                      "new_id":1812,
                      "name":"Raguz",
                      "first_name":"Emma",
                      "last_name":"SMITH",
                      "parent":1,
                      "parent_id":"5f2a930d6ec416001719a360",
                      "parent_username":"Trolek",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1997-08-05T13:23:07.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"Raguz@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":7,
                      "children":[]
                   },
                   {
                      "id":"5f2ab3f17fbfdd0017582676",
                      "new_id":1813,
                      "name":"Dalton",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a930d6ec416001719a360",
                      "parent_username":"Trolek",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"2002-08-05T13:27:12.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"Dalton@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":8,
                      "children":[]
                   }
                  ]}]
              }
  }
  else if (month === 4) {

    sponsorships = [{"id":"5f2a900b6ec416001719a351",
    "new_id":1798,
    "parent":1,
    "name":"Okdar",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Barbara",
    "last_name":"CURTIS",
    "country":"GB",
    "gender":"F",
    "date_of_birth":"2002-07-29T10:52:45.000Z",
    "status":"M",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":1,
    "date_creation":"2020-08-20",
    "date_member":"2020-10-25",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]},
    {"id":"5f2a90a16ec416001719a354",
    "new_id":1799,
    "parent":1,
    "name":"Drevon",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Ethan",
    "last_name":"CLARK",
    "country":"GB",
    "gender":"M",
    "date_of_birth":"2000-08-03T10:55:49.000Z",
    "status":"M",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":2,
    "date_creation":"2020-08-25",
    "date_member":"2020-10-25",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]}]

    if (newIndex === -1) {
   partners = [{"_id":"5f2a900b6ec416001719a352",
  "id":"5f2a900b6ec416001719a351",
  "new_id":1798,
  "name":"Okdar",
  "first_name":"Barbara",
  "last_name":"CURTIS",
  "parent":1,
  "parent_id":"5f2a89d86ec416001719a34e",
  "parent_username":"Golden",
  "country":"GB",
  "email":"Okdar@gmail.com",
  "gender":"F",
  "date_of_birth":"2002-07-29T10:52:45.000Z",
  "date_creation":"2020-08-20",
  "date_member":"2020-10-25",
  "date_repositioning":"",
  "confirmed":false,
  "role":1,
  "status":"M",
  "verticalLevel":user.verticalLevel + 1,
  "horizontalLevel":1,
  "children":[{"_id":"5f2a918d6ec416001719a358",
              "id":"5f2a918d6ec416001719a357",
              "new_id":1800,
              "name":"Nagval",
              "first_name":"Diana",
              "last_name":"BROWN",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Nagval@gmail.com",
              "gender":"F",
              "date_of_birth":"1995-08-09T10:59:48.000Z",
              "date_creation":"2020-09-20",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"M",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":1,
              "children":[]},
              {"_id":"5f2a92086ec416001719a35b",
              "id":"5f2a92086ec416001719a35a",
              "new_id":1801,
              "name":"Tarek",
              "first_name":"John",
              "last_name":"DAVIES",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Tarek@gmail.com",
              "gender":"M",
              "date_of_birth":"2002-07-31T11:02:06.000Z",
              "date_creation":"2020-09-25",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"M",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":2,
              "children":[]}]},
      {"_id":"5f2a90a16ec416001719a355",
      "id":"5f2a90a16ec416001719a354",
      "new_id":1799,
      "name":"Drevon",
      "first_name":"Ethan",
      "last_name":"CLARK",
      "parent":1,
      "parent_id":"5f2a89d86ec416001719a34e",
      "parent_username":"Golden",
      "country":"GB",
      "email":"Drevon@gmail.com",
      "gender":"M",
      "date_of_birth":"2000-08-03T10:55:49.000Z",
      "date_creation":"2020-08-25",
      "date_member":"2020-10-25",
      "date_repositioning":"",
      "confirmed":false,
      "role":1,
      "status":"M",
      "verticalLevel":user.verticalLevel + 1,
      "horizontalLevel":2,
      "children":[{"_id":"5f2a92a46ec416001719a35e",
                "id":"5f2a92a36ec416001719a35d",
                "new_id":1802,
                "name":"Zakar",
                "first_name":"Sarah",
                "last_name":"COOPER",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Zakar@gmail.com",
                "gender":"F",
                "date_of_birth":"1998-07-29T11:04:56.000Z",
                "date_creation":"2020-09-20",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"M",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":3,
                "children":[]},
                {"_id":"5f2a930d6ec416001719a361",
                "id":"5f2a930d6ec416001719a360",
                "new_id":1803,
                "name":"Trolek",
                "first_name":"Thomas",
                "last_name":"JACKSON",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Trolek@gmail.com",
                "gender":"M",
                "date_of_birth":"1993-08-23T11:06:20.000Z",
                "date_creation":"2020-09-25",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"M",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":4,
                "children":[]}]}]
              }

              else if (newIndex === 0) {
                partners = [
                  {
                    "_id":"5f2a918d6ec416001719a358",
                    "id":"5f2a918d6ec416001719a357",
                    "new_id":1800,
                    "name":"Nagval",
                    "first_name":"Diana",
                    "last_name":"BROWN",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Nagval@gmail.com",
                    "gender":"F",
                    "date_of_birth":"1995-08-09T10:59:48.000Z",
                    "date_creation":"2020-09-20",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"M",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":1,
                    "children":[
                      {"_id":"5f2a946e6ec416001719a364",
                                  "id":"5f2a946e6ec416001719a363",
                                  "new_id":1804,
                                  "name":"Thaok",
                                  "first_name":"Tracy",
                                  "last_name":"EVANS",
                                  "parent":1,
                                  "parent_id":"5f2a918d6ec416001719a357",
                                  "parent_username":"Nagval",
                                  "country":"GB",
                                  "email":"Thaok@gmail.com",
                                  "gender":"F",
                                  "date_of_birth":"1998-08-11T11:12:33.000Z",
                                  "date_creation":"2020-10-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":false,
                                  "role":1,
                                  "status":"C",
                                  "verticalLevel":user.verticalLevel + 3,
                                  "horizontalLevel":1,
                                  "children":[]},
                                  {"_id":"5f2a94c06ec416001719a367",
                                  "id":"5f2a94c06ec416001719a366",
                                  "new_id":1805,
                                  "name":"Nagza",
                                  "first_name":"Alexander",
                                  "last_name":"Miller",
                                  "parent":1,
                                  "parent_id":"5f2a918d6ec416001719a357",
                                  "parent_username":"Nagval",
                                  "country":"GB",
                                  "email":"Nagza@gmail.com",
                                  "gender":"M",
                                  "date_of_birth":"1996-08-14T11:13:55.000Z",
                                  "date_creation":"2020-10-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":false,
                                  "role":1,
                                  "status":"C",
                                  "verticalLevel":user.verticalLevel + 3,
                                  "horizontalLevel":2,
                                  "children":[]}
                    ]},
                    {"_id":"5f2a92086ec416001719a35b",
                    "id":"5f2a92086ec416001719a35a",
                    "new_id":1801,
                    "name":"Tarek",
                    "first_name":"John",
                    "last_name":"DAVIES",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Tarek@gmail.com",
                    "gender":"M",
                    "date_of_birth":"2002-07-31T11:02:06.000Z",
                    "date_creation":"2020-08-05",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"M",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":2,
                    "children":[
                      {
                        "id":"5f2a95676ec416001719a369",
                        "new_id":1806,
                        "name":"Lokuz",
                        "first_name":"Emily",
                        "last_name":"LEWIS",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"F",
                        "date_of_birth":"1995-08-16T11:15:51.000Z",
                        "date_creation":"2020-10-20",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"Lokuz@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":3,
                        "children":[]
                     },
                     {
                        "id":"5f2a95b06ec416001719a36c",
                        "new_id":1807,
                        "name":"Gringo",
                        "first_name":"James",
                        "last_name":"ROBINSON",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"M",
                        "date_of_birth":"1990-08-13T11:18:08.000Z",
                        "date_creation":"2020-10-25",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"Gringo@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":4,
                        "children":[]
                     }
                    ]}]
              }

              else if (newIndex === 1) {
                partners = [{
                  "_id":"5f2a92a46ec416001719a35e",
                  "id":"5f2a92a36ec416001719a35d",
                  "new_id":1802,
                  "name":"Zakar",
                  "first_name":"Sarah",
                  "last_name":"COOPER",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Zakar@gmail.com",
                  "gender":"F",
                  "date_of_birth":"1998-07-29T11:04:56.000Z",
                  "date_creation":"2020-09-20",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"M",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":3,
                  "children":[
                    {
                      "id":"5f2a96a16ec416001719a36f",
                      "new_id":1808,
                      "name":"Stobar",
                      "first_name":"Margaret",
                      "last_name":"MURPHY",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1995-08-09T11:19:43.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"Stobar@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":5,
                      "children":[]
                   },
                   {
                      "id":"5f2a98637fbfdd001758266a",
                      "new_id":1809,
                      "name":"Kronos",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"1990-08-21T11:28:15.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"Kronos@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":6,
                      "children":[]
                   }
                  ]},
                  {"_id":"5f2a930d6ec416001719a361",
                  "id":"5f2a930d6ec416001719a360",
                  "new_id":1803,
                  "name":"Trolek",
                  "first_name":"Thomas",
                  "last_name":"JACKSON",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Trolek@gmail.com",
                  "gender":"M",
                  "date_of_birth":"1993-08-23T11:06:20.000Z",
                  "date_creation":"2020-09-25",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"M",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":4,
                  "children":[
                    {
                      "id":"5f2ab39f7fbfdd0017582673",
                      "new_id":1812,
                      "name":"Raguz",
                      "first_name":"Emma",
                      "last_name":"SMITH",
                      "parent":1,
                      "parent_id":"5f2a930d6ec416001719a360",
                      "parent_username":"Trolek",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1997-08-05T13:23:07.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"Raguz@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":7,
                      "children":[]
                   },
                   {
                      "id":"5f2ab3f17fbfdd0017582676",
                      "new_id":1813,
                      "name":"Dalton",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a930d6ec416001719a360",
                      "parent_username":"Trolek",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"2002-08-05T13:27:12.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"Dalton@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":8,
                      "children":[]
                   }
                  ]}]
              }
  }
  else if (month === 5 || month === 6) {

    sponsorships = [{"id":"5f2a900b6ec416001719a351",
    "new_id":1798,
    "parent":1,
    "name":"Okdar",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Barbara",
    "last_name":"CURTIS",
    "country":"GB",
    "gender":"F",
    "date_of_birth":"2002-07-29T10:52:45.000Z",
    "status":"M",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":1,
    "date_creation":"2020-08-20",
    "date_member":"2020-10-25",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]},
    {"id":"5f2a90a16ec416001719a354",
    "new_id":1799,
    "parent":1,
    "name":"Drevon",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Ethan",
    "last_name":"CLARK",
    "country":"GB",
    "gender":"M",
    "date_of_birth":"2000-08-03T10:55:49.000Z",
    "status":"M",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":2,
    "date_creation":"2020-08-25",
    "date_member":"2020-10-25",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]}]

    if (newIndex === -1) {
   partners = [{"_id":"5f2a900b6ec416001719a352",
  "id":"5f2a900b6ec416001719a351",
  "new_id":1798,
  "name":"Okdar",
  "first_name":"Barbara",
  "last_name":"CURTIS",
  "parent":1,
  "parent_id":"5f2a89d86ec416001719a34e",
  "parent_username":"Golden",
  "country":"GB",
  "email":"Okdar@gmail.com",
  "gender":"F",
  "date_of_birth":"2002-07-29T10:52:45.000Z",
  "date_creation":"2020-08-20",
  "date_member":"2020-10-25",
  "date_repositioning":"",
  "confirmed":false,
  "role":1,
  "status":"M",
  "verticalLevel":user.verticalLevel + 1,
  "horizontalLevel":1,
  "children":[{"_id":"5f2a918d6ec416001719a358",
              "id":"5f2a918d6ec416001719a357",
              "new_id":1800,
              "name":"Nagval",
              "first_name":"Diana",
              "last_name":"BROWN",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Nagval@gmail.com",
              "gender":"F",
              "date_of_birth":"1995-08-09T10:59:48.000Z",
              "date_creation":"2020-09-20",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"M",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":1,
              "children":[]},
              {"_id":"5f2a92086ec416001719a35b",
              "id":"5f2a92086ec416001719a35a",
              "new_id":1801,
              "name":"Tarek",
              "first_name":"John",
              "last_name":"DAVIES",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Tarek@gmail.com",
              "gender":"M",
              "date_of_birth":"2002-07-31T11:02:06.000Z",
              "date_creation":"2020-09-25",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"M",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":2,
              "children":[]}]},
      {"_id":"5f2a90a16ec416001719a355",
      "id":"5f2a90a16ec416001719a354",
      "new_id":1799,
      "name":"Drevon",
      "first_name":"Ethan",
      "last_name":"CLARK",
      "parent":1,
      "parent_id":"5f2a89d86ec416001719a34e",
      "parent_username":"Golden",
      "country":"GB",
      "email":"Drevon@gmail.com",
      "gender":"M",
      "date_of_birth":"2000-08-03T10:55:49.000Z",
      "date_creation":"2020-08-25",
      "date_member":"2020-10-25",
      "date_repositioning":"",
      "confirmed":false,
      "role":1,
      "status":"M",
      "verticalLevel":user.verticalLevel + 1,
      "horizontalLevel":2,
      "children":[{"_id":"5f2a92a46ec416001719a35e",
                "id":"5f2a92a36ec416001719a35d",
                "new_id":1802,
                "name":"Zakar",
                "first_name":"Sarah",
                "last_name":"COOPER",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Zakar@gmail.com",
                "gender":"F",
                "date_of_birth":"1998-07-29T11:04:56.000Z",
                "date_creation":"2020-09-20",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"M",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":3,
                "children":[]},
                {"_id":"5f2a930d6ec416001719a361",
                "id":"5f2a930d6ec416001719a360",
                "new_id":1803,
                "name":"Trolek",
                "first_name":"Thomas",
                "last_name":"JACKSON",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Trolek@gmail.com",
                "gender":"M",
                "date_of_birth":"1993-08-23T11:06:20.000Z",
                "date_creation":"2020-09-25",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"M",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":4,
                "children":[]}]}]
              }

              else if (newIndex === 0) {
                partners = [
                  {
                    "_id":"5f2a918d6ec416001719a358",
                    "id":"5f2a918d6ec416001719a357",
                    "new_id":1800,
                    "name":"Nagval",
                    "first_name":"Diana",
                    "last_name":"BROWN",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Nagval@gmail.com",
                    "gender":"F",
                    "date_of_birth":"1995-08-09T10:59:48.000Z",
                    "date_creation":"2020-09-20",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"M",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":1,
                    "children":[
                      {"_id":"5f2a946e6ec416001719a364",
                                  "id":"5f2a946e6ec416001719a363",
                                  "new_id":1804,
                                  "name":"Thaok",
                                  "first_name":"Tracy",
                                  "last_name":"EVANS",
                                  "parent":1,
                                  "parent_id":"5f2a918d6ec416001719a357",
                                  "parent_username":"Nagval",
                                  "country":"GB",
                                  "email":"Thaok@gmail.com",
                                  "gender":"F",
                                  "date_of_birth":"1998-08-11T11:12:33.000Z",
                                  "date_creation":"2020-10-20",
                                  "date_member":"2020-12-25",
                                  "date_repositioning":"",
                                  "confirmed":false,
                                  "role":1,
                                  "status":"M",
                                  "verticalLevel":user.verticalLevel + 3,
                                  "horizontalLevel":1,
                                  "children":[]},
                                  {"_id":"5f2a94c06ec416001719a367",
                                  "id":"5f2a94c06ec416001719a366",
                                  "new_id":1805,
                                  "name":"Nagza",
                                  "first_name":"Alexander",
                                  "last_name":"Miller",
                                  "parent":1,
                                  "parent_id":"5f2a918d6ec416001719a357",
                                  "parent_username":"Nagval",
                                  "country":"GB",
                                  "email":"Nagza@gmail.com",
                                  "gender":"M",
                                  "date_of_birth":"1996-08-14T11:13:55.000Z",
                                  "date_creation":"2020-10-25",
                                  "date_member":"2020-12-25",
                                  "date_repositioning":"",
                                  "confirmed":false,
                                  "role":1,
                                  "status":"M",
                                  "verticalLevel":user.verticalLevel + 3,
                                  "horizontalLevel":2,
                                  "children":[]}
                    ]},
                    {"_id":"5f2a92086ec416001719a35b",
                    "id":"5f2a92086ec416001719a35a",
                    "new_id":1801,
                    "name":"Tarek",
                    "first_name":"John",
                    "last_name":"DAVIES",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Tarek@gmail.com",
                    "gender":"M",
                    "date_of_birth":"2002-07-31T11:02:06.000Z",
                    "date_creation":"2020-08-05",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"M",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":2,
                    "children":[
                      {
                        "id":"5f2a95676ec416001719a369",
                        "new_id":1806,
                        "name":"Lokuz",
                        "first_name":"Emily",
                        "last_name":"LEWIS",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"F",
                        "date_of_birth":"1995-08-16T11:15:51.000Z",
                        "date_creation":"2020-10-20",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Lokuz@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":3,
                        "children":[]
                     },
                     {
                        "id":"5f2a95b06ec416001719a36c",
                        "new_id":1807,
                        "name":"Gringo",
                        "first_name":"James",
                        "last_name":"ROBINSON",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"M",
                        "date_of_birth":"1990-08-13T11:18:08.000Z",
                        "date_creation":"2020-10-25",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Gringo@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":4,
                        "children":[]
                     }
                    ]}]
              }

              else if (newIndex === 1) {
                partners = [{
                  "_id":"5f2a92a46ec416001719a35e",
                  "id":"5f2a92a36ec416001719a35d",
                  "new_id":1802,
                  "name":"Zakar",
                  "first_name":"Sarah",
                  "last_name":"COOPER",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Zakar@gmail.com",
                  "gender":"F",
                  "date_of_birth":"1998-07-29T11:04:56.000Z",
                  "date_creation":"2020-09-20",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"M",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":3,
                  "children":[
                    {
                      "id":"5f2a96a16ec416001719a36f",
                      "new_id":1808,
                      "name":"Stobar",
                      "first_name":"Margaret",
                      "last_name":"MURPHY",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1995-08-09T11:19:43.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Stobar@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":5,
                      "children":[]
                   },
                   {
                      "id":"5f2a98637fbfdd001758266a",
                      "new_id":1809,
                      "name":"Kronos",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"1990-08-21T11:28:15.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Kronos@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":6,
                      "children":[]
                   }
                  ]},
                  {"_id":"5f2a930d6ec416001719a361",
                  "id":"5f2a930d6ec416001719a360",
                  "new_id":1803,
                  "name":"Trolek",
                  "first_name":"Thomas",
                  "last_name":"JACKSON",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Trolek@gmail.com",
                  "gender":"M",
                  "date_of_birth":"1993-08-23T11:06:20.000Z",
                  "date_creation":"2020-09-25",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"M",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":4,
                  "children":[
                    {
                      "id":"5f2ab39f7fbfdd0017582673",
                      "new_id":1812,
                      "name":"Raguz",
                      "first_name":"Emma",
                      "last_name":"SMITH",
                      "parent":1,
                      "parent_id":"5f2a930d6ec416001719a360",
                      "parent_username":"Trolek",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1997-08-05T13:23:07.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Raguz@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":7,
                      "children":[]
                   },
                   {
                      "id":"5f2ab3f17fbfdd0017582676",
                      "new_id":1813,
                      "name":"Dalton",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a930d6ec416001719a360",
                      "parent_username":"Trolek",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"2002-08-05T13:27:12.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Dalton@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":8,
                      "children":[]
                   }
                  ]}]
              }
  }

  else if (month === 7) {

    sponsorships = [{"id":"5f2a900b6ec416001719a351",
    "new_id":1798,
    "parent":1,
    "name":"Okdar",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Barbara",
    "last_name":"CURTIS",
    "country":"GB",
    "gender":"F",
    "date_of_birth":"2002-07-29T10:52:45.000Z",
    "status":"M",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":1,
    "date_creation":"2020-08-20",
    "date_member":"2020-10-25",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]},
    {"id":"5f2a90a16ec416001719a354",
    "new_id":1799,
    "parent":1,
    "name":"Drevon",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Ethan",
    "last_name":"CLARK",
    "country":"GB",
    "gender":"M",
    "date_of_birth":"2000-08-03T10:55:49.000Z",
    "status":"M",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":2,
    "date_creation":"2020-08-25",
    "date_member":"2020-10-25",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]}]

    if (newIndex === -1) {
   partners = [{"_id":"5f2a900b6ec416001719a352",
  "id":"5f2a900b6ec416001719a351",
  "new_id":1798,
  "name":"Okdar",
  "first_name":"Barbara",
  "last_name":"CURTIS",
  "parent":1,
  "parent_id":"5f2a89d86ec416001719a34e",
  "parent_username":"Golden",
  "country":"GB",
  "email":"Okdar@gmail.com",
  "gender":"F",
  "date_of_birth":"2002-07-29T10:52:45.000Z",
  "date_creation":"2020-08-20",
  "date_member":"2020-10-25",
  "date_repositioning":"",
  "confirmed":false,
  "role":1,
  "status":"M",
  "verticalLevel":user.verticalLevel + 1,
  "horizontalLevel":1,
  "children":[{"_id":"5f2a918d6ec416001719a358",
              "id":"5f2a918d6ec416001719a357",
              "new_id":1800,
              "name":"Nagval",
              "first_name":"Diana",
              "last_name":"BROWN",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Nagval@gmail.com",
              "gender":"F",
              "date_of_birth":"1995-08-09T10:59:48.000Z",
              "date_creation":"2020-09-20",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"M",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":1,
              "children":[]},
              {"_id":"5f2a92086ec416001719a35b",
              "id":"5f2a92086ec416001719a35a",
              "new_id":1801,
              "name":"Tarek",
              "first_name":"John",
              "last_name":"DAVIES",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Tarek@gmail.com",
              "gender":"M",
              "date_of_birth":"2002-07-31T11:02:06.000Z",
              "date_creation":"2020-09-25",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"M",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":2,
              "children":[]}]},
      {"_id":"5f2a90a16ec416001719a355",
      "id":"5f2a90a16ec416001719a354",
      "new_id":1799,
      "name":"Drevon",
      "first_name":"Ethan",
      "last_name":"CLARK",
      "parent":1,
      "parent_id":"5f2a89d86ec416001719a34e",
      "parent_username":"Golden",
      "country":"GB",
      "email":"Drevon@gmail.com",
      "gender":"M",
      "date_of_birth":"2000-08-03T10:55:49.000Z",
      "date_creation":"2020-08-25",
      "date_member":"2020-10-25",
      "date_repositioning":"",
      "confirmed":false,
      "role":1,
      "status":"M",
      "verticalLevel":user.verticalLevel + 1,
      "horizontalLevel":2,
      "children":[{"_id":"5f2a92a46ec416001719a35e",
                "id":"5f2a92a36ec416001719a35d",
                "new_id":1802,
                "name":"Zakar",
                "first_name":"Sarah",
                "last_name":"COOPER",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Zakar@gmail.com",
                "gender":"F",
                "date_of_birth":"1998-07-29T11:04:56.000Z",
                "date_creation":"2020-09-20",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"M",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":3,
                "children":[]},
                {"_id":"5f2a930d6ec416001719a361",
                "id":"5f2a930d6ec416001719a360",
                "new_id":1803,
                "name":"Trolek",
                "first_name":"Thomas",
                "last_name":"JACKSON",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Trolek@gmail.com",
                "gender":"M",
                "date_of_birth":"1993-08-23T11:06:20.000Z",
                "date_creation":"2020-09-25",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"M",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":4,
                "children":[]}]}]
              }

              else if (newIndex === 0) {
                partners = [
                  {
                    "_id":"5f2a918d6ec416001719a358",
                    "id":"5f2a918d6ec416001719a357",
                    "new_id":1800,
                    "name":"Nagval",
                    "first_name":"Diana",
                    "last_name":"BROWN",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Nagval@gmail.com",
                    "gender":"F",
                    "date_of_birth":"1995-08-09T10:59:48.000Z",
                    "date_creation":"2020-09-20",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"M",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":1,
                    "children":[
                      {"_id":"5f2a946e6ec416001719a364",
                                  "id":"5f2a946e6ec416001719a363",
                                  "new_id":1804,
                                  "name":"Thaok",
                                  "first_name":"Tracy",
                                  "last_name":"EVANS",
                                  "parent":1,
                                  "parent_id":"5f2a918d6ec416001719a357",
                                  "parent_username":"Nagval",
                                  "country":"GB",
                                  "email":"Thaok@gmail.com",
                                  "gender":"F",
                                  "date_of_birth":"1998-08-11T11:12:33.000Z",
                                  "date_creation":"2020-10-20",
                                  "date_member":"2020-12-25",
                                  "date_repositioning":"",
                                  "confirmed":false,
                                  "role":1,
                                  "status":"M",
                                  "verticalLevel":user.verticalLevel + 3,
                                  "horizontalLevel":1,
                                  "children":[]},
                                  {"_id":"5f2a94c06ec416001719a367",
                                  "id":"5f2a94c06ec416001719a366",
                                  "new_id":1805,
                                  "name":"Nagza",
                                  "first_name":"Alexander",
                                  "last_name":"Miller",
                                  "parent":1,
                                  "parent_id":"5f2a918d6ec416001719a357",
                                  "parent_username":"Nagval",
                                  "country":"GB",
                                  "email":"Nagza@gmail.com",
                                  "gender":"M",
                                  "date_of_birth":"1996-08-14T11:13:55.000Z",
                                  "date_creation":"2020-10-25",
                                  "date_member":"2020-12-25",
                                  "date_repositioning":"",
                                  "confirmed":false,
                                  "role":1,
                                  "status":"M",
                                  "verticalLevel":user.verticalLevel + 3,
                                  "horizontalLevel":2,
                                  "children":[]}
                    ]},
                    {"_id":"5f2a92086ec416001719a35b",
                    "id":"5f2a92086ec416001719a35a",
                    "new_id":1801,
                    "name":"Tarek",
                    "first_name":"John",
                    "last_name":"DAVIES",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Tarek@gmail.com",
                    "gender":"M",
                    "date_of_birth":"2002-07-31T11:02:06.000Z",
                    "date_creation":"2020-08-05",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"M",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":2,
                    "children":[
                      {
                        "id":"5f2a95676ec416001719a369",
                        "new_id":1806,
                        "name":"Lokuz",
                        "first_name":"Emily",
                        "last_name":"LEWIS",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"F",
                        "date_of_birth":"1995-08-16T11:15:51.000Z",
                        "date_creation":"2020-10-20",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Lokuz@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":3,
                        "children":[]
                     },
                     {
                        "id":"5f2a95b06ec416001719a36c",
                        "new_id":1807,
                        "name":"Gringo",
                        "first_name":"James",
                        "last_name":"ROBINSON",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"M",
                        "date_of_birth":"1990-08-13T11:18:08.000Z",
                        "date_creation":"2020-10-25",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Gringo@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":4,
                        "children":[]
                     }
                    ]}]
              }

              else if (newIndex === 1) {
                partners = [{
                  "_id":"5f2a92a46ec416001719a35e",
                  "id":"5f2a92a36ec416001719a35d",
                  "new_id":1802,
                  "name":"Zakar",
                  "first_name":"Sarah",
                  "last_name":"COOPER",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Zakar@gmail.com",
                  "gender":"F",
                  "date_of_birth":"1998-07-29T11:04:56.000Z",
                  "date_creation":"2020-09-20",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"M",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":3,
                  "children":[
                    {
                      "id":"5f2a96a16ec416001719a36f",
                      "new_id":1808,
                      "name":"Stobar",
                      "first_name":"Margaret",
                      "last_name":"MURPHY",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1995-08-09T11:19:43.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Stobar@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":5,
                      "children":[]
                   },
                   {
                      "id":"5f2a98637fbfdd001758266a",
                      "new_id":1809,
                      "name":"Kronos",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"1990-08-21T11:28:15.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Kronos@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":6,
                      "children":[]
                   }
                  ]},
                  {"_id":"5f2a930d6ec416001719a361",
                  "id":"5f2a930d6ec416001719a360",
                  "new_id":1803,
                  "name":"Trolek",
                  "first_name":"Thomas",
                  "last_name":"JACKSON",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Trolek@gmail.com",
                  "gender":"M",
                  "date_of_birth":"1993-08-23T11:06:20.000Z",
                  "date_creation":"2020-09-25",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"M",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":4,
                  "children":[
                    {
                      "id":"5f2ab39f7fbfdd0017582673",
                      "new_id":1812,
                      "name":"Raguz",
                      "first_name":"Emma",
                      "last_name":"SMITH",
                      "parent":1,
                      "parent_id":"5f2a930d6ec416001719a360",
                      "parent_username":"Trolek",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1997-08-05T13:23:07.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Raguz@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":7,
                      "children":[]
                   },
                   {
                      "id":"5f2ab3f17fbfdd0017582676",
                      "new_id":1813,
                      "name":"Dalton",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a930d6ec416001719a360",
                      "parent_username":"Trolek",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"2002-08-05T13:27:12.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Dalton@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":8,
                      "children":[]
                   }
                  ]}]
              }
  }

  else if (month === 8) {

    sponsorships = [{"id":"5f2a900b6ec416001719a351",
    "new_id":1798,
    "parent":1,
    "name":"Okdar*",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Barbara",
    "last_name":"CURTIS",
    "country":"GB",
    "gender":"F",
    "date_of_birth":"2002-07-29T10:52:45.000Z",
    "status":"M*",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":1,
    "date_creation":"2020-08-20",
    "date_member":"2020-10-25,2021-03-25",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]},
    {"id":"5f2a90a16ec416001719a354",
    "new_id":1799,
    "parent":1,
    "name":"Drevon*",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Ethan",
    "last_name":"CLARK",
    "country":"GB",
    "gender":"M",
    "date_of_birth":"2000-08-03T10:55:49.000Z",
    "status":"M*",
    "verticalLevel":user.verticalLevel + 1,
    "horizontalLevel":2,
    "date_creation":"2020-08-25",
    "date_member":"2020-10-25,2021-03-25",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]}]

    if (newIndex === -1) {
   partners = [{"_id":"5f2a900b6ec416001719a352",
  "id":"5f2a900b6ec416001719a351",
  "new_id":1798,
  "name":"Okdar*",
  "first_name":"Barbara",
  "last_name":"CURTIS",
  "parent":1,
  "parent_id":"5f2a89d86ec416001719a34e",
  "parent_username":"Golden",
  "country":"GB",
  "email":"Okdar@gmail.com",
  "gender":"F",
  "date_of_birth":"2002-07-29T10:52:45.000Z",
  "date_creation":"2020-08-20",
  "date_member":"2020-10-25,2021-03-25",
  "date_repositioning":"",
  "confirmed":false,
  "role":1,
  "status":"M*",
  "verticalLevel":user.verticalLevel + 1,
  "horizontalLevel":1,
  "children":[{"_id":"5f2a918d6ec416001719a358",
              "id":"5f2a918d6ec416001719a357",
              "new_id":1800,
              "name":"Nagval",
              "first_name":"Diana",
              "last_name":"BROWN",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Nagval@gmail.com",
              "gender":"F",
              "date_of_birth":"1995-08-09T10:59:48.000Z",
              "date_creation":"2020-09-20",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"M",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":1,
              "children":[]},
              {"_id":"5f2a92086ec416001719a35b",
              "id":"5f2a92086ec416001719a35a",
              "new_id":1801,
              "name":"Tarek",
              "first_name":"John",
              "last_name":"DAVIES",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "email":"Tarek@gmail.com",
              "gender":"M",
              "date_of_birth":"2002-07-31T11:02:06.000Z",
              "date_creation":"2020-09-25",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":false,
              "role":1,
              "status":"M",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":2,
              "children":[]}]},
      {"_id":"5f2a90a16ec416001719a355",
      "id":"5f2a90a16ec416001719a354",
      "new_id":1799,
      "name":"Drevon*",
      "first_name":"Ethan",
      "last_name":"CLARK",
      "parent":1,
      "parent_id":"5f2a89d86ec416001719a34e",
      "parent_username":"Golden",
      "country":"GB",
      "email":"Drevon@gmail.com",
      "gender":"M",
      "date_of_birth":"2000-08-03T10:55:49.000Z",
      "date_creation":"2020-08-25",
      "date_member":"2020-10-25,2021-03-25",
      "date_repositioning":"",
      "confirmed":false,
      "role":1,
      "status":"M*",
      "verticalLevel":user.verticalLevel + 1,
      "horizontalLevel":2,
      "children":[{"_id":"5f2a92a46ec416001719a35e",
                "id":"5f2a92a36ec416001719a35d",
                "new_id":1802,
                "name":"Zakar",
                "first_name":"Sarah",
                "last_name":"COOPER",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Zakar@gmail.com",
                "gender":"F",
                "date_of_birth":"1998-07-29T11:04:56.000Z",
                "date_creation":"2020-09-20",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"M",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":3,
                "children":[]},
                {"_id":"5f2a930d6ec416001719a361",
                "id":"5f2a930d6ec416001719a360",
                "new_id":1803,
                "name":"Trolek",
                "first_name":"Thomas",
                "last_name":"JACKSON",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "email":"Trolek@gmail.com",
                "gender":"M",
                "date_of_birth":"1993-08-23T11:06:20.000Z",
                "date_creation":"2020-09-25",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"M",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":4,
                "children":[]}]}]
              }

              else if (newIndex === 0) {
                partners = [
                  {
                    "_id":"5f2a918d6ec416001719a358",
                    "id":"5f2a918d6ec416001719a357",
                    "new_id":1800,
                    "name":"Nagval",
                    "first_name":"Diana",
                    "last_name":"BROWN",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Nagval@gmail.com",
                    "gender":"F",
                    "date_of_birth":"1995-08-09T10:59:48.000Z",
                    "date_creation":"2020-09-20",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"M",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":1,
                    "children":[
                      {"_id":"5f2a946e6ec416001719a364",
                                  "id":"5f2a946e6ec416001719a363",
                                  "new_id":1804,
                                  "name":"Thaok",
                                  "first_name":"Tracy",
                                  "last_name":"EVANS",
                                  "parent":1,
                                  "parent_id":"5f2a918d6ec416001719a357",
                                  "parent_username":"Nagval",
                                  "country":"GB",
                                  "email":"Thaok@gmail.com",
                                  "gender":"F",
                                  "date_of_birth":"1998-08-11T11:12:33.000Z",
                                  "date_creation":"2020-10-20",
                                  "date_member":"2020-12-25",
                                  "date_repositioning":"",
                                  "confirmed":false,
                                  "role":1,
                                  "status":"M",
                                  "verticalLevel":user.verticalLevel + 3,
                                  "horizontalLevel":1,
                                  "children":[]},
                                  {"_id":"5f2a94c06ec416001719a367",
                                  "id":"5f2a94c06ec416001719a366",
                                  "new_id":1805,
                                  "name":"Nagza",
                                  "first_name":"Alexander",
                                  "last_name":"Miller",
                                  "parent":1,
                                  "parent_id":"5f2a918d6ec416001719a357",
                                  "parent_username":"Nagval",
                                  "country":"GB",
                                  "email":"Nagza@gmail.com",
                                  "gender":"M",
                                  "date_of_birth":"1996-08-14T11:13:55.000Z",
                                  "date_creation":"2020-10-25",
                                  "date_member":"2020-12-25",
                                  "date_repositioning":"",
                                  "confirmed":false,
                                  "role":1,
                                  "status":"M",
                                  "verticalLevel":user.verticalLevel + 3,
                                  "horizontalLevel":2,
                                  "children":[]}
                    ]},
                    {"_id":"5f2a92086ec416001719a35b",
                    "id":"5f2a92086ec416001719a35a",
                    "new_id":1801,
                    "name":"Tarek",
                    "first_name":"John",
                    "last_name":"DAVIES",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "email":"Tarek@gmail.com",
                    "gender":"M",
                    "date_of_birth":"2002-07-31T11:02:06.000Z",
                    "date_creation":"2020-08-05",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":false,
                    "role":1,
                    "status":"M",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":2,
                    "children":[
                      {
                        "id":"5f2a95676ec416001719a369",
                        "new_id":1806,
                        "name":"Lokuz",
                        "first_name":"Emily",
                        "last_name":"LEWIS",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"F",
                        "date_of_birth":"1995-08-16T11:15:51.000Z",
                        "date_creation":"2020-10-20",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Lokuz@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":3,
                        "children":[]
                     },
                     {
                        "id":"5f2a95b06ec416001719a36c",
                        "new_id":1807,
                        "name":"Gringo",
                        "first_name":"James",
                        "last_name":"ROBINSON",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"M",
                        "date_of_birth":"1990-08-13T11:18:08.000Z",
                        "date_creation":"2020-10-25",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Gringo@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":4,
                        "children":[]
                     }
                    ]}]
              }

              else if (newIndex === 1) {
                partners = [{
                  "_id":"5f2a92a46ec416001719a35e",
                  "id":"5f2a92a36ec416001719a35d",
                  "new_id":1802,
                  "name":"Zakar",
                  "first_name":"Sarah",
                  "last_name":"COOPER",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Zakar@gmail.com",
                  "gender":"F",
                  "date_of_birth":"1998-07-29T11:04:56.000Z",
                  "date_creation":"2020-09-20",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"M",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":3,
                  "children":[
                    {
                      "id":"5f2a96a16ec416001719a36f",
                      "new_id":1808,
                      "name":"Stobar",
                      "first_name":"Margaret",
                      "last_name":"MURPHY",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1995-08-09T11:19:43.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Stobar@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":5,
                      "children":[]
                   },
                   {
                      "id":"5f2a98637fbfdd001758266a",
                      "new_id":1809,
                      "name":"Kronos",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"1990-08-21T11:28:15.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Kronos@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":6,
                      "children":[]
                   }
                  ]},
                  {"_id":"5f2a930d6ec416001719a361",
                  "id":"5f2a930d6ec416001719a360",
                  "new_id":1803,
                  "name":"Trolek",
                  "first_name":"Thomas",
                  "last_name":"JACKSON",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "email":"Trolek@gmail.com",
                  "gender":"M",
                  "date_of_birth":"1993-08-23T11:06:20.000Z",
                  "date_creation":"2020-09-25",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"M",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":4,
                  "children":[
                    {
                      "id":"5f2ab39f7fbfdd0017582673",
                      "new_id":1812,
                      "name":"Raguz",
                      "first_name":"Emma",
                      "last_name":"SMITH",
                      "parent":1,
                      "parent_id":"5f2a930d6ec416001719a360",
                      "parent_username":"Trolek",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1997-08-05T13:23:07.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Raguz@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":7,
                      "children":[]
                   },
                   {
                      "id":"5f2ab3f17fbfdd0017582676",
                      "new_id":1813,
                      "name":"Dalton",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a930d6ec416001719a360",
                      "parent_username":"Trolek",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"2002-08-05T13:27:12.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Dalton@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":8,
                      "children":[]
                   }
                  ]}]
              }
  }
  else {
    sponsorships = [];
    partners = [];
    if (newIndex !== -1) {
      setNewIndex(-1)
    }
  }



  var newUserArray = [];
  if (sponsorships && partners) {
    var jsonArr = [];
    if (month === 7) {
      jsonArr = [{
        "id": "0",
        "parent": null,
        "name": "Golden*",
        "parent_id": "0",
        "parent_username": "Ratof",
        "first_name": "Brian",
        "last_name": "ANDERSON",
        "country": "GB",
        "gender": "M",
        "date_of_birth": "1997-08-07T05:03:20.000+00:00",
        "status": "M*",
        "verticalLevel": user.verticalLevel,
        "horizontalLevel": "1",
        "date_creation": "2020-08-05",
        "date_member": "2020-09-25,2021-02-25",
        "date_repositioning": "",
        "confirmed": true,
        "children": partners
      }];
    }
    else if (month === 8) {
      jsonArr = [{
        "id": "0",
        "parent": null,
        "name": "Golden*",
        "parent_id": "0",
        "parent_username": "Ratof",
        "first_name": "Brian",
        "last_name": "ANDERSON",
        "country": "GB",
        "gender": "M",
        "date_of_birth": "1997-08-07T05:03:20.000+00:00",
        "status": "M*",
        "verticalLevel": user.verticalLevel,
        "horizontalLevel": "1",
        "date_creation": "2020-08-05",
        "date_member": "2020-09-25,2021-02-25",
        "date_repositioning": "",
        "confirmed": true,
        "children": partners
      }];
    }
    else if (month >= 2) {
      jsonArr = [{
        "id": "0",
        "parent": null,
        "name": "Golden",
        "parent_id": "0",
        "parent_username": "Ratof",
        "first_name": "Brian",
        "last_name": "ANDERSON",
        "country": "GB",
        "gender": "M",
        "date_of_birth": "1997-08-07T05:03:20.000+00:00",
        "status": "M",
        "verticalLevel": user.verticalLevel,
        "horizontalLevel": "1",
        "date_creation": "2020-08-05",
        "date_member": "2020-09-25",
        "date_repositioning": "",
        "confirmed": true,
        "children": partners
      }];
    }
    else {
      jsonArr = [{
        "id": "0",
        "parent": null,
        "name": "Golden",
        "parent_id": "0",
        "parent_username": "Ratof",
        "first_name": "Brian",
        "last_name": "ANDERSON",
        "country": "GB",
        "gender": "M",
        "date_of_birth": "1997-08-07T05:03:20.000+00:00",
        "status": "C",
        "verticalLevel": user.verticalLevel,
        "horizontalLevel": "32842",
        "date_creation": "2020-08-05",
        "date_member": "",
        "date_repositioning": "",
        "confirmed": true,
        "children": partners
      }];
    }


    if (newIndex >= 0) {
      if (sponsorships.length > 0) {
        jsonArr = [sponsorships[newIndex]]
      }
    }

    newUserArray = jsonArr.map(

      obj => {
        return {
          "id": obj.id,
          "parent": null,
          "name": obj.name,
          "parent_id": obj.parent_id,
          "parent_username": obj.parent_username,
          "first_name": obj.first_name,
          "last_name": obj.last_name,
          "country": obj.country,
          "gender": obj.gender,
          "date_of_birth": obj.date_of_birth,
          "status": obj.status,
          "verticalLevel": obj.verticalLevel,
          "horizontalLevel": obj.horizontalLevel,
          "date_creation": obj.date_creation,
          "date_member": obj.date_member,
          "date_repositioning": obj.date_repositioning,
          "confirmed": obj.confirmed,
          "children": partners
        }
      }
    );
  }
  /*      
<Tree data={newUserArray}
direction 
onClick={item => onClickItem(item.id) }/> */


  /*    var counterCandidate = 0;
      var counterMember = 0;
      var counterMember1 = 0;
      var counterMember2 = 0;

      var countCandidates = 0;
      var countMembers= 0;
      var countMembers1= 0;
      var countMembers2= 0;

      for(var i = 0; i < sponsorships.length; ++i){
          if(sponsorships[i].status === "C")
          countCandidates++;
          else if(sponsorships[i].status === "M")
          countMembers++
          else if(sponsorships[i].status === "M*")
          countMembers1++
          else if(sponsorships[i].status === "M**")
          countMembers2++
      }*/


  const showTable =


    <Fragment>


      <h1 className='large text-primary'>{t('Sponsorship.Partners')}</h1>



      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>

        <div className="scrollme">


          <table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
            <thead className={theme === "light" ? "thead-dark" : "thead-light"} >
              <tr>

                <th scope="col">{t('Sponsorship.Number')}</th>
                <th scope="col">{t('Sponsorship.Username')}</th>
                <th scope="col">{t('Sponsorship.Status')}</th>
                <th scope="col">{t('Sponsorship.Level')}</th>
                <th scope="col">{t('Sponsorship.Action')}</th>
              </tr>
            </thead>
            <tbody>
              {sponsorships.map((sponsorship, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1/*sponsorship.new_id*/}</td>
                    <td>{sponsorship.name}</td>
                    <td> {(sponsorship.status === "C" ? t('Sponsorship.Candidate') :
                      sponsorship.status === "J" ? t('Sponsorship.Joker') :
                        sponsorship.status.charAt(0) === "M" ? t('Sponsorship.Member') + sponsorship.status.substring(1) : "")}</td>
                    <td>{sponsorship.verticalLevel - user.verticalLevel}</td>

                    <td>{<button type="button" className="btn btn-primary" onClick={() => onRefreshItem(sponsorship.id, index)}>{t('Sponsorship.Consult_tree')}</button>}</td>
                  </tr>
                );
              }
              )}

              {sponsorships.length === 0 ?
                <tr>
                  <th colSpan={5}>{t('Sponsorship.No_partners')}</th>
                </tr>
                : null}
            </tbody>
          </table>
        </div>
      </div>

    </Fragment>;


  const showModal =
    <Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
      <ModalHeader closeButton as="span">
        <ModalTitle as="h4">{modalTitle}</ModalTitle>
      </ModalHeader>
      <ModalBody as="section"
        style={{
          whiteSpace: "pre-line"
        }}>{modalBody}</ModalBody>
      <ModalFooter as="footer">


        {modalFirstButton !== "" ?
          <button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalFirstButton}</button> :
          null
        }



      </ModalFooter>
    </Modal>

  const trees =

    <Fragment>
      <SponsorshipActionsDemo />

      <select className="form-control" name="action" value={'{"index": "' + month + '"}'} onChange={e => dropDownSelected(e.target.value)}>
        <option value={'{"index": "0"}'}>{t('Sponsorship.Month')} 0</option>
        <option value={'{"index": "1"}'}>{t('Sponsorship.Month')} 1</option>
        <option value={'{"index": "2"}'}>{t('Sponsorship.Month')} 2</option>
        <option value={'{"index": "3"}'}>{t('Sponsorship.Month')} 3</option>
        <option value={'{"index": "4"}'}>{t('Sponsorship.Month')} 4</option>
        <option value={'{"index": "5"}'}>{t('Sponsorship.Month')} 5</option>
        <option value={'{"index": "6"}'}>{t('Sponsorship.Month')} 6</option>
        <option value={'{"index": "7"}'}>{t('Sponsorship.Month')} 7</option>
        <option value={'{"index": "8"}'}>{t('Sponsorship.Month')} 8</option>
      </select>
      <br />
      {sponsorships !== null && partners !== null ? (

        <div>
          {showTable}


          {sponsorships && partners ?
            <h1 className='large text-primary'>{user._id === newUserArray[0].id ? t('Sponsorship.Affiliation_tree') : t('Language.Lang') === "English" ? (newUserArray && newUserArray[0] ? newUserArray[0].name : "") + t('Sponsorship.Affiliation_tree_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('Sponsorship.Affiliation_tree_of') + " " + (newUserArray && newUserArray[0] ? newUserArray[0].name : "") : ""}</h1>
            : <h1 className='large text-primary'>{t('Sponsorship.Calculating') + " ..."}</h1>}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>

            <div className="scrollme">

              <StyledTree data={newUserArray}
                direction
                onClick={item => onClickItem(item)} />

            </div>
          </div>
        </div>
      ) : (
          <Fragment>
          </Fragment>
        )}
    </Fragment>;




  function onClickItem(item) {

    var dateCreation = JSON.parse(JSON.stringify(item.date_creation))
    var finalDateCreation = ""
    if (dateCreation !== "") {
      dateCreation = JSON.parse(JSON.stringify(item.date_creation)).substring(0, 10).split("-")
      finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];

      if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
        finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
      }
    }

    var dateMember = JSON.parse(JSON.stringify(item.date_member))

    var dateMemberArr = dateMember.split(',');
  
    var finalDateMember = ""
    for(var i=0; i<dateMemberArr.length; i++){
    if (dateMemberArr[i] !== "") {
      dateMember = dateMemberArr[i].substring(0, 10).split("-")
    
      if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
        if (i === 0) {
          finalDateMember = `${finalDateMember + dateMember[2] + "/" + dateMember[1] + "/" + dateMember[0]}`;
        }
        else {
          finalDateMember = `${finalDateMember + "\n" + t('Sponsorship.Date_member') + " (" + t('Sponsorship.Member') + "*".repeat(i) + ") : " + dateMember[2] + '/' + dateMember[1] + '/' + dateMember[0]}`;
        }
      }
      else {
        if (i === 0) {
          finalDateMember = `${finalDateMember + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
        }
        else {
          finalDateMember = `${finalDateMember + "\n" + t('Sponsorship.Date_member') + " (" + t('Sponsorship.Member') + "*".repeat(i) + ") : " + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
        }
      } 
    }
  }
  
    var dateRepositioning = JSON.parse(JSON.stringify(item.date_repositioning?item.date_repositioning:""))
    var finalDateRepositioning = ""
    if (dateRepositioning !== "") {
      dateRepositioning = JSON.parse(JSON.stringify(item.date_repositioning)).substring(0, 10).split("-")
      finalDateRepositioning = dateRepositioning[1] + '/' + dateRepositioning[2] + '/' + dateRepositioning[0];

      if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
        finalDateRepositioning = dateRepositioning[2] + '/' + dateRepositioning[1] + '/' + dateRepositioning[0];
      }
    }

    setModalTitle(item.name);
    setModalBody(
      t('Sponsorship.Status') + " : " + (item.status === "C" ? t('Sponsorship.Candidate') :
        item.status === "J" ? t('Sponsorship.Joker') :
          item.status.charAt(0) === "M" ? t('Sponsorship.Member') + item.status.substring(1) : "") +
      "\n" + t('Sponsorship.Guarantor') + " : " + (item.parent_username !== '' ?
        item.parent_username : "\"" + t('Sponsorship.None') + "\"") +
      "\n" + t('Sponsorship.Country') + " : " + t(`Country.${item.country}`) +
      "\n" + t('Sponsorship.Level') + " : " + (item.verticalLevel - user.verticalLevel) +
      (finalDateCreation !== "" ? ("\n" + t('Sponsorship.Subscription_date') + " : " + finalDateCreation) : "") +
      (finalDateMember !== "" ? ("\n" + t('Sponsorship.Date_member') +" (" + t('Sponsorship.Member')+") : "  + finalDateMember) : "") +
      (finalDateRepositioning !== "" ? ("\n" + t('Sponsorship.Date_repositioning') + " : " + finalDateRepositioning) : "")
    );
    setModalFirstButton("");
    setModalFirstButton(t('Sponsorship.Ok'));
    setModalIsOpen(true);
  }

  function onRefreshItem(id, i) {
  //  getPartners(id);
    setNewIndex(i)
    window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
  }

  /*function getPartners(id) {
  if (month === 0) {
    sponsorships = [];
    partners = [];
  }
  else if (month === 1) {
   
   }
   else if (month === 5) {
    sponsorships = [{"id":"5f2a900b6ec416001719a351",
    "new_id":1798,
    "parent":1,
    "name":"Okdar",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Barbara",
    "last_name":"CURTIS",
    "country":"GB",
    "gender":"F",
    "date_of_birth":"2002-07-29T10:52:45.000Z",
    "status":"M",
    "verticalLevel":17,
    "horizontalLevel":65683,
    "date_creation":"2020-08-05",
    "date_member":"2020-08-05",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]},
    {"id":"5f2a90a16ec416001719a354",
    "new_id":1799,
    "parent":1,
    "name":"Drevon",
    "parent_id":"5f2a89d86ec416001719a34e",
    "parent_username":"Golden",
    "first_name":"Ethan",
    "last_name":"CLARK",
    "country":"GB",
    "gender":"M",
    "date_of_birth":"2000-08-03T10:55:49.000Z",
    "status":"M",
    "verticalLevel":17,
    "horizontalLevel":65684,
    "date_creation":"2020-08-05",
    "date_member":"2020-08-05",
    "date_repositioning":"",
    "confirmed":false,
    "children":[]}]






    partners = [{"_id":"5f0dead3debe400017f53697",
    "id":"5f0dead3debe400017f53696",
    "new_id":539,
    "name":"bebo",
    "first_name":"bebo",
    "last_name":"djeieorl",
    "parent":1,
    "parent_id":"5f0dea61debe400017f53690",
    "parent_username":"babo",
    "country":"AX",
    "email":"bebo@gmail.com",
    "gender":"M",
    "date_of_birth":"2002-07-09T17:26:03.000Z",
    "date_creation":"2020-07-14",
    "date_member":"",
    "date_repositioning":"",
    "confirmed":false,
    "role":1,
    "status":"C",
    "verticalLevel":11,
    "horizontalLevel":1,
    "children":[{"_id":"5f0dec2adebe400017f536a3",
                "id":"5f0dec2adebe400017f536a2",
                "new_id":543,
                "name":"cedo",
                "first_name":"cedo",
                "last_name":"fkglfl",
                "parent":1,
                "parent_id":"5f0dead3debe400017f53696",
                "parent_username":"bebo",
                "country":"AX",
                "email":"cedo@gmail.com",
                "gender":"M",
                "date_of_birth":"2002-07-09T17:31:46.000Z",
                "date_creation":"2020-07-14",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":false,
                "role":1,
                "status":"C",
                "verticalLevel":12,
                "horizontalLevel":1,
                "children":[]},
      {"_id":"5f0dec5adebe400017f536a6",
      "id":"5f0dec5adebe400017f536a5",
      "new_id":544,
      "name":"doce",
      "first_name":"doce",
      "last_name":"djdkll",
      "parent":1,
      "parent_id":"5f0dead3debe400017f53696",
      "parent_username":"bebo",
      "country":"AD",
      "email":"doce@gmail.com",
      "gender":"M",
      "date_of_birth":"2002-07-10T17:32:33.000Z",
      "date_creation":"2020-07-14",
      "date_member":"",
      "date_repositioning":"",
      "confirmed":false,
      "role":1,
      "status":"C",
      "verticalLevel":12,
      "horizontalLevel":2,
      "children":[]}]},
      {"_id":"5f0deb0cdebe400017f5369a",
      "id":"5f0deb0cdebe400017f53699",
      "new_id":540,
      "name":"bobe",
      "first_name":"bobe",
      "last_name":"djdkflg",
      "parent":1,
      "parent_id":"5f0dea61debe400017f53690",
      "parent_username":"babo",
      "country":"DZ",
      "email":"bobe@gmail.com",
      "gender":"M",
      "date_of_birth":"2002-07-08T17:26:52.000Z",
      "date_creation":"2020-07-14",
      "date_member":"",
      "date_repositioning":"",
      "confirmed":false,
      "role":1,
      "status":"C",
      "verticalLevel":11,
      "horizontalLevel":2,
      "children":[{"_id":"5f0deca8debe400017f536a9",
                  "id":"5f0deca8debe400017f536a8",
                  "new_id":545,
                  "name":"cobe",
                  "first_name":"cobe",
                  "last_name":"cjfkkg",
                  "parent":1,
                  "parent_id":"5f0deb0cdebe400017f53699",
                  "parent_username":"bobe",
                  "country":"AS",
                  "email":"cobe@gmail.com",
                  "gender":"M",
                  "date_of_birth":"2002-07-02T17:33:51.000Z",
                  "date_creation":"2020-07-14",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":false,
                  "role":1,
                  "status":"C",
                  "verticalLevel":12,
                  "horizontalLevel":3,
                  "children":[]},
        {"_id":"5f0decd2debe400017f536ac",
        "id":"5f0decd2debe400017f536ab",
        "new_id":546,
        "name":"beco",
        "first_name":"beco",
        "last_name":"fkflglg",
        "parent":1,
        "parent_id":"5f0deb0cdebe400017f53699",
        "parent_username":"bobe",
        "country":"AS",
        "email":"beco@gmail.com",
        "gender":"M",
        "date_of_birth":"2002-07-10T17:34:40.000Z",
        "date_creation":"2020-07-14",
        "date_member":"",
        "date_repositioning":"",
        "confirmed":false,
        "role":1,
        "status":"C",
        "verticalLevel":12,
        "horizontalLevel":4,
        "children":[]}]}]
   }
  }*/

  const dropDownSelected = event => {
    setNewIndex(-1)
    if (JSON.parse(event).index === "0") {
      updateMonth(0);
    }
    else if (JSON.parse(event).index === "1") {
      updateMonth(1);
    }
    else if (JSON.parse(event).index === "2") {
      updateMonth(2);
    }
    else if (JSON.parse(event).index === "3") {
      updateMonth(3);
    }
    else if (JSON.parse(event).index === "4") {
      updateMonth(4);
    }
    else if (JSON.parse(event).index === "5") {
      updateMonth(5);
    }
    else if (JSON.parse(event).index === "6") {
      updateMonth(6);
    }
    else if (JSON.parse(event).index === "7") {
      if (month !== 7 && month !== 8) {
        setModalTitle(t('Sponsorship.Congratulations'));
        setModalBody(t('Sponsorship.Full_matrix1'));
        setModalFirstButton("");
        setModalFirstButton(t('Sponsorship.Ok'));
        setModalIsOpen(true);
      }
      updateMonth(7);
    }
    else if (JSON.parse(event).index === "8") {
      if (month !== 7 && month !== 8) {
        setModalTitle(t('Sponsorship.Congratulations'));
        setModalBody(t('Sponsorship.Full_matrix1'));
        setModalFirstButton("");
        setModalFirstButton(t('Sponsorship.Ok'));
        setModalIsOpen(true);
      }
      updateMonth(8);
    }
  }

  return sponsorships && partners === null ? (
    <Spinner />
  ) : (
      <Fragment>
        {showModal}
        {trees}

      </Fragment>
    );
};


SponsorshipTreeDemo.propTypes = {
  auth: PropTypes.object.isRequired,
  updateMonth: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { updateMonth }
)(SponsorshipTreeDemo);


