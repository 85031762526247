import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SponsorshipActionsDemo from './SponsorshipActionsDemo';
import { updateMonth } from '../../actions/sponsorship';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useTranslation } from 'react-i18next';




const GenealogyDemo = ({ updateMonth, auth: { user, theme }, sponsorship: { month } }) => {

  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
    
  useEffect(() => {
    if (theme === 'light') {
    document.body.style = 'background: #b9d7f7';
    }
    else {
    document.body.style = 'background: #398DE7';
    }
   // document.body.style = 'background: #b9d7f7'; //#DCDCDC

    // returned function will be called on component unmount 
    return () => {
      // document.body.style = 'background: #D3D3D3'; //#DCDCDC
      document.body.style = null;
    }
  }, [theme]);

  const { t } = useTranslation();

  const dropDownSelected = event => {
    if (JSON.parse(event).index === "0") {
      updateMonth(0);
    }
    else if (JSON.parse(event).index === "1") {
      updateMonth(1);
    }
    else if (JSON.parse(event).index === "2") {
      updateMonth(2);
    }
    else if (JSON.parse(event).index === "3") {
      updateMonth(3);
    }
    else if (JSON.parse(event).index === "4") {
      updateMonth(4);
    }
    else if (JSON.parse(event).index === "5") {
      updateMonth(5);
    }
    else if (JSON.parse(event).index === "6") {
      updateMonth(6);
    }
    else if (JSON.parse(event).index === "7") {
      if (month !== 7 && month !== 8) {
        setModalTitle(t('DigitalWallet.Congratulations'));
        setModalBody(t('DigitalWallet.Full_matrix1'));
        setModalIsOpen(true);
      }
      updateMonth(7);
    }
    else if (JSON.parse(event).index === "8") {
      if (month !== 7 && month !== 8) {
        setModalTitle(t('DigitalWallet.Congratulations'));
        setModalBody(t('DigitalWallet.Full_matrix1'));
        setModalIsOpen(true);
      }
      updateMonth(8);
    }
  }

      const showTable =
      <Fragment>
       <SponsorshipActionsDemo />
      
       <select className="form-control" name="action" value={'{"index": "' + month + '"}'} onChange={e => dropDownSelected(e.target.value)}>
        <option value={'{"index": "0"}'}>{t('Sponsorship.Month')} 0</option>
        <option value={'{"index": "1"}'}>{t('Sponsorship.Month')} 1</option>
        <option value={'{"index": "2"}'}>{t('Sponsorship.Month')} 2</option>
        <option value={'{"index": "3"}'}>{t('Sponsorship.Month')} 3</option>
        <option value={'{"index": "4"}'}>{t('Sponsorship.Month')} 4</option>
        <option value={'{"index": "5"}'}>{t('Sponsorship.Month')} 5</option>
        <option value={'{"index": "6"}'}>{t('Sponsorship.Month')} 6</option>
        <option value={'{"index": "7"}'}>{t('Sponsorship.Month')} 7</option>
        <option value={'{"index": "8"}'}>{t('Sponsorship.Month')} 8</option>
      </select>
      <br />
<h1 className='large text-primary'>{t('Sponsorship.Payment_table')}</h1>

  
 <div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 


<table id="tbl" style={{ width : 950 }} className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      <th scope="col" style={{ width : 250 }}>{t('Sponsorship.Levels')}</th>
      <th scope="col">{t('Sponsorship.Subscribers')}</th>
      <th scope="col">{t('Sponsorship.Matrix_commissions')}</th>
      <th scope="col">{t('Sponsorship.Training_bonuses')}</th>
      <th scope="col">{t('Sponsorship.Monthly_income')}</th>
    </tr>
  </thead>
  <tbody>

  {
month === 1 ?
    <tr>
    <th scope="row">1</th>
<td>2</td>
<td>0€</td>
<td>200€</td>
<td>200€</td>
</tr>
:
month === 2 || month === 3 || month === 4 || month === 5 || month === 6 || month === 7 || month === 8 ?
    <tr>
    <th scope="row">1</th>
<td>2</td>
<td>12€</td>
<td>0€</td>
<td>12€</td>
</tr>
:
<tr>
<th scope="row">1</th>
<td>0</td>
<td>0€</td>
<td>0€</td>
<td>0€</td>
</tr> 
}
{
  month === 2 ?
<tr>
<th scope="row">2</th>
  <td>4</td>
  <td>0€</td>
  <td>0€</td>
  <td>0€</td>
</tr>
:
month === 3 || month === 4 || month === 5 || month === 6 || month === 7 || month === 8 ?
<tr>
<th scope="row">2</th>
  <td>4</td>
  <td>24€</td>
  <td>0€</td>
  <td>24€</td>
</tr>
:
<tr>
<th scope="row">2</th>
  <td>0</td>
  <td>0€</td>
  <td>0€</td>
  <td>0€</td>
</tr>
}
{
  month === 3 ?
<tr>
<th scope="row">3</th>
  <td>8</td>
  <td>0€</td>
  <td>0€</td>
  <td>0€</td>
</tr>
:
  month === 4 || month === 5 || month === 6 || month === 7 || month === 8 ?
  <tr>
  <th scope="row">3</th>
    <td>8</td>
    <td>48€</td>
    <td>0€</td>
    <td>48€</td>
  </tr>
  :
<tr>
  <th scope="row">3</th>
  <td>0</td>
  <td>0€</td>
  <td>0€</td>
  <td>0€</td>
</tr>
}
{
  month === 4 ?
<tr>
<th scope="row">4</th>
  <td>16</td>
  <td>0€</td>
  <td>0€</td>
  <td>0€</td>
</tr>
:
  month === 5 || month === 6 || month === 7 || month === 8 ?
  <tr>
  <th scope="row">4</th>
    <td>16</td>
    <td>96€</td>
    <td>0€</td>
    <td>96€</td>
  </tr>
  :
<tr>
  <th scope="row">4</th>
  <td>0</td>
  <td>0€</td>
  <td>0€</td>
  <td>0€</td>
</tr>
}
{
  month === 5 ?
  <tr>
  <th scope="row">5</th>
    <td>32</td>
    <td>0€</td>
    <td>0€</td>
    <td>0€</td>
  </tr>
  :
  month === 6 || month === 7 || month === 8 ?
  <tr>
  <th scope="row">5</th>
    <td>32</td>
    <td>192€</td>
    <td>0€</td>
    <td>192€</td>
  </tr>
  :
<tr>
  <th scope="row">5</th>
  <td>0</td>
  <td>0€</td>
  <td>0€</td>
  <td>0€</td>
</tr>
}
{
  month === 6 ?
  <tr>
  <th scope="row">6</th>
    <td>64</td>
    <td>0€</td>
    <td>0€</td>
    <td>0€</td>
  </tr>
  :
  month === 7 || month === 8 ?
  <tr>
  <th scope="row">6</th>
    <td>64</td>
    <td>384€</td>
    <td>0€</td>
    <td>384€</td>
  </tr>
  :
<tr>
  <th scope="row">6</th>
  <td>0</td>
  <td>0€</td>
  <td>0€</td>
  <td>0€</td>
</tr>
}
{
  month === 7 ?
  <tr>
  <th scope="row">7</th>
    <td>128</td>
    <td>0€</td>
    <td>0€</td>
    <td>0€</td>
  </tr>
  :
  month === 8 ?
  <tr>
  <th scope="row">7</th>
    <td>128</td>
    <td>768€</td>
    <td>0€</td>
    <td>768€</td>
  </tr>
  :
<tr>
  <th scope="row">7</th>
  <td>0</td>
  <td>0€</td>
  <td>0€</td>
  <td>0€</td>
</tr>
}
  {
month === 1 ?
<tr>
  <th scope="row">{t('Sponsorship.Total')}</th>
<td>2</td>
<td>0€</td>
<td>200€</td>
<td>200€</td>
</tr>
:
month === 2 ?
<tr>
  <th scope="row">{t('Sponsorship.Total')}</th>
<td>6</td>
<td>12€</td>
<td>0€</td>
<td>12€</td>
</tr>
:
month === 3 ?
<tr>
  <th scope="row">{t('Sponsorship.Total')}</th>
<td>14</td>
<td>36€</td>
<td>0€</td>
<td>36€</td>
</tr>
:
month === 4 ?
<tr>
  <th scope="row">{t('Sponsorship.Total')}</th>
<td>30</td>
<td>84€</td>
<td>0€</td>
<td>84€</td>
</tr>
:
month === 5 ?
<tr>
  <th scope="row">{t('Sponsorship.Total')}</th>
<td>62</td>
<td>180€</td>
<td>0€</td>
<td>180€</td>
</tr>
:
month === 6 ?
<tr>
  <th scope="row">{t('Sponsorship.Total')}</th>
<td>126</td>
<td>372€</td>
<td>0€</td>
<td>372€</td>
</tr>
:
month === 7 ?
<tr>
  <th scope="row">{t('Sponsorship.Total')}</th>
<td>254</td>
<td>756€</td>
<td>0€</td>
<td>756€</td>
</tr>
:
month === 8 ?
<tr>
  <th scope="row">{t('Sponsorship.Total')}</th>
<td>254</td>
<td>1524€</td>
<td>0€</td>
<td>1524€</td>
</tr>
:
<tr>
  <th scope="row">{t('Sponsorship.Total')}</th>
<td>0</td>
<td>0€</td>
<td>0€</td>
<td>0€</td>
</tr> 
}
  </tbody>
</table>

</div>
</div>
   
  </Fragment>;




const showModal =
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
  <ModalHeader closeButton as="span">
    <ModalTitle as="h4">{modalTitle}</ModalTitle>
  </ModalHeader>
  <ModalBody as="section"
    style={{
      whiteSpace: "pre-line"
    }}>{modalBody}
  </ModalBody>
  <ModalFooter as="footer">

    <button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{t('Sponsorship.Ok')}</button>

  </ModalFooter>
</Modal>


return (

<Fragment>
{showTable}
{showModal}
</Fragment>
);
};




GenealogyDemo.propTypes = {
    updateMonth: PropTypes.func.isRequired,
    sponsorship: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    sponsorship: state.sponsorship,
    auth: state.auth
});

export default connect(
  mapStateToProps,
  { updateMonth }
)(GenealogyDemo);