import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import SponsorshipActions from './SponsorshipActions';
import Tree, { withStyles } from 'react-vertical-tree';
import { getSponsorships, getGenealogies, getPartners } from '../../actions/sponsorship';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';




const History = ({ getSponsorships, getPartners, getGenealogies, auth: { user, theme }, sponsorship: { sponsorships, old_users, partners, level_affiliation_old, level_affiliation_repositioned_old, genealogies_old, bonus_old, genealogies_this_month_old, bonus_genealogy_old, loading } }) => {



  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");
  const [selectedUser, setSelectedUser] = useState(false);
  const [switchTree, setSwitchTree] = useState(1);
  
  let history = useHistory();
  useEffect(() => {
    getSponsorships(selectedUser._id ? selectedUser._id : user._id, selectedUser._id ? true : false);
  }, [getSponsorships, selectedUser._id, user._id]);

    useEffect(() => {
        getPartners(selectedUser._id ? selectedUser._id : user._id) 
    }, [getPartners, selectedUser._id, user._id]);

    useEffect(() => {
        getGenealogies(selectedUser._id ? selectedUser._id : user._id, selectedUser._id ? true : false);
      }, [getGenealogies, selectedUser._id, user._id]);

   /*   useEffect(() => {
        getGenealogies(user._id, true, false);
      }, [getGenealogies, user._id]); */

   /* const styles = {
      lines: {
        color: '#1890ff',
        height: '90px',
      },
      node: {
        backgroundColor: '#1890ff',
        border: '1px solid #1890ff',
      },
      text: {
        color: '#fff',
      }
    }*/
    const styles = {
      lines: {
        color: theme === "light" ? '#bc9f51' : '#edd99a',
        height: '90px',
      },
      node: {
        backgroundColor: theme === "light" ? '#bc9f51' : '#edd99a',
        border: '1px solid',
        borderColor: theme === "light" ? '#bc9f51' : '#edd99a',
      },
      text: {
        color: theme === "light" ? '#fff' : '#000',
        fontWeight: 'bold'
      }
    }
    const StyledTree = withStyles(styles)(Tree)



  const { t } = useTranslation();

  if (user && (user.status === "C" || user.status === "A")) {
    return <Redirect to="/dashboard" />;
  }
  



      var newUserArray = [];
      var old_accounts = [];
      if(sponsorships && partners && old_users) {
         old_accounts = old_users;


        if (old_accounts[0] && old_accounts[0]._id && !selectedUser) {
            setSelectedUser(old_accounts[0])
        }
        

  
        newUserArray = [{
            "id" : selectedUser._id,
            "new_id":selectedUser.new_id,
            "parent":null,
            "name":selectedUser.name ? selectedUser.name : selectedUser.username ? selectedUser.username : user.username,
            "parent_id":selectedUser.parent_id,
            "parent_username":selectedUser.parent_username,
            "first_name":selectedUser.first_name,
            "last_name":selectedUser.last_name,
            "country":selectedUser.country,
            "gender":selectedUser.gender,
            "date_of_birth":selectedUser.date_of_birth,
            "status":selectedUser.status,
            "email":selectedUser.email,
            "verticalLevel":selectedUser.verticalLevel,
            "horizontalLevel":selectedUser.horizontalLevel,
            "date_creation":selectedUser.date_creation,
            "date_member":selectedUser.date_member,
            "date_repositioning":selectedUser.date_repositioning,
            "confirmed":selectedUser.confirmed,
            "children":partners
          }];
      }

    if (sponsorships && partners && (!old_users || old_users.length === 0)) {
        return <Redirect to="/dashboard" />;
    }

    function isFunction(functionToCheck) {
      return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
     }

    var oldUsers = 0
    var oldUsersAll = 0
    var oldUsersAffiliations = 0

      const showTableGenealogy =
      
      
      <Fragment>



      <div
      style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
      }}>
      
      <div className="scrollme"> 
      
      
      <table id="tbl" style={{ width : 950 }} className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
      <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
      <tr>
        <th scope="col" style={{ width : 250 }}>{t('History.Levels')}</th>
        <th scope="col">{t('History.Subscribers')}</th>
        <th scope="col">{t('History.Matrix_commissions')}</th>
        <th scope="col">{t('History.Training_bonuses')}</th>
        <th scope="col">{t('History.Monthly_income')}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row">1</th>
        <td>{genealogies_old && !isNaN(genealogies_old[0]) && bonus_old && genealogies_this_month_old ? genealogies_old[0] + genealogies_this_month_old[0] : t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[0]) && bonus_old ? (genealogies_old[0] * 6) + "€": t('History.Calculating') + " ..."}</td>
        <td>{level_affiliation_old && !isNaN(level_affiliation_old[0]) && bonus_old ? level_affiliation_old[0] * 100 + "€": t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[0]) && bonus_old && level_affiliation_old ? (genealogies_old[0] * 6) + level_affiliation_old[0] * 100 + "€" : t('History.Calculating') + " ..."}</td>
      </tr>
      <tr>
      <th scope="row">2</th>
        <td>{genealogies_old && !isNaN(genealogies_old[1]) && bonus_old && genealogies_this_month_old ? genealogies_old[1] + genealogies_this_month_old[1] : t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[1]) && bonus_old ? (genealogies_old[1] * 6) + "€": t('History.Calculating') + " ..."}</td>
        <td>{level_affiliation_old && !isNaN(level_affiliation_old[1]) && bonus_old ? level_affiliation_old[1] * 100 + "€": t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[1]) && bonus_old && level_affiliation_old ? (genealogies_old[1] * 6) + level_affiliation_old[1] * 100 + "€" : t('History.Calculating') + " ..."}</td>
      </tr>
      <tr>
        <th scope="row">3</th>
        <td>{genealogies_old && !isNaN(genealogies_old[2]) && bonus_old && genealogies_this_month_old ? genealogies_old[2] + genealogies_this_month_old[2] : t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[2]) && bonus_old ? (genealogies_old[2] * 6) + "€": t('History.Calculating') + " ..."}</td>
        <td>{level_affiliation_old && !isNaN(level_affiliation_old[2]) && bonus_old ? level_affiliation_old[2] * 100 + "€": t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[2]) && bonus_old && level_affiliation_old ? (genealogies_old[2] * 6) + level_affiliation_old[2] * 100 + "€" : t('History.Calculating') + " ..."}</td>
      </tr>
      <tr>
        <th scope="row">4</th>
        <td>{genealogies_old && !isNaN(genealogies_old[3]) && bonus_old && genealogies_this_month_old ? genealogies_old[3] + genealogies_this_month_old[3] : t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[3]) && bonus_old ? (genealogies_old[3] * 6) + "€": t('History.Calculating') + " ..."}</td>
        <td>{level_affiliation_old && !isNaN(level_affiliation_old[3]) && bonus_old ? level_affiliation_old[3] * 100 + "€": t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[3]) && bonus_old && level_affiliation_old ? (genealogies_old[3] * 6) + level_affiliation_old[3] * 100 + "€" : t('History.Calculating') + " ..."}</td>
      </tr>
      <tr>
        <th scope="row">5</th>
        <td>{genealogies_old && !isNaN(genealogies_old[4]) && bonus_old && genealogies_this_month_old ? genealogies_old[4] + genealogies_this_month_old[4] : t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[4]) && bonus_old ? (genealogies_old[4] * 6) + "€": t('History.Calculating') + " ..."}</td>
        <td>{level_affiliation_old && !isNaN(level_affiliation_old[4]) && bonus_old ? level_affiliation_old[4] * 100 + "€": t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[4]) && bonus_old && level_affiliation_old ? (genealogies_old[4] * 6) + level_affiliation_old[4] * 100 + "€" : t('History.Calculating') + " ..."}</td>
      </tr>
      <tr>
        <th scope="row">6</th>
        <td>{genealogies_old && !isNaN(genealogies_old[5]) && bonus_old && genealogies_this_month_old ? genealogies_old[5] + genealogies_this_month_old[5] : t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[5]) && bonus_old ? (genealogies_old[5] * 6) + "€": t('History.Calculating') + " ..."}</td>
        <td>{level_affiliation_old && !isNaN(level_affiliation_old[5]) && bonus_old ? level_affiliation_old[5] * 100 + "€": t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[5]) && bonus_old && level_affiliation_old ? (genealogies_old[5] * 6) + level_affiliation_old[5] * 100 + "€" : t('History.Calculating') + " ..."}</td>
      </tr>
      <tr>
        <th scope="row">7</th>
        <td>{genealogies_old && !isNaN(genealogies_old[6]) && bonus_old && genealogies_this_month_old ? genealogies_old[6] + genealogies_this_month_old[6] : t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[6]) && bonus_old ? (genealogies_old[6] * 6) + "€": t('History.Calculating') + " ..."}</td>
        <td>{level_affiliation_old && !isNaN(level_affiliation_old[6]) && bonus_old ? level_affiliation_old[6] * 100 + "€": t('History.Calculating') + " ..."}</td>
        <td>{genealogies_old && !isNaN(genealogies_old[6]) && bonus_old && level_affiliation_old ? (genealogies_old[6] * 6) + level_affiliation_old[6] * 100 + "€" : t('History.Calculating') + " ..."}</td>
      </tr>
      <tr>
        <th scope="row">{bonus_old && bonus_old.length > 0 ? t('History.Matrix') + " " + (bonus_old.length + 1) : t('History.Total')}</th>
        <td>
        {genealogies_old && !isNaN(genealogies_old[0]) && bonus_old && genealogies_this_month_old ? (genealogies_old[0] + genealogies_old[1] +
        genealogies_old[2] + genealogies_old[3] + genealogies_old[4] +
        genealogies_old[5] + genealogies_old[6] + genealogies_this_month_old[0]
        + genealogies_this_month_old[1] + genealogies_this_month_old[2] + genealogies_this_month_old[3]
        + genealogies_this_month_old[4] + genealogies_this_month_old[5] + genealogies_this_month_old[6]) : t('History.Calculating') + " ..."}
        </td>
        <td>
        {genealogies_old && !isNaN(genealogies_old[0]) && bonus_old ? ((genealogies_old[0] * 6) + (genealogies_old[1] * 6) +
        (genealogies_old[2] * 6) + (genealogies_old[3] * 6) + (genealogies_old[4] * 6) +
        (genealogies_old[5] * 6) + (genealogies_old[6] * 6)) + "€" : t('History.Calculating') + " ..."}
        </td>
        <td>
        {level_affiliation_old && !isNaN(level_affiliation_old[0]) && bonus_old ? (level_affiliation_old[0] * 100 + level_affiliation_old[1] * 100 +
        level_affiliation_old[2] * 100 + level_affiliation_old[3] * 100 + level_affiliation_old[4] * 100 +
        level_affiliation_old[5] * 100 + level_affiliation_old[6] * 100) + "€" : t('History.Calculating') + " ..."
        }
        </td>
        <td>
        { genealogies_old && !isNaN(genealogies_old[0]) && bonus_old && level_affiliation_old ? ((genealogies_old[0] * 6) + (genealogies_old[1] * 6) +
        (genealogies_old[2] * 6) + (genealogies_old[3] * 6) + (genealogies_old[4] * 6) +
        (genealogies_old[5] * 6) + (genealogies_old[6] * 6) +
        level_affiliation_old[0] * 100 + level_affiliation_old[1] * 100 +
        level_affiliation_old[2] * 100 + level_affiliation_old[3] * 100 + level_affiliation_old[4] * 100 +
        level_affiliation_old[5] * 100 + level_affiliation_old[6] * 100 )
        + "€" : t('History.Calculating') + " ..."
          }
        </td>
      </tr>
      <div style={{ display : (bonus_genealogy_old && bonus_genealogy_old !== 0) ? "" : "none" }}><br/></div>
      <tr>
        <th style={{ display : (bonus_genealogy_old && bonus_genealogy_old !== 0) ? "" : "none" }} scope="row">{t('History.Repositioning_bonus')}</th>
        <td style={{ display : (bonus_genealogy_old && bonus_genealogy_old !== 0) ? "" : "none" }} colSpan={3}></td>
        <td style={{ display : (bonus_genealogy_old && bonus_genealogy_old !== 0) ? "" : "none" }}>{bonus_genealogy_old + "€"}</td>
        </tr>
      </tbody>
      </table>
      
      
      
      
      
      
      
      {/*<h1 className='large text-primary' style={{ display : bonus && bonus.length > 0 ? "" : "none" }}>{t('AdminSponsorship.Total_all_matrices')}</h1>*/}
      
      
      
      
          
      <table  style={{ display : bonus_old && bonus_old.length > 0 ? "" : "none", width : 950 }} id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
      <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
      <tr>
        <th scope="col" style={{ width : 250 }}>{t('History.Full_matrices')}</th>
        <th scope="col">{t('History.Subscribers')}</th>
        <th scope="col">{t('History.Matrix_commissions')}</th>
        <th scope="col">{t('History.Training_bonuses')}</th>
        <th scope="col">{t('History.Monthly_income')}</th>
      </tr>
      </thead>
      <tbody>
      
      
      {(!bonus_old || !level_affiliation_repositioned_old) || ((bonus_old && bonus_old.length === 0) || (level_affiliation_repositioned_old && level_affiliation_repositioned_old.length === 0)) ? 
        <>
      </>
      :
      (bonus_old && isFunction(bonus_old.map)) ?
      bonus_old.map(( get_bonus, index ) => {
        oldUsers += get_bonus ? get_bonus !== undefined ? get_bonus.user_number : 0 : 0
        oldUsersAll += get_bonus ? get_bonus !== undefined ? get_bonus.user_number_all : 0 : 0
        oldUsersAffiliations += level_affiliation_repositioned_old[index] ? level_affiliation_repositioned_old[index] !== undefined ? level_affiliation_repositioned_old[index].user_number : 0 : 0
      return (
      <tr key={index}>
      <th scope="row">{t('History.Matrix') + " " + (bonus_old && bonus_old.length ? bonus_old.length - index : index)}</th>
      <td>{get_bonus ? get_bonus !== undefined ? get_bonus.user_number + oldUsersAll : t('History.Calculating') + " ..." : t('History.Calculating') + " ..."}</td>
      <td>{get_bonus ? get_bonus !== undefined ? get_bonus.user_number * 6 + "€" : t('History.Calculating') + " ..." : t('History.Calculating') + " ..."}</td>
      <td>{level_affiliation_repositioned_old[index] ? level_affiliation_repositioned_old[index] !== undefined ? level_affiliation_repositioned_old[index].user_number * 100 + "€" : t('History.Calculating') + " ..." : t('History.Calculating') + " ..."}</td>
      <td>{get_bonus ? get_bonus !== undefined ? get_bonus.user_number * 6 + level_affiliation_repositioned_old[index].user_number * 100 + "€" : t('History.Calculating') + " ..." : t('History.Calculating') + " ..."}</td>
      </tr>
      );
      }) : null }
      
      <tr>
        <th scope="row">{t('History.Total')}</th>
        <td>
        {genealogies_old && !isNaN(genealogies_old[0]) && genealogies_this_month_old && bonus_old && level_affiliation_repositioned_old ? (genealogies_old[0] + genealogies_old[1] +
        genealogies_old[2] + genealogies_old[3] + genealogies_old[4] +
        genealogies_old[5] + genealogies_old[6] + genealogies_this_month_old[0]
        + genealogies_this_month_old[1] + genealogies_this_month_old[2] + genealogies_this_month_old[3]
        + genealogies_this_month_old[4] + genealogies_this_month_old[5] + genealogies_this_month_old[6] + oldUsers + oldUsersAll) : t('History.Calculating') + " ..."}
        </td>
        <td>
        {genealogies_old && !isNaN(genealogies_old[0]) && bonus_old ? ((genealogies_old[0] * 6) + (genealogies_old[1] * 6) +
        (genealogies_old[2] * 6) + (genealogies_old[3] * 6) + (genealogies_old[4] * 6) +
        (genealogies_old[5] * 6) + (genealogies_old[6] * 6) + (oldUsers * 6)) + "€" : t('History.Calculating') + " ..."}
        </td>
        <td>
      
        {level_affiliation_old && !isNaN(level_affiliation_old[0]) && level_affiliation_repositioned_old? (level_affiliation_old[0] * 100 + level_affiliation_old[1] * 100 +
        level_affiliation_old[2] * 100 + level_affiliation_old[3] * 100 + level_affiliation_old[4] * 100 +
        level_affiliation_old[5] * 100 + level_affiliation_old[6] * 100 + oldUsersAffiliations * 100) + "€" : t('History.Calculating') + " ..."
        }
        </td>
        <td>
        {genealogies_old && !isNaN(genealogies_old[0]) && level_affiliation_old && bonus_old && level_affiliation_repositioned_old ? ((genealogies_old[0] * 6) + (genealogies_old[1] * 6) +
        (genealogies_old[2] * 6) + (genealogies_old[3] * 6) + (genealogies_old[4] * 6) +
        (genealogies_old[5] * 6) + (genealogies_old[6] * 6) +
        level_affiliation_old[0] * 100 + level_affiliation_old[1] * 100 +
        level_affiliation_old[2] * 100 + level_affiliation_old[3] * 100 + level_affiliation_old[4] * 100 +
        level_affiliation_old[5] * 100 + level_affiliation_old[6] * 100
        + oldUsers * 6 + oldUsersAffiliations * 100 + bonus_genealogy_old)
        + "€" : t('History.Calculating') + " ..."
          }
        </td>
      </tr>
        </tbody>
      </table>
      
      </div>
      </div>
      
      </Fragment>;











      
      const showTable =
      
      
      <Fragment>
      
      
<h1 className='large text-primary'>{t('History.Full_matrices')}</h1>

 
  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 


<table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
    
      <th scope="col">{t('History.Matrix')}</th>
      <th scope="col">{t('History.Date')}</th>
      <th scope="col">{t('History.Username')}</th>
      <th scope="col">{t('History.Actions')}</th>
    </tr>

  </thead>
  <tbody>
    {old_accounts.map(( old_account, index ) => {
      return(
      <tr key={index}>
      <td>{index + 1/*sponsorship.new_id*/}</td>
      <td>{(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? old_account.date_creation ? old_account.date_creation.substring(0, 10).split("-")[2] + '/' + old_account.date_creation.substring(0, 10).split("-")[1] + '/' + old_account.date_creation.substring(0, 10).split("-")[0] : old_account.date_repositioning.substring(0, 10).split("-")[2] + '/' + old_account.date_repositioning.substring(0, 10).split("-")[1] + '/' + old_account.date_repositioning.substring(0, 10).split("-")[0] : old_account.date_creation ? old_account.date_creation.substring(0, 10).split("-")[1] + '/' + old_account.date_creation.substring(0, 10).split("-")[2] + '/' + old_account.date_creation.substring(0, 10).split("-")[0] : old_account.date_repositioning.substring(0, 10).split("-")[1] + '/' + old_account.date_repositioning.substring(0, 10).split("-")[2] + '/' + old_account.date_repositioning.substring(0, 10).split("-")[0]}</td>
      <td>{old_account.username}</td>
      {/*
      <td> {(old_account.status === "C" ?  t('History.Candidate') :
      old_account.status === "J" ?  t('History.Joker') :
      old_account.status.charAt(0) === "M" ? t('History.Member') + old_account.status.substring(1) : "")}</td>*/}
      {/*<td>{old_account.verticalLevel - user.verticalLevel}</td>*/}
      
      
        <td>
      <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('History.Select_action')}</option>
             <option value= {'{"index": "1", "user": {"_id": "' + old_account._id + '", "new_id": "' + old_account.new_id + '", "username": "' + old_account.username + '", "first_name": "' + old_account.first_name + '", "last_name": "' + old_account.last_name + '", "status": "' + old_account.status + '", "parent_username": "' + old_account.parent_username + '", "parent_id": "' + old_account.parent_id + '", "country": "' + old_account.country + '", "verticalLevel": "' + old_account.verticalLevel + '", "horizontalLevel": "' + old_account.horizontalLevel + '", "gender": "' + old_account.gender + '", "date_of_birth": "' + old_account.date_of_birth + '", "date_creation": "' + old_account.date_creation + '", "date_member": "' + old_account.date_member + '", "date_repositioning": "' + old_account.date_repositioning + '", "confirmed": "' + old_account.confirmed + '", "email": "' + old_account.email +'"}}'}>{t('History.Matrix')}</option>
             <option value= {'{"index": "2", "user": {"_id": "' + old_account._id + '", "new_id": "' + old_account.new_id + '", "username": "' + old_account.username + '", "first_name": "' + old_account.first_name + '", "last_name": "' + old_account.last_name + '", "status": "' + old_account.status + '", "parent_username": "' + old_account.parent_username + '", "parent_id": "' + old_account.parent_id + '", "country": "' + old_account.country + '", "verticalLevel": "' + old_account.verticalLevel + '", "horizontalLevel": "' + old_account.horizontalLevel + '", "gender": "' + old_account.gender + '", "date_of_birth": "' + old_account.date_of_birth + '", "date_creation": "' + old_account.date_creation + '", "date_member": "' + old_account.date_member + '", "date_repositioning": "' + old_account.date_repositioning + '", "confirmed": "' + old_account.confirmed + '", "email": "' + old_account.email +'"}}'}>{t('History.Affiliation_tree')}</option>
             <option value= {'{"index": "3", "user": {"_id": "' + old_account._id + '", "new_id": "' + old_account.new_id + '", "username": "' + old_account.username + '", "first_name": "' + old_account.first_name + '", "last_name": "' + old_account.last_name + '", "status": "' + old_account.status + '", "parent_username": "' + old_account.parent_username + '", "parent_id": "' + old_account.parent_id + '", "country": "' + old_account.country + '", "verticalLevel": "' + old_account.verticalLevel + '", "horizontalLevel": "' + old_account.horizontalLevel + '", "gender": "' + old_account.gender + '", "date_of_birth": "' + old_account.date_of_birth + '", "date_creation": "' + old_account.date_creation + '", "date_member": "' + old_account.date_member + '", "date_repositioning": "' + old_account.date_repositioning + '", "confirmed": "' + old_account.confirmed + '", "email": "' + old_account.email +'"}}'}>{t('History.Payment_table')}</option>
           </select>
        </td>
      </tr>
      );}
    )}

    {old_accounts.length === 0 ? 
          <tr>
          <th colSpan={5}>{t('History.No_partners')}</th>
        </tr>
      : null}


  </tbody>
</table>
</div>
</div>
   
  </Fragment>;


const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">


    {modalFirstButton !== "" ? 
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalFirstButton}</button> :
null
  }



</ModalFooter>
</Modal>

      const trees =
      
      <Fragment>
       <SponsorshipActions />
      
      
    {sponsorships !== null && partners !== null && old_users !== null ? (
 






<div>
{showTable}








  {sponsorships && partners && old_users ? 
  
/*<h1 className='large text-primary'>{ user.username === newUserArray[0].name ? t('Sponsorship.My_affiliation_tree') : t('Language.Lang') === "English" ? (newUserArray && newUserArray[0] ? newUserArray[0].name : "") + t('Sponsorship.Affiliation_tree_of') : t('Language.Lang') === "Français" ? t('Sponsorship.Affiliation_tree_of') + " " + (newUserArray && newUserArray[0] ? newUserArray[0].name : "") : ""}</h1>
  : <h1 className='large text-primary'>{t('Sponsorship.Calculating') + " ..."}</h1>}*/

<h1 className='large text-primary'>{switchTree === 1 ? t('Language.Lang') === "English" ? selectedUser.username + t('History.Affiliation_tree_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('History.Affiliation_tree_of') + " " + selectedUser.username : ""  : switchTree === 2 ? t('Language.Lang') === "English" ? selectedUser.username + t('History.Payment_table_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('History.Payment_table_of') + " " + selectedUser.username : "" : ""}</h1>
/*<h2>{bonus && bonus.length > 0 && switchTree === 2 ? t('History.Position') + ": " + selectedUser.verticalLevel + "-" + selectedUser.horizontalLevel : ""}</h2>
  </div>*/
 : <h1 className='large text-primary'>{t('History.Calculating') + " ..."}</h1>
 }

 

 <div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 
{!loading ? 

switchTree === 1 ?
<div>
<StyledTree data={newUserArray}
direction 
onClick={item => onClickItem(item) }/>
</div>
:
switchTree === 2 ?
showTableGenealogy
:null:
<Spinner />}

</div>
</div>
</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;

      




function onClickItem(newUser) {
  var dateCreation = JSON.parse(JSON.stringify(newUser.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(newUser.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }
  
  var dateMember = JSON.parse(JSON.stringify(newUser.date_member))

  var dateMemberArr = dateMember.split(',');

  var finalDateMember = ""
  for(var i=0; i<dateMemberArr.length; i++){
  if (dateMemberArr[i] !== "") {
    dateMember = dateMemberArr[i].substring(0, 10).split("-")
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[2] + "/" + dateMember[1] + "/" + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('History.Date_member') + " (" + t('History.Member') + "*".repeat(i) + ") : " + dateMember[2] + '/' + dateMember[1] + '/' + dateMember[0]}`;
      }
    }
    else {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('History.Date_member') + " (" + t('History.Member') + "*".repeat(i) + ") : " + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
    } 
  }
}

  var dateRepositioning = JSON.parse(JSON.stringify(newUser.date_repositioning?newUser.date_repositioning:""))
  var finalDateRepositioning = ""
  if (dateRepositioning !== "") {
    dateRepositioning = JSON.parse(JSON.stringify(newUser.date_repositioning)).substring(0, 10).split("-")
    finalDateRepositioning = dateRepositioning[1] + '/' + dateRepositioning[2] + '/' + dateRepositioning[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateRepositioning = dateRepositioning[2] + '/' + dateRepositioning[1] + '/' + dateRepositioning[0];
    }
  }

  /*setModalTitle(item.name);
  setModalBody(
  t('Sponsorship.Status')+" : " + (item.status === "C" ? t('Sponsorship.Candidate') :
  item.status === "J" ? t('Sponsorship.Joker') :
  item.status.charAt(0) === "M" ? t('Sponsorship.Member') + item.status.substring(1) : "" ) +
  "\n"+t('Sponsorship.Guarantor_username')+" : " + (item.parent_username !== '' ?
  item.parent_username : "\""+t('Sponsorship.None')+"\"")  +
  "\n"+t('Sponsorship.Country')+" : " + item.country +
  "\n"+t('Sponsorship.Level')+" : " + (item.verticalLevel - user.verticalLevel)
  );
  setModalFirstButton("");
  setModalFirstButton(t('Sponsorship.Ok'));
 setModalIsOpen(true); */


 setModalTitle(JSON.parse(JSON.stringify(newUser.name)));
 setModalBody(
 t('History.Status')+" : " + (JSON.parse(JSON.stringify(newUser.status)) === "C" ? t('History.Candidate') :
 JSON.parse(JSON.stringify(newUser.status)) === "J" ? t('History.Joker') :
 JSON.parse(JSON.stringify(newUser.status)) === "A" ? t('History.Member') :
 JSON.parse(JSON.stringify(newUser.status)) === "B" ? t('History.Candidate_member') :
 JSON.parse(JSON.stringify(newUser.status)).charAt(0) === "M" ? t('History.Actif_member') + JSON.parse(JSON.stringify(newUser.status)).substring(1) : "") +
 "\n"+t('History.Guarantor')+" : " + (JSON.parse(JSON.stringify(newUser.parent_username)) !== '' ?
 JSON.parse(JSON.stringify(newUser.parent_username)) : t('History.None'))  +
 "\n"+t('History.Country')+" : " + t(`Country.${JSON.parse(JSON.stringify(newUser.country))}`) +
 "\n"+t('History.Level')+" : " + JSON.parse(JSON.stringify(newUser.verticalLevel - (selectedUser ? selectedUser.verticalLevel : user.verticalLevel))) +
    (finalDateCreation !== "" ? ("\n"+t('History.Subscription_date')+" : "  + finalDateCreation) : "") +
    (finalDateMember !== "" ? ("\n"+t('History.Date_member') +" (" + t('History.Member')+") : "  + finalDateMember) : "") +
    (finalDateRepositioning !== "" ? ("\n"+t('History.Date_repositioning')+" : "  + finalDateRepositioning) : "")
 );
 setModalFirstButton(t('History.Ok'));
setModalIsOpen(true);
 }

/*function onRefreshItem(id, i) {
         <td>{<button type="button" className="btn btn-primary" onClick={() => onRefreshItem(old_account.id, index)}>{t('Sponsorship.Consult_tree')}</button>}</td>

  getPartners(id);
  setNewIndex(i)
  window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
}*/


const dropDownSelected = event => {
  if (JSON.parse(event).index === "1") {

      setSelectedUser(JSON.parse(event).user)
    history.push({
      pathname: '/full-matrices-matrix',
      state: { selectedUser: JSON.parse(event).user }
    })
  }
     else if (JSON.parse(event).index === "2") {
      setSwitchTree(1);
      setSelectedUser(JSON.parse(event).user)
      getPartners(JSON.parse(event).user._id)
      window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
    else if (JSON.parse(event).index === "3") {
          setSwitchTree(2);
          if (bonus_old && bonus_old !== undefined) {
          setSelectedUser(JSON.parse(event).user);
          getGenealogies(JSON.parse(event).user._id, true, false);
          getSponsorships(JSON.parse(event).user._id, true, false);
        }
          window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
        }
  }

return loading && sponsorships === null && partners === null && old_users === null ? (
  <Spinner />
) : (
  <Fragment>

  {showModal}
  {trees}


</Fragment>
);
};


History.propTypes = {
  getSponsorships: PropTypes.func.isRequired,
  getPartners: PropTypes.func.isRequired,
  getGenealogies: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getSponsorships, getPartners, getGenealogies }
)(History);


