import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';

const NotFound = ({isAuthenticated}) => {
  const { t } = useTranslation();

  function handleClick(lang) {
    i18next.changeLanguage(lang)
  }

  useEffect(() => {
    if (!isAuthenticated) {
    handleClick('fr');
    }
  }, [isAuthenticated]);
  
  return (
    <Fragment>
    <section className="container">
      <h1 className='x-large text-primary'>
        <i className='fas fa-exclamation-triangle' /> {t('NotFound.Page')}
      </h1>
      <p className='large'>{t('NotFound.Sorry')}</p>
      </section>
    </Fragment>
  );
};


NotFound.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(NotFound);

