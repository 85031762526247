import {
    ADD_USER_SUCCESS,
    EDIT_PROFILE_SUCCESS,
    GET_SPONSORSHIPS,
    GET_LEVEL_AFFILIATION,
    GET_LEVEL_AFFILIATION_REPOSITIONED,
    GET_LEVEL_AFFILIATION_OTHER,
    GET_LEVEL_AFFILIATION_REPOSITIONED_OTHER,
    GET_LEVEL_AFFILIATION_OLD,
    GET_LEVEL_AFFILIATION_REPOSITIONED_OLD,
    GET_BONUS_GENEALOGY_OLD,
    GET_DELETED_AFFILIATION,
    GET_FULL_MATRIX,
    GET_OLD_USER,
    GET_CANDIDATE_WARNING,
    GET_BONUS_GENEALOGY,
    GET_BONUS_GENEALOGY_OTHER,
    GET_OLD_USERS,
    SPONSORSHIP_ERROR,
    UPDATE_SPONSORSHIPS,
    GET_GENEALOGIES,
    GET_GENEALOGIES_THIS_MONTH,
    GET_BONUS,
    GET_GENEALOGIES_OTHER,
    GET_GENEALOGIES_THIS_MONTH_OTHER,
    GET_BONUS_OTHER,
    GET_GENEALOGIES_OLD,
    GET_GENEALOGIES_THIS_MONTH_OLD,
    GET_BONUS_OLD,
    GENEALOGY_ERROR,
    GET_PARTNERS,
    PARTNER_ERROR,
    GET_ADMIN_PARTNERS,
    ADMIN_PARTNER_ERROR,
    DELETE_USER,
    DELETE_USER_ERROR,
    ALL_USERS,
    UPDATE_USERS,
    ALL_USERS_ERROR,
    REPOSITIONING_USER,
    REPOSITIONING_USER_ERROR,
    PAYED,
    PAYED_ERROR,
    ADD_RIB,
    ADD_RIB_ERROR,
    RIBS,
    RIBS_ERROR,
    DELETE_RIB,
    DELETE_RIB_ERROR,
    UPDATE_RIB,
    UPDATE_RIB_ERROR,
    UPDATE_MONTH,
    UPDATE_MONTH_ERROR,
    VALIDATE_INVOICE,
    VALIDATE_INVOICE_ERROR,
    VALIDATE_PAYMENT,
    VALIDATE_PAYMENT_ERROR,
    ACCEPT_MEMBERSHIP,
    ACCEPT_MEMBERSHIP_ERROR,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    REGISTER_USER_MEMBER,
    REGISTER_USER_MEMBER_ERROR,
    PAY_AFFILIATION_PROGRAM,
    PAY_AFFILIATION_PROGRAM_ERROR,
    GET_AFFILIATION_COMMISSION_NUMBER_YEAR,
    GET_AFFILIATION_COMMISSION_NUMBER_YEAR_OTHER,
  //  LOGOUT
} from '../actions/types';


const initialState = {
    loading: true,
    sponsorship: null,
    accept_membership_user: null,
    sponsorships: [],
    genealogies: [],
    genealogies_other: [],
    genealogies_old: [],
    partners: [],
    actif_and_all_partners: [],
    month: 0,
    admin_partners: [],
    affiliations_commissions_number_year: 0,
    affiliations_commissions_number_year_other: 0,
    error: {}
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_USER_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                add_user: payload
            };
            case UPDATE_USER_SUCCESS:
                                            return {
                                                ...state,
                                                updated_user: payload,
                                                loading: false
                                            };
                                
                                        case UPDATE_USER_FAIL:
                                            return {
                                                ...state,
                                                error: payload,
                                                loading: false
                                            };
            case EDIT_PROFILE_SUCCESS:
                return {
                    ...state,
                    ...payload,
                    loading: false,
                    edit_profile: payload
                };
        case GET_SPONSORSHIPS:
            return {
                ...state,
                sponsorships: payload,
                loading: false
            };
        case GET_LEVEL_AFFILIATION:
            return {
                ...state,
                level_affiliation: payload,
                loading: false
            };

        case GET_LEVEL_AFFILIATION_REPOSITIONED:
                return {
                    ...state,
                    level_affiliation_repositioned: payload,
                    loading: false
                };
                case GET_LEVEL_AFFILIATION_OTHER:
            return {
                ...state,
                level_affiliation_other: payload,
                loading: false
            };

        case GET_LEVEL_AFFILIATION_REPOSITIONED_OTHER:
                return {
                    ...state,
                    level_affiliation_repositioned_other: payload,
                    loading: false
                };
        case GET_LEVEL_AFFILIATION_OLD:
            return {
                ...state,
                level_affiliation_old: payload,
                loading: false
            };

        case GET_LEVEL_AFFILIATION_REPOSITIONED_OLD:
                return {
                    ...state,
                    level_affiliation_repositioned_old: payload,
                    loading: false
                };
                case GET_BONUS_GENEALOGY_OLD:
                    return {
                        ...state,
                        bonus_genealogy_old: payload,
                        loading: false
                    };
            case GET_DELETED_AFFILIATION:
                return {
                    ...state,
                    deleted_affiliation: payload,
                    loading: false
                };
                case GET_FULL_MATRIX:
                    return {
                        ...state,
                        full_matrix: payload !== null ? payload : state.full_matrix,
                        loading: false
                    };  
                    case GET_OLD_USER:
                    return {
                        ...state,
                        old_user: payload !== null ? payload : state.old_user,
                        loading: false
                    };  
                    case GET_CANDIDATE_WARNING:
                    return {
                        ...state,
                        candidate_warning: payload,//payload > 0 ? payload : state.candidate_warning,
                        loading: false
                    };  
                    case GET_BONUS_GENEALOGY:
                        return {
                            ...state,
                            bonus_genealogy: payload,
                            loading: false
                        };     
                        case GET_BONUS_GENEALOGY_OTHER:
                            return {
                                ...state,
                                bonus_genealogy_other: payload,
                                loading: false
                            };          
                    case GET_OLD_USERS:
                            return {
                                ...state,
                                old_users: payload.length > 0 ? payload : state.old_users,
                                loading: false
                            };  

        case SPONSORSHIP_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };

        case UPDATE_SPONSORSHIPS:
            return {
                ...state,
                sponsorships: state.sponsorships.map(sponsorship =>
                    sponsorship.id === payload.id ? { ...sponsorship, children: payload.childs } : sponsorship
                ),
                loading: false
            };

        case GET_GENEALOGIES:
            return {
                ...state,
                genealogies: payload,
                loading: false
            };
            case GET_GENEALOGIES_THIS_MONTH:
                return {
                    ...state,
                    genealogies_this_month: payload,
                    loading: false
                };
                case GET_BONUS:
                        return {
                            ...state,
                            bonus: payload,
                            loading: false
                        };
                
                        case GET_GENEALOGIES_OTHER:
                            return {
                                ...state,
                                genealogies_other: payload,
                                loading: false
                            };
                            case GET_GENEALOGIES_THIS_MONTH_OTHER:
                                return {
                                    ...state,
                                    genealogies_this_month_other: payload,
                                    loading: false
                                };
                                case GET_BONUS_OTHER:
                                        return {
                                            ...state,
                                            bonus_other: payload,
                                            loading: false
                                        };
                                        case GET_AFFILIATION_COMMISSION_NUMBER_YEAR_OTHER:
                                        return {
                                            ...state,
                                            affiliations_commissions_number_year_other: payload,
                                            loading: false
                                        };
                                        case GET_GENEALOGIES_OLD:
                                            return {
                                                ...state,
                                                genealogies_old: payload,
                                                loading: false
                                            };
                                            case GET_GENEALOGIES_THIS_MONTH_OLD:
                                                return {
                                                    ...state,
                                                    genealogies_this_month_old: payload,
                                                    loading: false
                                                };
                                                case GET_BONUS_OLD:
                                                        return {
                                                            ...state,
                                                            bonus_old: payload,
                                                            loading: false
                                                        };
            case GET_AFFILIATION_COMMISSION_NUMBER_YEAR:
                return {
                    ...state,
                    affiliations_commissions_number_year: payload,
                    loading: false
                };
        case GENEALOGY_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };

        case GET_PARTNERS:
            return {
                ...state,
                partners: payload,
                loading: false
            };

        case PARTNER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };

        case GET_ADMIN_PARTNERS:
            return {
                ...state,
                admin_partners: payload,
                loading: false
            };

        case ADMIN_PARTNER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        case DELETE_USER:
            return {
                ...state,
                deleted_user: payload,
                loading: false
            };

        case DELETE_USER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };

        case ALL_USERS:
            return {
                ...state,
                all_users: payload,
                loading: false
            };

            case UPDATE_USERS:
                return {
                    ...state,
                    all_users: state.all_users.map(all_user => all_user._id === payload._id ?
                        // transform the one with a matching id
                        { ...all_user, wallet: payload.wallet } : 
                        // otherwise return original todo
                        all_user
                    ), 
                    loading: false
                };

        case ALL_USERS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
            case REPOSITIONING_USER:
                    return {
                        ...state,
                        repositioning_user: payload,
                        loading: false
                    };
        
                case REPOSITIONING_USER_ERROR:
                    return {
                        ...state,
                        error: payload,
                        loading: false
                    };
                    case PAYED:
                        return {
                            ...state,
                            payed: payload,
                            loading: false
                        };
            
                    case PAYED_ERROR:
                        return {
                            ...state,
                            error: payload,
                            loading: false
                        };
                    case ADD_RIB:
                            return {
                                ...state,
                                ribs: [payload, ...state.ribs],
                                loading: false
                            };
                
                        case ADD_RIB_ERROR:
                            return {
                                ...state,
                                error: payload,
                                loading: false
                            };
                            case RIBS:
                                    return {
                                        ...state,
                                        ribs: payload,
                                        loading: false
                                    };
                        
                                case RIBS_ERROR:
                                    return {
                                        ...state,
                                        error: payload,
                                        loading: false
                                    };
                                    case DELETE_RIB:
                                    return {
                                        ...state,
                                        deleted_rib: payload,
                                        loading: false
                                    };
                        
                                case DELETE_RIB_ERROR:
                                    return {
                                        ...state,
                                        error: payload,
                                        loading: false
                                    };
                                    case UPDATE_RIB:
                                            return {
                                                ...state,
                                                updated_rib: payload,
                                               /* ribs: state.ribs.map(rib =>
                                                    rib._id === payload._id ? payload : rib), */
                                                loading: false
                                            };
                                
                                        case UPDATE_RIB_ERROR:
                                            return {
                                                ...state,
                                                error: payload,
                                                loading: false
                                            };

                                            case UPDATE_MONTH:
                                                return {
                                                    ...state,
                                                    month: payload,
                                                    loading: false
                                                };
                                    
                                            case UPDATE_MONTH_ERROR:
                                                return {
                                                    ...state,
                                                    error: payload,
                                                    loading: false
                                                };

        case VALIDATE_INVOICE:
            return {
                ...state,
                validate_invoice: payload,
                loading: false
            };

        case VALIDATE_INVOICE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
            case VALIDATE_PAYMENT:
                return {
                    ...state,
                    validate_payment: payload,
                    loading: false
                };
    
            case VALIDATE_PAYMENT_ERROR:
                return {
                    ...state,
                    error: payload,
                    loading: false
                };
                case REGISTER_USER_MEMBER:
                    return {
                        ...state,
                        register_user_member: payload,
                        loading: false
                    };
        
                case REGISTER_USER_MEMBER_ERROR:
                    return {
                        ...state,
                        error: payload,
                        loading: false
                    };
                    
                case PAY_AFFILIATION_PROGRAM:
                    return {
                        ...state,
                        register_user_member: payload,
                        loading: false
                    };
        
                case PAY_AFFILIATION_PROGRAM_ERROR:
                    return {
                        ...state,
                        error: payload,
                        loading: false
                    };

                case ACCEPT_MEMBERSHIP:
                    return {
                        ...state,
                        accept_membership_user: payload,
                        loading: false
                    };
        
                case ACCEPT_MEMBERSHIP_ERROR:
                    return {
                        ...state,
                        error: payload,
                        loading: false
                    };
                
   /* case LOGOUT:
        return {
          ...state,
          full_matrix: null,
          old_user: null,
          bonus_genealogy: 0,
          candidate_warning: null
        };*/
        default:
            return state;
    }
}