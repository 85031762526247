
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { addArticle, getArticles } from '../../../actions/article';


import SponsorshipActionsAdmin from './SponsorshipActionsAdmin';

import QuillEditor from '../../layout/QuillEditor';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const AddArticleAdmin = ({
  auth: { user }, addArticle, getArticles
}) => {

  const animatedComponents = makeAnimated();
  const { t } = useTranslation();

  //const [value, setValue] = useState('');
  const [content, setContent] = useState("")
    const [files, setFiles] = useState([])
    const [title, setTitle] = useState('');


    const [countries, setCountries] = useState([])
    const [languages, setLanguages] = useState([])

    useEffect(() => {
      setCountries([])
      setLanguages([])
    }, [t]);
  
  if ( user.role !== 0 ) {
    return <Redirect to="/dashboard" />;
  }


  const onSubmit = async e => {
    e.preventDefault();
    await addArticle(user.username, countries, languages, title, content);
    await getArticles(2, 10, user.country, t('Language.Lang'));
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  };
  
    const onEditorChange = (value) => {
        setContent(value)
    }

    const onFilesChange = (newFiles) => {
        setFiles(newFiles)
        console.log(files)
    }

    const countryOptions = [
      { value: 'ALL', label: t('AddArticle.All') },
      { value: 'BE', label: t('Country.BE') },
      { value: 'FR', label: t('Country.FR') },
      { value: 'IT', label: t('Country.IT') },
      { value: 'LU', label: t('Country.LU') },
      { value: 'CH', label: t('Country.CH') },
      { value: 'GB', label: t('Country.GB') },
    /*  { value: '', label: '', isDisabled: true} */
    ]

    const languageOptions = [
      { value: 'ALL', label: t('AddArticle.All') },
      { value: 'English', label: t('AddArticle.English') },
      { value: 'Français', label: t('AddArticle.French') },
      { value: 'Italiano', label: t('AddArticle.Italian') }
    /*  { value: '', label: '', isDisabled: true} */
    ]



    function onChangeInputCountry (value) {
      if (value && value.length === 0) {
        setCountries(value)
      }
      else if (value && value[value.length - 1] && value[value.length - 1].value === "ALL") {
        setCountries([value[value.length - 1]])
      }
      else if (value && value[0].value && value[0].value === "ALL") {
      }
      else {
        setCountries(value)
      }
   //     setCountries(countries => [...countries, value])

    }
    
    function onChangeInputLanguage (value) {
      if (value && value.length === 0) {
        setLanguages(value)
      }
      else if (value && value[value.length - 1] && value[value.length - 1].value === "ALL") {
        setLanguages([value[value.length - 1]])
      }
      else if (value && value[0].value && value[0].value === "ALL") {
      }
      else {
        setLanguages(value)
      }
    }
  return (
    <Fragment>
      <SponsorshipActionsAdmin />
      <h1 className='large text-primary'>{t('AddArticle.Add_article')}</h1>
      <div className="p-3 mb-2 text-dark">
        <Fragment>
          <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
            <div style={{ textAlign: 'center' }}>
            </div>

            <div className='form'>
<div className="form-group">
            <Select
            theme={newTheme => ({
              ...newTheme,
              borderRadius: 0,
              colors: {
                ...newTheme.colors,
                primary25: '#bf9f52',
                primary: '#bf9f52',
                primary50: '#bf9f52',
                neutral0: '#edd99a',
              },
            })}
      closeMenuOnSelect={true}
      placeholder={t('AddArticle.Country_recipients')}
      components={animatedComponents}
      className="mb-3"
      isMulti
      options={countryOptions}
      noOptionsMessage={() => t('AddArticle.No_other_country')}
      autoFocus
      isSearchable={false}
      value={countries}
      onChange={onChangeInputCountry}
    />
<Select
            theme={newTheme => ({
              ...newTheme,
              borderRadius: 0,
              colors: {
                ...newTheme.colors,
              /*  primary25: theme === "light" ? '#bf9f52' : '#edd99a',
                primary: theme === "light" ? '#bf9f52' : '#edd99a',
                primary50: theme === "light" ? '#bf9f52' : '#edd99a',
                neutral0: theme === "light" ? '#edd99a' : '#bf9f52', */

                primary25: '#bf9f52',
                primary: '#bf9f52',
                primary50: '#bf9f52',
                neutral0: '#edd99a',
              },
            })}
            closeMenuOnSelect={true}
      placeholder={t('AddArticle.Language')}
      components={animatedComponents}
      className="mb-3"
      isMulti
      options={languageOptions}
      noOptionsMessage={() => t('AddArticle.No_other_language')}
      isSearchable={false}
      value={languages}
      onChange={onChangeInputLanguage}
    />
            <div className="form-group">
	<div className="input-group">
		<div className="input-group-prepend">
		 </div>
    <input 
      style={{width:"90%"}}
                type="text"
                placeholder={t('AddArticle.Title')}
                name="title"
                className="form-control"
                required
                  value={title}
                  onChange={e => setTitle(e.target.value)}/>
	</div>
	</div> 
  <div className="bg-light">
            <QuillEditor
                placeholder={t('AddArticle.Share_information') + " ..."}
                onEditorChange={onEditorChange}
                onFilesChange={onFilesChange}
            />
            </div>
            	<form className="form" onSubmit={e => onSubmit(e)}>
	<div className="form-group">
  <input type="submit" className="btn btn-primary btn-block" value={t('AddArticle.Add_article')} />
	</div> 	</form>
  </div></div>
        </div>
        </Fragment>
        </div>
    </Fragment>
  );
};

AddArticleAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
  addArticle: PropTypes.func.isRequired,
  getArticles: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { addArticle, getArticles }
)(AddArticleAdmin);
