import React, { Fragment, useState, useEffect, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { useTranslation } from 'react-i18next';
import { getSponsorships } from '../../actions/sponsorship';

const IdleTimerContainer = ({ auth: { user, isAuthenticated }, logout, getSponsorships }) =>
{

    const [modalsOpen, setModalIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalFirstButton, setModalFirstButton] = useState("");

  const idleTimerRef = useRef(null);
  const idleTimerModalRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
 // const idleTimerActiveRef = useRef(null);
  const sessionTimeoutActiveRef = useRef(null);

  const { t } = useTranslation();
  
    //Only once
    const isInitialMount = useRef(true);

   /* useEffect(() => {
      if (isInitialMount.current && user) {
         isInitialMount.current = false;
    if (isAuthenticated && user) {
      getSponsorships(user._id, false, false, false);
    }
            onActive();
      }
    });*/

    useEffect(() => {
      /*if (isInitialMount.current && user) {
         isInitialMount.current = false; */
         if (isInitialMount.current) {
    if (isAuthenticated && user) {
      sessionTimeoutActiveRef.current = null;
      isInitialMount.current = false;
      getSponsorships(user._id, false, false, false);
      onActive();
    }
    else {
       if (sessionTimeoutActiveRef.current) {
        sessionTimeoutActiveRef.current = null;
        isInitialMount.current = false;
      }
    }
  }
    //  }
    });


    useEffect(() => {
      isInitialMount.current = true;
    }, [isAuthenticated]);


 /* window.onbeforeunload = function () {
    //logout();
  //  setModalIsOpen(true);
    return 'Are you really want to perform the action?';
};*/

  
  const onActive = () => {
    if (isAuthenticated && user) {
        sessionTimeoutActiveRef.current = setTimeout (updateToken, 300000);
    }
    }

  const onIdle = () => {
    if (isAuthenticated && user) {
      getSponsorships(user._id, false, false, false);
        setModalTitle(t('IdleTimerContainer.Idle_while') + "!");
        setModalBody(t('IdleTimerContainer.Want_continue') + "?");
        setModalFirstButton(t('IdleTimerContainer.Logout'));
       setModalIsOpen(true);
    
          sessionTimeoutRef.current = setTimeout (logoutFunc, 60000)
    }
  }

  const stayActive = () => {
   setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
}

const updateToken =  () => {
    if (isAuthenticated && user) {
    getSponsorships(user._id, false, false, false);
    clearTimeout(sessionTimeoutActiveRef.current);
    onActive();
  }
}

const logoutFunc =  () => {
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    clearTimeout(sessionTimeoutActiveRef.current);
    clearTimeout(idleTimerRef.current);
    clearTimeout(idleTimerModalRef.current);
    logout();
}



const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)}  backdrop="static" as="section">
<ModalHeader as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  
<button className="btn btn-danger" onClick={() => logoutFunc()}>{modalFirstButton}</button>
<button className="btn btn-success" onClick={() => stayActive()}>{t('IdleTimerContainer.Continue')}</button>

     
  </ModalFooter>
  </Modal>


return(
    <Fragment>
    <div>
        {isAuthenticated && user ?
      <IdleTimer ref={idleTimerRef} timeout = {300 * 1000} onIdle={onIdle} onActive={onActive}></IdleTimer> : 
      modalsOpen === true ?
      <IdleTimer ref={idleTimerModalRef} timeout = {0.1 * 1000} onIdle={setModalIsOpen(false)}></IdleTimer> : <></>
        }
    </div>
         {showModal}
           </Fragment>
);
}

IdleTimerContainer.propTypes = {
    logout: PropTypes.func.isRequired,
    getSponsorships: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  };

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps,
    { logout, getSponsorships })(IdleTimerContainer);
