import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const GoodDealsActions = () => {

  const { t } = useTranslation();

  return (
    <div className='dash-buttons'>
    <Link to='/all-deals' className='btn btn-dark text-button-color mb-2'>
      <i className='fab fa-black-tie text-primary' /> {t('GoodDeals.Good_deals')}
    </Link>
      <Link to='/add-good-deal' className='btn btn-dark text-button-color mb-2'>
        <i className='fab fa-black-tie text-primary' /> {t('GoodDeals.Add_new_deal')}
      </Link>
    </div>
  );
};

export default GoodDealsActions;
