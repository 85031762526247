import {  axios } from '../utils/axios';
import { setAlert } from './alert';
import {
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAIL,
    SHOW_LOADING_API,
    HIDE_LOADING_API
} from './types';


// Send Email Contact
export const sendEmail = ({ name, email, subject, message }) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ name, email, subject, message });

  try {
    const res = await axios.post('/api/users/send_email/', body, config);

    dispatch({
      type: SEND_EMAIL_SUCCESS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch(setAlert('server_17_user', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: SEND_EMAIL_FAIL
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  }
};