import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
//import { Button } from 'react-bootstrap';

const SponsorshipActionsDemo = 
({ auth: { user }, sponsorship: {month} }) => {
  const { t } = useTranslation();
  let history = useHistory();
  

const [modalsOpen, setModalIsOpen] = useState(false);



const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{t('Sponsorship.Demonstration_mode')}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{t('Sponsorship.Demo_coopt')}</ModalBody>
<ModalFooter as="footer">
 <button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{t('Sponsorship.Ok')}</button>
</ModalFooter>
</Modal>
  return (
    <div className='dash-buttons'>

      <Link to='/demo/affiliations' onClick={() => window.location.pathname === '/demo/affiliations' ? history.go() : console.log("")} className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Affiliation_tree')}
      </Link>
      <Link to='/demo/matrix' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Matrix')}
      </Link>
      <Link to='/demo/payment-table' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Payment_table')}
      </Link>
      <Link to='/demo/digital-wallet' className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Digital_wallet')}
      </Link>
     <Link  to= {`${window.location.pathname}`} onClick={() => setModalIsOpen(true)} className='btn btn-dark text-button-color mb-2'>
        <i className='fas fa-user-circle text-primary' /> {t('Sponsorship.Co-opt') + (month === 1 || month === 2 ? " (1)" : " (3)")}
           </Link>

      <br /><br /><br />
      {showModal}
    </div>

  );
};

SponsorshipActionsDemo.propTypes = {
    auth: PropTypes.object.isRequired,
    sponsorship: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = state => ({
    auth: state.auth,
    sponsorship: state.sponsorship
  });
  
  export default connect(
    mapStateToProps,
    { }
  )(SponsorshipActionsDemo);
  