import {  axios } from '../utils/axios';
import { setAlert } from './alert';
import {
  ADD_TRANSFER_SUCCESS,
  ADD_TRANSFER_FAIL,
  SHOW_LOADING_API,
  HIDE_LOADING_API,
  UPDATE_STATUS,
  UPDATE_TOKEN,
  TRANSACTIONS,
  UPDATE_TRANSFER,
  UPDATE_TRANSFER_ERROR,
  TRANSFERS_SUCCESS,
  TRANSFERS_FAIL,
  LOGOUT
} from './types';




// Add transfer
export const addTransfer = (id, card_holder, iban, bic, domiciliation, amount) => async dispatch => {
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify({ id, card_holder, iban, bic, domiciliation, amount });

      const body1 = JSON.stringify({ id : id });

    try {
      const res = await axios.post('/api/transfers/add_transfer/', body, config);

      if (res) {

        const res1 = await axios.post('/api/users/sponsorship/', body1, config);

        dispatch({
            type: UPDATE_STATUS,
            payload: res1.data.item
          });

          dispatch({
            type: UPDATE_TOKEN,
            payload: res1.data.token
          });


          const res2 = await axios.post('/api/transactions/get_transactions/', body1, config);

          dispatch({
            type: TRANSACTIONS,
            payload: res2.data
          });
        
    }
  
      dispatch({
        type: ADD_TRANSFER_SUCCESS,
        payload: res.data
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch(setAlert('server_3_transfer', 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.msg, 'danger'));
      
      dispatch({
        type: ADD_TRANSFER_FAIL
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    }
  };





  // Update transfer
export const updateTransfer = (id, state) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ id, state });
    
    try {
      const res = await axios.post('/api/transfers/update_transfer/', body, config);
  
      if (res) {
  
  
  
          const res2 = await axios.post('/api/transfers/get_transfers/', body, config);
  
          dispatch({
            type: TRANSFERS_SUCCESS,
            payload: res2.data
          });
  
  
      }
      dispatch({
        type: UPDATE_TRANSFER,
        payload: res.data
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: UPDATE_TRANSFER_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };




  // Get transactions
export const getTransfers = () => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify({ });
    
    try {
      const res = await axios.post('/api/transfers/get_transfers/', body, config);
      dispatch({
        type: TRANSFERS_SUCCESS,
        payload: res.data
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: TRANSFERS_FAIL,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };