import {
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAIL,
} from '../actions/types';



const initialState = {
    loading: true,
    error: {}
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                send_email: payload
            };
        case SEND_EMAIL_FAIL:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}