import {  axios } from '../utils/axios';
import { setAlert } from './alert';
import {
  ADD_ARTICLE_SUCCESS,
  ARTICLES_SUCCESS,
  FIRST_ARTICLES_SUCCESS,
  ARTICLES_FAIL,
  ARTICLES_NEXT,
  ARTICLES_CURRENT,
  ALL_ARTICLES_SUCCESS,
  ALL_ARTICLES_FAIL,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_FAIL,
  SHOW_LOADING_API,
  HIDE_LOADING_API,
  LOGOUT
} from './types';




// Add Article
export const addArticle = (username, countries, languages, title, content) => async dispatch => {
  console.log(countries)
    dispatch({
      type: SHOW_LOADING_API
    });
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      var language = null
      if (languages) {
        const newLanguages = languages.map(function(lang) {
          return lang['value'];
        });
         language = newLanguages.join();
      }

      var country = null
      if (countries) {
      const newCountries = countries.map(function(country) {
        return country['value'];
      });
      country = newCountries.join();
    }

      const body = JSON.stringify({ username, country, language, title, content });

    try {
      const res = await axios.post('/api/articles/add_article/', body, config);
      
      dispatch({
        type: ADD_ARTICLE_SUCCESS,
        payload: res.data
      });
 
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch(setAlert('server_7_article', 'success'));
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }
      
        //dispatch(setAlert(err.response.data.msg, 'danger'));
  
      dispatch({
        type: HIDE_LOADING_API
      });
    }
  };




  // Get articles with pagination
  export const getArticles = (page, limit, country, language) => async dispatch => {
   

    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify({ page: 1, limit: limit, country, language });


      const body1 = JSON.stringify({ page: page, limit: limit, country, language });
    
    try {
      const res = await axios.post('/api/articles/get_articles/', body, config);
      const res1 = await axios.post('/api/articles/get_articles/', body1, config);
     /* console.log("from action :" + res.data.results)

      console.log("hoo")
console.log(res.data)
console.log("haa")*/
      dispatch({
        type: ARTICLES_NEXT,
        payload: res1.data.next ? res1.data.next : null
      });
      dispatch({
        type: ARTICLES_CURRENT,
        payload: res1.data.current
      });
        dispatch({
          type: FIRST_ARTICLES_SUCCESS,
          payload: res.data.results
        });

        dispatch({
          type: ARTICLES_SUCCESS,
          payload: res1.data.results
        });
        
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: ARTICLES_FAIL,
    //    payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };

  


    // Get all articles
export const getAllArticles = () => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ });
  
  try {
    const res = await axios.post('/api/articles/get_all_articles/', body, config);
    dispatch({
      type: ALL_ARTICLES_SUCCESS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: ALL_ARTICLES_FAIL,
   //   payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};



  // Get transactions
  export const getArticleById = (id) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      
      const body = JSON.stringify({ _id: id});
    
    try {
      const res = await axios.post('/api/articles/get_article_by_id/', body, config);

   

      dispatch({
        type: GET_ARTICLE_SUCCESS,
        payload: res.data
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: GET_ARTICLE_FAIL,
    //    payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };