import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LegalNotices = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
    <section className="container">
    <h1 className='large text-primary'>{t('LegalNotices.Title')}</h1>
    <details>
    <summary>I. {t('LegalNotices.Subtitle1')}</summary>
      <p>
    <div style={{marginLeft : "2em"}}>
    <strong>● </strong>
      {t('LegalNotices.Paragraph1')} < br/>
    <strong>● </strong>
      {t('LegalNotices.Paragraph2')} < br/>
    <div style={{marginLeft : "1em"}}> 
      {t('LegalNotices.Paragraph3')} < br/>
      </div>
    <strong>● </strong>
      {t('LegalNotices.Paragraph4')} < br/>
    <strong>● </strong>
      {t('LegalNotices.Paragraph5')}<br />
    <strong>● </strong>
      {t('LegalNotices.Paragraph6')}<br /><br />
      </div>
    </p>
  </details>

  <details>
    <summary>II. {t('LegalNotices.Subtitle2')}</summary>
    <p>
    <div style={{marginLeft : "2em"}}>
    <strong>● </strong>
      {t('LegalNotices.Paragraph7')} < br/>
    <strong>● </strong>
      {t('LegalNotices.Paragraph8')} < br/>
    <strong>● </strong>
      {t('LegalNotices.Paragraph9')} < br/>
    <div style={{marginLeft : "1em"}}> 
      {t('LegalNotices.Paragraph10')} < br/>
      </div>
    <strong>● </strong>
      {t('LegalNotices.Paragraph11')} < br/>
    <strong>● </strong>
      {t('LegalNotices.Paragraph12')}<br /><br />
      </div>
    </p>
  </details>

    <details>
    <summary>III. {t('LegalNotices.Subtitle3')}</summary>
    <p>
    {t('LegalNotices.Paragraph13')}<br />
    {t('LegalNotices.Paragraph14')}
    </p>
  </details>

  <details>
    <summary>IV. {t('LegalNotices.Subtitle4')}</summary>
    <p>
    {t('LegalNotices.Paragraph15')}<br /><br />{t('LegalNotices.Paragraph16')}<br /><br />
    {t('LegalNotices.Paragraph17')}
    </p>
  </details>

  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

LegalNotices.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(LegalNotices);
