import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

const DashboardAdmin = ({
  auth: { user }
}) => {

  
  const { t } = useTranslation();
  if ( user.role !== 0 ) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Fragment>
      <h1 className='large text-primary'>{t('AdminDashboard.AdminDashboard')}</h1>
      <p className='lead'>
        <i className='fas fa-user' /> {t('AdminDashboard.Welcome')} {user && user.username}
      </p>

        <Fragment>
          <p>{t('AdminDashboard.Subtitle')}</p>
        </Fragment>
    
    </Fragment>
  );
};

DashboardAdmin.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(DashboardAdmin);
