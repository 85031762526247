import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
//import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import SponsorshipActions from './SponsorshipActions';
import Spinner from '../layout/Spinner';
import { getSponsorships, addRib, getRibs, deleteRib, updateRib } from '../../actions/sponsorship';
import { getTransactions, getInvoice } from '../../actions/transaction';
import { addTransfer } from '../../actions/transfer';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Pagination from '../layout/Pagination';
import { useTranslation } from 'react-i18next';

const DigitalWallet = ({
    getTransactions,
    getSponsorships,
    addRib,
    getRibs,
    updateRib,
    deleteRib,
    addTransfer,
    getInvoice,
  auth: { user, theme }, transaction: { transactions }, sponsorship: { sponsorships, ribs, loading }
}) => {

    const [modalsOpen, setModalIsOpen] = useState(false);
    const [secondModalsOpen, setSecondModalIsOpen] = useState(false);
    const [thirdModalsOpen, setThirdModalIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalFirstButton, setModalFirstButton] = useState("");
    const [modalSecondButton, setModalSecondButton] = useState("");


    const [cardHolder, setCardHolder] = useState("");
    const [iban, setIban] = useState("");
    const [bic, setBic] = useState("");
    const [domiciliation, setDomiciliation] = useState("");


    const [amount, setAmount] = useState("");


    const [selectedCard, setSelectedCard] = useState("");

     const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(5);




  useEffect(() => {
    getTransactions(user._id);
  }, [getTransactions, user._id]);

  useEffect(() => {
    getRibs(user._id);
  }, [getRibs, user._id]);

  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);

  const { t } = useTranslation();

  if (user && (user.status === "C" || user.status === "A")) {
    return <Redirect to="/dashboard" />;
  }

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;

  var currentTransactions = [];

  /*if (!user.payed) {
    return <Redirect to="/affiliations" />;
  }*/


  if (transactions && transactions.length > 0) {
    currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);
  }


  const paginate = pageNum => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage === Math.ceil(transactions.length / transactionsPerPage) ? currentPage : currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(Math.ceil(transactions.length / transactionsPerPage));





  const showTableRibs =
      
      
  <Fragment>



<div
style={{
display: "flex",
justifyContent: "center",
alignItems: "center"
}}>

<div className="scrollme"> 




    
<table id="tbl"  style={{ width : 1000 }} className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
<thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
<tr>
  <th scope="col">{t('DigitalWallet.Date')}</th>
  <th scope="col">{t('DigitalWallet.Bank')}</th>
  <th scope="col">{t('DigitalWallet.Iban')}</th>
  <th scope="col">{t('DigitalWallet.Actions')}</th>
</tr>
</thead>
<tbody>


{!ribs ? 
      <tr>
      <th colSpan={4}>{t('DigitalWallet.Calculating') + " ..."}</th>
    </tr>
  :
  ribs.length === 0 ?
  <tr>
      <th colSpan={4}>{t('DigitalWallet.No_ribs')}</th>
    </tr>
   :
   ribs.map(( get_rib, index ) => {
var date = get_rib.date_creation.substring(0, 10).split("-")
var finalDate = date[1] + '/' + date[2] + '/' + date[0];
  if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
    finalDate = date[2] + '/' + date[1] + '/' + date[0];
  }
 return (
<tr key={index}>
    <td>{finalDate}</td>
  <td>{get_rib.domiciliation}</td>
  <td>{get_rib.iban}</td>
  <td>
    {user.status === "C" || user.status === "A" || user.status === "B" ?
  <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
  <option value="">{t('DigitalWallet.Select_action')}</option>
  <option value= {'{"index": "2", "rib": {"_id": "' + get_rib._id + '", "card_holder": "' + get_rib.card_holder  + '", "iban": "' + get_rib.iban + '", "bic": "' + get_rib.bic + '", "domiciliation": "' + get_rib.domiciliation +'"}}'}>{t('DigitalWallet.Modify')}</option>
  <option value= {'{"index": "3", "rib": {"_id": "' + get_rib._id + '", "card_holder": "' + get_rib.card_holder  + '", "iban": "' + get_rib.iban + '", "bic": "' + get_rib.bic + '", "domiciliation": "' + get_rib.domiciliation +'"}}'}>{t('DigitalWallet.Delete')}</option>
</select>
    :
    <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('DigitalWallet.Select_action')}</option>
             <option value= {'{"index": "1", "rib": {"_id": "' + get_rib._id + '", "card_holder": "' + get_rib.card_holder  + '", "iban": "' + get_rib.iban + '", "bic": "' + get_rib.bic + '", "domiciliation": "' + get_rib.domiciliation +'"}}'}>{t('DigitalWallet.Transfer_money')}</option>
             <option value= {'{"index": "2", "rib": {"_id": "' + get_rib._id + '", "card_holder": "' + get_rib.card_holder  + '", "iban": "' + get_rib.iban + '", "bic": "' + get_rib.bic + '", "domiciliation": "' + get_rib.domiciliation +'"}}'}>{t('DigitalWallet.Modify')}</option>
             <option value= {'{"index": "3", "rib": {"_id": "' + get_rib._id + '", "card_holder": "' + get_rib.card_holder  + '", "iban": "' + get_rib.iban + '", "bic": "' + get_rib.bic + '", "domiciliation": "' + get_rib.domiciliation +'"}}'}>{t('DigitalWallet.Delete')}</option>
           </select>
  }

  </td>
</tr>
);
})}

</tbody>
</table>
</div>
</div>
</Fragment>;












//var penalityCounter = 0

  const showTableTrasactions =
      
      
  <Fragment>
  
  
<h1 className='large text-primary'>{t('DigitalWallet.Activities')}</h1>



<div
style={{
display: "flex",
justifyContent: "center",
alignItems: "center"
}}>

<div className="scrollme"> 




    
<table id="tbl" style={{ width : 1000 }} className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
<thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
<tr>
  <th scope="col">{t('DigitalWallet.Ref')}</th>
  <th scope="col">{t('DigitalWallet.Date')}</th>
  <th scope="col">{t('DigitalWallet.Description')}</th>
  <th scope="col">{t('DigitalWallet.Amount')}</th>
  <th scope="col">{t('DigitalWallet.Balance')}</th>
  <th scope="col">{t('DigitalWallet.Action')}</th>
</tr>
</thead>
<tbody>


{!transactions ? 
      <tr>
      <th colSpan={6}>{t('DigitalWallet.Calculating') + " ..."}</th>
    </tr>
  :
  transactions.length === 0 ?
  <tr>
      <th colSpan={6}>{t('DigitalWallet.No_activities')}</th>
    </tr>
   :
   currentTransactions.map(( get_transaction, index ) => {
// console.log("sponsorships"+JSON.stringify(sponsorship))
var date = get_transaction.date_creation.substring(0, 10).split("-")
var finalDate = date[1] + '/' + date[2] + '/' + date[0];
  if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
    finalDate = date[2] + '/' + date[1] + '/' + date[0];
  }
 return (
   get_transaction.availability === 1 ?
<tr key={index}>
  <td>{get_transaction.new_id}</td>
    <td>{finalDate}</td>
  <td>{t(`Server.${get_transaction.title}`) + (/*get_transaction.title === "server_12_transaction" ? " " + ++penalityCounter : */get_transaction.transaction_rib !== "" ? " " + t('Server.server_21_transaction') + " " + get_transaction.transaction_rib : get_transaction.transaction_username !== "" ? " " + t('Server.server_13_transaction') + " " + get_transaction.transaction_username : "") + ((get_transaction.transaction_type === 4 || get_transaction.transaction_type === 5) ? " - (" + t('Server.server_22_transaction') + " ...)" : ((get_transaction.transaction_type === 8 || get_transaction.transaction_type === 10) ? " - (" + t('Server.server_23_transaction') + ")" : ""))}</td>
  <td><font color={/*#03925e*/get_transaction.amount >= 0 ? "" : "red"}>{get_transaction.amount.toFixed(2) + "€"}</font></td>
  <td>{get_transaction.balance.toFixed(2) + "€"}</td>
  <td>
    {get_transaction.transaction_type === 0 || get_transaction.transaction_type === 2 || get_transaction.transaction_type === 3 || get_transaction.transaction_type === 13 ?
<button type="button" className="btn btn-primary" onClick={() => getInvoice(get_transaction.user, get_transaction.username, get_transaction, t('Language.Lang'), t(`Country.${get_transaction.country}`))}>{t('DigitalWallet.Consult_invoice')}</button>
    :
    get_transaction.transaction_type === 1 ?
<button type="button" className="btn btn-primary" onClick={() => getInvoice(get_transaction.user, get_transaction.username, get_transaction, t('Language.Lang'), t(`Country.${get_transaction.country}`))}>{t('DigitalWallet.Consult_file')}</button>
:
get_transaction.transaction_type === 8 ?
<button type="button" className="btn btn-primary" onClick={() => getInvoice(get_transaction.user, get_transaction.username, get_transaction, t('Language.Lang'),t(`Country.${get_transaction.country}`))}>{t('DigitalWallet.Consult_receipt')}</button>
:
    t('DigitalWallet.No_action')
  }
</td>
</tr>
: 

<tr key={index}>
  <td>{get_transaction.new_id}</td>
    <td>{finalDate}</td>
  <td>{t(`Server.${get_transaction.title}`) + (/*get_transaction.title === "server_12_transaction" ? " " + ++penalityCounter : */get_transaction.transaction_rib !== "" ? " " + t('Server.server_21_transaction') + " " + get_transaction.transaction_rib : get_transaction.transaction_username !== "" ? " " + t('Server.server_13_transaction') + " " + get_transaction.transaction_username : "") + ((get_transaction.transaction_type === 4 || get_transaction.transaction_type === 5) ? " - (" + t('Server.server_22_transaction') + " ...)" : ((get_transaction.transaction_type === 8 || get_transaction.transaction_type === 10) ? " - (" + t('Server.server_23_transaction') + ")" : ""))}</td>
  <td>{t('DigitalWallet.Calculating') + " ..."}</td>
  <td>{t('DigitalWallet.Calculating') + " ..."}</td>
  <td>{t('DigitalWallet.Calculating') + " ..."}</td>
</tr>

);
})}

</tbody>
</table>
</div>
</div>

<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>
<div className="scrollme"> 
<table>
{ transactions && transactions.length > 0 ? <Pagination usersPerPage={transactionsPerPage} totalUsers={transactions && transactions.length > 0 ? transactions.length : 0} paginate={paginate} nextPage={nextPage} prevPage={prevPage} firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} /> : null }
</table>
</div>
</div>
</Fragment>;


const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}><p style={{
  color: "red"}}>{modalBody}</p>
 
 <form className="form">
        <div className="form-group">
        <label for="cardHolder">{t('DigitalWallet.Holder')}</label>
          <input
           className="form-control"
            type="text"
            placeholder={t('DigitalWallet.Holder')}
            name="cardHolder"
            value={cardHolder.replace(/[^a-zA-Z ]/g, '')}
            readOnly = "readOnly"
            onChange={e => cardHolderChanged(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder={t('DigitalWallet.Iban')}
            name="iban"
            value={iban.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => ibanChanged(e.target.value)}
            minLength="3"
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder={t('DigitalWallet.Bic')}
            name="bic"
            value={bic.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => bicChanged(e.target.value)}
            minLength="3"
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder={t('DigitalWallet.Domiciliation')}
            name="domiciliation"
            value={domiciliation.replace(/[^a-zA-Z ]/g, '')}
            onChange={e => domiciliationChanged(e.target.value)}
            minLength="3"
          />
        </div>
      </form>
      </ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => selectedCard ? updateButtonClicked() : addButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }
</ModalFooter>
</Modal>











const showSecondModal = 
<Modal className="my-modal"show={secondModalsOpen} onHide={() => setSecondModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}
      </ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-danger" onClick={() => removeButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setSecondModalIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }
</ModalFooter>
</Modal>




const showThirdModal = 
<Modal className="my-modal" show={thirdModalsOpen} onHide={() => setThirdModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  color: "red",
whiteSpace: "pre-line"
 }}>{modalBody}
 {user.wallet > 200 ?
  <form className="form">
        <div className="form-group">
          <input
            type="text"
            placeholder={t('DigitalWallet.Amount')}
            name="amount"
            value={amount.replace(/\D/,'')}
            onPaste={e=>{
              e.preventDefault();
              return false
            }}
            onChange={e => amountChanged(e.target.value)}
            minLength="1"
          />
        </div>
      </form>
 : null }
      </ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => transferButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setThirdModalIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



const dropDownSelected = event => {
    if (JSON.parse(event).index === "1") {
     /*   setModalTitle(t('DigitalWallet.Transfer_money'));
        setModalBody(t('DigitalWallet.In_development'));
        setModalFirstButton("");
        setModalSecondButton(t('DigitalWallet.Ok'));
        setSecondModalIsOpen(true);*/

        transfertAmount(JSON.parse(event).rib);
    }
    else if (JSON.parse(event).index === "2") {
        modifyCard(JSON.parse(event).rib);
    }
    else if (JSON.parse(event).index === "3") {
        removeCard(JSON.parse(event).rib);
    }
  }

function addCard() {
    setSelectedCard(null)
    setCardHolder(user.first_name + " " + user.last_name)
    setIban("")
    setBic("")
    setDomiciliation("")
    setModalTitle(t('DigitalWallet.Add_account'));
    setModalBody("");
    setModalFirstButton(t('DigitalWallet.Add'));
    setModalSecondButton("");
   setModalIsOpen(true);
  }

  function transfertAmount(newRib) {
    setSelectedCard(newRib)
    setModalTitle(t('DigitalWallet.Transfer_money'));
    setModalBody(t('DigitalWallet.Wrong_amount3'));
    if (user.wallet <= 200) {
      setModalFirstButton("");
      setModalSecondButton(t('DigitalWallet.Ok'));
      setThirdModalIsOpen(true);
    }
    else {
      setModalTitle(t('DigitalWallet.Transfer_money'));
      setModalBody("");
      setModalFirstButton(t('DigitalWallet.Transfer'));
      setModalSecondButton("");
      setThirdModalIsOpen(true);
    }
  }

  function modifyCard(newRib) {
    setSelectedCard(newRib)
    setCardHolder(newRib.card_holder)
    setIban(newRib.iban)
    setBic(newRib.bic)
    setDomiciliation(newRib.domiciliation)
    setModalTitle(t('DigitalWallet.Modify_card_sure') + "?");
    setModalBody("");
    setModalFirstButton(t('DigitalWallet.Modify'));
    setModalSecondButton("");
    setModalIsOpen(true);
  }

  function removeCard(newRib) {
    setSelectedCard(newRib)
    setModalTitle(t('DigitalWallet.Remove_card_sure') + "?");
    setModalBody(
        t('DigitalWallet.Holder')+" : " + JSON.parse(JSON.stringify(newRib.card_holder)) +
       "\n"+t('DigitalWallet.Iban')+" : " + JSON.parse(JSON.stringify(newRib.iban)) +
       "\n"+t('DigitalWallet.Bic')+" : " + JSON.parse(JSON.stringify(newRib.bic)) +
       "\n"+t('DigitalWallet.Domiciliation')+" : " + JSON.parse(JSON.stringify(newRib.domiciliation))
      );
    setModalFirstButton(t('DigitalWallet.Delete'));
    setModalSecondButton("");
    setSecondModalIsOpen(true);
  }

  function cardHolderChanged(value) {
    if (value.length > 0) {
    setModalBody("");
    }
    else {
        setModalBody(t('DigitalWallet.Wrong_card_holder'));
    }
        setCardHolder(value);
  }

  function ibanChanged(value) {
    if (value.length > 0) {
    setModalBody("");
    }
    else {
        setModalBody(
            t('DigitalWallet.Wrong_iban'));
    }
        setIban(value);
  }

  function amountChanged(value) {
    if (value >= 100 && value <= user.wallet - 100) {
    setModalBody("");
    }
    else {
        setModalBody(
            t('DigitalWallet.Wrong_amount1') + " 100€ " + t('DigitalWallet.Wrong_amount2') + " " + (user.wallet - 100) + "€");
    }
        setAmount(value);
  }

  function bicChanged(value) {
    if (value.length > 0) {
    setModalBody("");
    }
    else {
        setModalBody(
            t('DigitalWallet.Wrong_bic'));
    }
        setBic(value);
  }

  function domiciliationChanged(value) {
    if (value.length > 0) {
    setModalBody("");
    }
    else {
        setModalBody(
            t('DigitalWallet.Wrong_domiciliation'));
    }
    setDomiciliation(value);
  }

  function addButtonClicked() {
    if (cardHolder.length < 1) {
        setModalBody(
            t('DigitalWallet.Wrong_card_holder'));
    }
    else if (iban.length < 1) {
        setModalBody(
            t('DigitalWallet.Wrong_iban'));
    }
    else if (bic.length < 1) {
        setModalBody(
            t('DigitalWallet.Wrong_bic'));
    }
    else if (domiciliation.length < 1) {
        setModalBody(
            t('DigitalWallet.Wrong_domiciliation'));
    }
    else {
        addRib( cardHolder, iban, bic, domiciliation );
        setModalIsOpen(false);
      //  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }
  }

  function updateButtonClicked() {
 /*   if (cardHolder.length < 5) {
        setModalBody(
            t('DigitalWallet.Wrong_card_holder'));
    }
    else if (iban.length !== 23) {
        setModalBody(
            t('DigitalWallet.Wrong_iban'));
    }
    else if (bic.length !== 4) {
        setModalBody(
            t('DigitalWallet.Wrong_bic'));
    }
    else if (bic.length < 3) {
        setModalBody(
            t('DigitalWallet.Wrong_domiciliation'));
    }*/
    if (cardHolder.length < 1) {
      setModalBody(
          t('DigitalWallet.Wrong_card_holder'));
  }
  else if (iban.length < 1) {
      setModalBody(
          t('DigitalWallet.Wrong_iban'));
  }
  else if (bic.length < 1) {
      setModalBody(
          t('DigitalWallet.Wrong_bic'));
  }
  else if (domiciliation.length < 1) {
      setModalBody(
          t('DigitalWallet.Wrong_domiciliation'));
  }
    else {
        updateRib( user._id, selectedCard._id, cardHolder, iban, bic, domiciliation );
        setModalIsOpen(false);
       // window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }
  }


  function removeButtonClicked() {
        deleteRib( user._id, selectedCard._id );
        setSecondModalIsOpen(false);
   //     window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }

  function transferButtonClicked() {
    if (amount < 100 || amount > user.wallet - 100 || !amount) {
      setModalBody(
        t('DigitalWallet.Wrong_amount1') + " 100€ " + t('DigitalWallet.Wrong_amount2') + " " + (user.wallet - 100) + "€");
  }
  else {
    addTransfer( user._id, selectedCard.card_holder, selectedCard.iban, selectedCard.bic, selectedCard.domiciliation, amount );
    setThirdModalIsOpen(false);
  }
//     window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
}


return loading && transactions === null && ribs === null ? (
    <Spinner />
  ) : (
    
    <Fragment>
  
  <SponsorshipActions />

  <h1 className='large text-primary'>{t('DigitalWallet.Digital_wallet')}</h1>
      <p className='lead'>
        <i className='fas fa-user' /> {t('DigitalWallet.Current_balance') + " : "}
        <strong style={{fontSize: "30px"}}>{transactions && transactions.length > 0 && transactions[0].availability === 0 ? t('DigitalWallet.Monthly_commissions') + " ..." : user.wallet.toFixed(2) + "€"}</strong>
      </p>
      <button type="button" className="btn btn-primary" onClick={() => addCard()}>{t('DigitalWallet.Add_account')}</button>

      <br /><br />
    {showTableRibs}
    <br />
     {showTableTrasactions}
     {showModal}
     {showSecondModal}
     {showThirdModal}
  
  
  </Fragment>
  );

};

DigitalWallet.propTypes = {
  auth: PropTypes.object.isRequired,
  getTransactions: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  getSponsorships: PropTypes.func.isRequired,
  addRib: PropTypes.func.isRequired,
  getRibs: PropTypes.func.isRequired,
  deleteRib: PropTypes.func.isRequired,
  updateRib: PropTypes.func.isRequired,
  addTransfer: PropTypes.func.isRequired,
  getInvoice: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    sponsorship: state.sponsorship,
    transaction: state.transaction,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getTransactions, getSponsorships, addRib, getRibs, deleteRib, updateRib, addTransfer, getInvoice }
)(DigitalWallet);