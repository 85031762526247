import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';
import { connect } from 'react-redux';
import { deleteEducation } from '../../actions/profile';
import { useTranslation } from 'react-i18next';

const Education = ({ education, deleteEducation }) => {
  const { t } = useTranslation();
  const educations = education.map(edu => (
    <tr key={edu._id}>
      <td>{edu.school}</td>
      <td className="hide-sm">{edu.degree}</td>
      <td>
        <Moment format="YYYY/MM/DD">{moment.utc(edu.from)}</Moment> -{' '}
        {edu.to === null ? (
          " " +t('Dashboard.Now')
        ) : (
          <Moment format="YYYY/MM/DD">{moment.utc(edu.to)}</Moment>
        )}
      </td>
      <td>
        <button
          onClick={() => deleteEducation(edu._id)}
          className="btn btn-danger"
        >
          {t('Dashboard.Delete')}
        </button>
      </td>
    </tr>
  ));

  return (
    <Fragment>
      <h2 className="my-2">{t('Dashboard.Education_credentials')}</h2>
      <table className="table">
        <thead>
          <tr>
            <th>{t('Dashboard.School')}</th>
            <th className="hide-sm">{t('Dashboard.Degree')}</th>
            <th className="hide-sm">{t('Dashboard.Years')}</th>
            <th />
          </tr>
        </thead>
        <tbody>{educations}</tbody>
      </table>
    </Fragment>
  );
};

Education.propTypes = {
  education: PropTypes.array.isRequired,
  deleteEducation: PropTypes.func.isRequired
};

export default connect(
  null,
  { deleteEducation }
)(Education);
