import React, { Fragment, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import SponsorshipActions from './SponsorshipActions';
import Tree, { withStyles } from 'react-vertical-tree';
import { getSponsorships, updateSponsorship, getPartners } from '../../actions/sponsorship';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useTranslation } from 'react-i18next';




const SponsorshipTree = ({ getSponsorships, updateSponsorship, getPartners, auth: { user, theme }, sponsorship: { sponsorships, partners, loading } }) => {

  const [newIndex, setNewIndex] = useState(-1);


  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");

  
  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);

    useEffect(() => {
      getPartners(user._id) 
    }, [getPartners, user._id]);


    const styles = {
      lines: {
        color: theme === "light" ? '#bc9f51' : '#edd99a',
        height: '90px',
      },
      node: {
        backgroundColor: theme === "light" ? '#bc9f51' : '#edd99a',
        border: '1px solid',
        borderColor: theme === "light" ? '#bc9f51' : '#edd99a',
      },
      text: {
        color: theme === "light" ? '#fff' : '#000',
        fontWeight: 'bold'
      }
    }
    const StyledTree = withStyles(styles)(Tree)



  const { t } = useTranslation();


  if (user && (user.status === "C" || user.status === "A")) {
    return <Redirect to="/dashboard" />;
  }

   // const  data = [




    /*  {id: 1, name: 'company', parent: null, children: [
        {id: 2, parent: {id: 1}, name: 'subcompany1', children: [
          {id: 4, parent: {id: 2}, name: 'example-company', children: [
          ]}
        ]},
        {id: 4, parent: {id: 2}, name: 'subcompany2', children: [
        ]}
      ]}, */
      








     /*  {id: 1, name: 'company', parent: null, children: [
          {id: 2, parent: {id: 1}, name: 'subcompany1', children: [
              




            {id: 4, parent: {id: 3}, name: 'example-company', children: [
                {id: 4, parent: {id: 3}, name: 'example-company', children: [
                    {id: 4, parent: {id: 3}, name: 'example-company', children: [
                        {id: 4, parent: {id: 3}, name: 'example-company', children: [
                            {id: 4, parent: {id: 3}, name: 'example-company', children: [
                                {id: 4, parent: {id: 3}, name: 'example-company', children: []},
                                {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                              ]},
                            {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                          ]},
                        {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                      ]},
                    {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                  ]},
                {id: 4, parent: {id: 3}, name: 'example-company', children: []}
              ]},
            {id: 4, parent: {id: 3}, name: 'example-company', children: []}





          ]},
          {id: 3, parent: {id: 1}, name: 'subcompany2', children: [
            {id: 4, parent: {id: 3}, name: 'example-company', children: [
                {id: 4, parent: {id: 3}, name: 'example-company', children: [
                    {id: 4, parent: {id: 3}, name: 'example-company', children: [
                        {id: 4, parent: {id: 3}, name: 'example-company', children: [
                            {id: 4, parent: {id: 3}, name: 'example-company', children: [
                                {id: 4, parent: {id: 3}, name: 'example-company', children: []},
                                {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                              ]},
                            {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                          ]},
                        {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                      ]},
                    {id: 4, parent: {id: 3}, name: 'example-company', children: []}
                  ]},
                {id: 4, parent: {id: 3}, name: 'example-company', children: []}
              ]},
            {id: 4, parent: {id: 3}, name: 'example-company', children: []}
          ]}
        ]} */
  //    ]

    //  var newUserArray = data;
  /* var newUserArray = [];

      if(sponsorships && partners) {

      let jsonArr = [sponsorships[0]]
      newUserArray = jsonArr.map(
        
          obj => {
              return {
                  "id" : obj._id,
                  "parent":null,
                  "name":obj.username,
                  "parent_username":obj.parent_username,
                  "first_name":obj.first_name,
                  "last_name":obj.last_name,
                  "country":obj.country,
                  "status":obj.status,
                  "verticalLevel":obj.verticalLevel,
                  "horizontalLevel":obj.horizontalLevel,
                  "children":partners
              }
          }
        );
      }*/

      var newUserArray = [];
      if(sponsorships && partners) {
     //   console.log(JSON.stringify(partners))

        var jsonArr = [{
          "id" : user._id,
          "parent":null,
          "name":user.username,
          "parent_id":user.parent_id,
          "parent_username":user.parent_username,
          "first_name":user.first_name,
          "last_name":user.last_name,
          "country":user.country,
          "gender":user.gender,
          "date_of_birth":user.date_of_birth,
          "status":user.status,
          "verticalLevel":user.verticalLevel,
          "horizontalLevel":user.horizontalLevel,
          "date_creation":user.date_creation,
          "date_member":user.date_member,
          "date_repositioning":user.date_repositioning,
          "payed_status":user.payed_status,
          "confirmed":user.confirmed,
          "children":partners
        }];
        if (newIndex >= 0) {
          jsonArr = [sponsorships[newIndex]]
        }

      newUserArray = jsonArr.map(
        
          obj => {
              return {
                  "id" : obj.id,
                  "parent":null,
                  "name":obj.name,
                  "parent_id":obj.parent_id,
                  "parent_username":obj.parent_username,
                  "first_name":obj.first_name,
                  "last_name":obj.last_name,
                  "country":obj.country,
                  "gender":obj.gender,
                  "date_of_birth":obj.date_of_birth,
                  "status":obj.status,
                  "verticalLevel":obj.verticalLevel,
                  "horizontalLevel":obj.horizontalLevel,
                  "date_creation":obj.date_creation,
                  "date_member":obj.date_member,
                  "date_repositioning":obj.date_repositioning,
                  "payed_status":obj.payed_status,
                  "confirmed":obj.confirmed,
                  "children":partners
              }
          }
        );
      }
  /*      
<Tree data={newUserArray}
direction 
onClick={item => onClickItem(item.id) }/> */


  /*    var counterCandidate = 0;
      var counterMember = 0;
      var counterMember1 = 0;
      var counterMember2 = 0;

      var countCandidates = 0;
      var countMembers= 0;
      var countMembers1= 0;
      var countMembers2= 0;

      for(var i = 0; i < sponsorships.length; ++i){
          if(sponsorships[i].status === "C")
          countCandidates++;
          else if(sponsorships[i].status === "M")
          countMembers++
          else if(sponsorships[i].status === "M*")
          countMembers1++
          else if(sponsorships[i].status === "M**")
          countMembers2++
      }*/
    
      
      const showTable =
      
      
      <Fragment>
      
      
<h1 className='large text-primary'>{t('Sponsorship.Actif_partners')}</h1>

 
  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 


<table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className= {theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      <th scope="col">{t('Sponsorship.Number')}</th>
      <th scope="col">{t('Sponsorship.Username')}</th>
      <th scope="col">{t('Sponsorship.Status')}</th>
      <th scope="col">{t('Sponsorship.Level')}</th>
      <th scope="col">{t('Sponsorship.Action')}</th>
    </tr>
  </thead>
  <tbody>
    {sponsorships.map(( sponsorship, index ) => {
      return(
        sponsorship.status.charAt(0) === "M" || sponsorship.status === "B" ?
      <tr key={index}>
      <th scope="row">{index + 1/*sponsorship.new_id*/}</th>
      <td>{sponsorship.name}</td>
      <td> {(sponsorship.status === "C" ? t('Sponsorship.Candidate') :
      sponsorship.status === "J" ?  t('Sponsorship.Joker') :
      sponsorship.status === "A" ?  t('Sponsorship.Member') :
      sponsorship.status === "B" ?  t('Sponsorship.Candidate_member') :
      sponsorship.status === "D" ?  t('Sponsorship.Deleted') :
            sponsorship.status.charAt(0) === "M" ? t('Sponsorship.Actif_member') + sponsorship.status.substring(1) : "")}</td>
      <td>{sponsorship.verticalLevel - user.verticalLevel}</td>
      
      <td>{<button type="button" className="btn btn-primary" onClick={() => onRefreshItem(sponsorship.id, index)}>{t('Sponsorship.Consult_tree')}</button>}</td>
      </tr> : null
      );}
    )}

    {sponsorships.length === 0 || sponsorships.every(sponsorship => sponsorship.status.charAt(0) !== "M" && sponsorship.status !== "B") ? 
          <tr>
          <th colSpan={5}>{t('Sponsorship.No_actif_partners')}</th>
        </tr>
      : null}

  {/*sponsorships.map(( sponsorship, index ) => {
   // console.log("sponsorships"+JSON.stringify(sponsorship))

    if (sponsorship.status === "C") {
      counterCandidate++;
     return (
    <tr key={index}>
      <th rowSpan={counterCandidate === 1 ? countCandidates : 1} style={{display: counterCandidate === 1 ? '' : 'none', textAlign: 'center', verticalAlign: 'middle' }}scope="row">{t('Sponsorship.Candidates')}</th>
      <td>{counterCandidate/*sponsorship.new_id*//*}</td>
      <td>{sponsorship.verticalLevel.toString() + sponsorship.horizontalLevel.toString()}</td>
      <td>{sponsorship.name}</td>
      <td>{<button type="button" className="btn btn-primary" onClick={() => onRefreshItem(sponsorship.id, index)}>{t('Sponsorship.Refresh_tree')}</button>}</td>
    </tr>
     );}
     return true;
})*/}


{/*sponsorships.map(( sponsorship, index ) => {
  // console.log("sponsorships"+JSON.stringify(sponsorship))

if (sponsorship.status === "M") {
   counterMember++;
   return (
  <tr key={index}>
     <th rowSpan={counterMember === 1 ? countMembers : 1} style={{display: counterMember === 1 ? '' : 'none', textAlign: 'center', verticalAlign: 'middle' }}scope="row">{t('Sponsorship.Members')}</th>
    <td>{counterMember}</td>
    <td>{sponsorship.verticalLevel.toString() + sponsorship.horizontalLevel.toString()}</td>
    <td>{sponsorship.name}</td>
    <td>{<button type="button" className="btn btn-primary" onClick={() => onRefreshItem(sponsorship.id, index)}>{t('Sponsorship.Refresh_tree')}</button>}</td>
  </tr>
);}
    return true;
})*/}


{/*sponsorships.map(( sponsorship, index ) => {
  // console.log("sponsorships"+JSON.stringify(sponsorship))

  if (sponsorship.status === "M*") {
 counterMember1++;
 return (
<tr key={index}>
   <th rowSpan={counterMember1 === 1 ? countMembers1 : 1} style={{display: counterMember1 === 1 ? '' : 'none', textAlign: 'center', verticalAlign: 'middle' }}scope="row">{t('Sponsorship.Members') + "*"}</th>
  <td>{counterMember1}</td>
  <td>{sponsorship.verticalLevel.toString() + sponsorship.horizontalLevel.toString()}</td>
  <td>{sponsorship.name}</td>
  <td>{<button type="button" className="btn btn-primary" onClick={() => onRefreshItem(sponsorship.id, index)}>{t('Sponsorship.Refresh_tree')}</button>}</td>
</tr>
);}
    return true;
})*/}

{/*sponsorships.map(( sponsorship, index ) => {
  // console.log("sponsorships"+JSON.stringify(sponsorship))
if (sponsorship.status === "M**") {
 counterMember2++;
 return (
<tr key={index}>
  <th rowSpan={counterMember2 === 1 ? countMembers2 : 1} style={{display: counterMember2 === 1 ? '' : 'none', textAlign: 'center', verticalAlign: 'middle' }}scope="row">{t('Sponsorship.Members') + "**"}</th>
  <td>{counterMember2}</td>
  <td>{sponsorship.verticalLevel.toString() + sponsorship.horizontalLevel.toString()}</td>
  <td>{sponsorship.name }</td>
  <td>{<button type="button" className="btn btn-primary" onClick={() => onRefreshItem(sponsorship.id, index)}>{t('Sponsorship.Refresh_tree')}</button>}</td>
</tr>
);}
    return true;
})*/}
  </tbody>
</table>
</div>
</div>
   
  </Fragment>;


const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">


    {modalFirstButton !== "" ? 
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalFirstButton}</button> :
null
  }



</ModalFooter>
</Modal>

      const trees =
      
      <Fragment>
       <SponsorshipActions />
      
      
    {sponsorships !== null && partners !== null ? (
 






<div>
{showTable}








{/*<button type="button" className="btn btn-secondary" onClick={() => onRefreshItem(user._id, -1)}>{t('Sponsorship.Refresh_my_tree')}</button>*/}


  {sponsorships && partners ? 
<h1 className='large text-primary'>{ user._id === newUserArray[0].id ? t('Sponsorship.Affiliation_tree') : t('Language.Lang') === "English" ? (newUserArray && newUserArray[0] ? newUserArray[0].name : "") + t('Sponsorship.Affiliation_tree_of') : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? t('Sponsorship.Affiliation_tree_of') + " " + (newUserArray && newUserArray[0] ? newUserArray[0].name : "") : ""}</h1>
 : <h1 className='large text-primary'>{t('Sponsorship.Calculating') + " ..."}</h1>}
 <div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 

<StyledTree data={newUserArray}
direction 
onClick={item => onClickItem(item) }/>

</div>
</div>
</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;

      




/*function find(searchData, target, accum=[]){

  target.forEach((f)=>{
    if(f.children !== 0){
      console.log("children" + f.children)
      find(searchData, f.children, accum)
      setLevel(level + 1);
    }
    if(f.id.includes(searchData)){
      accum.push(f);
    }
  });
  return JSON.stringify(accum);
} */

/**
function find(searchData, target, accum=[]){
  target.forEach((f)=>{
    if(f.children){
      find(searchData, f.children, accum)
    }
    if(f.id.includes(searchData)){
      accum.push(f);
    }
  });
  return accum;
} */

/*function onClickItem(id) {

 // console.log("jsonArr" + JSON.stringify(newUserArray) )

  if (parseInt(JSON.stringify(find(id, newUserArray)[0].parent)) <= 7) {
    updateSponsorship(id, parseInt(JSON.stringify(find(id, newUserArray)[0].parent)) + 1, newUserArray);
    alert(t('Sponsorship.Username')+" : " + JSON.stringify(find(id, newUserArray)[0].name) +
    "\n"+t('Sponsorship.Guarantor_nickname')+" : " + JSON.stringify(find(id, newUserArray)[0].parent_username) +
    "\n"+t('Sponsorship.Country')+" : " + JSON.stringify(find(id, newUserArray)[0].country) +
    "\n"+t('Sponsorship.Status')+" : " + (JSON.stringify(find(id, newUserArray)[0].status) === "\"C\"" ? "\""+t('Sponsorship.Candidate')+"\"" :
    JSON.stringify(find(id, newUserArray)[0].status) === "\"M\"" ? "\""+t('Sponsorship.Member')+"\"" :
    JSON.stringify(find(id, newUserArray)[0].status) === "\"M*\"" ? "\""+t('Sponsorship.Member')+"*\"":
    JSON.stringify(find(id, newUserArray)[0].status) === "\"M**\"" ? "\""+t('Sponsorship.Member')+"**\"" : "" ));
  }
  else if ((JSON.stringify(find(id, newUserArray)[0].parent)) >= 8) {
    alert(t('Sponsorship.Username')+" : " + JSON.stringify(find(id, newUserArray)[0].name) +
    "\n"+t('Sponsorship.Guarantor_nickname')+" : " + JSON.stringify(find(id, newUserArray)[0].parent_username) +
    "\n"+t('Sponsorship.Country')+" : " + JSON.stringify(find(id, newUserArray)[0].country) +
    "\n"+t('Sponsorship.Status')+" : " + (JSON.stringify(find(id, newUserArray)[0].status) === "\"C\"" ? "\""+t('Sponsorship.Candidate')+"\"" :
    JSON.stringify(find(id, newUserArray)[0].status) === "\"M\"" ? "\""+t('Sponsorship.Member')+"\"" :
    JSON.stringify(find(id, newUserArray)[0].status) === "\"M*\"" ? "\""+t('Sponsorship.Member')+"*\"":
    JSON.stringify(find(id, newUserArray)[0].status) === "\"M**\"" ? "\""+t('Sponsorship.Member')+"**\"" : "") +
    "\n"+t('Sponsorship.Reached_level')+"." );
  }
 // console.log(parseInt(JSON.stringify(find(id, newUserArray)[0].parent)))
}*/




function onClickItem(item) {

  // console.log("jsonArr" + JSON.stringify(newUserArray) )
 

 //    updateSponsorship(id, parseInt(JSON.stringify(find(id, newUserArray)[0].parent)) + 1, newUserArray);
  /*   alert(t('Sponsorship.Username')+" : " + JSON.stringify(find(id, newUserArray)[0].name) +
     "\n"+t('Sponsorship.Guarantor_nickname')+" : " + JSON.stringify(find(id, newUserArray)[0].parent_username) +
     "\n"+t('Sponsorship.Country')+" : " + JSON.stringify(find(id, newUserArray)[0].country) +
     "\n"+t('Sponsorship.Status')+" : " + (JSON.stringify(find(id, newUserArray)[0].status) === "\"C\"" ? "\""+t('Sponsorship.Candidate')+"\"" :
     JSON.stringify(find(id, newUserArray)[0].status) === "\"M\"" ? "\""+t('Sponsorship.Member')+"\"" :
     JSON.stringify(find(id, newUserArray)[0].status) === "\"M*\"" ? "\""+t('Sponsorship.Member')+"*\"":
     JSON.stringify(find(id, newUserArray)[0].status) === "\"M**\"" ? "\""+t('Sponsorship.Member')+"**\"" : "") +
     "\n"+t('Sponsorship.Level')+" : " + JSON.stringify(find(id, newUserArray)[0].verticalLevel.toString() + find(id, newUserArray)[0].horizontalLevel.toString()));
*/
  // console.log(parseInt(JSON.stringify(find(id, newUserArray)[0].parent)))

  var dateCreation = JSON.parse(JSON.stringify(item.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(item.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }

  var dateMember = JSON.parse(JSON.stringify(item.date_member))

  var dateMemberArr = dateMember.split(',');

  var finalDateMember = ""
  for(var i=0; i<dateMemberArr.length; i++){
  if (dateMemberArr[i] !== "") {
    dateMember = dateMemberArr[i].substring(0, 10).split("-")
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[2] + "/" + dateMember[1] + "/" + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('Sponsorship.Date_member') + " (" + t('Sponsorship.Member') + "*".repeat(i) + ") : " + dateMember[2] + '/' + dateMember[1] + '/' + dateMember[0]}`;
      }
    }
    else {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('Sponsorship.Date_member') + " (" + t('Sponsorship.Member') + "*".repeat(i) + ") : " + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
    }  
  }
}

  var dateRepositioning = JSON.parse(JSON.stringify(item.date_repositioning ? item.date_repositioning : ""))
  var finalDateRepositioning = ""
  if (dateRepositioning !== "") {
    dateRepositioning = JSON.parse(JSON.stringify(item.date_repositioning)).substring(0, 10).split("-")
    finalDateRepositioning = dateRepositioning[1] + '/' + dateRepositioning[2] + '/' + dateRepositioning[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateRepositioning = dateRepositioning[2] + '/' + dateRepositioning[1] + '/' + dateRepositioning[0];
    }
  }

  setModalTitle(item.name);
  setModalBody(
  t('Sponsorship.Status')+" : " + (item.status === "C" ? t('Sponsorship.Candidate') :
  item.status === "J" ? t('Sponsorship.Joker') :
  item.status === "A" ? t('Sponsorship.Member') :
  item.status === "B" ? t('Sponsorship.Candidate_member') :
  item.status.charAt(0) === "M" ? t('Sponsorship.Actif_member') + item.status.substring(1) : "" ) +
  "\n"+t('Sponsorship.Guarantor')+" : " + (item.parent_username !== '' ?
  item.parent_username : "\""+t('Sponsorship.None')+"\"")  +
  "\n"+t('Sponsorship.Country')+" : " + t(`Country.${item.country}`) +
  "\n"+t('Sponsorship.Level')+" : " + (item.verticalLevel - user.verticalLevel)+
  (finalDateCreation !== "" ? ("\n"+t('Sponsorship.Subscription_date')+" : "  + finalDateCreation) : "") +
  (finalDateMember !== "" ? ("\n"+t('Sponsorship.Date_member')+" (" + t('Sponsorship.Member')+") : "  + finalDateMember) : "") +
  (finalDateRepositioning !== "" ? ("\n"+t('Sponsorship.Date_repositioning')+" : "  + finalDateRepositioning) : "")
  );
  setModalFirstButton("");
  setModalFirstButton(t('Sponsorship.Ok'));
 setModalIsOpen(true);
 }

function onRefreshItem(id, i) {
  getPartners(id);
  setNewIndex(i)
  window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
}

return loading && sponsorships && partners === null ? (
  <Spinner />
) : (
  <Fragment>

  {showModal}
  {trees}


</Fragment>
);
};


SponsorshipTree.propTypes = {
  getSponsorships: PropTypes.func.isRequired,
  updateSponsorship: PropTypes.func.isRequired,
  getPartners: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getSponsorships, getPartners, updateSponsorship }
)(SponsorshipTree);


