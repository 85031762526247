import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import SponsorshipActionsAdmin from './SponsorshipActionsAdmin';
import { getSponsorships } from '../../../actions/sponsorship';
import { getTransfers, updateTransfer } from '../../../actions/transfer';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Pagination from '../../layout/Pagination';
import csvIcon from '../../../img/csv.png';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const TransferAdmin = ({ getTransfers, getSponsorships, updateTransfer, auth: { user, theme }, transfer: { transfers }, sponsorship: { loading } }) => {

 // const [newIndex, setNewIndex] = useState(0);
  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalsSecondOpen, setModalSecondIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");
  const [modalSecondButton, setModalSecondButton] = useState("");
  const [transferId, setTransferId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [transfersPerPage] = useState(10);

  const [criteria, setCriteria] = useState("T");
  const [searchCriteria, setSearchCriteria] = useState("H");
  const [searchField, setSearchField] = useState("");
  
  
 /* useEffect(() => {
    document.body.style = 'background: #EDAE6A'; //#DCDCDC

    // returned function will be called on component unmount 
    return () => {
      document.body.style = 'background: #D3D3D3'; //#DCDCDC
    }
  }, []); */

  useEffect(() => {
    getTransfers(user._id);
  }, [getTransfers, user._id]);

  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);



  const { t } = useTranslation();

  if ( user.role !== 0 ) {
    return <Redirect to="/dashboard" />;
  }


  var numberCounter = 0;

  const indexOfLastTransfer = currentPage * transfersPerPage;
  const indexOfFirstTransfer = indexOfLastTransfer - transfersPerPage;
  var currentTransfers = [];
  var sortedTransfers = [];
  if (transfers && transfers.length > 0) {
    let tempData = [...transfers];
    for (var i = 0; i < tempData.length; i++) {

      if (i > 0 && (tempData[i - 1].date_creation !== tempData[i].date_creation)) {
        numberCounter = 0;
      }
      else {
        if (i > 0 && ((criteria === "A") || (criteria === "T" && tempData[i - 1].transfer_state === 0)
        || (criteria === "D" && tempData[i - 1].transfer_state === 1)
        || (criteria === "R" && tempData[i - 1].transfer_state === 2))) {
            numberCounter ++;
        }
      }
      tempData[i].number = numberCounter + 1;

    if ((criteria === "A") || (criteria === "T" && tempData[i].transfer_state === 0)
    || (criteria === "D" && tempData[i].transfer_state === 1)
    || (criteria === "R" && tempData[i].transfer_state === 2))
    {

      if (searchField) {
           if (searchCriteria === "H") {
        if (tempData[i].card_holder.toUpperCase().startsWith(searchField.toUpperCase())) {
            sortedTransfers.push(tempData[i]);
        }
      }
        else if (searchCriteria === "I") {
          if (tempData[i].iban.toUpperCase() === "") {
            if (t('Language.Lang') === "Français") {
              if ("Aucun".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedTransfers.push(tempData[i]);
            }
            }
            else if (t('Language.Lang') === "Italiano") {
              if ("Nessuna".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedTransfers.push(tempData[i]);
            }
            }
            else {
              if ("None".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedTransfers.push(tempData[i]);
            }
            }
          }
          else {
            if (tempData[i].iban.toUpperCase().startsWith(searchField.toUpperCase())) {
              sortedTransfers.push(tempData[i]);
          }
          }
        }
      else if (searchCriteria === "D") {

        var dateDay = tempData[i].date_creation.substring(0, 10).split("-")
        var finalDateDay = dateDay[1] + "-" + dateDay[2] + "-" + dateDay[0]
          if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
            finalDateDay = dateDay[2] + "-" + dateDay[1] + "-" + dateDay[0];
          }

      if (finalDateDay.startsWith(searchField)) {
        sortedTransfers.push(tempData[i]);
      }
    }
    else if (searchCriteria === "M") {
      var dateMonth = tempData[i].date_creation.substring(0, 10).split("-")
      
        dateMonth = dateMonth[1] + "-" + dateMonth[0]
     

    if (dateMonth.startsWith(searchField)) {
        sortedTransfers.push(tempData[i]);
    }
  }
  else if (searchCriteria === "Y") {

    var dateYear = tempData[i].date_creation.substring(0, 10).split("-")[0]


  if (dateYear.startsWith(searchField)) {
    sortedTransfers.push(tempData[i]);
  }
}
      }
      else {
        sortedTransfers.push(tempData[i]);
      }
    }
    }

    currentTransfers = sortedTransfers.reverse().slice(indexOfFirstTransfer, indexOfLastTransfer);
  }

  function exportCSV()
  {
    var csvRow = [];
    var sum = 0;


    var A = [[t('AdminTransfer.Number'), t('AdminTransfer.Date'), t('AdminTransfer.Type'), t('AdminTransfer.Holder'), t('AdminTransfer.Iban'), t('AdminTransfer.Amount')]]
    
  if (transfers && transfers.length > 0) {
    for (var item = 0; item < sortedTransfers.length; item++) {
      A.push([sortedTransfers[item].number, (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? sortedTransfers[item].date_creation.substring(0, 10).split("-")[2] + '/' + sortedTransfers[item].date_creation.substring(0, 10).split("-")[1] + '/' + sortedTransfers[item].date_creation.substring(0, 10).split("-")[0] : sortedTransfers[item].date_creation.substring(0, 10).split("-")[1] + '/' + sortedTransfers[item].date_creation.substring(0, 10).split("-")[2] + '/' + sortedTransfers[item].date_creation.substring(0, 10).split("-")[0],
      (sortedTransfers[item].transfer_type === 0 ? t('AdminTransfer.Transfer') :
      sortedTransfers[item].transfer_type === 1 ? t('AdminTransfer.Balance_account') : "" ),
      sortedTransfers[item].card_holder, sortedTransfers[item].iban, sortedTransfers[item].amount])
      sum = sum + sortedTransfers[item].amount;
    }
    A.push([],[t('AdminTransfer.Total'), '', '', '', '', sum])
    for (var i = 0; i < A.length; ++i) {
      csvRow.push(A[i].join(";"))
    }
    var csvString=csvRow.join("%0A");

    var a = document.createElement("a");
    a.href='data:attachment/csv,' + csvString;
    a.target="_Blank";
    a.download= t('AdminTransfer.Transfers') + (searchField !== "" ? (" " + searchField) : "") +".csv";
    document.body.appendChild(a);
    a.click();
   // console.warn(csvString)
  }
  }

  const paginate = pageNum => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage === Math.ceil(sortedTransfers.length / transfersPerPage) ? currentPage : currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(Math.ceil(sortedTransfers.length / transfersPerPage));



  function handleCriteriaChange (changeEvent) {
    setCriteria(changeEvent.currentTarget.value);
    setCurrentPage(1);
}

function handleSearchCriteriaChange (changeEvent) {

  if ((changeEvent.currentTarget.value === "D" || changeEvent.currentTarget.value === "M" || changeEvent.currentTarget.value === "Y") && isNaN(searchField)) {
    setSearchField("");
  }
  else if ((changeEvent.currentTarget.value === "H" || changeEvent.currentTarget.value === "I") && !isNaN(searchField)) {
    setSearchField("");
  }

  setSearchCriteria(changeEvent.currentTarget.value);

  setCurrentPage(1);
}

      


      const showTable =
      
      
      <Fragment>
      
      
<h1 className='large text-primary'>{t('AdminTransfer.Transfer_list')}</h1>


  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 
<div style={{width: 50, height: 'auto'}}>
    <button style={{backgroundColor: "#D3D3D3"}}><img src={csvIcon} alt="Csv icon"  className="img-fluid" onClick={() => exportCSV()} /></button>
    </div>

<div className='form'>
<div className="form-group">
          <input
            type='text'
            placeholder={t('AdminTransfer.Search_by') + " " + (searchCriteria === "H" ? t('AdminTransfer.Holder').toLowerCase() : searchCriteria === "I" ? t('AdminTransfer.Iban').toLowerCase() : searchCriteria === "D" ? t('AdminTransfer.Day_text').toLowerCase() : searchCriteria === "M" ? t('AdminTransfer.Month_text').toLowerCase() : searchCriteria === "Y" ? t('AdminTransfer.Year_text').toLowerCase() : "")}
            name='searchField'
            value={searchField}
            onChange={e => findByCriteria(e.target.value)}
          />
        </div>
</div>


<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('AdminTransfer.Search_by')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>



      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="H" 
         id="holder"
         className="custom-control-input"
                      checked={searchCriteria === 'H'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="holder">
          {t('AdminTransfer.Holder')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="I" 
         id="iban"
         className="custom-control-input"
                      checked={searchCriteria === 'I'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="iban">
          {t('AdminTransfer.Iban')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="D" 
         id="day"
         className="custom-control-input"
                      checked={searchCriteria === 'D'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="day">
          {t('AdminTransfer.Day')}
      </label>
      </div>
      
      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="M" 
         id="month"
         className="custom-control-input"
                      checked={searchCriteria === 'M'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="month">
          {t('AdminTransfer.Month')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Y" 
         id="year"
         className="custom-control-input"
                      checked={searchCriteria === 'Y'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="year">
          {t('AdminTransfer.Year')}
      </label>
      </div>

    </div>

<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('AdminTransfer.State')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="A" 
         id="all"
         className="custom-control-input"
                      checked={criteria === 'A'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="all">
          {t('AdminTransfer.All')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="T" 
         id="to_do"
         className="custom-control-input"
                      checked={criteria === 'T'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="to_do">
          {t('AdminTransfer.To_do')}
      </label>
      </div>

  
      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="D" 
         id="done"
         className="custom-control-input"
                      checked={criteria === 'D'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="done">
          {t('AdminTransfer.Done')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="R" 
         id="canceled"
         className="custom-control-input"
                      checked={criteria === 'R'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="canceled">
          {t('AdminTransfer.Canceled')}
      </label>
      </div>

    </div>

  
<table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      <th scope="col">{t('AdminTransfer.Number')}</th>
      <th scope="col">{t('AdminTransfer.Date')}</th>
      <th scope="col">{t('AdminTransfer.State')}</th>
      <th scope="col">{t('AdminTransfer.Holder')}</th>
      <th scope="col">{t('AdminTransfer.Iban')}</th>
      <th scope="col">{t('AdminTransfer.Amount')}</th>
      <th scope="col">{t('AdminTransfer.Actions')}</th>
    </tr>
  </thead>
  <tbody>


  {!transfers  ? 
          <tr>
          <th colSpan={7}>{t('AdminTransfer.Calculating') + " ..."}</th>
        </tr>
      :
      sortedTransfers.length === 0 ?
      <tr>
          <th colSpan={7}>{t('AdminTransfer.No_transfers')}</th>
        </tr>
       :
      currentTransfers.map(( get_transfer, index ) => {
   // console.log("sponsorships"+JSON.stringify(sponsorship))

     return (
    <tr key={index}>
      <td>{get_transfer.number}</td>

    <td>{(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? get_transfer.date_creation.substring(0, 10).split("-")[2] + '/' + get_transfer.date_creation.substring(0, 10).split("-")[1] + '/' + get_transfer.date_creation.substring(0, 10).split("-")[0] : get_transfer.date_creation.substring(0, 10).split("-")[1] + '/' + get_transfer.date_creation.substring(0, 10).split("-")[2] + '/' + get_transfer.date_creation.substring(0, 10).split("-")[0]}</td>
    <td>
      {(get_transfer.transfer_state === 0 ? t('AdminTransfer.To_do') :
      get_transfer.transfer_state === 1 ? t('AdminTransfer.Done') :
      get_transfer.transfer_state === 2 ? t('AdminTransfer.Canceled') : "" )}</td>
      <td>{get_transfer.card_holder}</td>
      <td>{get_transfer.iban !== '' ?
      get_transfer.iban : t('AdminTransfer.None')}</td>
      <td>{get_transfer.amount.toFixed(2) + "€"}</td>
      <td>
        {get_transfer.transfer_state === 0 ? 
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('AdminTransfer.Select_action')}</option>
             <option value= {'{"index": "1", "transfer": {"_id": "' + get_transfer._id + '", "email": "' + get_transfer.email + '", "username": "' + get_transfer.username + '", "card_holder": "' + get_transfer.card_holder + '", "iban": "' + get_transfer.iban + '", "bic": "' + get_transfer.bic + '", "domiciliation": "' + get_transfer.domiciliation + '", "amount": "' + get_transfer.amount.toFixed(2) + '", "transfer_type": "' + get_transfer.transfer_type + '", "transfer_state": "' + get_transfer.transfer_state + '", "date_creation": "' + get_transfer.date_creation +'"}}'}>{t('AdminTransfer.Details')}</option>
             <option value= {'{"index": "2", "transfer": {"_id": "' + get_transfer._id + '", "email": "' + get_transfer.email + '", "username": "' + get_transfer.username + '", "card_holder": "' + get_transfer.card_holder + '", "iban": "' + get_transfer.iban + '", "bic": "' + get_transfer.bic + '", "domiciliation": "' + get_transfer.domiciliation + '", "amount": "' + get_transfer.amount.toFixed(2) + '", "transfer_type": "' + get_transfer.transfer_type + '", "transfer_state": "' + get_transfer.transfer_state + '", "date_creation": "' + get_transfer.date_creation +'"}}'}>{t('AdminTransfer.Done')}</option>
             <option value= {'{"index": "3", "transfer": {"_id": "' + get_transfer._id + '", "email": "' + get_transfer.email + '", "username": "' + get_transfer.username + '", "card_holder": "' + get_transfer.card_holder + '", "iban": "' + get_transfer.iban + '", "bic": "' + get_transfer.bic + '", "domiciliation": "' + get_transfer.domiciliation + '", "amount": "' + get_transfer.amount.toFixed(2) + '", "transfer_type": "' + get_transfer.transfer_type + '", "transfer_state": "' + get_transfer.transfer_state + '", "date_creation": "' + get_transfer.date_creation +'"}}'}>{t('AdminTransfer.Cancel_transfer')}</option>
           </select>
           : 
           <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('AdminTransfer.Select_action')}</option>
             <option value= {'{"index": "1", "transfer": {"_id": "' + get_transfer._id + '", "email": "' + get_transfer.email + '", "username": "' + get_transfer.username + '", "card_holder": "' + get_transfer.card_holder + '", "iban": "' + get_transfer.iban + '", "bic": "' + get_transfer.bic + '", "domiciliation": "' + get_transfer.domiciliation + '", "amount": "' + get_transfer.amount.toFixed(2) + '", "transfer_type": "' + get_transfer.transfer_type + '", "transfer_state": "' + get_transfer.transfer_state + '", "date_creation": "' + get_transfer.date_creation +'"}}'}>{t('AdminTransfer.Details')}</option>
           </select>
           }
      </td>
    </tr>
  );
})}

  </tbody>
</table>
</div>
</div>


<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>
<div className="scrollme"> 
<table>
{ sortedTransfers.length > 0 ? <Pagination usersPerPage={transfersPerPage} totalUsers={transfers && sortedTransfers.length > 0 ? sortedTransfers.length : 0} paginate={paginate} nextPage={nextPage} prevPage={prevPage} firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} /> : null }
</table>
</div>
</div>
  </Fragment>;



const showModalAccept = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => acceptButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



const showModalRefuse = 
<Modal className="my-modal" show={modalsSecondOpen} onHide={() => setModalSecondIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-danger" onClick={() => refuseButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalSecondIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>

      const tables =
      
      <Fragment>
       <SponsorshipActionsAdmin />
      
     
    { transfers !== null ? (
 
<div>

{showTable}

</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;

      


function findByCriteria (name) {

  if ( searchCriteria === "Y") {
    setSearchField(name.replace(/\D/,''));
  }
  else if (searchCriteria === "D" || searchCriteria === "M") {
    setSearchField(name.replace(/(?!-)[^0-9.]/,''));
  }
  else {
    setSearchField(name);
  }


  if (searchCriteria === "D" && ((name.length === 2 && searchField.length === 1) || (name.length === 5 && searchField.length === 4))){
    setSearchField(name + "-")
}
else if (searchCriteria === "M" && ((name.length === 2 && searchField.length === 1))){
  setSearchField(name + "-")
}


  setCurrentPage(1);
}

function onClickAcceptItem(newTransfer) {

    setModalTitle(t('AdminTransfer.Confirm_sure')+ ': "' + JSON.parse(JSON.stringify(newTransfer.username))+'"?');
    setModalBody(
      t('AdminTransfer.Holder')+" : " + (JSON.parse(JSON.stringify(newTransfer.card_holder)) !== '' ?
      JSON.parse(JSON.stringify(newTransfer.card_holder)) : t('AdminTransfer.None'))  +
      "\n"+t('AdminTransfer.Domiciliation')+" : " + (JSON.parse(JSON.stringify(newTransfer.domiciliation)) !== '' ?
     JSON.parse(JSON.stringify(newTransfer.domiciliation)) : t('AdminTransfer.None'))  +
     "\n"+t('AdminTransfer.Iban')+" : " + (JSON.parse(JSON.stringify(newTransfer.iban)) !== '' ?
    JSON.parse(JSON.stringify(newTransfer.iban)) : t('AdminTransfer.None'))  +
     "\n"+t('AdminTransfer.Amount')+" : " + JSON.parse(JSON.stringify(newTransfer.amount)) + "€"
    );
    setModalFirstButton(t('AdminTransfer.Done'));
    setModalSecondButton("");
   setModalIsOpen(true);

}


function onClickRefuseItem(newTransfer) {

    setModalTitle(t('AdminTransfer.Cancel_sure')+ ': "' + JSON.parse(JSON.stringify(newTransfer.username))+'"?');
    setModalBody(
      t('AdminTransfer.Holder')+" : " + (JSON.parse(JSON.stringify(newTransfer.card_holder)) !== '' ?
      JSON.parse(JSON.stringify(newTransfer.card_holder)) : t('AdminTransfer.None'))  +
      "\n"+t('AdminTransfer.Domiciliation')+" : " + (JSON.parse(JSON.stringify(newTransfer.domiciliation)) !== '' ?
     JSON.parse(JSON.stringify(newTransfer.domiciliation)) : t('AdminTransfer.None'))  +
     "\n"+t('AdminTransfer.Iban')+" : " + (JSON.parse(JSON.stringify(newTransfer.iban)) !== '' ?
    JSON.parse(JSON.stringify(newTransfer.iban)) : t('AdminTransfer.None'))  +
     "\n"+t('AdminTransfer.Amount')+" : " + JSON.parse(JSON.stringify(newTransfer.amount)) + "€"
    );
    setModalFirstButton(t('AdminTransfer.Cancel_transfer'));
    setModalSecondButton("");
   setModalSecondIsOpen(true);

}

function showDetailsTable(transfer) {


  var dateCreation = JSON.parse(JSON.stringify(transfer.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(transfer.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }


  
    setModalTitle(JSON.parse(JSON.stringify(transfer.username)));
    setModalBody(
      t('AdminTransfer.Email_address')+" : " + JSON.parse(JSON.stringify(transfer.email))  +
      "\n"+t('AdminTransfer.Holder')+" : " + (JSON.parse(JSON.stringify(transfer.card_holder)) !== '' ?
      JSON.parse(JSON.stringify(transfer.card_holder)) : t('AdminTransfer.None'))  +
      "\n"+t('AdminTransfer.Iban')+" : " + (JSON.parse(JSON.stringify(transfer.iban)) !== '' ?
    JSON.parse(JSON.stringify(transfer.iban)) : t('AdminTransfer.None')) +
    "\n"+t('AdminTransfer.Bic')+" : " + (JSON.parse(JSON.stringify(transfer.bic)) !== '' ?
    JSON.parse(JSON.stringify(transfer.bic)) : t('AdminTransfer.None')) +
    "\n"+t('AdminTransfer.Domiciliation')+" : " + (JSON.parse(JSON.stringify(transfer.domiciliation)) !== '' ?
    JSON.parse(JSON.stringify(transfer.domiciliation)) : t('AdminTransfer.None')) +
    "\n"+t('AdminTransfer.State')+" : " + (JSON.parse(JSON.stringify(transfer.transfer_state)) === "0" ? t('AdminTransfer.To_do') :
    JSON.parse(JSON.stringify(transfer.transfer_state)) === "1" ? t('AdminTransfer.Done') :
    JSON.parse(JSON.stringify(transfer.transfer_state)) === "2" ? t('AdminTransfer.Canceled') : "") +
    "\n"+t('AdminTransfer.Type')+" : " + (JSON.parse(JSON.stringify(transfer.transfer_type)) === "0" ? t('AdminTransfer.Transfer') :
    JSON.parse(JSON.stringify(transfer.transfer_type)) === "1" ? t('AdminTransfer.Balance_account') : "") +
    "\n"+t('AdminTransfer.Amount')+" : " + JSON.parse(JSON.stringify(transfer.amount)) + "€" +
    (finalDateCreation !== "" ? ("\n"+t('AdminTransfer.Date')+" : "  + finalDateCreation) : "")
    );
    setModalFirstButton("");
    setModalSecondButton(t('AdminTransfer.Ok'));
   setModalIsOpen(true);
  }





function acceptButtonClicked() {
    updateTransfer(transferId, 1);

    setModalIsOpen(false);
}

function refuseButtonClicked() {
    updateTransfer(transferId, 2);

    setModalSecondIsOpen(false);
}


const dropDownSelected = event => {
  if (JSON.parse(event).index === "1") {
    showDetailsTable(JSON.parse(event).transfer);
  }
  else if (JSON.parse(event).index === "2") {
    setTransferId(JSON.parse(event).transfer._id)
    onClickAcceptItem(JSON.parse(event).transfer);
  }
  else if (JSON.parse(event).index === "3") {
    setTransferId(JSON.parse(event).transfer._id)
    onClickRefuseItem(JSON.parse(event).transfer);
  }
}




return loading && transfers === null ? (
  <Spinner />
) : (
  
  <Fragment>

    {showModalAccept}
    {showModalRefuse}
  {tables}



</Fragment>
);
};


TransferAdmin.propTypes = {
  getTransfers: PropTypes.func.isRequired,
  updateTransfer: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  transfer: PropTypes.object.isRequired,
  getSponsorships: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  transfer: state.transfer,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getTransfers, updateTransfer, getSponsorships }
)(TransferAdmin);


