import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GoodDealsActions from './GoodDealsActions';


const AddGoodDeal = ({ auth: { user, theme } }) => {

    const { t } = useTranslation();

    if (user && user.status === "C") {
        return <Redirect to="/dashboard" />;
      }

  return (
    <Fragment>
    <GoodDealsActions />
<section className="container">
  <h1 className='x-large text-primary'>
    <i className='fas fa-exclamation-triangle' /> {t('GoodDeals.Add_new_deal')}
  </h1>
  <p className='large'>{t('NavBar.Wip')}</p>
  <div class="progress">
<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: "75%"}}></div>
</div>
  </section>
</Fragment>
    ) ;
}


AddGoodDeal.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(AddGoodDeal);



