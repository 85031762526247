import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendEmail } from '../../actions/contact';
import { useTranslation } from 'react-i18next';
/*import L from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import iconPosition from '../../img/position.png';
import iconAddress from '../../img/address.png';
import iconPhone from '../../img/phone.png';
import iconEmail from '../../img/email.png';*/
//import leafGreen from './assets/leaf-green.png';
//import leafRed from './assets/leaf-red.png';
//import leafOrange from './assets/leaf-orange.png';
//import leafShadow from './assets/leaf-shadow.png';

 

    const ContactForm = ({ sendEmail,
        auth: { user, loading }}) => {
    

      const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
      });

      const [name/*, setName*/] = useState(user.username);
      const [email/*, setEmail*/] = useState(user.email);
   /*   
      var markerIcon = L.icon({
        iconUrl: iconPosition,
        className: 'marker-icon',
        iconSize: [16,16],
        iconAnchor: [8,8],
      });

      var [position] = useState({
        lat: 51.509865,
        lng: -0.118092
      });


      const [zoom] = useState(12);*/
    
    
      const { t } = useTranslation();
    
      const { /*name, email,*/ subject, message } = formData;
    
    
      const onChange = e =>
        setFormData({ ...formData, [e.target.name]: e.target.value });
    
      const onSubmit = async e => {
         /* var newName = name
        if (name === "") {
            newName = user.username
        }
        var newEmail = email
        if (email === "") {
          newEmail = user.email
        }*/
        e.preventDefault();
        sendEmail({ /*name: newName, email: newEmail,*/ name, email, subject, message });
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
      };

   /* //Only once
    const isInitialMount = useRef(true);

    useEffect(() => {
      if (isInitialMount.current) {
         isInitialMount.current = false;
  
         if (user && user._id) {
          getSponsorships(user._id);
          getPartners(user._id);
        }
      }
    }); */

  return (
    <Fragment>
      <h1 className="large text-primary">{t('Contact.Contact_us')}</h1>
      <p className="lead">
        <i className="fas fa-user" /> {t('Contact.Send_email')}
      </p>
      <small>* = {t('Contact.Required_field')}</small>
      <form className="form" onSubmit={onSubmit}>
       {/* <div className="form-group">
          <input
            type="text"
            placeholder={t('Contact.Name')}
            name="name"
            value={name}
            onChange={onChange}
          />
          <small className="form-text">
          {t('Contact.Name_descrip')}
          </small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder={"* "+t('Contact.Email_address')}
            name="email"
            value={email}
            onChange={onChange}
          />
          <small className="form-text">
          {t('Contact.Email_descrip')}
          </small>
       </div>*/}
        <div className="form-group">
          <input
            type="text"
            placeholder={"* "+t('Contact.Subject')}
            name="subject"
            value={subject}
            onChange={onChange}
          />
          <small className="form-text">
          {t('Contact.Subject_descrip')}
          </small>
        </div>
        <div className="form-group">
          <textarea
            placeholder={"* "+t('Contact.Message')}
            name="message"
            value={message}
            onChange={onChange}
          />
          <small className="form-text">{t('Contact.Message_descrip')}</small>
        </div>

        <input type="submit"  value={t('Contact.Validate')} className="btn btn-block btn-primary my-1" />
      </form>
    {/*  <br /><br />
      <p className="lead">
        <i className="fas fa-user" /> {t('Contact.Head_office')}
      </p>

      <Map className="map" center={position} zoom={zoom}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={markerIcon}>
          <Popup>
            {t('Contact.Atrakxion')} <br /> {t('Contact.Address')+ ", " + t('Contact.Address_country')}
          </Popup>
        </Marker>
      </Map>
      <br /><br />
      <div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

     <div
     style={{
        textAlign : 'center',
        margin: "20px"
    }}>

     <img src={iconAddress} alt='address'
     style={{ width: '60px', margin: 'auto', display: 'block' }} />
    <br />
     <p>{t('Contact.Address') + ","}</p>
     <p>{t('Contact.Address_country')}</p>
     </div>
     <div
     style={{
        textAlign : 'center',
        margin: "20px"
    }}>

     <img  src={iconPhone} alt='phone'
     style={{ width: '60px', margin: 'auto', display: 'block' }} />
     <br />
     <p>(+33) 7 12 34 56 78</p>
     <p>{t('Contact.Time')}</p>
     </div>
     <div
     style={{
        textAlign : 'center',
        margin: "20px"
    }}>
     <img  src={iconEmail} alt='email'
     style={{ width: '60px', margin: 'auto', display: 'block' }} />
     <br />
     <p>contact@atrakxion.com</p>
     <p>client@atrakxion.com</p>
      </div>
  </div>*/}


    </Fragment>
  );
};

ContactForm.propTypes = {
  auth: PropTypes.object.isRequired,
  sendEmail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { sendEmail }
)(ContactForm);
