import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
    <section className="container">
    <h1 className='large text-primary'>{t('PrivacyPolicy.Title')}</h1>
  <details>
    <summary>I. {t('PrivacyPolicy.Subtitle1')}</summary>
    <p>
    {t('PrivacyPolicy.Paragraph7')}<br />{t('PrivacyPolicy.Paragraph8')}<br />
    {t('PrivacyPolicy.Paragraph9')}<br />{t('PrivacyPolicy.Paragraph10')}<br /><br />
    {t('PrivacyPolicy.Paragraph11')}
    <div style={{marginLeft : "2em"}}>
    <strong>- </strong>
      {t('PrivacyPolicy.Paragraph12')} < br/>
    <strong>- </strong>
      {t('PrivacyPolicy.Paragraph13')} < br/>
    <strong>- </strong>
      {t('PrivacyPolicy.Paragraph14')}
      </div>
    </p>
  </details>

  <details>
    <summary>II. {t('PrivacyPolicy.Subtitle2')}</summary>
    <p>
    {t('PrivacyPolicy.Paragraph15')}<br />
    {t('PrivacyPolicy.Paragraph16')}<br />{t('PrivacyPolicy.Paragraph17')}
    </p>
  </details>

  <details>
    <summary>III. {t('PrivacyPolicy.Subtitle3')}</summary>
    <p>
    {t('PrivacyPolicy.Paragraph1')}<br />
    <div style={{marginLeft : "2em"}}>
    <strong>1. </strong>
      {t('PrivacyPolicy.Paragraph2')} < br/>
    <strong>2. </strong>
      {t('PrivacyPolicy.Paragraph3')} < br/>
    <strong>3. </strong>
      {t('PrivacyPolicy.Paragraph4')} < br/>
    <strong>4. </strong>
      {t('PrivacyPolicy.Paragraph5')} < br/>
    <strong>5. </strong>
      {t('PrivacyPolicy.Paragraph6')}<br /><br />
      </div>
    </p>
  </details>

  <details>
    <summary>IV. {t('PrivacyPolicy.Subtitle4')}</summary>
    <p>
    {t('PrivacyPolicy.Paragraph18')}<br />{t('PrivacyPolicy.Paragraph19')}<br />
    {t('PrivacyPolicy.Paragraph20')}
    <div style={{marginLeft : "2em"}}>
    <strong>- </strong>
      {t('PrivacyPolicy.Paragraph21')} < br/>
    <strong>- </strong>
      {t('PrivacyPolicy.Paragraph22')} < br/>
    <strong>- </strong>
      {t('PrivacyPolicy.Paragraph23')} < br/>
    <strong>- </strong>
      {t('PrivacyPolicy.Paragraph24')} < br/>
    <strong>- </strong>
      {t('PrivacyPolicy.Paragraph25')} < br/>
    <strong>- </strong>
      {t('PrivacyPolicy.Paragraph26')}
      </div>
    </p>
  </details>

  <details>
    <summary>V. {t('PrivacyPolicy.Subtitle5')}</summary>
    <p>
    {t('PrivacyPolicy.Paragraph27')}<br />
    {t('PrivacyPolicy.Paragraph28')}
    </p>
  </details>

  <details>
    <summary>VI. {t('PrivacyPolicy.Subtitle6')}</summary>
    <p>
    {t('PrivacyPolicy.Paragraph29')}<br />
    {t('PrivacyPolicy.Paragraph30')}
    </p>
  </details>

  <details>
    <summary>VII. {t('PrivacyPolicy.Subtitle7')}</summary>
    <p>
    {t('PrivacyPolicy.Paragraph31')}<br />
    {t('PrivacyPolicy.Paragraph32')}<br />
    {t('PrivacyPolicy.Paragraph33')}
    </p>
  </details>

  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

PrivacyPolicy.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(PrivacyPolicy);
