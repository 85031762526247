import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

const PoliciesProcedures = ({
  auth: { user }
}) => {

  const { t } = useTranslation();
  if (user && (user.status === "C" || user.status === "A")) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Fragment>
            <section>
      <h1 className='large text-primary'>{t('PoliciesProcedures.Title')}</h1>
  <details>
    <summary>I. {t('PoliciesProcedures.Subtitle1')}</summary>
    <p>
    1. {t('PoliciesProcedures.Paragraph1')}<br />
    2. {t('PoliciesProcedures.Paragraph2')}<br />
    3. {t('PoliciesProcedures.Paragraph3')}<br />
    4. {t('PoliciesProcedures.Paragraph4')}
    </p>
  </details>
  <details>
    <summary>II. {t('PoliciesProcedures.Subtitle2')}</summary>
    <p>
    1. {t('PoliciesProcedures.Paragraph5')}<br />
    2. {t('PoliciesProcedures.Paragraph6')}<br />
    3. {t('PoliciesProcedures.Paragraph7')}<br />
    4. {t('PoliciesProcedures.Paragraph8')}
    </p>
  </details>
  <details>
    <summary>III. {t('PoliciesProcedures.Subtitle3')}</summary>
    <p>
    <strong>1. {t('PoliciesProcedures.Paragraph9')}</strong>
      <div style={{marginLeft : "2em"}}>{t('PoliciesProcedures.Paragraph10')} <br/> {t('PoliciesProcedures.Paragraph11')}</div>
    <strong>2. {t('PoliciesProcedures.Paragraph12')}</strong> < br/>
    <div style={{marginLeft : "2em"}}>{t('PoliciesProcedures.Paragraph13')} <br/> {t('PoliciesProcedures.Paragraph14')}</div>
    <strong>3. {t('PoliciesProcedures.Paragraph15')}</strong> < br/>
    <div style={{marginLeft : "2em"}}>{t('PoliciesProcedures.Paragraph16')} <br/> {t('PoliciesProcedures.Paragraph17')}</div>
    </p>
  </details>
  <details>
    <summary>IV. {t('PoliciesProcedures.Subtitle4')}</summary>
    <p style={{marginLeft : "2em"}}>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph18')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph19')} < br/> {t('PoliciesProcedures.Paragraph20')} < br/>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph21')}
    </p>
  </details>
  <details>
    <summary>V. {t('PoliciesProcedures.Subtitle5')}</summary>
    <p style={{marginLeft : "2em"}}>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph22')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph23')} < br/>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph24')} < br/>
    <strong>4. </strong>
      {t('PoliciesProcedures.Paragraph25')} < br/>
    <strong>5. </strong>
      {t('PoliciesProcedures.Paragraph26')} < br/>
    <strong>6. </strong>
      {t('PoliciesProcedures.Paragraph27')} < br/>
    <strong>7. </strong>
      {t('PoliciesProcedures.Paragraph28')} < br/>
      {t('PoliciesProcedures.Paragraph29')}
    </p>
  </details>
  <details>
    <summary>VI. {t('PoliciesProcedures.Subtitle6')}</summary>
    <p style={{marginLeft : "2em"}}>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph30')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph31')} < br/>
    <div style={{marginLeft : "2em"}}>
      a. {t('PoliciesProcedures.Paragraph32')} < br/>
      b. {t('PoliciesProcedures.Paragraph33')} < br/>
      c. {t('PoliciesProcedures.Paragraph34')} < br/>
      d. {t('PoliciesProcedures.Paragraph35')} < br/>
      e. {t('PoliciesProcedures.Paragraph36')} < br/>
    </div>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph37')} < br/>
    <strong>4. </strong>
      {t('PoliciesProcedures.Paragraph38')}
    </p>
  </details>
  <details>
    <summary>VII. {t('PoliciesProcedures.Subtitle7')}</summary>
    <p style={{marginLeft : "2em"}}>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph39')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph40')} < br/>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph41')}
    </p>
  </details>
  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

PoliciesProcedures.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(PoliciesProcedures);
