import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chat from './Chat';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Messaging = ({ auth: { user, theme } }) => {

    const { t } = useTranslation();


  return (
    <Chat/>
    ) ;
}


Messaging.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(Messaging);
