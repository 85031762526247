import React, { forwardRef } from "react";
import { useTranslation } from 'react-i18next';


const DateButton = (props, ref) => 
{
    const { t } = useTranslation();
  const datearray = props.value.split("/");
  let newValue = props.value

  if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
    newValue = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
  }
  return(
      <button ref={ref} type="button" className="btn date-button" onClick={props.onClick}>
  {newValue}
</button>);
};

export default forwardRef(DateButton);