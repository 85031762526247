import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';




const SpinnerApi = ({ loading_api,  auth: { theme } }) =>
{
  return(
  loading_api ?
  <div className={`${theme === "light" ? "loading-container-light" : "loading-container-dark"}`}>
        <div className="loading">
        <div className="text-center">
  <div className="spinner-border" role="status">
    <span className="sr-only">Loading...</span>
  </div>
</div>
        </div>
      </div>
      : null);
}


SpinnerApi.propTypes = {
    loading_api: PropTypes.bool,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    loading_api: state.auth.loading_api,
  auth: state.auth
});

export default connect(mapStateToProps)(SpinnerApi);