import React from 'react';
import { useTranslation } from 'react-i18next';

const Pagination = (props) => {
  const { t } = useTranslation();
  const { usersPerPage, totalUsers, paginate, nextPage, prevPage, firstPage, lastPage, currentPage } = props;

  const pageNumbers = [];

  for (let i = currentPage >= 6 ? currentPage >= Math.ceil(totalUsers / usersPerPage) - 4 ? Math.ceil(totalUsers / usersPerPage) - 9 > 0 ? Math.ceil(totalUsers / usersPerPage) - 9 : 1 : currentPage - 5 : 1; i <= ( currentPage < Math.ceil(totalUsers / usersPerPage) - 4 ? currentPage < 6 ? Math.ceil(totalUsers / usersPerPage) > 10 ? 10 : Math.ceil(totalUsers / usersPerPage) : currentPage + 4 : Math.ceil(totalUsers / usersPerPage) ); i++) {
      pageNumbers.push(i);
  }
  return (
    <nav aria-label="Page navigation">
    <ul className="pagination justify-content-center">
      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
      <a className="page-link" href="#!" onClick={() => firstPage()} aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
        <span className="sr-only">{t('Pagination.Previous')}</span>
      </a>
        </li>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#!" onClick={() => prevPage()}>{t('Pagination.Previous')}</a>
        </li>
        {pageNumbers.map(num => (
            <li className={`page-item ${num === currentPage ? 'active' : ''}`} key={num}>
                <a onClick={() => paginate(num)} href="#!" className="page-link">{num}</a>
            </li>
        ))}
        <li className={`page-item ${currentPage === Math.ceil(totalUsers / usersPerPage) ? 'disabled' : ''}`}>
            <a className="page-link" href="#!" onClick={() => nextPage()}>{t('Pagination.Next')}</a>
        </li>
        <li className={`page-item ${currentPage === Math.ceil(totalUsers / usersPerPage) ? 'disabled' : ''}`}>
        <a className="page-link" href="#!" onClick={() => lastPage()} aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
        <span className="sr-only">{t('Pagination.Next')}</span>
      </a>
        </li>

    </ul>
</nav>
  );
};

export default Pagination;
