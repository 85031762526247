import React, { Fragment/*, useState*/ } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
/*import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";*/

import { useTranslation } from 'react-i18next';
import firstTrainingImg_fr from '../../img/firstTrainingImg_fr.png';
import firstTrainingImg_en from '../../img/firstTrainingImg_en.png';
import firstTrainingImg_it from '../../img/firstTrainingImg_it.png';
import secondTrainingImg_fr from '../../img/secondTrainingImg_fr.png';
import secondTrainingImg_en from '../../img/secondTrainingImg_en.png';
import secondTrainingImg_it from '../../img/secondTrainingImg_it.png';
import thirdTrainingImg_fr from '../../img/thirdTrainingImg_fr.png';
import thirdTrainingImg_en from '../../img/thirdTrainingImg_en.png';
import thirdTrainingImg_it from '../../img/thirdTrainingImg_it.png';
import fourthTrainingImg_fr from '../../img/fourthTrainingImg_fr.png';
import fourthTrainingImg_en from '../../img/fourthTrainingImg_en.png';
import fourthTrainingImg_it from '../../img/fourthTrainingImg_it.png';

const Training = ({
  auth: { user }
}) => {

  const { t } = useTranslation();
  let history = useHistory();
  if (user && (user.status === "C" || user.status === "A")) {
    return <Redirect to="/dashboard" />;
  }

  /*const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");
  
  const displayModal = () => {
        setModalTitle(t('NavBar.Interactive_demo'));
        setModalBody(t('NavBar.Demo_accept1') + "\n" + t('NavBar.Demo_accept2') + "\n" + t('NavBar.Demo_accept3'));
        setModalFirstButton(t('NavBar.Continue'));
       setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false)
    history.push({
      pathname: '/demo/affiliations'
    })
}


const showModal =
<Modal show={modalsOpen} onHide={() => setModalIsOpen(false)} backdrop="static" as="section">
  <ModalHeader as="span">
    <ModalTitle as="h4">{modalTitle}</ModalTitle>
  </ModalHeader>
  <ModalBody as="section"
    style={{
      whiteSpace: "pre-line"
    }}>{modalBody}</ModalBody>
  <ModalFooter as="footer">
      <button className="btn btn-primary" onClick={() => closeModal()}>{modalFirstButton}</button>
  </ModalFooter>
</Modal>*/

  return (
    <Fragment>
            <section>
      <h1 className='large text-primary'>{t('Training.Title')}</h1>
      <p>
    {t('Training.Training_description')}<br /><br />
    </p>
  <details>
    <summary>I. {t('Training.Subtitle1')}</summary>
    <p>
    {t('Training.Paragraph1')}<br /><br />{t('Training.Paragraph2')}<br />
    {t('Training.Paragraph3')}<br /><br />{t('Training.Paragraph4')}<br />
    {t('Training.Paragraph5')}<br />
    {t('Training.Paragraph6')}<br />
    <strong>1. </strong>{t('Training.Paragraph7')}
    <strong>  2. </strong>{t('Training.Paragraph8')}
    <strong>  3. </strong>{t('Training.Paragraph9')}
    <strong>  4. </strong>{t('Training.Paragraph10')}<br /><br />
    {t('Training.Paragraph11')}
    <br /><br />
    {t('Training.Paragraph12')}
    </p>
    <center><h4>{t('Training.Paragraph13')}</h4></center>
    <p>
    <strong>1. </strong>{t('Training.Paragraph14')}<br />
    {t('Training.Paragraph15')}<br /><br />
    <strong>2. </strong>{t('Training.Paragraph16')}<br />
    {t('Training.Paragraph17')}<br /><br />
    <strong>3. </strong>{t('Training.Paragraph18')}<br />
    <strong style={{marginLeft : "2em"}}>A. </strong>{t('Training.Paragraph19')}<br />
    <strong style={{marginLeft : "2em"}}>B. </strong>{t('Training.Paragraph20')}<br />
    <strong style={{marginLeft : "2em"}}>C. </strong>{t('Training.Paragraph21')}<br /><br />
    <strong>4. </strong>{t('Training.Paragraph22')}<br />
    {t('Training.Paragraph23')}<br /><br />
    {t('Training.Paragraph24')}<br /><br />
    {t('Training.Paragraph25')}
    </p>
  </details>
  <details>
    <summary>II. {t('Training.Subtitle2')}</summary>
    <p>
    {t('Training.Paragraph26')} < br/>
    {t('Training.Paragraph27')} < br/>< br/>
    <div className="d-flex justify-content-center"><img src={t('Language.Lang') === "Français" ? firstTrainingImg_fr : t('Language.Lang') === "Italiano" ? firstTrainingImg_it : firstTrainingImg_en}  alt="First training img" className="img-fluid" /></div>< br/>< br/>
    <strong>1. {t('Training.SubtitleSubtitle1')}</strong>
      <div>{t('Training.Paragraph28')}</div>
      <div>{t('Training.Paragraph29')}</div>
      <div>{t('Training.Paragraph30')}</div> < br/>
    <strong>2. {t('Training.SubtitleSubtitle2')} :</strong> < br/>
    <div>{t('Training.Paragraph31')}</div>
    <div>{t('Training.Paragraph32')}</div>
    <div>{t('Training.Paragraph33')}</div>
    <div>{t('Training.Paragraph34')}</div>
    <div>{t('Training.Paragraph35')}</div>
    <div>{t('Training.Paragraph36')}</div>
    <div>{t('Training.Paragraph37')}</div>
    <div>{t('Training.Paragraph38')}</div>
    <div>{t('Training.Paragraph39')}</div>
    <div>{t('Training.Paragraph40')}</div> < br/>
    <strong>3. {t('Training.SubtitleSubtitle3')} :</strong> < br/>
    <div>{t('Training.Paragraph41')}</div>
    </p>
  </details>
  <details>
    <summary>III. {t('Training.Subtitle3')}</summary>
    <p>
    {t('Training.Paragraph42')} < br/>
    {t('Training.Paragraph43')} < br/>
    {t('Training.Paragraph44')} < br/>
    {t('Training.Paragraph45')} < br/>
    {t('Training.Paragraph46')} < br/> < br/>
    <p>{t('Training.Paragraph47')} </p>



{t('Training.Paragraph48')} < br/>
    {t('Training.Paragraph49')} < br/>< br/>
    <div className="d-flex justify-content-center"><img src={t('Language.Lang') === "Français" ? secondTrainingImg_fr : t('Language.Lang') === "Italiano" ? secondTrainingImg_it : secondTrainingImg_en}  alt="Second training img" className="img-fluid" /></div>< br/>< br/>
    <p>{t('Training.Paragraph50')}</p>
    {t('Training.Paragraph51')} < br/>
    {t('Training.Paragraph52')} < br/>< br/>
    <div className="d-flex justify-content-center"><img src={t('Language.Lang') === "Français" ? thirdTrainingImg_fr : t('Language.Lang') === "Italiano" ? thirdTrainingImg_it : thirdTrainingImg_en}  alt="third training img" className="img-fluid" /></div>< br/>< br/>
    <p>{t('Training.Paragraph53')}</p>
    {t('Training.Paragraph54')} < br/>
    {t('Training.Paragraph55')} < br/>
    {t('Training.Paragraph56')} < br/>< br/>
    <div className="d-flex justify-content-center"><img src={t('Language.Lang') === "Français" ? fourthTrainingImg_fr : t('Language.Lang') === "Italiano" ? fourthTrainingImg_it : fourthTrainingImg_en}  alt="Fourth training img" className="img-fluid" /></div>< br/>< br/>
    </p>
  </details>
  <details>
    <summary>IV. {t('Training.Subtitle4')}</summary>
    <p>
    {t('Training.Paragraph57')} < br/>
    {t('Training.Paragraph58')} < br/>
    {t('Training.Paragraph59')} < br/>
    {t('Training.Paragraph60')} < br/> < br/>
    <div className="d-flex justify-content-center">
    <button type="button" className="btn btn-primary btn-block" onClick={() => history.push({
      pathname: '/demo/affiliations'
    })}>{t('Training.Continue')}</button>
    </div>
    </p>
  </details>
  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

Training.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(Training);
