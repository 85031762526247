import React from 'react';
//import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import Moment from 'react-moment';
import { deleteComment } from '../../actions/post';
import { useTranslation } from 'react-i18next';

const CommentItem = ({
  postId,
  comment: { _id, text, username, avatar, user, date },
  auth,
  deleteComment
}) =>  {
  
  const { t } = useTranslation();

  return (
  <div className='post bg-white p-1 my-1'>
    <div>
      {/*<Link to={`/profile/${user}`}>*/}
        <img className='round-img' src={avatar} alt='' />
        <h4>{username}</h4>
      {/*</Link>*/}
    </div>
    <div>
      <p className='my-1'>{text}</p>
      <p className='post-date'>
      {t('Post.Posted_on') + " " /*<Moment format='YYYY/MM/DD'>{date}</Moment>*/}
      {(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? date ? date.substring(0, 10).split("-")[2] + '/' + date.substring(0, 10).split("-")[1] + '/' + date.substring(0, 10).split("-")[0] : date.substring(0, 10).split("-")[2] + '/' + date.substring(0, 10).split("-")[1] + '/' + date.substring(0, 10).split("-")[0] : date ? date.substring(0, 10).split("-")[1] + '/' + date.substring(0, 10).split("-")[2] + '/' + date.substring(0, 10).split("-")[0] : date.substring(0, 10).split("-")[1] + '/' + date.substring(0, 10).split("-")[2] + '/' + date.substring(0, 10).split("-")[0]}
      </p>
      {!auth.loading && user === auth.user._id && (
        <button
          onClick={() => deleteComment(postId, _id)}
          type='button'
          className='btn btn-danger'
        >
          {t('Post.Delete')}
          <i className='fas fa-times' />
        </button>
      )}
    </div>
  </div>
)};

CommentItem.propTypes = {
  postId: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { deleteComment }
)(CommentItem);
