import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SponsorshipActionsDemo from './SponsorshipActionsDemo';
import Tree, { withStyles } from 'react-vertical-tree';
import { updateMonth } from '../../actions/sponsorship';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useTranslation } from 'react-i18next';


const MatrixDemo = ({ updateMonth, auth: { user, theme }, sponsorship: { month } }) => {


 // const [newIndex, setNewIndex] = useState(0);
  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");


  /*const styles = {
    lines: {
      color: '#1890ff',
      height: '90px',
    },
    node: {
      backgroundColor: '#1890ff',
      border: '1px solid #1890ff',
    },
    text: {
      color: '#fff',
    }
  }*/
  const styles = {
    lines: {
      color: theme === "light" ? '#bc9f51' : '#edd99a',
      height: '90px',
    },
    node: {
      backgroundColor: theme === "light" ? '#bc9f51' : '#edd99a',
      border: '1px solid',
      borderColor: theme === "light" ? '#bc9f51' : '#edd99a',
    },
    text: {
      color: theme === "light" ? '#fff' : '#000',
      fontWeight: 'bold'
    }
  }
  const StyledTree = withStyles(styles)(Tree)

  
  useEffect(() => {
    if (theme === 'light') {
    document.body.style = 'background: #b9d7f7';
    }
    else {
    document.body.style = 'background: #398DE7';
    }
   // document.body.style = 'background: #b9d7f7'; //#DCDCDC
    window.scrollTo({top: document.body.scrollHeight / 2, left: 0, behavior: 'smooth' });
    // returned function will be called on component unmount 
    return () => {
      // document.body.style = 'background: #D3D3D3'; //#DCDCDC
      document.body.style = null;
    }
  }, [theme]);

  /*useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);*/




  const { t } = useTranslation();

  const dropDownSelected = event => {
    if (JSON.parse(event).index === "0") {
      updateMonth(0);
    }
    else if (JSON.parse(event).index === "1") {
      updateMonth(1);
    }
    else if (JSON.parse(event).index === "2") {
      updateMonth(2);
    }
    else if (JSON.parse(event).index === "3") {
      updateMonth(3);
    }
    else if (JSON.parse(event).index === "4") {
      updateMonth(4);
    }
    else if (JSON.parse(event).index === "5") {
      updateMonth(5);
    }
    else if (JSON.parse(event).index === "6") {
      updateMonth(6);
    }
    else if (JSON.parse(event).index === "7") {
      if (month !== 7 && month !== 8) {
        setModalTitle(t('Sponsorship.Congratulations'));
        setModalBody(t('Sponsorship.Full_matrix1'));
        setModalFirstButton("");
        setModalFirstButton(t('Sponsorship.Ok'));
        setModalIsOpen(true);
      }
      updateMonth(7);
    }
    else if (JSON.parse(event).index === "8") {
      if (month !== 7 && month !== 8) {
        setModalTitle(t('Sponsorship.Congratulations'));
        setModalBody(t('Sponsorship.Full_matrix1'));
        setModalFirstButton("");
        setModalFirstButton(t('Sponsorship.Ok'));
        setModalIsOpen(true);
      }
      updateMonth(8);
    }
  }

var admin_partners = []

if (month === 1) {
admin_partners = [
  {
     "id":"5f2a89d86ec416001719a34e",
     "new_id":1797,
     "name":"Golden",
     "first_name":"Brian",
     "last_name":"ANDERSON",
     "parent":1,
     "parent_id":"5f291d612d3c250017fa4239",
     "parent_username":"Ratof",
     "country":"GB",
     "gender":"M",
     "date_of_birth":"1997-08-07T10:20:14.000Z",
     "date_creation":"2020-08-05",
     "date_member":"",
     "date_repositioning":"",
     "confirmed":true,
     "role":1,
     "status":"C",
     "email":"Golden@gmail.com",
     "verticalLevel":user.verticalLevel,
     "horizontalLevel":1,
     "children":[
       {            
       "id":"5f2a900b6ec416001719a351",
       "new_id":1798,
       "name":"Okdar",
       "first_name":"Barbara",
       "last_name":"CURTIS",
       "parent":1,
       "parent_id":"5f2a89d86ec416001719a34e",
       "parent_username":"Golden",
       "country":"GB",
       "gender":"F",
       "date_of_birth":"2002-07-29T10:52:45.000Z",
       "date_creation":"2020-08-20",
       "date_member":"",
       "date_repositioning":"",
       "confirmed":true,
       "role":1,
       "status":"C",
       "email":"Okdar@gmail.com",
       "verticalLevel":user.verticalLevel + 1,
       "horizontalLevel":1,
       "children":[]
    },
    {
       "id":"5f2a90a16ec416001719a354",
       "new_id":1799,
       "name":"Drevon",
       "first_name":"Ethan",
       "last_name":"CLARK",
       "parent":1,
       "parent_id":"5f2a89d86ec416001719a34e",
       "parent_username":"Golden",
       "country":"GB",
       "gender":"M",
       "date_of_birth":"2000-08-03T10:55:49.000Z",
       "date_creation":"2020-08-25",
       "date_member":"",
       "date_repositioning":"",
       "confirmed":true,
       "role":1,
       "status":"C",
       "email":"Drevon@gmail.com",
       "verticalLevel":user.verticalLevel + 1,
       "horizontalLevel":2,
       "children":[]
    }
     ]
  }
]
}
else if (month === 2) {
  admin_partners = [
    {
       "id":"5f2a89d86ec416001719a34e",
       "new_id":1797,
       "name":"Golden",
       "first_name":"Brian",
       "last_name":"ANDERSON",
       "parent":1,
       "parent_id":"5f291d612d3c250017fa4239",
       "parent_username":"Ratof",
       "country":"GB",
       "gender":"M",
       "date_of_birth":"1997-08-07T10:20:14.000Z",
       "date_creation":"2020-08-05",
       "date_member":"2020-09-25",
       "date_repositioning":"",
       "confirmed":true,
       "role":1,
       "status":"M",
       "email":"Golden@gmail.com",
       "verticalLevel":user.verticalLevel,
       "horizontalLevel":1,
       "children":[
         {            
         "id":"5f2a900b6ec416001719a351",
         "new_id":1798,
         "name":"Okdar",
         "first_name":"Barbara",
         "last_name":"CURTIS",
         "parent":1,
         "parent_id":"5f2a89d86ec416001719a34e",
         "parent_username":"Golden",
         "country":"GB",
         "gender":"F",
         "date_of_birth":"2002-07-29T10:52:45.000Z",
         "date_creation":"2020-08-20",
         "date_member":"",
         "date_repositioning":"",
         "confirmed":true,
         "role":1,
         "status":"C",
         "email":"Okdar@gmail.com",
         "verticalLevel":user.verticalLevel + 1,
         "horizontalLevel":1,
         "children":[
          {
            "id":"5f2a918d6ec416001719a357",
            "new_id":1800,
            "name":"Nagval",
            "first_name":"Diana",
            "last_name":"BROWN",
            "parent":1,
            "parent_id":"5f2a900b6ec416001719a351",
            "parent_username":"Okdar",
            "country":"GB",
            "gender":"F",
            "date_of_birth":"1995-08-09T10:59:48.000Z",
            "date_creation":"2020-09-20",
            "date_member":"",
            "date_repositioning":"",
            "confirmed":true,
            "role":1,
            "status":"C",
            "email":"Nagval@gmail.com",
            "verticalLevel":user.verticalLevel + 2,
            "horizontalLevel":1,
            "children":[]
         },
         {
            "id":"5f2a92086ec416001719a35a",
            "new_id":1801,
            "name":"Tarek",
            "first_name":"John",
            "last_name":"DAVIES",
            "parent":1,
            "parent_id":"5f2a900b6ec416001719a351",
            "parent_username":"Okdar",
            "country":"GB",
            "gender":"M",
            "date_of_birth":"2002-07-31T11:02:06.000Z",
            "date_creation":"2020-09-25",
            "date_member":"",
            "date_repositioning":"",
            "confirmed":true,
            "role":1,
            "status":"C",
            "email":"Tarek@gmail.com",
            "verticalLevel":user.verticalLevel + 2,
            "horizontalLevel":2,
            "children":[]
         }
         ]
      },
      {
         "id":"5f2a90a16ec416001719a354",
         "new_id":1799,
         "name":"Drevon",
         "first_name":"Ethan",
         "last_name":"CLARK",
         "parent":1,
         "parent_id":"5f2a89d86ec416001719a34e",
         "parent_username":"Golden",
         "country":"GB",
         "gender":"M",
         "date_of_birth":"2000-08-03T10:55:49.000Z",
         "date_creation":"2020-08-25",
         "date_member":"",
         "date_repositioning":"",
         "confirmed":true,
         "role":1,
         "status":"C",
         "email":"Drevon@gmail.com",
         "verticalLevel":user.verticalLevel + 1,
         "horizontalLevel":2,
         "children":[
          {
            "id":"5f2a92a36ec416001719a35d",
            "new_id":1802,
            "name":"Zakar",
            "first_name":"Sarah",
            "last_name":"COOPER",
            "parent":1,
            "parent_id":"5f2a90a16ec416001719a354",
            "parent_username":"Drevon",
            "country":"GB",
            "gender":"F",
            "date_of_birth":"1998-07-29T11:04:56.000Z",
            "date_creation":"2020-09-20",
            "date_member":"",
            "date_repositioning":"",
            "confirmed":true,
            "role":1,
            "status":"C",
            "email":"Zakar@gmail.com",
            "verticalLevel":user.verticalLevel + 2,
            "horizontalLevel":3,
            "children":[]
         },
         {
            "id":"5f2a930d6ec416001719a360",
            "new_id":1803,
            "name":"Trolek",
            "first_name":"Thomas",
            "last_name":"JACKSON",
            "parent":1,
            "parent_id":"5f2a90a16ec416001719a354",
            "parent_username":"Drevon",
            "country":"GB",
            "gender":"M",
            "date_of_birth":"1993-08-23T11:06:20.000Z",
            "date_creation":"2020-09-25",
            "date_member":"",
            "date_repositioning":"",
            "confirmed":true,
            "role":1,
            "status":"C",
            "email":"Trolek@gmail.com",
            "verticalLevel":user.verticalLevel + 2,
            "horizontalLevel":4,
            "children":[]
         }
         ]
      }
       ]
    }
  ]
  }
else if (month === 3) {
  admin_partners = [
    {
       "id":"5f2a89d86ec416001719a34e",
       "new_id":1797,
       "name":"Golden",
       "first_name":"Brian",
       "last_name":"ANDERSON",
       "parent":1,
       "parent_id":"5f291d612d3c250017fa4239",
       "parent_username":"Ratof",
       "country":"GB",
       "gender":"M",
       "date_of_birth":"1997-08-07T10:20:14.000Z",
       "date_creation":"2020-08-05",
       "date_member":"2020-09-25",
       "date_repositioning":"",
       "confirmed":true,
       "role":1,
       "status":"M",
       "email":"Golden@gmail.com",
       "verticalLevel":user.verticalLevel,
       "horizontalLevel":1,
       "children":[
         {            
         "id":"5f2a900b6ec416001719a351",
         "new_id":1798,
         "name":"Okdar",
         "first_name":"Barbara",
         "last_name":"CURTIS",
         "parent":1,
         "parent_id":"5f2a89d86ec416001719a34e",
         "parent_username":"Golden",
         "country":"GB",
         "gender":"F",
         "date_of_birth":"2002-07-29T10:52:45.000Z",
         "date_creation":"2020-08-20",
         "date_member":"2020-10-25",
         "date_repositioning":"",
         "confirmed":true,
         "role":1,
         "status":"M",
         "email":"Okdar@gmail.com",
         "verticalLevel":user.verticalLevel + 1,
         "horizontalLevel":1,
         "children":[
          {
            "id":"5f2a918d6ec416001719a357",
            "new_id":1800,
            "name":"Nagval",
            "first_name":"Diana",
            "last_name":"BROWN",
            "parent":1,
            "parent_id":"5f2a900b6ec416001719a351",
            "parent_username":"Okdar",
            "country":"GB",
            "gender":"F",
            "date_of_birth":"1995-08-09T10:59:48.000Z",
            "date_creation":"2020-09-20",
            "date_member":"",
            "date_repositioning":"",
            "confirmed":true,
            "role":1,
            "status":"C",
            "email":"Nagval@gmail.com",
            "verticalLevel":user.verticalLevel + 2,
            "horizontalLevel":1,
            "children":[
              {
                "id":"5f2a946e6ec416001719a363",
                "new_id":1804,
                "name":"Thaok",
                "first_name":"Tracy",
                "last_name":"EVANS",
                "parent":1,
                "parent_id":"5f2a918d6ec416001719a357",
                "parent_username":"Nagval",
                "country":"GB",
                "gender":"F",
                "date_of_birth":"1998-08-11T11:12:33.000Z",
                "date_creation":"2020-10-20",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":true,
                "role":1,
                "status":"C",
                "email":"Thaok@gmail.com",
                "verticalLevel":user.verticalLevel + 3,
                "horizontalLevel":1,
                "children":[]
             },
             {
                "id":"5f2a94c06ec416001719a366",
                "new_id":1805,
                "name":"Nagza",
                "first_name":"Alexander",
                "last_name":"Miller",
                "parent":1,
                "parent_id":"5f2a918d6ec416001719a357",
                "parent_username":"Nagval",
                "country":"GB",
                "gender":"M",
                "date_of_birth":"1996-08-14T11:13:55.000Z",
                "date_creation":"2020-10-25",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":true,
                "role":1,
                "status":"C",
                "email":"Nagza@gmail.com",
                "verticalLevel":user.verticalLevel + 3,
                "horizontalLevel":2,
                "children":[]
             }
            ]
         },
         {
            "id":"5f2a92086ec416001719a35a",
            "new_id":1801,
            "name":"Tarek",
            "first_name":"John",
            "last_name":"DAVIES",
            "parent":1,
            "parent_id":"5f2a900b6ec416001719a351",
            "parent_username":"Okdar",
            "country":"GB",
            "gender":"M",
            "date_of_birth":"2002-07-31T11:02:06.000Z",
            "date_creation":"2020-09-25",
            "date_member":"",
            "date_repositioning":"",
            "confirmed":true,
            "role":1,
            "status":"C",
            "email":"Tarek@gmail.com",
            "verticalLevel":user.verticalLevel + 2,
            "horizontalLevel":2,
            "children":[
              {
                "id":"5f2a95676ec416001719a369",
                "new_id":1806,
                "name":"Lokuz",
                "first_name":"Emily",
                "last_name":"LEWIS",
                "parent":1,
                "parent_id":"5f2a92086ec416001719a35a",
                "parent_username":"Tarek",
                "country":"GB",
                "gender":"F",
                "date_of_birth":"1995-08-16T11:15:51.000Z",
                "date_creation":"2020-10-20",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":true,
                "role":1,
                "status":"C",
                "email":"Lokuz@gmail.com",
                "verticalLevel":user.verticalLevel + 3,
                "horizontalLevel":3,
                "children":[]
             },
             {
                "id":"5f2a95b06ec416001719a36c",
                "new_id":1807,
                "name":"Gringo",
                "first_name":"James",
                "last_name":"ROBINSON",
                "parent":1,
                "parent_id":"5f2a92086ec416001719a35a",
                "parent_username":"Tarek",
                "country":"GB",
                "gender":"M",
                "date_of_birth":"1990-08-13T11:18:08.000Z",
                "date_creation":"2020-10-25",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":true,
                "role":1,
                "status":"C",
                "email":"Gringo@gmail.com",
                "verticalLevel":user.verticalLevel + 3,
                "horizontalLevel":4,
                "children":[]
             }
            ]
         }
         ]
      },
      {
         "id":"5f2a90a16ec416001719a354",
         "new_id":1799,
         "name":"Drevon",
         "first_name":"Ethan",
         "last_name":"CLARK",
         "parent":1,
         "parent_id":"5f2a89d86ec416001719a34e",
         "parent_username":"Golden",
         "country":"GB",
         "gender":"M",
         "date_of_birth":"2000-08-03T10:55:49.000Z",
         "date_creation":"2020-08-25",
         "date_member":"2020-10-25",
         "date_repositioning":"",
         "confirmed":true,
         "role":1,
         "status":"M",
         "email":"Drevon@gmail.com",
         "verticalLevel":user.verticalLevel + 1,
         "horizontalLevel":2,
         "children":[
          {
            "id":"5f2a92a36ec416001719a35d",
            "new_id":1802,
            "name":"Zakar",
            "first_name":"Sarah",
            "last_name":"COOPER",
            "parent":1,
            "parent_id":"5f2a90a16ec416001719a354",
            "parent_username":"Drevon",
            "country":"GB",
            "gender":"F",
            "date_of_birth":"1998-07-29T11:04:56.000Z",
            "date_creation":"2020-09-20",
            "date_member":"",
            "date_repositioning":"",
            "confirmed":true,
            "role":1,
            "status":"C",
            "email":"Zakar@gmail.com",
            "verticalLevel":user.verticalLevel + 2,
            "horizontalLevel":3,
            "children":[
              {
                "id":"5f2a96a16ec416001719a36f",
                "new_id":1808,
                "name":"Stobar",
                "first_name":"Margaret",
                "last_name":"MURPHY",
                "parent":1,
                "parent_id":"5f2a92a36ec416001719a35d",
                "parent_username":"Zakar",
                "country":"GB",
                "gender":"F",
                "date_of_birth":"1995-08-09T11:19:43.000Z",
                "date_creation":"2020-10-20",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":true,
                "role":1,
                "status":"C",
                "email":"Stobar@gmail.com",
                "verticalLevel":user.verticalLevel + 3,
                "horizontalLevel":5,
                "children":[

                ]
             },
             {
                "id":"5f2a98637fbfdd001758266a",
                "new_id":1809,
                "name":"Kronos",
                "first_name":"Jack",
                "last_name":"WILSON",
                "parent":1,
                "parent_id":"5f2a92a36ec416001719a35d",
                "parent_username":"Zakar",
                "country":"GB",
                "gender":"M",
                "date_of_birth":"1990-08-21T11:28:15.000Z",
                "date_creation":"2020-10-25",
                "date_member":"",
                "date_repositioning":"",
                "confirmed":true,
                "role":1,
                "status":"C",
                "email":"Kronos@gmail.com",
                "verticalLevel":user.verticalLevel + 3,
                "horizontalLevel":6,
                "children":[]
             }
            ]
         },
         {
            "id":"5f2a930d6ec416001719a360",
            "new_id":1803,
            "name":"Trolek",
            "first_name":"Thomas",
            "last_name":"JACKSON",
            "parent":1,
            "parent_id":"5f2a90a16ec416001719a354",
            "parent_username":"Drevon",
            "country":"GB",
            "gender":"M",
            "date_of_birth":"1993-08-23T11:06:20.000Z",
            "date_creation":"2020-09-25",
            "date_member":"",
            "date_repositioning":"",
            "confirmed":true,
            "role":1,
            "status":"C",
            "email":"Trolek@gmail.com",
            "verticalLevel":user.verticalLevel + 2,
            "horizontalLevel":4,
            "children":[
              {
                 "id":"5f2ab39f7fbfdd0017582673",
                 "new_id":1812,
                 "name":"Raguz",
                 "first_name":"Emma",
                 "last_name":"SMITH",
                 "parent":1,
                 "parent_id":"5f2a930d6ec416001719a360",
                 "parent_username":"Trolek",
                 "country":"GB",
                 "gender":"F",
                 "date_of_birth":"1997-08-05T13:23:07.000Z",
                 "date_creation":"2020-10-20",
                 "date_member":"",
                 "date_repositioning":"",
                 "confirmed":true,
                 "role":1,
                 "status":"C",
                 "email":"Raguz@gmail.com",
                 "verticalLevel":user.verticalLevel + 3,
                 "horizontalLevel":7,
                 "children":[]
              },
              {
                 "id":"5f2ab3f17fbfdd0017582676",
                 "new_id":1813,
                 "name":"Dalton",
                 "first_name":"Jack",
                 "last_name":"WILSON",
                 "parent":1,
                 "parent_id":"5f2a930d6ec416001719a360",
                 "parent_username":"Trolek",
                 "country":"GB",
                 "gender":"M",
                 "date_of_birth":"2002-08-05T13:27:12.000Z",
                 "date_creation":"2020-10-25",
                 "date_member":"",
                 "date_repositioning":"",
                 "confirmed":true,
                 "role":1,
                 "status":"C",
                 "email":"Dalton@gmail.com",
                 "verticalLevel":user.verticalLevel + 3,
                 "horizontalLevel":8,
                 "children":[]
              }
            ]
         }
         ]
      }
       ]
    }
  ]
  }
  else if (month === 4) {
    admin_partners = [
      {
         "id":"5f2a89d86ec416001719a34e",
         "new_id":1797,
         "name":"Golden",
         "first_name":"Brian",
         "last_name":"ANDERSON",
         "parent":1,
         "parent_id":"5f291d612d3c250017fa4239",
         "parent_username":"Ratof",
         "country":"GB",
         "gender":"M",
         "date_of_birth":"1997-08-07T10:20:14.000Z",
         "date_creation":"2020-08-05",
         "date_member":"2020-09-25",
         "date_repositioning":"",
         "confirmed":true,
         "role":1,
         "status":"M",
         "email":"Golden@gmail.com",
         "verticalLevel":user.verticalLevel,
         "horizontalLevel":1,
         "children":[
           {            
           "id":"5f2a900b6ec416001719a351",
           "new_id":1798,
           "name":"Okdar",
           "first_name":"Barbara",
           "last_name":"CURTIS",
           "parent":1,
           "parent_id":"5f2a89d86ec416001719a34e",
           "parent_username":"Golden",
           "country":"GB",
           "gender":"F",
           "date_of_birth":"2002-07-29T10:52:45.000Z",
           "date_creation":"2020-08-20",
           "date_member":"2020-10-25",
           "date_repositioning":"",
           "confirmed":true,
           "role":1,
           "status":"M",
           "email":"Okdar@gmail.com",
           "verticalLevel":user.verticalLevel + 1,
           "horizontalLevel":1,
           "children":[
            {
              "id":"5f2a918d6ec416001719a357",
              "new_id":1800,
              "name":"Nagval",
              "first_name":"Diana",
              "last_name":"BROWN",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "gender":"F",
              "date_of_birth":"1995-08-09T10:59:48.000Z",
              "date_creation":"2020-09-20",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":true,
              "role":1,
              "status":"M",
              "email":"Nagval@gmail.com",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":1,
              "children":[
                {
                  "id":"5f2a946e6ec416001719a363",
                  "new_id":1804,
                  "name":"Thaok",
                  "first_name":"Tracy",
                  "last_name":"EVANS",
                  "parent":1,
                  "parent_id":"5f2a918d6ec416001719a357",
                  "parent_username":"Nagval",
                  "country":"GB",
                  "gender":"F",
                  "date_of_birth":"1998-08-11T11:12:33.000Z",
                  "date_creation":"2020-10-20",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":true,
                  "role":1,
                  "status":"C",
                  "email":"Thaok@gmail.com",
                  "verticalLevel":user.verticalLevel + 3,
                  "horizontalLevel":1,
                  "children":[
                    {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 1",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Thaok",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":1,
                      "children":[]
                   },
                   {
                    "id":"",
                    "new_id":0,
                    "name":t('Sponsorship.User') + " 2",
                    "first_name":"",
                    "last_name":"",
                    "parent":1,
                    "parent_id":"",
                    "parent_username":"Thaok",
                    "country":"GB",
                    "gender":"",
                    "date_of_birth":"",
                    "date_creation":"2020-11-25",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"C",
                    "email":"",
                    "verticalLevel":user.verticalLevel + 4,
                    "horizontalLevel":2,
                    "children":[]
                   }
                  ]
               },
               {
                  "id":"5f2a94c06ec416001719a366",
                  "new_id":1805,
                  "name":"Nagza",
                  "first_name":"Alexander",
                  "last_name":"Miller",
                  "parent":1,
                  "parent_id":"5f2a918d6ec416001719a357",
                  "parent_username":"Nagval",
                  "country":"GB",
                  "gender":"M",
                  "date_of_birth":"1996-08-14T11:13:55.000Z",
                  "date_creation":"2020-10-25",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":true,
                  "role":1,
                  "status":"C",
                  "email":"Nagza@gmail.com",
                  "verticalLevel":user.verticalLevel + 3,
                  "horizontalLevel":2,
                  "children":[
                    {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 3",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Nagza",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":3,
                      "children":[]
                   },
                   {
                    "id":"",
                    "new_id":0,
                    "name":t('Sponsorship.User') + " 4",
                    "first_name":"",
                    "last_name":"",
                    "parent":1,
                    "parent_id":"",
                    "parent_username":"Nagza",
                    "country":"GB",
                    "gender":"",
                    "date_of_birth":"",
                    "date_creation":"2020-11-25",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"C",
                    "email":"",
                    "verticalLevel":user.verticalLevel + 4,
                    "horizontalLevel":4,
                    "children":[]
                   }
                  ]
               }
              ]
           },
           {
              "id":"5f2a92086ec416001719a35a",
              "new_id":1801,
              "name":"Tarek",
              "first_name":"John",
              "last_name":"DAVIES",
              "parent":1,
              "parent_id":"5f2a900b6ec416001719a351",
              "parent_username":"Okdar",
              "country":"GB",
              "gender":"M",
              "date_of_birth":"2002-07-31T11:02:06.000Z",
              "date_creation":"2020-09-25",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":true,
              "role":1,
              "status":"M",
              "email":"Tarek@gmail.com",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":2,
              "children":[
                {
                  "id":"5f2a95676ec416001719a369",
                  "new_id":1806,
                  "name":"Lokuz",
                  "first_name":"Emily",
                  "last_name":"LEWIS",
                  "parent":1,
                  "parent_id":"5f2a92086ec416001719a35a",
                  "parent_username":"Tarek",
                  "country":"GB",
                  "gender":"F",
                  "date_of_birth":"1995-08-16T11:15:51.000Z",
                  "date_creation":"2020-10-20",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":true,
                  "role":1,
                  "status":"C",
                  "email":"Lokuz@gmail.com",
                  "verticalLevel":user.verticalLevel + 3,
                  "horizontalLevel":3,
                  "children":[
                    {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 5",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Lokuz",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":5,
                      "children":[]
                   },
                   {
                    "id":"",
                    "new_id":0,
                    "name":t('Sponsorship.User') + " 6",
                    "first_name":"",
                    "last_name":"",
                    "parent":1,
                    "parent_id":"",
                    "parent_username":"Lokuz",
                    "country":"GB",
                    "gender":"",
                    "date_of_birth":"",
                    "date_creation":"2020-11-25",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"C",
                    "email":"",
                    "verticalLevel":user.verticalLevel + 4,
                    "horizontalLevel":6,
                    "children":[]
                   }
                  ]
               },
               {
                  "id":"5f2a95b06ec416001719a36c",
                  "new_id":1807,
                  "name":"Gringo",
                  "first_name":"James",
                  "last_name":"ROBINSON",
                  "parent":1,
                  "parent_id":"5f2a92086ec416001719a35a",
                  "parent_username":"Tarek",
                  "country":"GB",
                  "gender":"M",
                  "date_of_birth":"1990-08-13T11:18:08.000Z",
                  "date_creation":"2020-10-25",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":true,
                  "role":1,
                  "status":"C",
                  "email":"Gringo@gmail.com",
                  "verticalLevel":user.verticalLevel + 3,
                  "horizontalLevel":4,
                  "children":[
                    {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 7",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Gringo",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":7,
                      "children":[]
                   },
                   {
                    "id":"",
                    "new_id":0,
                    "name":t('Sponsorship.User') + " 8",
                    "first_name":"",
                    "last_name":"",
                    "parent":1,
                    "parent_id":"",
                    "parent_username":"Gringo",
                    "country":"GB",
                    "gender":"",
                    "date_of_birth":"",
                    "date_creation":"2020-11-25",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"C",
                    "email":"",
                    "verticalLevel":user.verticalLevel + 4,
                    "horizontalLevel":8,
                    "children":[]
                   }
                  ]
               }
              ]
           }
           ]
        },
        {
           "id":"5f2a90a16ec416001719a354",
           "new_id":1799,
           "name":"Drevon",
           "first_name":"Ethan",
           "last_name":"CLARK",
           "parent":1,
           "parent_id":"5f2a89d86ec416001719a34e",
           "parent_username":"Golden",
           "country":"GB",
           "gender":"M",
           "date_of_birth":"2000-08-03T10:55:49.000Z",
           "date_creation":"2020-08-25",
           "date_member":"2020-10-25",
           "date_repositioning":"",
           "confirmed":true,
           "role":1,
           "status":"M",
           "email":"Drevon@gmail.com",
           "verticalLevel":user.verticalLevel + 1,
           "horizontalLevel":2,
           "children":[
            {
              "id":"5f2a92a36ec416001719a35d",
              "new_id":1802,
              "name":"Zakar",
              "first_name":"Sarah",
              "last_name":"COOPER",
              "parent":1,
              "parent_id":"5f2a90a16ec416001719a354",
              "parent_username":"Drevon",
              "country":"GB",
              "gender":"F",
              "date_of_birth":"1998-07-29T11:04:56.000Z",
              "date_creation":"2020-09-20",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":true,
              "role":1,
              "status":"M",
              "email":"Zakar@gmail.com",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":3,
              "children":[
                {
                  "id":"5f2a96a16ec416001719a36f",
                  "new_id":1808,
                  "name":"Stobar",
                  "first_name":"Margaret",
                  "last_name":"MURPHY",
                  "parent":1,
                  "parent_id":"5f2a92a36ec416001719a35d",
                  "parent_username":"Zakar",
                  "country":"GB",
                  "gender":"F",
                  "date_of_birth":"1995-08-09T11:19:43.000Z",
                  "date_creation":"2020-10-20",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":true,
                  "role":1,
                  "status":"C",
                  "email":"Stobar@gmail.com",
                  "verticalLevel":user.verticalLevel + 3,
                  "horizontalLevel":5,
                  "children":[
                    {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 9",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Stobar",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":9,
                      "children":[]
                   },
                   {
                    "id":"",
                    "new_id":0,
                    "name":t('Sponsorship.User') + " 10",
                    "first_name":"",
                    "last_name":"",
                    "parent":1,
                    "parent_id":"",
                    "parent_username":"Stobar",
                    "country":"GB",
                    "gender":"",
                    "date_of_birth":"",
                    "date_creation":"2020-11-25",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"C",
                    "email":"",
                    "verticalLevel":user.verticalLevel + 4,
                    "horizontalLevel":10,
                    "children":[]
                   }
                  ]
               },
               {
                  "id":"5f2a98637fbfdd001758266a",
                  "new_id":1809,
                  "name":"Kronos",
                  "first_name":"Jack",
                  "last_name":"WILSON",
                  "parent":1,
                  "parent_id":"5f2a92a36ec416001719a35d",
                  "parent_username":"Zakar",
                  "country":"GB",
                  "gender":"M",
                  "date_of_birth":"1990-08-21T11:28:15.000Z",
                  "date_creation":"2020-10-25",
                  "date_member":"",
                  "date_repositioning":"",
                  "confirmed":true,
                  "role":1,
                  "status":"C",
                  "email":"Kronos@gmail.com",
                  "verticalLevel":user.verticalLevel + 3,
                  "horizontalLevel":6,
                  "children":[
                    {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 11",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Kronos",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":11,
                      "children":[]
                   },
                   {
                    "id":"",
                    "new_id":0,
                    "name":t('Sponsorship.User') + " 12",
                    "first_name":"",
                    "last_name":"",
                    "parent":1,
                    "parent_id":"",
                    "parent_username":"Kronos",
                    "country":"GB",
                    "gender":"",
                    "date_of_birth":"",
                    "date_creation":"2020-11-25",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"C",
                    "email":"",
                    "verticalLevel":user.verticalLevel + 4,
                    "horizontalLevel":12,
                    "children":[]
                   }
                  ]
               }
              ]
           },
           {
              "id":"5f2a930d6ec416001719a360",
              "new_id":1803,
              "name":"Trolek",
              "first_name":"Thomas",
              "last_name":"JACKSON",
              "parent":1,
              "parent_id":"5f2a90a16ec416001719a354",
              "parent_username":"Drevon",
              "country":"GB",
              "gender":"M",
              "date_of_birth":"1993-08-23T11:06:20.000Z",
              "date_creation":"2020-09-25",
              "date_member":"2020-11-25",
              "date_repositioning":"",
              "confirmed":true,
              "role":1,
              "status":"M",
              "email":"Trolek@gmail.com",
              "verticalLevel":user.verticalLevel + 2,
              "horizontalLevel":4,
              "children":[
                {
                   "id":"5f2ab39f7fbfdd0017582673",
                   "new_id":1812,
                   "name":"Raguz",
                   "first_name":"Emma",
                   "last_name":"SMITH",
                   "parent":1,
                   "parent_id":"5f2a930d6ec416001719a360",
                   "parent_username":"Trolek",
                   "country":"GB",
                   "gender":"F",
                   "date_of_birth":"1997-08-05T13:23:07.000Z",
                   "date_creation":"2020-10-20",
                   "date_member":"",
                   "date_repositioning":"",
                   "confirmed":true,
                   "role":1,
                   "status":"C",
                   "email":"Raguz@gmail.com",
                   "verticalLevel":user.verticalLevel + 3,
                   "horizontalLevel":7,
                   "children":[
                    {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 13",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Raguz",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":13,
                      "children":[]
                   },
                   {
                    "id":"",
                    "new_id":0,
                    "name":t('Sponsorship.User') + " 14",
                    "first_name":"",
                    "last_name":"",
                    "parent":1,
                    "parent_id":"",
                    "parent_username":"Raguz",
                    "country":"GB",
                    "gender":"",
                    "date_of_birth":"",
                    "date_creation":"2020-11-25",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"C",
                    "email":"",
                    "verticalLevel":user.verticalLevel + 4,
                    "horizontalLevel":14,
                    "children":[]
                   }
                   ]
                },
                {
                   "id":"5f2ab3f17fbfdd0017582676",
                   "new_id":1813,
                   "name":"Dalton",
                   "first_name":"Jack",
                   "last_name":"WILSON",
                   "parent":1,
                   "parent_id":"5f2a930d6ec416001719a360",
                   "parent_username":"Trolek",
                   "country":"GB",
                   "gender":"M",
                   "date_of_birth":"2002-08-05T13:27:12.000Z",
                   "date_creation":"2020-10-25",
                   "date_member":"",
                   "date_repositioning":"",
                   "confirmed":true,
                   "role":1,
                   "status":"C",
                   "email":"Dalton@gmail.com",
                   "verticalLevel":user.verticalLevel + 3,
                   "horizontalLevel":8,
                   "children":[
                    {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 15",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Dalton",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-20",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":15,
                      "children":[]
                   },
                   {
                    "id":"",
                    "new_id":0,
                    "name":t('Sponsorship.User') + " 16",
                    "first_name":"",
                    "last_name":"",
                    "parent":1,
                    "parent_id":"",
                    "parent_username":"Dalton",
                    "country":"GB",
                    "gender":"",
                    "date_of_birth":"",
                    "date_creation":"2020-11-25",
                    "date_member":"",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"C",
                    "email":"",
                    "verticalLevel":user.verticalLevel + 4,
                    "horizontalLevel":16,
                    "children":[]
                   }
                   ]
                }
              ]
           }
           ]
        }
         ]
      }
    ]
    }
    else if (month === 5) {
      admin_partners = [
        {
           "id":"5f2a89d86ec416001719a34e",
           "new_id":1797,
           "name":"Golden",
           "first_name":"Brian",
           "last_name":"ANDERSON",
           "parent":1,
           "parent_id":"5f291d612d3c250017fa4239",
           "parent_username":"Ratof",
           "country":"GB",
           "gender":"M",
           "date_of_birth":"1997-08-07T10:20:14.000Z",
           "date_creation":"2020-08-05",
           "date_member":"2020-09-25",
           "date_repositioning":"",
           "confirmed":true,
           "role":1,
           "status":"M",
           "email":"Golden@gmail.com",
           "verticalLevel":user.verticalLevel,
           "horizontalLevel":1,
           "children":[
             {            
             "id":"5f2a900b6ec416001719a351",
             "new_id":1798,
             "name":"Okdar",
             "first_name":"Barbara",
             "last_name":"CURTIS",
             "parent":1,
             "parent_id":"5f2a89d86ec416001719a34e",
             "parent_username":"Golden",
             "country":"GB",
             "gender":"F",
             "date_of_birth":"2002-07-29T10:52:45.000Z",
             "date_creation":"2020-08-20",
             "date_member":"2020-10-25",
             "date_repositioning":"",
             "confirmed":true,
             "role":1,
             "status":"M",
             "email":"Okdar@gmail.com",
             "verticalLevel":user.verticalLevel + 1,
             "horizontalLevel":1,
             "children":[
              {
                "id":"5f2a918d6ec416001719a357",
                "new_id":1800,
                "name":"Nagval",
                "first_name":"Diana",
                "last_name":"BROWN",
                "parent":1,
                "parent_id":"5f2a900b6ec416001719a351",
                "parent_username":"Okdar",
                "country":"GB",
                "gender":"F",
                "date_of_birth":"1995-08-09T10:59:48.000Z",
                "date_creation":"2020-09-20",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":true,
                "role":1,
                "status":"M",
                "email":"Nagval@gmail.com",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":1,
                "children":[
                  {
                    "id":"5f2a946e6ec416001719a363",
                    "new_id":1804,
                    "name":"Thaok",
                    "first_name":"Tracy",
                    "last_name":"EVANS",
                    "parent":1,
                    "parent_id":"5f2a918d6ec416001719a357",
                    "parent_username":"Nagval",
                    "country":"GB",
                    "gender":"F",
                    "date_of_birth":"1998-08-11T11:12:33.000Z",
                    "date_creation":"2020-10-20",
                    "date_member":"2020-12-25",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"M",
                    "email":"Thaok@gmail.com",
                    "verticalLevel":user.verticalLevel + 3,
                    "horizontalLevel":1,
                    "children":[
                      {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 1",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Thaok",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-20",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":1,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 17",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 1",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":1,
                            "children":[]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 18",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 1",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":2,
                          "children":[]
                         }
                        ]
                     },
                     {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 2",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Thaok",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":2,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 19",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 2",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-20",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":3,
                          "children":[]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 20",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":t('Sponsorship.User') + " 2",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-12-25",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 5,
                        "horizontalLevel":4,
                        "children":[]
                       }
                      ]
                     }
                    ]
                 },
                 {
                    "id":"5f2a94c06ec416001719a366",
                    "new_id":1805,
                    "name":"Nagza",
                    "first_name":"Alexander",
                    "last_name":"Miller",
                    "parent":1,
                    "parent_id":"5f2a918d6ec416001719a357",
                    "parent_username":"Nagval",
                    "country":"GB",
                    "gender":"M",
                    "date_of_birth":"1996-08-14T11:13:55.000Z",
                    "date_creation":"2020-10-25",
                    "date_member":"2020-12-25",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"M",
                    "email":"Nagza@gmail.com",
                    "verticalLevel":user.verticalLevel + 3,
                    "horizontalLevel":2,
                    "children":[
                      {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 3",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Nagza",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-20",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":3,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 21",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 3",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":5,
                            "children":[]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 22",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 3",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":6,
                          "children":[]
                         }
                        ]
                     },
                     {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 4",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Nagza",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":4,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 23",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 4",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-20",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":7,
                          "children":[]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 24",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":t('Sponsorship.User') + " 4",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-12-25",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 5,
                        "horizontalLevel":8,
                        "children":[]
                       }
                      ]
                     }
                    ]
                 }
                ]
             },
             {
                "id":"5f2a92086ec416001719a35a",
                "new_id":1801,
                "name":"Tarek",
                "first_name":"John",
                "last_name":"DAVIES",
                "parent":1,
                "parent_id":"5f2a900b6ec416001719a351",
                "parent_username":"Okdar",
                "country":"GB",
                "gender":"M",
                "date_of_birth":"2002-07-31T11:02:06.000Z",
                "date_creation":"2020-09-25",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":true,
                "role":1,
                "status":"M",
                "email":"Tarek@gmail.com",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":2,
                "children":[
                  {
                    "id":"5f2a95676ec416001719a369",
                    "new_id":1806,
                    "name":"Lokuz",
                    "first_name":"Emily",
                    "last_name":"LEWIS",
                    "parent":1,
                    "parent_id":"5f2a92086ec416001719a35a",
                    "parent_username":"Tarek",
                    "country":"GB",
                    "gender":"F",
                    "date_of_birth":"1995-08-16T11:15:51.000Z",
                    "date_creation":"2020-10-20",
                    "date_member":"2020-12-25",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"M",
                    "email":"Lokuz@gmail.com",
                    "verticalLevel":user.verticalLevel + 3,
                    "horizontalLevel":3,
                    "children":[
                      {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 5",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Lokuz",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-20",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":5,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 25",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 5",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":9,
                            "children":[]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 26",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 5",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":10,
                          "children":[]
                         }
                        ]
                     },
                     {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 6",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Lokuz",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":6,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 27",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 6",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-20",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":11,
                          "children":[]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 28",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":t('Sponsorship.User') + " 6",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-12-25",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 5,
                        "horizontalLevel":12,
                        "children":[]
                       }
                      ]
                     }
                    ]
                 },
                 {
                    "id":"5f2a95b06ec416001719a36c",
                    "new_id":1807,
                    "name":"Gringo",
                    "first_name":"James",
                    "last_name":"ROBINSON",
                    "parent":1,
                    "parent_id":"5f2a92086ec416001719a35a",
                    "parent_username":"Tarek",
                    "country":"GB",
                    "gender":"M",
                    "date_of_birth":"1990-08-13T11:18:08.000Z",
                    "date_creation":"2020-10-25",
                    "date_member":"2020-12-25",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"M",
                    "email":"Gringo@gmail.com",
                    "verticalLevel":user.verticalLevel + 3,
                    "horizontalLevel":4,
                    "children":[
                      {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 7",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Gringo",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-20",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":7,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 29",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 7",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":13,
                            "children":[]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 30",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 7",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":14,
                          "children":[]
                         }
                        ]
                     },
                     {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 8",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Gringo",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":8,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 31",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 8",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-20",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":15,
                          "children":[]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 32",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":t('Sponsorship.User') + " 8",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-12-25",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 5,
                        "horizontalLevel":16,
                        "children":[]
                       }
                      ]
                     }
                    ]
                 }
                ]
             }
             ]
          },
          {
             "id":"5f2a90a16ec416001719a354",
             "new_id":1799,
             "name":"Drevon",
             "first_name":"Ethan",
             "last_name":"CLARK",
             "parent":1,
             "parent_id":"5f2a89d86ec416001719a34e",
             "parent_username":"Golden",
             "country":"GB",
             "gender":"M",
             "date_of_birth":"2000-08-03T10:55:49.000Z",
             "date_creation":"2020-08-25",
             "date_member":"2020-10-25",
             "date_repositioning":"",
             "confirmed":true,
             "role":1,
             "status":"M",
             "email":"Drevon@gmail.com",
             "verticalLevel":user.verticalLevel + 1,
             "horizontalLevel":2,
             "children":[
              {
                "id":"5f2a92a36ec416001719a35d",
                "new_id":1802,
                "name":"Zakar",
                "first_name":"Sarah",
                "last_name":"COOPER",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "gender":"F",
                "date_of_birth":"1998-07-29T11:04:56.000Z",
                "date_creation":"2020-09-20",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":true,
                "role":1,
                "status":"M",
                "email":"Zakar@gmail.com",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":3,
                "children":[
                  {
                    "id":"5f2a96a16ec416001719a36f",
                    "new_id":1808,
                    "name":"Stobar",
                    "first_name":"Margaret",
                    "last_name":"MURPHY",
                    "parent":1,
                    "parent_id":"5f2a92a36ec416001719a35d",
                    "parent_username":"Zakar",
                    "country":"GB",
                    "gender":"F",
                    "date_of_birth":"1995-08-09T11:19:43.000Z",
                    "date_creation":"2020-10-20",
                    "date_member":"2020-12-25",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"M",
                    "email":"Stobar@gmail.com",
                    "verticalLevel":user.verticalLevel + 3,
                    "horizontalLevel":5,
                    "children":[
                      {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 9",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Stobar",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-20",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":9,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 33",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 9",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":17,
                            "children":[]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 34",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 9",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":18,
                          "children":[]
                         }
                        ]
                     },
                     {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 10",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Stobar",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":10,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 35",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 10",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-20",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":19,
                          "children":[]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 36",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":t('Sponsorship.User') + " 10",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-12-25",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 5,
                        "horizontalLevel":20,
                        "children":[]
                       }
                      ]
                     }
                    ]
                 },
                 {
                    "id":"5f2a98637fbfdd001758266a",
                    "new_id":1809,
                    "name":"Kronos",
                    "first_name":"Jack",
                    "last_name":"WILSON",
                    "parent":1,
                    "parent_id":"5f2a92a36ec416001719a35d",
                    "parent_username":"Zakar",
                    "country":"GB",
                    "gender":"M",
                    "date_of_birth":"1990-08-21T11:28:15.000Z",
                    "date_creation":"2020-10-25",
                    "date_member":"2020-12-25",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"M",
                    "email":"Kronos@gmail.com",
                    "verticalLevel":user.verticalLevel + 3,
                    "horizontalLevel":6,
                    "children":[
                      {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 11",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Kronos",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-20",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":11,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 37",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 11",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":21,
                            "children":[]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 38",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 11",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":22,
                          "children":[]
                         }
                        ]
                     },
                     {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 12",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Kronos",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":12,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 39",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 12",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-20",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":23,
                          "children":[]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 40",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":t('Sponsorship.User') + " 12",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-12-25",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 5,
                        "horizontalLevel":24,
                        "children":[]
                       }
                      ]
                     }
                    ]
                 }
                ]
             },
             {
                "id":"5f2a930d6ec416001719a360",
                "new_id":1803,
                "name":"Trolek",
                "first_name":"Thomas",
                "last_name":"JACKSON",
                "parent":1,
                "parent_id":"5f2a90a16ec416001719a354",
                "parent_username":"Drevon",
                "country":"GB",
                "gender":"M",
                "date_of_birth":"1993-08-23T11:06:20.000Z",
                "date_creation":"2020-09-25",
                "date_member":"2020-11-25",
                "date_repositioning":"",
                "confirmed":true,
                "role":1,
                "status":"M",
                "email":"Trolek@gmail.com",
                "verticalLevel":user.verticalLevel + 2,
                "horizontalLevel":4,
                "children":[
                  {
                     "id":"5f2ab39f7fbfdd0017582673",
                     "new_id":1812,
                     "name":"Raguz",
                     "first_name":"Emma",
                     "last_name":"SMITH",
                     "parent":1,
                     "parent_id":"5f2a930d6ec416001719a360",
                     "parent_username":"Trolek",
                     "country":"GB",
                     "gender":"F",
                     "date_of_birth":"1997-08-05T13:23:07.000Z",
                     "date_creation":"2020-10-20",
                     "date_member":"2020-12-25",
                     "date_repositioning":"",
                     "confirmed":true,
                     "role":1,
                     "status":"M",
                     "email":"Raguz@gmail.com",
                     "verticalLevel":user.verticalLevel + 3,
                     "horizontalLevel":7,
                     "children":[
                      {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 13",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Raguz",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-20",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":13,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 41",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 13",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":25,
                            "children":[]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 42",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 13",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":26,
                          "children":[]
                         }
                        ]
                     },
                     {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 14",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Raguz",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":14,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 43",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 14",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-20",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":27,
                          "children":[]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 44",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":t('Sponsorship.User') + " 14",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-12-25",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 5,
                        "horizontalLevel":28,
                        "children":[]
                       }
                      ]
                     }
                     ]
                  },
                  {
                     "id":"5f2ab3f17fbfdd0017582676",
                     "new_id":1813,
                     "name":"Dalton",
                     "first_name":"Jack",
                     "last_name":"WILSON",
                     "parent":1,
                     "parent_id":"5f2a930d6ec416001719a360",
                     "parent_username":"Trolek",
                     "country":"GB",
                     "gender":"M",
                     "date_of_birth":"2002-08-05T13:27:12.000Z",
                     "date_creation":"2020-10-25",
                     "date_member":"2020-12-25",
                     "date_repositioning":"",
                     "confirmed":true,
                     "role":1,
                     "status":"M",
                     "email":"Dalton@gmail.com",
                     "verticalLevel":user.verticalLevel + 3,
                     "horizontalLevel":8,
                     "children":[
                      {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 15",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Dalton",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-20",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":15,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 45",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 15",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":29,
                            "children":[]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 46",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 15",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":30,
                          "children":[]
                         }
                        ]
                     },
                     {
                      "id":"",
                      "new_id":0,
                      "name":t('Sponsorship.User') + " 16",
                      "first_name":"",
                      "last_name":"",
                      "parent":1,
                      "parent_id":"",
                      "parent_username":"Dalton",
                      "country":"GB",
                      "gender":"",
                      "date_of_birth":"",
                      "date_creation":"2020-11-25",
                      "date_member":"",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"C",
                      "email":"",
                      "verticalLevel":user.verticalLevel + 4,
                      "horizontalLevel":16,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 47",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 16",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-20",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":31,
                          "children":[]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 48",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":t('Sponsorship.User') + " 16",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-12-25",
                        "date_member":"",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"C",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 5,
                        "horizontalLevel":32,
                        "children":[]
                       }
                      ]
                     }
                     ]
                  }
                ]
             }
             ]
          }
           ]
        }
      ]
      }
      else if (month === 6) {
        admin_partners = [
          {
             "id":"5f2a89d86ec416001719a34e",
             "new_id":1797,
             "name":"Golden",
             "first_name":"Brian",
             "last_name":"ANDERSON",
             "parent":1,
             "parent_id":"5f291d612d3c250017fa4239",
             "parent_username":"Ratof",
             "country":"GB",
             "gender":"M",
             "date_of_birth":"1997-08-07T10:20:14.000Z",
             "date_creation":"2020-08-05",
             "date_member":"2020-09-25",
             "date_repositioning":"",
             "confirmed":true,
             "role":1,
             "status":"M",
             "email":"Golden@gmail.com",
             "verticalLevel":user.verticalLevel,
             "horizontalLevel":1,
             "children":[
               {            
               "id":"5f2a900b6ec416001719a351",
               "new_id":1798,
               "name":"Okdar",
               "first_name":"Barbara",
               "last_name":"CURTIS",
               "parent":1,
               "parent_id":"5f2a89d86ec416001719a34e",
               "parent_username":"Golden",
               "country":"GB",
               "gender":"F",
               "date_of_birth":"2002-07-29T10:52:45.000Z",
               "date_creation":"2020-08-20",
               "date_member":"2020-10-25",
               "date_repositioning":"",
               "confirmed":true,
               "role":1,
               "status":"M",
               "email":"Okdar@gmail.com",
               "verticalLevel":user.verticalLevel + 1,
               "horizontalLevel":1,
               "children":[
                {
                  "id":"5f2a918d6ec416001719a357",
                  "new_id":1800,
                  "name":"Nagval",
                  "first_name":"Diana",
                  "last_name":"BROWN",
                  "parent":1,
                  "parent_id":"5f2a900b6ec416001719a351",
                  "parent_username":"Okdar",
                  "country":"GB",
                  "gender":"F",
                  "date_of_birth":"1995-08-09T10:59:48.000Z",
                  "date_creation":"2020-09-20",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":true,
                  "role":1,
                  "status":"M",
                  "email":"Nagval@gmail.com",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":1,
                  "children":[
                    {
                      "id":"5f2a946e6ec416001719a363",
                      "new_id":1804,
                      "name":"Thaok",
                      "first_name":"Tracy",
                      "last_name":"EVANS",
                      "parent":1,
                      "parent_id":"5f2a918d6ec416001719a357",
                      "parent_username":"Nagval",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1998-08-11T11:12:33.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Thaok@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":1,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 1",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Thaok",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-20",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":1,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 17",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 1",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":1,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 49",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 17",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":1,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 50",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 17",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":2,
                                "children":[]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 18",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 1",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":2,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 51",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 18",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":3,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 52",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 18",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":4,
                              "children":[]
                             }
                            ]
                           }
                          ]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 2",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Thaok",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-25",
                        "date_member":"2021-01-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":2,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 19",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 2",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":3,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 53",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 19",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":5,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 54",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 19",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":6,
                              "children":[]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 20",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 2",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":4,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 55",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 20",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":7,
                              "children":[]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 56",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 20",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2021-01-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 6,
                            "horizontalLevel":8,
                            "children":[]
                           }
                          ]
                         }
                        ]
                       }
                      ]
                   },
                   {
                      "id":"5f2a94c06ec416001719a366",
                      "new_id":1805,
                      "name":"Nagza",
                      "first_name":"Alexander",
                      "last_name":"Miller",
                      "parent":1,
                      "parent_id":"5f2a918d6ec416001719a357",
                      "parent_username":"Nagval",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"1996-08-14T11:13:55.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Nagza@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":2,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 3",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Nagza",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-20",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":3,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 21",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 3",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":5,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 57",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 21",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":9,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 58",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 21",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":10,
                                "children":[]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 22",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 3",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":6,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 59",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 22",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":11,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 60",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 22",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":12,
                              "children":[]
                             }
                            ]
                           }
                          ]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 4",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Nagza",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-25",
                        "date_member":"2021-01-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":4,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 23",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 4",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":7,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 61",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 23",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":13,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 62",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 23",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":14,
                              "children":[]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 24",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 4",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":8,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 63",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 24",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":15,
                              "children":[]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 64",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 24",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2021-01-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 6,
                            "horizontalLevel":16,
                            "children":[]
                           }
                          ]
                         }
                        ]
                       }
                      ]
                   }
                  ]
               },
               {
                  "id":"5f2a92086ec416001719a35a",
                  "new_id":1801,
                  "name":"Tarek",
                  "first_name":"John",
                  "last_name":"DAVIES",
                  "parent":1,
                  "parent_id":"5f2a900b6ec416001719a351",
                  "parent_username":"Okdar",
                  "country":"GB",
                  "gender":"M",
                  "date_of_birth":"2002-07-31T11:02:06.000Z",
                  "date_creation":"2020-09-25",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":true,
                  "role":1,
                  "status":"M",
                  "email":"Tarek@gmail.com",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":2,
                  "children":[
                    {
                      "id":"5f2a95676ec416001719a369",
                      "new_id":1806,
                      "name":"Lokuz",
                      "first_name":"Emily",
                      "last_name":"LEWIS",
                      "parent":1,
                      "parent_id":"5f2a92086ec416001719a35a",
                      "parent_username":"Tarek",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1995-08-16T11:15:51.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Lokuz@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":3,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 5",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Lokuz",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-20",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":5,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 25",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 5",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":9,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 65",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 25",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":17,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 66",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 25",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":18,
                                "children":[]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 26",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 5",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":10,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 67",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 26",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":19,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 68",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 26",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":20,
                              "children":[]
                             }
                            ]
                           }
                          ]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 6",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Lokuz",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-25",
                        "date_member":"2021-01-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":6,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 27",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 6",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":11,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 69",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 27",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":21,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 70",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 27",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":22,
                              "children":[]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 28",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 6",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":12,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 71",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 28",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":23,
                              "children":[]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 72",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 29",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2021-01-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 6,
                            "horizontalLevel":24,
                            "children":[]
                           }
                          ]
                         }
                        ]
                       }
                      ]
                   },
                   {
                      "id":"5f2a95b06ec416001719a36c",
                      "new_id":1807,
                      "name":"Gringo",
                      "first_name":"James",
                      "last_name":"ROBINSON",
                      "parent":1,
                      "parent_id":"5f2a92086ec416001719a35a",
                      "parent_username":"Tarek",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"1990-08-13T11:18:08.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Gringo@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":4,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 7",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Gringo",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-20",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":7,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 29",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 7",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":13,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 73",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 29",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":25,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 74",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 30",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":26,
                                "children":[]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 30",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 7",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":14,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 75",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 30",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":27,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 76",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 30",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":28,
                              "children":[]
                             }
                            ]
                           }
                          ]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 8",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Gringo",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-25",
                        "date_member":"2021-01-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":8,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 31",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 8",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":15,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 77",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 31",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":29,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 78",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 31",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":30,
                              "children":[]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 32",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 8",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":16,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 79",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 32",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":31,
                              "children":[]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 80",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 32",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2021-01-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 6,
                            "horizontalLevel":32,
                            "children":[]
                           }
                          ]
                         }
                        ]
                       }
                      ]
                   }
                  ]
               }
               ]
            },
            {
               "id":"5f2a90a16ec416001719a354",
               "new_id":1799,
               "name":"Drevon",
               "first_name":"Ethan",
               "last_name":"CLARK",
               "parent":1,
               "parent_id":"5f2a89d86ec416001719a34e",
               "parent_username":"Golden",
               "country":"GB",
               "gender":"M",
               "date_of_birth":"2000-08-03T10:55:49.000Z",
               "date_creation":"2020-08-25",
               "date_member":"2020-10-25",
               "date_repositioning":"",
               "confirmed":true,
               "role":1,
               "status":"M",
               "email":"Drevon@gmail.com",
               "verticalLevel":user.verticalLevel + 1,
               "horizontalLevel":2,
               "children":[
                {
                  "id":"5f2a92a36ec416001719a35d",
                  "new_id":1802,
                  "name":"Zakar",
                  "first_name":"Sarah",
                  "last_name":"COOPER",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "gender":"F",
                  "date_of_birth":"1998-07-29T11:04:56.000Z",
                  "date_creation":"2020-09-20",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":true,
                  "role":1,
                  "status":"M",
                  "email":"Zakar@gmail.com",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":3,
                  "children":[
                    {
                      "id":"5f2a96a16ec416001719a36f",
                      "new_id":1808,
                      "name":"Stobar",
                      "first_name":"Margaret",
                      "last_name":"MURPHY",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1995-08-09T11:19:43.000Z",
                      "date_creation":"2020-10-20",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Stobar@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":5,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 9",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Stobar",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-20",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":9,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 33",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 9",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":17,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 81",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 33",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":33,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 82",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 33",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":34,
                                "children":[]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 34",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 9",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":18,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 83",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 34",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":35,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 84",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 34",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":36,
                              "children":[]
                             }
                            ]
                           }
                          ]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 10",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Stobar",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-25",
                        "date_member":"2021-01-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":10,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 35",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 10",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":19,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 85",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 35",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":37,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 86",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 35",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":38,
                              "children":[]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 36",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 10",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":20,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 87",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 36",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":39,
                              "children":[]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 88",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 36",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2021-01-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 6,
                            "horizontalLevel":40,
                            "children":[]
                           }
                          ]
                         }
                        ]
                       }
                      ]
                   },
                   {
                      "id":"5f2a98637fbfdd001758266a",
                      "new_id":1809,
                      "name":"Kronos",
                      "first_name":"Jack",
                      "last_name":"WILSON",
                      "parent":1,
                      "parent_id":"5f2a92a36ec416001719a35d",
                      "parent_username":"Zakar",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"1990-08-21T11:28:15.000Z",
                      "date_creation":"2020-10-25",
                      "date_member":"2020-12-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Kronos@gmail.com",
                      "verticalLevel":user.verticalLevel + 3,
                      "horizontalLevel":6,
                      "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 11",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Kronos",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-20",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":11,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 37",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 11",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":21,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 89",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 37",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":41,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 90",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 37",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":42,
                                "children":[]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 38",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 11",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":22,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 91",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 38",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":43,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 92",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 38",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":44,
                              "children":[]
                             }
                            ]
                           }
                          ]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 12",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Kronos",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-25",
                        "date_member":"2021-01-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":12,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 39",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 12",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":23,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 93",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 39",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":45,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 94",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 39",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":46,
                              "children":[]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 40",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 12",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":24,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 95",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 40",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":47,
                              "children":[]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 96",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 40",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2021-01-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 6,
                            "horizontalLevel":48,
                            "children":[]
                           }
                          ]
                         }
                        ]
                       }
                      ]
                   }
                  ]
               },
               {
                  "id":"5f2a930d6ec416001719a360",
                  "new_id":1803,
                  "name":"Trolek",
                  "first_name":"Thomas",
                  "last_name":"JACKSON",
                  "parent":1,
                  "parent_id":"5f2a90a16ec416001719a354",
                  "parent_username":"Drevon",
                  "country":"GB",
                  "gender":"M",
                  "date_of_birth":"1993-08-23T11:06:20.000Z",
                  "date_creation":"2020-09-25",
                  "date_member":"2020-11-25",
                  "date_repositioning":"",
                  "confirmed":true,
                  "role":1,
                  "status":"M",
                  "email":"Trolek@gmail.com",
                  "verticalLevel":user.verticalLevel + 2,
                  "horizontalLevel":4,
                  "children":[
                    {
                       "id":"5f2ab39f7fbfdd0017582673",
                       "new_id":1812,
                       "name":"Raguz",
                       "first_name":"Emma",
                       "last_name":"SMITH",
                       "parent":1,
                       "parent_id":"5f2a930d6ec416001719a360",
                       "parent_username":"Trolek",
                       "country":"GB",
                       "gender":"F",
                       "date_of_birth":"1997-08-05T13:23:07.000Z",
                       "date_creation":"2020-10-20",
                       "date_member":"2020-12-25",
                       "date_repositioning":"",
                       "confirmed":true,
                       "role":1,
                       "status":"M",
                       "email":"Raguz@gmail.com",
                       "verticalLevel":user.verticalLevel + 3,
                       "horizontalLevel":7,
                       "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 13",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Raguz",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-20",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":13,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 41",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 13",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":25,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 97",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 41",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":49,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 98",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 41",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":50,
                                "children":[]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 42",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 13",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":26,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 99",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 42",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":51,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 100",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 42",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":52,
                              "children":[]
                             }
                            ]
                           }
                          ]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 14",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Raguz",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-25",
                        "date_member":"2021-01-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":14,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 43",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 14",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":27,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 101",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 43",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":53,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 102",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 43",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":54,
                              "children":[]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 44",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 14",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":28,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 103",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 44",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":55,
                              "children":[]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 104",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 44",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2021-01-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 6,
                            "horizontalLevel":56,
                            "children":[]
                           }
                          ]
                         }
                        ]
                       }
                       ]
                    },
                    {
                       "id":"5f2ab3f17fbfdd0017582676",
                       "new_id":1813,
                       "name":"Dalton",
                       "first_name":"Jack",
                       "last_name":"WILSON",
                       "parent":1,
                       "parent_id":"5f2a930d6ec416001719a360",
                       "parent_username":"Trolek",
                       "country":"GB",
                       "gender":"M",
                       "date_of_birth":"2002-08-05T13:27:12.000Z",
                       "date_creation":"2020-10-25",
                       "date_member":"2020-12-25",
                       "date_repositioning":"",
                       "confirmed":true,
                       "role":1,
                       "status":"M",
                       "email":"Dalton@gmail.com",
                       "verticalLevel":user.verticalLevel + 3,
                       "horizontalLevel":8,
                       "children":[
                        {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 15",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Dalton",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-20",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":15,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 45",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 15",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":29,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 105",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 45",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":57,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 106",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 45",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":58,
                                "children":[]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 46",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 15",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":30,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 107",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 46",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":59,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 108",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 46",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":60,
                              "children":[]
                             }
                            ]
                           }
                          ]
                       },
                       {
                        "id":"",
                        "new_id":0,
                        "name":t('Sponsorship.User') + " 16",
                        "first_name":"",
                        "last_name":"",
                        "parent":1,
                        "parent_id":"",
                        "parent_username":"Dalton",
                        "country":"GB",
                        "gender":"",
                        "date_of_birth":"",
                        "date_creation":"2020-11-25",
                        "date_member":"2021-01-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"",
                        "verticalLevel":user.verticalLevel + 4,
                        "horizontalLevel":16,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 47",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 16",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-20",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":31,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 109",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 47",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":61,
                                "children":[]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 110",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 47",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":62,
                              "children":[]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 48",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":t('Sponsorship.User') + " 16",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-12-25",
                          "date_member":"",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"C",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 5,
                          "horizontalLevel":32,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 111",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 48",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-20",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":63,
                              "children":[]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 112",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 48",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2021-01-25",
                            "date_member":"",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"C",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 6,
                            "horizontalLevel":64,
                            "children":[]
                           }
                          ]
                         }
                        ]
                       }
                       ]
                    }
                  ]
               }
               ]
            }
             ]
          }
        ]
        }













        else if (month === 7) {
          admin_partners = [
            {
               "id":"5f2a89d86ec416001719a34e",
               "new_id":1797,
               "name":"Golden*",
               "first_name":"Brian",
               "last_name":"ANDERSON",
               "parent":1,
               "parent_id":"5f291d612d3c250017fa4239",
               "parent_username":"Ratof",
               "country":"GB",
               "gender":"M",
               "date_of_birth":"1997-08-07T10:20:14.000Z",
               "date_creation":"2020-08-05",
               "date_member":"2020-09-25,2021-02-25",
               "date_repositioning":"",
               "confirmed":true,
               "role":1,
               "status":"M*",
               "email":"Golden@gmail.com",
               "verticalLevel":user.verticalLevel,
               "horizontalLevel":1,
               "children":[
                 {            
                 "id":"5f2a900b6ec416001719a351",
                 "new_id":1798,
                 "name":"Okdar",
                 "first_name":"Barbara",
                 "last_name":"CURTIS",
                 "parent":1,
                 "parent_id":"5f2a89d86ec416001719a34e",
                 "parent_username":"Golden",
                 "country":"GB",
                 "gender":"F",
                 "date_of_birth":"2002-07-29T10:52:45.000Z",
                 "date_creation":"2020-08-20",
                 "date_member":"2020-10-25",
                 "date_repositioning":"",
                 "confirmed":true,
                 "role":1,
                 "status":"M",
                 "email":"Okdar@gmail.com",
                 "verticalLevel":user.verticalLevel + 1,
                 "horizontalLevel":1,
                 "children":[
                  {
                    "id":"5f2a918d6ec416001719a357",
                    "new_id":1800,
                    "name":"Nagval",
                    "first_name":"Diana",
                    "last_name":"BROWN",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "gender":"F",
                    "date_of_birth":"1995-08-09T10:59:48.000Z",
                    "date_creation":"2020-09-20",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"M",
                    "email":"Nagval@gmail.com",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":1,
                    "children":[
                      {
                        "id":"5f2a946e6ec416001719a363",
                        "new_id":1804,
                        "name":"Thaok",
                        "first_name":"Tracy",
                        "last_name":"EVANS",
                        "parent":1,
                        "parent_id":"5f2a918d6ec416001719a357",
                        "parent_username":"Nagval",
                        "country":"GB",
                        "gender":"F",
                        "date_of_birth":"1998-08-11T11:12:33.000Z",
                        "date_creation":"2020-10-20",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Thaok@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":1,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 1",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Thaok",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-20",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":1,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 17",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 1",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":1,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 49",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 17",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":1,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 113",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 49",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":1,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 114",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 49",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":2,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 50",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 17",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":2,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 115",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 50",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":3,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 116",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 50",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":4,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 18",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 1",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":2,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 51",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 18",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":3,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 117",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 51",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":5,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 118",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 51",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":6,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 52",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 18",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":4,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 119",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 52",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":7,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 120",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 52",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":8,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 2",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Thaok",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-25",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":2,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 19",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 2",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":3,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 53",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 19",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":5,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 121",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 53",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":9,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 122",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 53",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":10,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 54",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 19",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":6,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 123",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 54",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":11,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 124",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 54",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":12,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 20",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 2",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"2021-02-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":4,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 55",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 20",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":7,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 125",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 55",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":13,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 126",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 55",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":14,
                                  "children":[]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 56",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 20",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":8,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 127",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 56",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":15,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 128",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 56",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-02-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 7,
                                "horizontalLevel":16,
                                "children":[]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                         }
                        ]
                     },
                     {
                        "id":"5f2a94c06ec416001719a366",
                        "new_id":1805,
                        "name":"Nagza",
                        "first_name":"Alexander",
                        "last_name":"Miller",
                        "parent":1,
                        "parent_id":"5f2a918d6ec416001719a357",
                        "parent_username":"Nagval",
                        "country":"GB",
                        "gender":"M",
                        "date_of_birth":"1996-08-14T11:13:55.000Z",
                        "date_creation":"2020-10-25",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Nagza@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":2,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 3",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Nagza",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-20",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":3,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 21",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 3",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":5,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 57",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 21",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":9,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 129",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 57",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":17,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 130",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 57",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":18,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 58",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 21",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":10,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 131",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 58",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":19,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 132",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 58",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":20,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 22",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 3",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":6,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 59",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 22",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":11,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 133",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 59",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":21,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 134",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 59",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":22,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 60",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 22",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":12,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 135",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 60",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":23,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 136",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 60",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":24,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 4",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Nagza",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-25",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":4,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 23",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 4",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":7,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 61",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 23",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":13,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 137",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 61",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":25,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 138",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 61",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":26,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 62",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 23",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":14,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 139",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 62",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":27,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 140",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 62",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":28,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 24",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 4",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"2021-02-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":8,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 63",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 24",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":15,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 141",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 63",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":29,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 142",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 63",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":30,
                                  "children":[]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 64",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 24",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":16,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 143",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 64",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":31,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 144",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 64",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-02-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 7,
                                "horizontalLevel":32,
                                "children":[]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                         }
                        ]
                     }
                    ]
                 },
                 {
                    "id":"5f2a92086ec416001719a35a",
                    "new_id":1801,
                    "name":"Tarek",
                    "first_name":"John",
                    "last_name":"DAVIES",
                    "parent":1,
                    "parent_id":"5f2a900b6ec416001719a351",
                    "parent_username":"Okdar",
                    "country":"GB",
                    "gender":"M",
                    "date_of_birth":"2002-07-31T11:02:06.000Z",
                    "date_creation":"2020-09-25",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"M",
                    "email":"Tarek@gmail.com",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":2,
                    "children":[
                      {
                        "id":"5f2a95676ec416001719a369",
                        "new_id":1806,
                        "name":"Lokuz",
                        "first_name":"Emily",
                        "last_name":"LEWIS",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"F",
                        "date_of_birth":"1995-08-16T11:15:51.000Z",
                        "date_creation":"2020-10-20",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Lokuz@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":3,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 5",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Lokuz",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-20",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":5,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 25",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 5",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":9,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 65",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 25",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":17,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 145",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 65",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":33,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 146",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 65",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":34,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 66",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 25",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":18,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 147",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 66",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":35,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 148",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 66",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":36,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 26",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 5",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":10,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 67",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 26",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":19,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 149",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 67",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":37,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 150",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 67",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":38,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 68",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 26",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":20,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 151",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 68",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":39,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 152",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 68",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":40,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 6",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Lokuz",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-25",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":6,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 27",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 6",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":11,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 69",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 27",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":21,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 153",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 69",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":41,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 154",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 69",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":42,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 70",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 27",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":22,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 155",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 70",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":43,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 156",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 70",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":44,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 28",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 6",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"2021-02-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":12,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 71",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 28",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":23,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 157",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 71",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":45,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 158",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 71",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":46,
                                  "children":[]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 72",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 29",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":24,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 159",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 72",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":47,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 160",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 72",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-02-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 7,
                                "horizontalLevel":49,
                                "children":[]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                         }
                        ]
                     },
                     {
                        "id":"5f2a95b06ec416001719a36c",
                        "new_id":1807,
                        "name":"Gringo",
                        "first_name":"James",
                        "last_name":"ROBINSON",
                        "parent":1,
                        "parent_id":"5f2a92086ec416001719a35a",
                        "parent_username":"Tarek",
                        "country":"GB",
                        "gender":"M",
                        "date_of_birth":"1990-08-13T11:18:08.000Z",
                        "date_creation":"2020-10-25",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Gringo@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":4,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 7",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Gringo",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-20",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":7,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 29",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 7",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":13,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 73",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 29",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":25,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 161",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 73",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":49,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 162",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 73",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":50,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 74",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 30",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":26,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 163",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 74",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":51,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 164",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 74",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":52,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 30",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 7",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":14,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 75",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 30",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":27,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 165",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 75",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":53,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 166",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 75",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":54,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 76",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 30",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":28,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 167",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 76",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":55,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 168",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 76",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":56,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 8",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Gringo",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-25",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":8,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 31",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 8",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":15,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 77",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 31",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":29,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 169",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 77",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":57,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 170",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 77",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":58,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 78",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 31",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":30,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 171",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 78",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":59,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 172",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 78",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":60,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 32",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 8",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"2021-02-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":16,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 79",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 32",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":31,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 173",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 79",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":61,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 174",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 79",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":62,
                                  "children":[]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 80",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 32",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":32,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 175",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 80",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":63,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 176",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 80",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-02-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 7,
                                "horizontalLevel":64,
                                "children":[]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                         }
                        ]
                     }
                    ]
                 }
                 ]
              },
              {
                 "id":"5f2a90a16ec416001719a354",
                 "new_id":1799,
                 "name":"Drevon",
                 "first_name":"Ethan",
                 "last_name":"CLARK",
                 "parent":1,
                 "parent_id":"5f2a89d86ec416001719a34e",
                 "parent_username":"Golden",
                 "country":"GB",
                 "gender":"M",
                 "date_of_birth":"2000-08-03T10:55:49.000Z",
                 "date_creation":"2020-08-25",
                 "date_member":"2020-10-25",
                 "date_repositioning":"",
                 "confirmed":true,
                 "role":1,
                 "status":"M",
                 "email":"Drevon@gmail.com",
                 "verticalLevel":user.verticalLevel + 1,
                 "horizontalLevel":2,
                 "children":[
                  {
                    "id":"5f2a92a36ec416001719a35d",
                    "new_id":1802,
                    "name":"Zakar",
                    "first_name":"Sarah",
                    "last_name":"COOPER",
                    "parent":1,
                    "parent_id":"5f2a90a16ec416001719a354",
                    "parent_username":"Drevon",
                    "country":"GB",
                    "gender":"F",
                    "date_of_birth":"1998-07-29T11:04:56.000Z",
                    "date_creation":"2020-09-20",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"M",
                    "email":"Zakar@gmail.com",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":3,
                    "children":[
                      {
                        "id":"5f2a96a16ec416001719a36f",
                        "new_id":1808,
                        "name":"Stobar",
                        "first_name":"Margaret",
                        "last_name":"MURPHY",
                        "parent":1,
                        "parent_id":"5f2a92a36ec416001719a35d",
                        "parent_username":"Zakar",
                        "country":"GB",
                        "gender":"F",
                        "date_of_birth":"1995-08-09T11:19:43.000Z",
                        "date_creation":"2020-10-20",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Stobar@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":5,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 9",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Stobar",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-20",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":9,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 33",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 9",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":17,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 81",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 33",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":33,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 177",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 81",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":65,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 178",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 81",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":66,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 82",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 33",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":34,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 179",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 82",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":67,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 180",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 82",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":68,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 34",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 9",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":18,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 83",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 34",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":35,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 181",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 83",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":69,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 182",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 83",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":70,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 84",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 34",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":36,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 183",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 84",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":71,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 184",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 84",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":72,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 10",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Stobar",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-25",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":10,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 35",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 10",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":19,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 85",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 35",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":37,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 185",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 85",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":73,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 186",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 85",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":74,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 86",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 35",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":38,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 187",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 86",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":75,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 188",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 86",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":76,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 36",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 10",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"2021-02-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":20,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 87",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 36",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":39,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 189",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 87",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":77,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 190",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 87",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":78,
                                  "children":[]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 88",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 36",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":40,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 191",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 88",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":79,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 192",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 88",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-02-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 7,
                                "horizontalLevel":80,
                                "children":[]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                         }
                        ]
                     },
                     {
                        "id":"5f2a98637fbfdd001758266a",
                        "new_id":1809,
                        "name":"Kronos",
                        "first_name":"Jack",
                        "last_name":"WILSON",
                        "parent":1,
                        "parent_id":"5f2a92a36ec416001719a35d",
                        "parent_username":"Zakar",
                        "country":"GB",
                        "gender":"M",
                        "date_of_birth":"1990-08-21T11:28:15.000Z",
                        "date_creation":"2020-10-25",
                        "date_member":"2020-12-25",
                        "date_repositioning":"",
                        "confirmed":true,
                        "role":1,
                        "status":"M",
                        "email":"Kronos@gmail.com",
                        "verticalLevel":user.verticalLevel + 3,
                        "horizontalLevel":6,
                        "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 11",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Kronos",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-20",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":11,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 37",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 11",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":21,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 89",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 37",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":41,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 193",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 89",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":81,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 194",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 89",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":82,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 90",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 37",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":42,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 195",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 90",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":83,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 196",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 90",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":84,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 38",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 11",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":22,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 91",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 38",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":43,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 197",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 91",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":85,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 198",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 91",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":86,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 92",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 38",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":44,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 199",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 92",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":87,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 200",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 92",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":88,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 12",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Kronos",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-25",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":12,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 39",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 12",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":23,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 93",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 39",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":45,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 201",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 93",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":89,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 202",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 93",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":90,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 94",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 39",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":46,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 203",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 94",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":91,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 204",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 94",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":92,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 40",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 12",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"2021-02-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":24,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 95",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 40",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":47,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 205",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 95",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":93,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 206",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 95",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":94,
                                  "children":[]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 96",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 40",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":48,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 207",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 96",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":95,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 208",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 96",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-02-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 7,
                                "horizontalLevel":96,
                                "children":[]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                         }
                        ]
                     }
                    ]
                 },
                 {
                    "id":"5f2a930d6ec416001719a360",
                    "new_id":1803,
                    "name":"Trolek",
                    "first_name":"Thomas",
                    "last_name":"JACKSON",
                    "parent":1,
                    "parent_id":"5f2a90a16ec416001719a354",
                    "parent_username":"Drevon",
                    "country":"GB",
                    "gender":"M",
                    "date_of_birth":"1993-08-23T11:06:20.000Z",
                    "date_creation":"2020-09-25",
                    "date_member":"2020-11-25",
                    "date_repositioning":"",
                    "confirmed":true,
                    "role":1,
                    "status":"M",
                    "email":"Trolek@gmail.com",
                    "verticalLevel":user.verticalLevel + 2,
                    "horizontalLevel":4,
                    "children":[
                      {
                         "id":"5f2ab39f7fbfdd0017582673",
                         "new_id":1812,
                         "name":"Raguz",
                         "first_name":"Emma",
                         "last_name":"SMITH",
                         "parent":1,
                         "parent_id":"5f2a930d6ec416001719a360",
                         "parent_username":"Trolek",
                         "country":"GB",
                         "gender":"F",
                         "date_of_birth":"1997-08-05T13:23:07.000Z",
                         "date_creation":"2020-10-20",
                         "date_member":"2020-12-25",
                         "date_repositioning":"",
                         "confirmed":true,
                         "role":1,
                         "status":"M",
                         "email":"Raguz@gmail.com",
                         "verticalLevel":user.verticalLevel + 3,
                         "horizontalLevel":7,
                         "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 13",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Raguz",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-20",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":13,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 41",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 13",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":25,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 97",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 41",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":49,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 209",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 97",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":97,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 210",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 97",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":98,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 98",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 41",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":50,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 211",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 98",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":99,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 212",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 98",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":100,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 42",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 13",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":26,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 99",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 42",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":51,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 213",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 99",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":101,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 214",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 99",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":102,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 100",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 42",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":52,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 215",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 100",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":103,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 216",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 100",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":104,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 14",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Raguz",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-25",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":14,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 43",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 14",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":27,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 101",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 43",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":53,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 217",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 101",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":105,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 218",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 101",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":106,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 102",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 43",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":54,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 219",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 102",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":107,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 220",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 102",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":108,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 44",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 14",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"2021-02-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":28,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 103",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 44",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":55,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 221",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 103",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":109,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 222",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 103",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":110,
                                  "children":[]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 104",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 44",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":56,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 223",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 104",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":111,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 224",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 104",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-02-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 7,
                                "horizontalLevel":112,
                                "children":[]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                         }
                         ]
                      },
                      {
                         "id":"5f2ab3f17fbfdd0017582676",
                         "new_id":1813,
                         "name":"Dalton",
                         "first_name":"Jack",
                         "last_name":"WILSON",
                         "parent":1,
                         "parent_id":"5f2a930d6ec416001719a360",
                         "parent_username":"Trolek",
                         "country":"GB",
                         "gender":"M",
                         "date_of_birth":"2002-08-05T13:27:12.000Z",
                         "date_creation":"2020-10-25",
                         "date_member":"2020-12-25",
                         "date_repositioning":"",
                         "confirmed":true,
                         "role":1,
                         "status":"M",
                         "email":"Dalton@gmail.com",
                         "verticalLevel":user.verticalLevel + 3,
                         "horizontalLevel":8,
                         "children":[
                          {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 15",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Dalton",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-20",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":15,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 45",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 15",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":29,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 105",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 45",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":57,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 225",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 105",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":113,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 226",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 105",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":114,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 106",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 45",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":58,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 227",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 106",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":115,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 228",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 106",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":116,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 46",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 15",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":30,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 107",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 46",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":59,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 229",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 107",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":117,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 230",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 107",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":118,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 108",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 46",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":60,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 231",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 108",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":119,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 232",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 108",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":120,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                         },
                         {
                          "id":"",
                          "new_id":0,
                          "name":t('Sponsorship.User') + " 16",
                          "first_name":"",
                          "last_name":"",
                          "parent":1,
                          "parent_id":"",
                          "parent_username":"Dalton",
                          "country":"GB",
                          "gender":"",
                          "date_of_birth":"",
                          "date_creation":"2020-11-25",
                          "date_member":"2021-01-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"",
                          "verticalLevel":user.verticalLevel + 4,
                          "horizontalLevel":16,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 47",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 16",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-20",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":31,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 109",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 47",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":61,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 233",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 109",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":121,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 234",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 109",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":122,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 110",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 47",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":62,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 235",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 110",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":123,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 236",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 110",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":124,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 48",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":t('Sponsorship.User') + " 16",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-12-25",
                            "date_member":"2021-02-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 5,
                            "horizontalLevel":32,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 111",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 48",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-20",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":63,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 237",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 111",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":125,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 238",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 111",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":126,
                                  "children":[]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 112",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 48",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2021-01-25",
                              "date_member":"",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"C",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 6,
                              "horizontalLevel":64,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 239",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 112",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-20",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":127,
                                  "children":[]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 240",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 112",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-02-25",
                                "date_member":"",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"C",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 7,
                                "horizontalLevel":128,
                                "children":[]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                         }
                         ]
                      }
                    ]
                 }
                 ]
              }
               ]
            }
          ]
          }









          else if (month === 8) {
            admin_partners = [
              {
                 "id":"5f2a89d86ec416001719a34e",
                 "new_id":1797,
                 "name":"Golden*",
                 "first_name":"Brian",
                 "last_name":"ANDERSON",
                 "parent":1,
                 "parent_id":"5f291d612d3c250017fa4239",
                 "parent_username":"Ratof",
                 "country":"GB",
                 "gender":"M",
                 "date_of_birth":"1997-08-07T10:20:14.000Z",
                 "date_creation":"2020-08-05",
                 "date_member":"2020-09-25,2021-02-25",
                 "date_repositioning":"",
                 "confirmed":true,
                 "role":1,
                 "status":"M*",
                 "email":"Golden@gmail.com",
                 "verticalLevel":user.verticalLevel,
                 "horizontalLevel":1,
                 "children":[
                   {            
                   "id":"5f2a900b6ec416001719a351",
                   "new_id":1798,
                   "name":"Okdar*",
                   "first_name":"Barbara",
                   "last_name":"CURTIS",
                   "parent":1,
                   "parent_id":"5f2a89d86ec416001719a34e",
                   "parent_username":"Golden",
                   "country":"GB",
                   "gender":"F",
                   "date_of_birth":"2002-07-29T10:52:45.000Z",
                   "date_creation":"2020-08-20",
                   "date_member":"2020-10-25,2021-03-25",
                   "date_repositioning":"",
                   "confirmed":true,
                   "role":1,
                   "status":"M*",
                   "email":"Okdar@gmail.com",
                   "verticalLevel":user.verticalLevel + 1,
                   "horizontalLevel":1,
                   "children":[
                    {
                      "id":"5f2a918d6ec416001719a357",
                      "new_id":1800,
                      "name":"Nagval",
                      "first_name":"Diana",
                      "last_name":"BROWN",
                      "parent":1,
                      "parent_id":"5f2a900b6ec416001719a351",
                      "parent_username":"Okdar",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1995-08-09T10:59:48.000Z",
                      "date_creation":"2020-09-20",
                      "date_member":"2020-11-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Nagval@gmail.com",
                      "verticalLevel":user.verticalLevel + 2,
                      "horizontalLevel":1,
                      "children":[
                        {
                          "id":"5f2a946e6ec416001719a363",
                          "new_id":1804,
                          "name":"Thaok",
                          "first_name":"Tracy",
                          "last_name":"EVANS",
                          "parent":1,
                          "parent_id":"5f2a918d6ec416001719a357",
                          "parent_username":"Nagval",
                          "country":"GB",
                          "gender":"F",
                          "date_of_birth":"1998-08-11T11:12:33.000Z",
                          "date_creation":"2020-10-20",
                          "date_member":"2020-12-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"Thaok@gmail.com",
                          "verticalLevel":user.verticalLevel + 3,
                          "horizontalLevel":1,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 1",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":"Thaok",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-11-20",
                              "date_member":"2021-01-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 4,
                              "horizontalLevel":1,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 17",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 1",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2020-12-20",
                                  "date_member":"2021-02-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 5,
                                  "horizontalLevel":1,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 49",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 17",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-01-20",
                                      "date_member":"2021-03-25",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"M",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 6,
                                      "horizontalLevel":1,
                                      "children":[
                                        {
                                          "id":"",
                                          "new_id":0,
                                          "name":t('Sponsorship.User') + " 113",
                                          "first_name":"",
                                          "last_name":"",
                                          "parent":1,
                                          "parent_id":"",
                                          "parent_username":t('Sponsorship.User') + " 49",
                                          "country":"GB",
                                          "gender":"",
                                          "date_of_birth":"",
                                          "date_creation":"2021-02-20",
                                          "date_member":"",
                                          "date_repositioning":"",
                                          "confirmed":true,
                                          "role":1,
                                          "status":"C",
                                          "email":"",
                                          "verticalLevel":user.verticalLevel + 7,
                                          "horizontalLevel":1,
                                          "children":[]
                                       },
                                       {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 114",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 49",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-25",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":2,
                                        "children":[]
                                       }
                                      ]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 50",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 17",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-25",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":2,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 115",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 50",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":3,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 116",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 50",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":4,
                                      "children":[]
                                     }
                                    ]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 18",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 1",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-25",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":2,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 51",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 18",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":3,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 117",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 51",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":5,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 118",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 51",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":6,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 52",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 18",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":4,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 119",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 52",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":7,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 120",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 52",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":8,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 2",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Thaok",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-25",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":2,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 19",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 2",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":3,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 53",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 19",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":5,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 121",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 53",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":9,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 122",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 53",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":10,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 54",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 19",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":6,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 123",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 54",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":11,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 124",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 54",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":12,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 20",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 2",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":4,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 55",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 20",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":7,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 125",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 55",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":13,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 126",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 55",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":14,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 56",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 20",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"2021-03-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":8,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 127",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 56",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":15,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 128",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 56",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":16,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                       },
                       {
                          "id":"5f2a94c06ec416001719a366",
                          "new_id":1805,
                          "name":"Nagza",
                          "first_name":"Alexander",
                          "last_name":"Miller",
                          "parent":1,
                          "parent_id":"5f2a918d6ec416001719a357",
                          "parent_username":"Nagval",
                          "country":"GB",
                          "gender":"M",
                          "date_of_birth":"1996-08-14T11:13:55.000Z",
                          "date_creation":"2020-10-25",
                          "date_member":"2020-12-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"Nagza@gmail.com",
                          "verticalLevel":user.verticalLevel + 3,
                          "horizontalLevel":2,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 3",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":"Nagza",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-11-20",
                              "date_member":"2021-01-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 4,
                              "horizontalLevel":3,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 21",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 3",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2020-12-20",
                                  "date_member":"2021-02-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 5,
                                  "horizontalLevel":5,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 57",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 21",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-01-20",
                                      "date_member":"2021-03-25",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"M",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 6,
                                      "horizontalLevel":9,
                                      "children":[
                                        {
                                          "id":"",
                                          "new_id":0,
                                          "name":t('Sponsorship.User') + " 129",
                                          "first_name":"",
                                          "last_name":"",
                                          "parent":1,
                                          "parent_id":"",
                                          "parent_username":t('Sponsorship.User') + " 57",
                                          "country":"GB",
                                          "gender":"",
                                          "date_of_birth":"",
                                          "date_creation":"2021-02-20",
                                          "date_member":"",
                                          "date_repositioning":"",
                                          "confirmed":true,
                                          "role":1,
                                          "status":"C",
                                          "email":"",
                                          "verticalLevel":user.verticalLevel + 7,
                                          "horizontalLevel":17,
                                          "children":[]
                                       },
                                       {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 130",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 57",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-25",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":18,
                                        "children":[]
                                       }
                                      ]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 58",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 21",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-25",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":10,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 131",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 58",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":19,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 132",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 58",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":20,
                                      "children":[]
                                     }
                                    ]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 22",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 3",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-25",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":6,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 59",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 22",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":11,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 133",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 59",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":21,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 134",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 59",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":22,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 60",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 22",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":12,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 135",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 60",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":23,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 136",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 60",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":24,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 4",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Nagza",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-25",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":4,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 23",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 4",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":7,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 61",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 23",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":13,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 137",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 61",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":25,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 138",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 61",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":26,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 62",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 23",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":14,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 139",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 62",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":27,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 140",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 62",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":28,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 24",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 4",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":8,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 63",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 24",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":15,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 141",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 63",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":29,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 142",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 63",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":30,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 64",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 24",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"2021-03-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":16,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 143",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 64",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":31,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 144",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 64",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":32,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                       }
                      ]
                   },
                   {
                      "id":"5f2a92086ec416001719a35a",
                      "new_id":1801,
                      "name":"Tarek",
                      "first_name":"John",
                      "last_name":"DAVIES",
                      "parent":1,
                      "parent_id":"5f2a900b6ec416001719a351",
                      "parent_username":"Okdar",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"2002-07-31T11:02:06.000Z",
                      "date_creation":"2020-09-25",
                      "date_member":"2020-11-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Tarek@gmail.com",
                      "verticalLevel":user.verticalLevel + 2,
                      "horizontalLevel":2,
                      "children":[
                        {
                          "id":"5f2a95676ec416001719a369",
                          "new_id":1806,
                          "name":"Lokuz",
                          "first_name":"Emily",
                          "last_name":"LEWIS",
                          "parent":1,
                          "parent_id":"5f2a92086ec416001719a35a",
                          "parent_username":"Tarek",
                          "country":"GB",
                          "gender":"F",
                          "date_of_birth":"1995-08-16T11:15:51.000Z",
                          "date_creation":"2020-10-20",
                          "date_member":"2020-12-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"Lokuz@gmail.com",
                          "verticalLevel":user.verticalLevel + 3,
                          "horizontalLevel":3,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 5",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":"Lokuz",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-11-20",
                              "date_member":"2021-01-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 4,
                              "horizontalLevel":5,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 25",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 5",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2020-12-20",
                                  "date_member":"2021-02-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 5,
                                  "horizontalLevel":9,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 65",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 25",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-01-20",
                                      "date_member":"2021-03-25",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"M",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 6,
                                      "horizontalLevel":17,
                                      "children":[
                                        {
                                          "id":"",
                                          "new_id":0,
                                          "name":t('Sponsorship.User') + " 145",
                                          "first_name":"",
                                          "last_name":"",
                                          "parent":1,
                                          "parent_id":"",
                                          "parent_username":t('Sponsorship.User') + " 65",
                                          "country":"GB",
                                          "gender":"",
                                          "date_of_birth":"",
                                          "date_creation":"2021-02-20",
                                          "date_member":"",
                                          "date_repositioning":"",
                                          "confirmed":true,
                                          "role":1,
                                          "status":"C",
                                          "email":"",
                                          "verticalLevel":user.verticalLevel + 7,
                                          "horizontalLevel":33,
                                          "children":[]
                                       },
                                       {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 146",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 65",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-25",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":34,
                                        "children":[]
                                       }
                                      ]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 66",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 25",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-25",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":18,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 147",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 66",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":35,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 148",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 66",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":36,
                                      "children":[]
                                     }
                                    ]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 26",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 5",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-25",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":10,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 67",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 26",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":19,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 149",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 67",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":37,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 150",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 67",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":38,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 68",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 26",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":20,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 151",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 68",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":39,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 152",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 68",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":40,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 6",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Lokuz",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-25",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":6,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 27",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 6",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":11,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 69",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 27",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":21,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 153",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 69",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":41,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 154",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 69",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":42,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 70",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 27",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":22,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 155",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 70",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":43,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 156",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 70",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":44,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 28",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 6",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":12,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 71",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 28",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":23,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 157",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 71",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":45,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 158",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 71",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":46,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 72",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 29",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"2021-03-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":24,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 159",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 72",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":47,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 160",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 72",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":49,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                       },
                       {
                          "id":"5f2a95b06ec416001719a36c",
                          "new_id":1807,
                          "name":"Gringo",
                          "first_name":"James",
                          "last_name":"ROBINSON",
                          "parent":1,
                          "parent_id":"5f2a92086ec416001719a35a",
                          "parent_username":"Tarek",
                          "country":"GB",
                          "gender":"M",
                          "date_of_birth":"1990-08-13T11:18:08.000Z",
                          "date_creation":"2020-10-25",
                          "date_member":"2020-12-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"Gringo@gmail.com",
                          "verticalLevel":user.verticalLevel + 3,
                          "horizontalLevel":4,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 7",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":"Gringo",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-11-20",
                              "date_member":"2021-01-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 4,
                              "horizontalLevel":7,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 29",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 7",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2020-12-20",
                                  "date_member":"2021-02-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 5,
                                  "horizontalLevel":13,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 73",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 29",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-01-20",
                                      "date_member":"2021-03-25",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"M",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 6,
                                      "horizontalLevel":25,
                                      "children":[
                                        {
                                          "id":"",
                                          "new_id":0,
                                          "name":t('Sponsorship.User') + " 161",
                                          "first_name":"",
                                          "last_name":"",
                                          "parent":1,
                                          "parent_id":"",
                                          "parent_username":t('Sponsorship.User') + " 73",
                                          "country":"GB",
                                          "gender":"",
                                          "date_of_birth":"",
                                          "date_creation":"2021-02-20",
                                          "date_member":"",
                                          "date_repositioning":"",
                                          "confirmed":true,
                                          "role":1,
                                          "status":"C",
                                          "email":"",
                                          "verticalLevel":user.verticalLevel + 7,
                                          "horizontalLevel":49,
                                          "children":[]
                                       },
                                       {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 162",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 73",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-25",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":50,
                                        "children":[]
                                       }
                                      ]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 74",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 30",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-25",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":26,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 163",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 74",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":51,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 164",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 74",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":52,
                                      "children":[]
                                     }
                                    ]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 30",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 7",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-25",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":14,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 75",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 30",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":27,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 165",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 75",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":53,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 166",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 75",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":54,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 76",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 30",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":28,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 167",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 76",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":55,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 168",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 76",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":56,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 8",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Gringo",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-25",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":8,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 31",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 8",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":15,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 77",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 31",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":29,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 169",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 77",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":57,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 170",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 77",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":58,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 78",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 31",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":30,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 171",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 78",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":59,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 172",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 78",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":60,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 32",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 8",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":16,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 79",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 32",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":31,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 173",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 79",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":61,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 174",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 79",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":62,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 80",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 32",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"2021-03-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":32,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 175",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 80",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":63,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 176",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 80",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":64,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                       }
                      ]
                   }
                   ]
                },
                {
                   "id":"5f2a90a16ec416001719a354",
                   "new_id":1799,
                   "name":"Drevon*",
                   "first_name":"Ethan",
                   "last_name":"CLARK",
                   "parent":1,
                   "parent_id":"5f2a89d86ec416001719a34e",
                   "parent_username":"Golden",
                   "country":"GB",
                   "gender":"M",
                   "date_of_birth":"2000-08-03T10:55:49.000Z",
                   "date_creation":"2020-08-25",
                   "date_member":"2020-10-25,2021-03-25",
                   "date_repositioning":"",
                   "confirmed":true,
                   "role":1,
                   "status":"M*",
                   "email":"Drevon@gmail.com",
                   "verticalLevel":user.verticalLevel + 1,
                   "horizontalLevel":2,
                   "children":[
                    {
                      "id":"5f2a92a36ec416001719a35d",
                      "new_id":1802,
                      "name":"Zakar",
                      "first_name":"Sarah",
                      "last_name":"COOPER",
                      "parent":1,
                      "parent_id":"5f2a90a16ec416001719a354",
                      "parent_username":"Drevon",
                      "country":"GB",
                      "gender":"F",
                      "date_of_birth":"1998-07-29T11:04:56.000Z",
                      "date_creation":"2020-09-20",
                      "date_member":"2020-11-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Zakar@gmail.com",
                      "verticalLevel":user.verticalLevel + 2,
                      "horizontalLevel":3,
                      "children":[
                        {
                          "id":"5f2a96a16ec416001719a36f",
                          "new_id":1808,
                          "name":"Stobar",
                          "first_name":"Margaret",
                          "last_name":"MURPHY",
                          "parent":1,
                          "parent_id":"5f2a92a36ec416001719a35d",
                          "parent_username":"Zakar",
                          "country":"GB",
                          "gender":"F",
                          "date_of_birth":"1995-08-09T11:19:43.000Z",
                          "date_creation":"2020-10-20",
                          "date_member":"2020-12-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"Stobar@gmail.com",
                          "verticalLevel":user.verticalLevel + 3,
                          "horizontalLevel":5,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 9",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":"Stobar",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-11-20",
                              "date_member":"2021-01-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 4,
                              "horizontalLevel":9,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 33",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 9",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2020-12-20",
                                  "date_member":"2021-02-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 5,
                                  "horizontalLevel":17,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 81",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 33",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-01-20",
                                      "date_member":"2021-03-25",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"M",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 6,
                                      "horizontalLevel":33,
                                      "children":[
                                        {
                                          "id":"",
                                          "new_id":0,
                                          "name":t('Sponsorship.User') + " 177",
                                          "first_name":"",
                                          "last_name":"",
                                          "parent":1,
                                          "parent_id":"",
                                          "parent_username":t('Sponsorship.User') + " 81",
                                          "country":"GB",
                                          "gender":"",
                                          "date_of_birth":"",
                                          "date_creation":"2021-02-20",
                                          "date_member":"",
                                          "date_repositioning":"",
                                          "confirmed":true,
                                          "role":1,
                                          "status":"C",
                                          "email":"",
                                          "verticalLevel":user.verticalLevel + 7,
                                          "horizontalLevel":65,
                                          "children":[]
                                       },
                                       {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 178",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 81",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-25",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":66,
                                        "children":[]
                                       }
                                      ]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 82",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 33",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-25",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":34,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 179",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 82",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":67,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 180",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 82",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":68,
                                      "children":[]
                                     }
                                    ]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 34",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 9",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-25",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":18,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 83",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 34",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":35,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 181",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 83",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":69,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 182",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 83",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":70,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 84",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 34",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":36,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 183",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 84",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":71,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 184",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 84",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":72,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 10",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Stobar",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-25",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":10,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 35",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 10",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":19,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 85",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 35",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":37,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 185",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 85",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":73,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 186",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 85",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":74,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 86",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 35",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":38,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 187",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 86",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":75,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 188",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 86",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":76,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 36",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 10",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":20,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 87",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 36",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":39,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 189",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 87",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":77,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 190",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 87",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":78,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 88",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 36",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"2021-03-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":40,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 191",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 88",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":79,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 192",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 88",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":80,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                       },
                       {
                          "id":"5f2a98637fbfdd001758266a",
                          "new_id":1809,
                          "name":"Kronos",
                          "first_name":"Jack",
                          "last_name":"WILSON",
                          "parent":1,
                          "parent_id":"5f2a92a36ec416001719a35d",
                          "parent_username":"Zakar",
                          "country":"GB",
                          "gender":"M",
                          "date_of_birth":"1990-08-21T11:28:15.000Z",
                          "date_creation":"2020-10-25",
                          "date_member":"2020-12-25",
                          "date_repositioning":"",
                          "confirmed":true,
                          "role":1,
                          "status":"M",
                          "email":"Kronos@gmail.com",
                          "verticalLevel":user.verticalLevel + 3,
                          "horizontalLevel":6,
                          "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 11",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":"Kronos",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-11-20",
                              "date_member":"2021-01-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 4,
                              "horizontalLevel":11,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 37",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 11",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2020-12-20",
                                  "date_member":"2021-02-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 5,
                                  "horizontalLevel":21,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 89",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 37",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-01-20",
                                      "date_member":"2021-03-25",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"M",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 6,
                                      "horizontalLevel":41,
                                      "children":[
                                        {
                                          "id":"",
                                          "new_id":0,
                                          "name":t('Sponsorship.User') + " 193",
                                          "first_name":"",
                                          "last_name":"",
                                          "parent":1,
                                          "parent_id":"",
                                          "parent_username":t('Sponsorship.User') + " 89",
                                          "country":"GB",
                                          "gender":"",
                                          "date_of_birth":"",
                                          "date_creation":"2021-02-20",
                                          "date_member":"",
                                          "date_repositioning":"",
                                          "confirmed":true,
                                          "role":1,
                                          "status":"C",
                                          "email":"",
                                          "verticalLevel":user.verticalLevel + 7,
                                          "horizontalLevel":81,
                                          "children":[]
                                       },
                                       {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 194",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 89",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-25",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":82,
                                        "children":[]
                                       }
                                      ]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 90",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 37",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-25",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":42,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 195",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 90",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":83,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 196",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 90",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":84,
                                      "children":[]
                                     }
                                    ]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 38",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 11",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-25",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":22,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 91",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 38",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":43,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 197",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 91",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":85,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 198",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 91",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":86,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 92",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 38",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":44,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 199",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 92",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":87,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 200",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 92",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":88,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 12",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Kronos",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-25",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":12,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 39",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 12",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":23,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 93",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 39",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":45,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 201",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 93",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":89,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 202",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 93",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":90,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 94",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 39",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":46,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 203",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 94",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":91,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 204",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 94",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":92,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 40",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 12",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":24,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 95",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 40",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":47,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 205",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 95",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":93,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 206",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 95",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":94,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 96",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 40",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"2021-03-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":48,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 207",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 96",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":95,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 208",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 96",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":96,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                           }
                          ]
                       }
                      ]
                   },
                   {
                      "id":"5f2a930d6ec416001719a360",
                      "new_id":1803,
                      "name":"Trolek",
                      "first_name":"Thomas",
                      "last_name":"JACKSON",
                      "parent":1,
                      "parent_id":"5f2a90a16ec416001719a354",
                      "parent_username":"Drevon",
                      "country":"GB",
                      "gender":"M",
                      "date_of_birth":"1993-08-23T11:06:20.000Z",
                      "date_creation":"2020-09-25",
                      "date_member":"2020-11-25",
                      "date_repositioning":"",
                      "confirmed":true,
                      "role":1,
                      "status":"M",
                      "email":"Trolek@gmail.com",
                      "verticalLevel":user.verticalLevel + 2,
                      "horizontalLevel":4,
                      "children":[
                        {
                           "id":"5f2ab39f7fbfdd0017582673",
                           "new_id":1812,
                           "name":"Raguz",
                           "first_name":"Emma",
                           "last_name":"SMITH",
                           "parent":1,
                           "parent_id":"5f2a930d6ec416001719a360",
                           "parent_username":"Trolek",
                           "country":"GB",
                           "gender":"F",
                           "date_of_birth":"1997-08-05T13:23:07.000Z",
                           "date_creation":"2020-10-20",
                           "date_member":"2020-12-25",
                           "date_repositioning":"",
                           "confirmed":true,
                           "role":1,
                           "status":"M",
                           "email":"Raguz@gmail.com",
                           "verticalLevel":user.verticalLevel + 3,
                           "horizontalLevel":7,
                           "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 13",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":"Raguz",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-11-20",
                              "date_member":"2021-01-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 4,
                              "horizontalLevel":13,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 41",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 13",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2020-12-20",
                                  "date_member":"2021-02-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 5,
                                  "horizontalLevel":25,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 97",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 41",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-01-20",
                                      "date_member":"2021-03-25",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"M",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 6,
                                      "horizontalLevel":49,
                                      "children":[
                                        {
                                          "id":"",
                                          "new_id":0,
                                          "name":t('Sponsorship.User') + " 209",
                                          "first_name":"",
                                          "last_name":"",
                                          "parent":1,
                                          "parent_id":"",
                                          "parent_username":t('Sponsorship.User') + " 97",
                                          "country":"GB",
                                          "gender":"",
                                          "date_of_birth":"",
                                          "date_creation":"2021-02-20",
                                          "date_member":"",
                                          "date_repositioning":"",
                                          "confirmed":true,
                                          "role":1,
                                          "status":"C",
                                          "email":"",
                                          "verticalLevel":user.verticalLevel + 7,
                                          "horizontalLevel":97,
                                          "children":[]
                                       },
                                       {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 210",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 97",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-25",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":98,
                                        "children":[]
                                       }
                                      ]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 98",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 41",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-25",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":50,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 211",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 98",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":99,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 212",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 98",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":100,
                                      "children":[]
                                     }
                                    ]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 42",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 13",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-25",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":26,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 99",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 42",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":51,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 213",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 99",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":101,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 214",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 99",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":102,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 100",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 42",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":52,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 215",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 100",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":103,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 216",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 100",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":104,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 14",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Raguz",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-25",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":14,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 43",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 14",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":27,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 101",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 43",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":53,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 217",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 101",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":105,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 218",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 101",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":106,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 102",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 43",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":54,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 219",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 102",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":107,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 220",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 102",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":108,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 44",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 14",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":28,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 103",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 44",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":55,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 221",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 103",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":109,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 222",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 103",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":110,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 104",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 44",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"2021-03-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":56,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 223",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 104",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":111,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 224",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 104",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":112,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                           }
                           ]
                        },
                        {
                           "id":"5f2ab3f17fbfdd0017582676",
                           "new_id":1813,
                           "name":"Dalton",
                           "first_name":"Jack",
                           "last_name":"WILSON",
                           "parent":1,
                           "parent_id":"5f2a930d6ec416001719a360",
                           "parent_username":"Trolek",
                           "country":"GB",
                           "gender":"M",
                           "date_of_birth":"2002-08-05T13:27:12.000Z",
                           "date_creation":"2020-10-25",
                           "date_member":"2020-12-25",
                           "date_repositioning":"",
                           "confirmed":true,
                           "role":1,
                           "status":"M",
                           "email":"Dalton@gmail.com",
                           "verticalLevel":user.verticalLevel + 3,
                           "horizontalLevel":8,
                           "children":[
                            {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 15",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":"Dalton",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-11-20",
                              "date_member":"2021-01-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 4,
                              "horizontalLevel":15,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 45",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 15",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2020-12-20",
                                  "date_member":"2021-02-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 5,
                                  "horizontalLevel":29,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 105",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 45",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-01-20",
                                      "date_member":"2021-03-25",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"M",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 6,
                                      "horizontalLevel":57,
                                      "children":[
                                        {
                                          "id":"",
                                          "new_id":0,
                                          "name":t('Sponsorship.User') + " 225",
                                          "first_name":"",
                                          "last_name":"",
                                          "parent":1,
                                          "parent_id":"",
                                          "parent_username":t('Sponsorship.User') + " 105",
                                          "country":"GB",
                                          "gender":"",
                                          "date_of_birth":"",
                                          "date_creation":"2021-02-20",
                                          "date_member":"",
                                          "date_repositioning":"",
                                          "confirmed":true,
                                          "role":1,
                                          "status":"C",
                                          "email":"",
                                          "verticalLevel":user.verticalLevel + 7,
                                          "horizontalLevel":113,
                                          "children":[]
                                       },
                                       {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 226",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 105",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-25",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":114,
                                        "children":[]
                                       }
                                      ]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 106",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 45",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-25",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":58,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 227",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 106",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":115,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 228",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 106",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":116,
                                      "children":[]
                                     }
                                    ]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 46",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 15",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-25",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":30,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 107",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 46",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":59,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 229",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 107",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":117,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 230",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 107",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":118,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 108",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 46",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":60,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 231",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 108",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":119,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 232",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 108",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":120,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                               }
                              ]
                           },
                           {
                            "id":"",
                            "new_id":0,
                            "name":t('Sponsorship.User') + " 16",
                            "first_name":"",
                            "last_name":"",
                            "parent":1,
                            "parent_id":"",
                            "parent_username":"Dalton",
                            "country":"GB",
                            "gender":"",
                            "date_of_birth":"",
                            "date_creation":"2020-11-25",
                            "date_member":"2021-01-25",
                            "date_repositioning":"",
                            "confirmed":true,
                            "role":1,
                            "status":"M",
                            "email":"",
                            "verticalLevel":user.verticalLevel + 4,
                            "horizontalLevel":16,
                            "children":[
                              {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 47",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 16",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2020-12-20",
                                "date_member":"2021-02-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 5,
                                "horizontalLevel":31,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 109",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 47",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-01-20",
                                    "date_member":"2021-03-25",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"M",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 6,
                                    "horizontalLevel":61,
                                    "children":[
                                      {
                                        "id":"",
                                        "new_id":0,
                                        "name":t('Sponsorship.User') + " 233",
                                        "first_name":"",
                                        "last_name":"",
                                        "parent":1,
                                        "parent_id":"",
                                        "parent_username":t('Sponsorship.User') + " 109",
                                        "country":"GB",
                                        "gender":"",
                                        "date_of_birth":"",
                                        "date_creation":"2021-02-20",
                                        "date_member":"",
                                        "date_repositioning":"",
                                        "confirmed":true,
                                        "role":1,
                                        "status":"C",
                                        "email":"",
                                        "verticalLevel":user.verticalLevel + 7,
                                        "horizontalLevel":121,
                                        "children":[]
                                     },
                                     {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 234",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 109",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-25",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":122,
                                      "children":[]
                                     }
                                    ]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 110",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 47",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-25",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":62,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 235",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 110",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":123,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 236",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 110",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":124,
                                    "children":[]
                                   }
                                  ]
                                 }
                                ]
                             },
                             {
                              "id":"",
                              "new_id":0,
                              "name":t('Sponsorship.User') + " 48",
                              "first_name":"",
                              "last_name":"",
                              "parent":1,
                              "parent_id":"",
                              "parent_username":t('Sponsorship.User') + " 16",
                              "country":"GB",
                              "gender":"",
                              "date_of_birth":"",
                              "date_creation":"2020-12-25",
                              "date_member":"2021-02-25",
                              "date_repositioning":"",
                              "confirmed":true,
                              "role":1,
                              "status":"M",
                              "email":"",
                              "verticalLevel":user.verticalLevel + 5,
                              "horizontalLevel":32,
                              "children":[
                                {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 111",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 48",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-01-20",
                                  "date_member":"2021-03-25",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"M",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 6,
                                  "horizontalLevel":63,
                                  "children":[
                                    {
                                      "id":"",
                                      "new_id":0,
                                      "name":t('Sponsorship.User') + " 237",
                                      "first_name":"",
                                      "last_name":"",
                                      "parent":1,
                                      "parent_id":"",
                                      "parent_username":t('Sponsorship.User') + " 111",
                                      "country":"GB",
                                      "gender":"",
                                      "date_of_birth":"",
                                      "date_creation":"2021-02-20",
                                      "date_member":"",
                                      "date_repositioning":"",
                                      "confirmed":true,
                                      "role":1,
                                      "status":"C",
                                      "email":"",
                                      "verticalLevel":user.verticalLevel + 7,
                                      "horizontalLevel":125,
                                      "children":[]
                                   },
                                   {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 238",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 111",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-25",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":126,
                                    "children":[]
                                   }
                                  ]
                               },
                               {
                                "id":"",
                                "new_id":0,
                                "name":t('Sponsorship.User') + " 112",
                                "first_name":"",
                                "last_name":"",
                                "parent":1,
                                "parent_id":"",
                                "parent_username":t('Sponsorship.User') + " 48",
                                "country":"GB",
                                "gender":"",
                                "date_of_birth":"",
                                "date_creation":"2021-01-25",
                                "date_member":"2021-03-25",
                                "date_repositioning":"",
                                "confirmed":true,
                                "role":1,
                                "status":"M",
                                "email":"",
                                "verticalLevel":user.verticalLevel + 6,
                                "horizontalLevel":64,
                                "children":[
                                  {
                                    "id":"",
                                    "new_id":0,
                                    "name":t('Sponsorship.User') + " 239",
                                    "first_name":"",
                                    "last_name":"",
                                    "parent":1,
                                    "parent_id":"",
                                    "parent_username":t('Sponsorship.User') + " 112",
                                    "country":"GB",
                                    "gender":"",
                                    "date_of_birth":"",
                                    "date_creation":"2021-02-20",
                                    "date_member":"",
                                    "date_repositioning":"",
                                    "confirmed":true,
                                    "role":1,
                                    "status":"C",
                                    "email":"",
                                    "verticalLevel":user.verticalLevel + 7,
                                    "horizontalLevel":127,
                                    "children":[]
                                 },
                                 {
                                  "id":"",
                                  "new_id":0,
                                  "name":t('Sponsorship.User') + " 240",
                                  "first_name":"",
                                  "last_name":"",
                                  "parent":1,
                                  "parent_id":"",
                                  "parent_username":t('Sponsorship.User') + " 112",
                                  "country":"GB",
                                  "gender":"",
                                  "date_of_birth":"",
                                  "date_creation":"2021-02-25",
                                  "date_member":"",
                                  "date_repositioning":"",
                                  "confirmed":true,
                                  "role":1,
                                  "status":"C",
                                  "email":"",
                                  "verticalLevel":user.verticalLevel + 7,
                                  "horizontalLevel":128,
                                  "children":[]
                                 }
                                ]
                               }
                              ]
                             }
                            ]
                           }
                           ]
                        }
                      ]
                   }
                   ]
                }
                 ]
              }
            ]
            }
else {
  admin_partners = [
    {
       "id":"5f2a89d86ec416001719a34e",
       "new_id":1797,
       "name":"Golden",
       "first_name":"Brian",
       "last_name":"ANDERSON",
       "parent":1,
       "parent_id":"5f291d612d3c250017fa4239",
       "parent_username":"Ratof",
       "country":"GB",
       "gender":"M",
       "date_of_birth":"1997-08-07T10:20:14.000Z",
       "date_creation":"2020-08-05",
       "date_member":"",
       "date_repositioning":"",
       "confirmed":true,
       "role":1,
       "status":"C",
       "email":"Golden@gmail.com",
       "verticalLevel":user.verticalLevel,
       "horizontalLevel":1,
       "children":[]
    }
  ]
}





const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">


    {modalFirstButton !== "" ? 
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalFirstButton}</button> :
null
  }


</ModalFooter>
</Modal>

      const trees =
      
      <Fragment>
       <SponsorshipActionsDemo />
      
       <select className="form-control" name="action" value={'{"index": "' + month + '"}'} onChange={e => dropDownSelected(e.target.value)}>
        <option value={'{"index": "0"}'}>{t('Sponsorship.Month')} 0</option>
        <option value={'{"index": "1"}'}>{t('Sponsorship.Month')} 1</option>
        <option value={'{"index": "2"}'}>{t('Sponsorship.Month')} 2</option>
        <option value={'{"index": "3"}'}>{t('Sponsorship.Month')} 3</option>
        <option value={'{"index": "4"}'}>{t('Sponsorship.Month')} 4</option>
        <option value={'{"index": "5"}'}>{t('Sponsorship.Month')} 5</option>
        <option value={'{"index": "6"}'}>{t('Sponsorship.Month')} 6</option>
        <option value={'{"index": "7"}'}>{t('Sponsorship.Month')} 7</option>
        <option value={'{"index": "8"}'}>{t('Sponsorship.Month')} 8</option>
      </select>
      <br />
<div>

<h1 className='large text-primary'>{ t('Sponsorship.Matrix') }</h1>

 <div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 
{
 admin_partners.length > 0 ?

<div>

<StyledTree data={admin_partners}
direction 
onClick={item => showDetails(item) }/>

</div>
 :null}
</div>
</div>
</div>
  </Fragment>;

      







function showDetails(newUser) {

  var dateCreation = JSON.parse(JSON.stringify(newUser.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(newUser.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }



  var dateMember = JSON.parse(JSON.stringify(newUser.date_member))

  var dateMemberArr = dateMember.split(',');

  var finalDateMember = ""
  for(var i=0; i<dateMemberArr.length; i++){
  if (dateMemberArr[i] !== "") {
    dateMember = dateMemberArr[i].substring(0, 10).split("-")

    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[2] + "/" + dateMember[1] + "/" + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('Sponsorship.Date_member') + " (" + t('Sponsorship.Member') + "*".repeat(i) + ") : " + dateMember[2] + '/' + dateMember[1] + '/' + dateMember[0]}`;
      }
    }
    else {
      if (i === 0) {
        finalDateMember = `${finalDateMember + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
      else {
        finalDateMember = `${finalDateMember + "\n" + t('Sponsorship.Date_member') + " (" + t('Sponsorship.Member') + "*".repeat(i) + ") : " + dateMember[1] + '/' + dateMember[2] + '/' + dateMember[0]}`;
      }
    } 
  }
}


  
  var dateRepositioning = JSON.parse(JSON.stringify(newUser.date_repositioning?newUser.date_repositioning:""))
  var finalDateRepositioning = ""
  if (dateRepositioning !== "") {
    dateRepositioning = JSON.parse(JSON.stringify(newUser.date_repositioning)).substring(0, 10).split("-")
    finalDateRepositioning = dateRepositioning[1] + '/' + dateRepositioning[2] + '/' + dateRepositioning[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateRepositioning = dateRepositioning[2] + '/' + dateRepositioning[1] + '/' + dateRepositioning[0];
    }
  }
  
    setModalTitle(JSON.parse(JSON.stringify(newUser.name)));
    setModalBody(
    t('Sponsorship.Status')+" : " + (JSON.parse(JSON.stringify(newUser.status)) === "C" ? t('Sponsorship.Candidate') :
    JSON.parse(JSON.stringify(newUser.status)) === "J" ? t('Sponsorship.Joker') :
    JSON.parse(JSON.stringify(newUser.status)) === "A" ? t('Sponsorship.Member') :
    JSON.parse(JSON.stringify(newUser.status)) === "B" ? t('Sponsorship.Candidate_member') :
    JSON.parse(JSON.stringify(newUser.status)).charAt(0) === "M" ? t('Sponsorship.Actif_member') + JSON.parse(JSON.stringify(newUser.status)).substring(1) : "") +
    "\n"+t('Sponsorship.Guarantor')+" : " + (JSON.parse(JSON.stringify(newUser.parent_username)) !== '' ?
    JSON.parse(JSON.stringify(newUser.parent_username)) : t('Sponsorship.None'))  +
    "\n"+t('Sponsorship.Country')+" : " + t(`Country.${JSON.parse(JSON.stringify(newUser.country))}`) +
    "\n"+t('Sponsorship.Level')+" : " + JSON.parse(JSON.stringify(newUser.verticalLevel - user.verticalLevel)) +
    (finalDateCreation !== "" ? ("\n"+t('Sponsorship.Subscription_date')+" : "  + finalDateCreation) : "") +
    (finalDateMember !== "" ? ("\n"+t('Sponsorship.Date_member')+" (" + t('Sponsorship.Member')+") : "  + finalDateMember) : "") +
    (finalDateRepositioning !== "" ? ("\n"+t('Sponsorship.Date_repositioning')+" : "  + finalDateRepositioning) : "")
    );
    setModalFirstButton("");
    setModalFirstButton(t('Sponsorship.Ok'));
   setModalIsOpen(true);
  }








return (
  
  <Fragment>

    {showModal}
  {trees}



</Fragment>
);
};


MatrixDemo.propTypes = {
  sponsorship: PropTypes.object.isRequired,
  updateMonth: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { updateMonth }
)(MatrixDemo);


