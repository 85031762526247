import {  axios } from '../utils/axios';
import {
  ADD_CHAT,
  CHAT_ERROR,
  GET_CHATS,
  FIND_CHAT,
  ADD_MESSAGE,
  MESSAGE_ERROR,
  GET_MESSAGES,
} from './types';



// Add chat
export const addChat = ({ senderId, receiverId }) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

      const body = JSON.stringify({senderId, receiverId});
  
    try {
      const res = await axios.post('/api/chats/add_chat', body, config);
  
      dispatch({
        type: ADD_CHAT,
        payload: res.data
      });
  
    } catch (err) {
      dispatch({
        type: CHAT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };
  
// Get chats
export const getChats = ({ id }) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({id});
  try {
    const res = await axios.post('/api/chats/get_chats', body, config);

    dispatch({
      type: GET_CHATS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CHAT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Find chat
export const findChat = ({ firstId, secondId }) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const body = JSON.stringify({firstId, secondId});
  try {
    const res = await axios.post('/api/chats/find_chat', body, config);

    dispatch({
      type: FIND_CHAT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CHAT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Add message
export const addMessage = ({ chatId, senderId, text }) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const body = JSON.stringify({chatId, senderId, text});

  try {
    const res = await axios.post('/api/messages/add_message', body, config);

    dispatch({
      type: ADD_MESSAGE,
      payload: res.data
    });

  } catch (err) {
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get messages
export const getMessages = (chatId) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({chatId});
  try {
    const res = await axios.post('/api/messages/get_messages', body, config);
    dispatch({
      type: GET_MESSAGES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};