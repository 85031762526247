import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AboutUs = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
         <section className="container">
      <h1 className='large text-primary'>{t('AboutUs.Title')}</h1>
      <details>
    <summary>{t('AboutUs.Subtitle1')}</summary>
    <p>
    {t('AboutUs.Paragraph1')}<br />
    {t('AboutUs.Paragraph2')}<br />
    {t('AboutUs.Paragraph3')}<br />
    {t('AboutUs.Paragraph4')}<br />
    {t('AboutUs.Paragraph5')}<br />
    {t('AboutUs.Paragraph6')}<br />
    {t('AboutUs.Paragraph7')}
    </p>
  </details>

        <details>
    <summary>{t('AboutUs.Subtitle2')}</summary>
    <p>
    {t('AboutUs.Paragraph8')}<br />{t('AboutUs.Paragraph9')}<br />
    {t('AboutUs.Paragraph10')}<br />{t('AboutUs.Paragraph11')}<br />
    {t('AboutUs.Paragraph12')}<br />{t('AboutUs.Paragraph13')}<br />
    {t('AboutUs.Paragraph14')}<br />{t('AboutUs.Paragraph15')}
    </p>
  </details>

  <details>
    <summary>{t('AboutUs.Subtitle3')}</summary>
    <p>
    {t('AboutUs.Paragraph16')}<br />{t('AboutUs.Paragraph17')}<br />
    {t('AboutUs.Paragraph18')}
    </p>
  </details>

  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

AboutUs.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(AboutUs);
