import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


const AllAds = ({ auth: { user, theme } }) => {
  const { t } = useTranslation();

  const adsData = [
    {
      id: 1,
      image: require('../../img/house.png'),
      name: t('ClassifiedAds.House'),
      sub_categories: [t('ClassifiedAds.Furnishings'), t('ClassifiedAds.Household_appliances'), t('ClassifiedAds.Decoration'), t('ClassifiedAds.DIY'), t('ClassifiedAds.Gardening')]
    },
    {
      id: 2,
      image: require('../../img/mode.png'),
      name: t('ClassifiedAds.Fashion'),
      sub_categories: [t('ClassifiedAds.Clothing'), t('ClassifiedAds.Shoes'), t('ClassifiedAds.Accessories_luggage'), 'Montres & bijoux', t('ClassifiedAds.Baby')]
    },
    {
      id: 3,
      image: require('../../img/multimedia.png'),
      name: t('ClassifiedAds.Multimedia'),
      sub_categories: [t('ClassifiedAds.Computers'), t('ClassifiedAds.Telephony'), t('ClassifiedAds.Image_sound'), t('ClassifiedAds.Console_video_games')]
    },
    {
      id: 4,
      image: require('../../img/sports_and_leisure.png'),
      name: t('ClassifiedAds.Sports_leisure'),
      sub_categories: [t('ClassifiedAds.Sports_equipment'), t('ClassifiedAds.Bikes'), t('ClassifiedAds.Musical_instruments'), t('ClassifiedAds.Games_toys'), t('ClassifiedAds.Collection')]
    },
    {
      id: 5,
      image: require('../../img/vehicle.png'),
      name: t('ClassifiedAds.Vehicle'),
      sub_categories: [t('ClassifiedAds.Cars'), t('ClassifiedAds.Motorcycles'), t('ClassifiedAds.Caravans'), t('ClassifiedAds.Boating'), t('ClassifiedAds.Equipment')]
    },
    {
      id: 6,
      image: require('../../img/various.png'),
      name: t('ClassifiedAds.Various'),
      sub_categories: [t('ClassifiedAds.Hotels_restaurants'), t('ClassifiedAds.Travel'), t('ClassifiedAds.Vacations'), t('ClassifiedAds.Services'), t('ClassifiedAds.Others')]
    }
  ]

  const [showMoreHouse, setShowMoreHouse] = useState(false);
  const [showMoreMode, setShowMoreMode] = useState(false);
  const [showMoreMultimedia, setShowMoreMultimedia] = useState(false);
  const [showMoreSports, setShowMoreSports] = useState(false);
  const [showMoreVehicle, setShowMoreVehicle] = useState(false);
  const [showMoreVarious, setShowMoreVarious] = useState(false);

  if (user && user.status === "C") {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Fragment>
    <section id="ad-categories" className="block ad-categories-block">
      <Container fluid>
        <div className="title-holder">
          <h2>{t('ClassifiedAds.Our_categories')}</h2>
          <div className="subtitle">{t('ClassifiedAds.And_subcategories')}</div>
        </div>
        <Row>
          {
            adsData.map(teams => {
              return (
                <Col sm={4} key={teams.id}>
                  <div className={`card " ${theme === "light" ? "bg-light border-primary" : "text-white bg-dark border-primary"}`}>
  <Image className="card-img-top" src={teams.image} alt="Card image cap" />
  <div className="card-body">
    <h5 className="card-title">{teams.name}</h5>
  </div>
  <ul className="list-group list-group-flush" >
  {teams.sub_categories.map(sub_categories => {
    return(
    <li className={`list-group-item " ${theme === "light" ? "bg-light" : "bg-primary"}`} style={{ display : (teams.id === 1 && showMoreHouse)
      || (teams.id === 2 && showMoreMode)
      || (teams.id === 3 && showMoreMultimedia)
      || (teams.id === 4 && showMoreSports)
      || (teams.id === 5 && showMoreVehicle)
      || (teams.id === 6 && showMoreVarious)
      ? "" : "none" }}>{sub_categories}</li>
    )
  })}
  </ul>
  <div className="card-body d-flex justify-content-center">
  <button className="btn btn-primary" onClick={() => {
    if (teams.id === 1)
    setShowMoreHouse(!showMoreHouse)
    else if (teams.id === 2)
    setShowMoreMode(!showMoreMode)
    else if (teams.id === 3)
    setShowMoreMultimedia(!showMoreMultimedia)
    else if (teams.id === 4)
    setShowMoreSports(!showMoreSports)
    else if (teams.id === 5)
    setShowMoreVehicle(!showMoreVehicle)
    else if (teams.id === 6)
    setShowMoreVarious(!showMoreVarious)
  }}>{
    (teams.id === 1 && showMoreHouse)
    || (teams.id === 2 && showMoreMode)
    || (teams.id === 3 && showMoreMultimedia)
    || (teams.id === 4 && showMoreSports)
    || (teams.id === 5 && showMoreVehicle)
    || (teams.id === 6 && showMoreVarious)
    ? t('ClassifiedAds.Show_less') : t('ClassifiedAds.Show_more')}</button>
  </div>
</div>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
    </Fragment>
  );
}


AllAds.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(AllAds);
