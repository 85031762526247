import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updatePassword } from '../../actions/auth';
import i18next from 'i18next';
import Alert from '../layout/Alert';
import { setAlert } from '../../actions/alert';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
  

const ResetPassword = ({ setAlert, updatePassword, isAuthenticated, match }) => {
  const [formData, setFormData] = useState({
    password: '',
    repeat_password: ''
  });


  
  const { password, repeat_password } = formData;



  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== repeat_password) {
        setAlert(t('server_11_user'), 'danger');
      } else {
        updatePassword(password, repeat_password, match.params.token, history);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
      }
  };

  const { t } = useTranslation();
  
  function handleClick(lang) {
    i18next.changeLanguage(lang)
  }


  let history = useHistory();

  useEffect(() => {
    handleClick('fr');
  }, []);





  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  



  return (
    <Fragment>
    <section className="container">
        <Alert />
      <h1 className="large text-primary-atrakxion">{t('ResetPassword.Forgot_password')}</h1>
      <p className="lead">
        <i className="fas fa-user" /> {t('ResetPassword.Subtitle')}
      </p>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <input
            type="password"
            placeholder={t('ResetPassword.Password')}
            name="password"
            value={password}
            onChange={e => onChange(e)}
            minLength="3"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder={t('ResetPassword.Repeat_password')}
            name="repeat_password"
            value={repeat_password}
            onChange={e => onChange(e)}
            minLength="3"
          />
        </div>
        <div className="form-group">
        </div>
        <input type="submit" className="btn btn-primary btn-block" value={t('ResetPassword.Reset')} />
      </form>

  

  </section>
    </Fragment>
  );
};
/*      <p className="my-1">
        Don't have an account? <Link to="/register">Sign Up</Link>
      </p>
      */
     ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, updatePassword })(ResetPassword);
