import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import SponsorshipActionsAdmin from './SponsorshipActionsAdmin';
import { getAllUsers, getSponsorships } from '../../../actions/sponsorship';
import { getAllTransactions } from '../../../actions/transaction';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Doughnut, Line} from 'react-chartjs-2';
import * as moment from 'moment-timezone';


const ChartAdmin = ({ getAllUsers, getSponsorships, getAllTransactions, auth: { user }, transaction: { all_transactions }, sponsorship: { admin_partners, partners, all_users, level_affiliation, genealogies, genealogies_this_month, loading } }) => {


    const [usersLabel, setUsersLabel] = useState();
    const [usersData, setUsersData] = useState();
    const [monthLabel, setMonthLabel] = useState();
    const [chartColor, setChartColor] = useState();

    const [creationLabel, setCreationLabel] = useState(1);
    const [memberLabel, setMemberLabel] = useState(2);
    const [repositioningLabel, setRepositioningLabel] = useState(3);

    const [usersCreation, setUsersCreation] = useState();
    const [usersMember, setUsersMember] = useState();
    const [usersRepositioning, setUsersRepositioning] = useState();


    const [grossLabel, setGrossLabel] = useState(1);
    const [expenseLabel, setExpenseLabel] = useState(2);
    const [taxLabel, setTaxLabel] = useState(3);
    const [netLabel, setNetLabel] = useState(4);

    const [usersGross, setUsersGross] = useState();
    const [usersExpense, setUsersExpense] = useState();
    const [usersTax, setUsersTax] = useState();
    const [usersNet, setUsersNet] = useState();


   /* var grossIncomes = 0;
    var expenses = 0;
    var taxes = 0;
    var netIncomes = 0; */


  /*  useEffect(() => {
      document.body.style = 'background: #EDAE6A'; //#DCDCDC
  
      // returned function will be called on component unmount 
      return () => {
        document.body.style = 'background: #D3D3D3'; //#DCDCDC
      }
    }, []); */

  useEffect(() => {
    getAllUsers(user._id);
  }, [getAllUsers, user._id]);

  useEffect(() => {
    getAllTransactions(user._id);
  }, [getAllTransactions, user._id]);

  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);

  useEffect(() => {
    newRefresh();
  });


  const { t } = useTranslation();



  const [oldLanguage, setOldLanguage] = useState(t('Language.Lang'));
  
  if ( user.role !== 0 ) {
    return <Redirect to="/dashboard" />;
  }

  if (oldLanguage !== t('Language.Lang')) {
    newRefresh(true)
    setOldLanguage(t('Language.Lang'))
}


/*if (all_transactions && all_transactions.length > 0) {

  for (var counter = 0; counter < all_transactions.length; counter++) {
    if (all_transactions[counter].date_creation.substring(0, 10).split("-")[0] === moment().tz("Europe/Paris").startOf('day').format("YYYY-MM-DD").substring(0, 10).split("-")[0]) {
    if (all_transactions[counter].amount > 0) {
      expenses += all_transactions[counter].amount
    }
    else {
      grossIncomes -= all_transactions[counter].amount
    }   
  }
  }

  taxes = grossIncomes * 0.2;
  netIncomes = grossIncomes - (expenses + taxes)

} */

  function newRefresh(refresh = false) {


  if (all_users && all_users.length > 0 && all_transactions/* && all_transactions.length > 0*/ && (usersLabel === undefined || refresh )) {
    let newDate = moment().tz("Europe/London").format("YYYY-MM-DD")
    let months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    setMonthLabel([t('AdminSponsorship.January'), t('AdminSponsorship.February'), t('AdminSponsorship.March'),
    t('AdminSponsorship.April'), t('AdminSponsorship.May'), t('AdminSponsorship.June'),
    t('AdminSponsorship.July'), t('AdminSponsorship.August'), t('AdminSponsorship.September'),
    t('AdminSponsorship.October'), t('AdminSponsorship.November'), t('AdminSponsorship.December')])

    setCreationLabel(t('AdminSponsorship.Subscriptions'))
    setMemberLabel(t('AdminSponsorship.Qualifications'))
    setRepositioningLabel(t('AdminSponsorship.Repositionings'))

    setGrossLabel(t('AdminSponsorship.Gross_incomes'))
    setExpenseLabel(t('AdminSponsorship.Expenses'))
    setTaxLabel(t('AdminSponsorship.Taxes'))
    setNetLabel(t('AdminSponsorship.Net_incomes'))


    const currentUsers = all_users.reduce((acc, currvalue) => {
      var status = currvalue.status;
      if (currvalue.status.length > 1 && currvalue.date_repositioning === "") {
        currvalue.status = currvalue.status.charAt(0)
      }
      else if (currvalue.status.length > 1 && currvalue.date_repositioning !== "") {
        status = currvalue.status.substring(0, 2)
      }

        const  users = [];
      all_users.map(u => u.status.substring(0, 2) !== status ?
      null :
      users.push(u)
      );
      if (currvalue.status.length > 1 && currvalue.date_repositioning !== "") {
        return {
          ...acc,
          [status]: users
        };
      }
        return {
          ...acc,
          [status.charAt(0)]: users
        };
      },{}); 

      
      const currentUsersCreation = months.reduce((acc, currvalue) => {
        const datesCreation = [];
        const creation = currvalue;
        
        if (datesCreation.length === 0) {

          
        all_users.map(u => u.date_creation.substring(0, 10).split("-")[1] !== creation ?
        u : u.date_creation.substring(0, 10).split("-")[0] === newDate.substring(0, 10).split("-")[0] && datesCreation.push(u)
      /*  u :  datesCreation.push(
            u.date_creation.substring(0, 10).split("-")[0] === newDate.substring(0, 10).split("-")[0] ? u : null ) */
      ); 
    }
    if (datesCreation.length > 0) {
      return {
        ...acc,
        [creation]: datesCreation
      };
    }
      else {
        return {
          ...acc
        };
      }
      },{}); 

      
      const currentUsersMember = months.reduce((acc, currvalue) => {
        const  datesMember = [];
        const member = currvalue;
    
        if (datesMember.length === 0) {
        all_users.map(u => u.date_member.substring(0, 10).split("-")[1] !== member && u.date_repositioning === "" ?
        u : u.date_member.substring(0, 10).split("-")[0] === newDate.substring(0, 10).split("-")[0] && datesMember.push(u)
       /* u : datesMember.push(
        u.date_member.substring(0, 10).split("-")[0] === newDate.substring(0, 10).split("-")[0] ? u : null)*/
      );
        }

        if (datesMember.length > 0) {
          return {
            ...acc,
            [member]: datesMember
          };
        }
          else {
            return {
              ...acc
            };
          }
      },{}); 
    /*  Object.entries(currentUsersMember).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
      console.log(currentUsersMember) */
      
      const currentUsersRepositioning = months.reduce((acc, currvalue) => {
        const  datesRepositioning = [];
        const repositioning = currvalue;
    
        
        if (datesRepositioning.length === 0) {
        all_users.map(u => u.date_repositioning.substring(0, 10).split("-")[1] !== repositioning ?
        u : u.date_repositioning.substring(0, 10).split("-")[0] === newDate.substring(0, 10).split("-")[0] && datesRepositioning.push(u)
       /* u : datesRepositioning.push(
        u.date_repositioning.substring(0, 10).split("-")[0] === newDate.substring(0, 10).split("-")[0] ? u : null) */
      );
        }
        if (datesRepositioning.length > 0) {
          return {
            ...acc,
            [repositioning]: datesRepositioning
          };
        }
          else {
            return {
              ...acc
            };
          }
      },{}); 











      const currentUsersGross = months.reduce((acc, currvalue) => {
        var datesGross = 0;
        const gross = currvalue;

        
        if (gross > 0 && datesGross === 0) {
          all_transactions.map(u => u.date_creation.substring(0, 10).split("-")[1] !== gross ?
        u :  
            u.date_creation.substring(0, 10).split("-")[0] === newDate.substring(0, 10).split("-")[0] && (u.transaction_type === 0 || u.transaction_type === 2 || u.transaction_type === 3) ? datesGross /* // TO UNCOMMENT -= */ += u.amount : 0 ); 
    }
    if (datesGross > 0) {
        return {
          ...acc,
          [gross]: datesGross
        };
      }
        else {
          return {
            ...acc
          };
        }
      },{}); 


      const currentUsersExpense = months.reduce((acc, currvalue) => {
        var  datesExpense = 0;
        const expense = currvalue;

        
        if (datesExpense === 0) {
          all_transactions.map(u => u.date_creation.substring(0, 10).split("-")[1] !== expense ?
        u :  
            u.date_creation.substring(0, 10).split("-")[0] === newDate.substring(0, 10).split("-")[0] && (u.transaction_type === 1 || u.transaction_type === 8 || u.transaction_type === 10) ? datesExpense = datesExpense + (u.amount > 0 ? u.amount : -u.amount) : 0 ); 
    }
        if (datesExpense > 0) {
          return {
            ...acc,
            [expense]: datesExpense
          };
        }
          else {
            return {
              ...acc
            };
          }
      },{}); 










      const labels = []
      const colors = []
    const datasets = []
    const labelsCreation = []
    const datasetsCreation = []
    const labelsMember = []
    const datasetsMember = []
    const labelsRepositioning = []
    const datasetsRepositioning = []


    const labelsGross = []
    const datasetsGross = []
    const labelsExpense = []
    const datasetsExpense = []
    const labelsTax = []
    const datasetsTax = []
    const labelsNet = []
    const datasetsNet = []

    var newStatus = ""
    var newColor = ""

    Object.keys(currentUsers).map((key) => {
          if (key === "C") {
             newStatus = t('AdminSponsorship.Candidate')
             newColor ='#0E2EDF'
          }
          else if (key === "A") {
             newStatus = t('AdminSponsorship.Member')
             newColor ='#BF0EDF'
          }
          else if (key === "B") {
             newStatus = t('AdminSponsorship.Candidate_member')
             newColor ='#97DF0E'
          }
          else if (key === "D") {
             newStatus = t('AdminSponsorship.Deleted')
             newColor ='#FF6384'
          }
          else if (key === "J") {
             newStatus = t('AdminSponsorship.Joker')
             newColor ='#FFCE56'
          }
          else if (key === "M") {
             newStatus = t('AdminSponsorship.Actif_member')
             newColor ='#36A2EB'
          }
          else if (key.charAt(0) === "M" && key.length > 1) {
             newStatus = t('AdminSponsorship.Star_member')
             newColor ='#a036eb'
          }
          labels.push(t(newStatus))
          colors.push(newColor)
          datasets.push(currentUsers[key].length)
        return { labels: t(newStatus) , datasets: currentUsers[key].length }
      });
      setUsersLabel(labels)
      setUsersData(datasets)
      setChartColor(colors)

      if (currentUsersCreation["undefined"]) {
        delete currentUsersCreation["undefined"]
      }
      Object.keys(currentUsersCreation).map((key) => {

            labelsCreation.push(key)

          if (datasetsCreation.length < key) {
          for (var i = datasetsCreation.length; i < key - 1; i++) {
            datasetsCreation.push(0)
          }
          }
            datasetsCreation.push(currentUsersCreation[key].length)
            
        return { labelsCreation: key , datasetsCreation: currentUsersCreation[key].length }
      });
      if (datasetsCreation.length < 12) {
        for (var i = datasetsCreation.length; i < 12; i++) {
            datasetsCreation.push(0)
          }
      }
      setUsersCreation(datasetsCreation)

      if (currentUsersMember["undefined"]) {
        delete currentUsersMember["undefined"]
      }
      Object.keys(currentUsersMember).map((key) => {

        labelsMember.push(key)

        if (datasetsMember.length < key) {
        for (var j = datasetsMember.length; j < key - 1; j++) {
            datasetsMember.push(0)
        }
        }
            datasetsMember.push(currentUsersMember[key].length)
        
      return { labelsMember: key , datasetsMember: currentUsersMember[key].length }
    });
    if (datasetsMember.length < 12) {
        for (var j = datasetsMember.length; j < 12; j++) {
            datasetsMember.push(0)
          }
      } 
    setUsersMember(datasetsMember)


    if (currentUsersRepositioning["undefined"]) {
        delete currentUsersRepositioning["undefined"]
      }
    Object.keys(currentUsersRepositioning).map((key) => {
        labelsRepositioning.push(key)

        if (datasetsRepositioning.length < key) {
        for (var k = datasetsRepositioning.length; k < key - 1; k++) {
            datasetsRepositioning.push(0)
        }
        }
        datasetsRepositioning.push(currentUsersRepositioning[key].length)
        
      return { labelsRepositioning: key , datasetsRepositioning: currentUsersRepositioning[key].length }
    });
    if (datasetsRepositioning.length < 12) {
        for (var k = datasetsRepositioning.length; k < 12; k++) {
            datasetsRepositioning.push(0)
          }
      } 
    setUsersRepositioning(datasetsRepositioning)







    if (currentUsersGross["undefined"]) {
      delete currentUsersGross["undefined"]
    }
  Object.keys(currentUsersGross).map((key) => {
      labelsGross.push(key)
      labelsTax.push(key)
      if (!currentUsersExpense[key] && currentUsersGross[key]) {
          labelsNet.push(key)
      }

      if (datasetsGross.length < key) {
      for (var l = datasetsGross.length; l < key - 1; l++) {
        datasetsGross.push(0)
        datasetsTax.push(0)
      if (!currentUsersExpense[key] && currentUsersGross[key]) {
        datasetsNet.push(0)
    }
      }
      }
      datasetsGross.push(currentUsersGross[key].toFixed(2))
      datasetsTax.push((currentUsersGross[key] - (currentUsersGross[key] / 1.2)).toFixed(2))

      if (!currentUsersExpense[key] && currentUsersGross[key]) {
        datasetsNet.push((currentUsersGross[key] - ((currentUsersGross[key] - (currentUsersGross[key] / 1.2)) + (currentUsersExpense[key] ? currentUsersExpense[key] : 0))).toFixed(2))
    }
      
    return { labelsGross: key , datasetsGross: currentUsersGross[key] }
  });
  if (datasetsGross.length < 12) {
      for (var l = datasetsGross.length; l < 12; l++) {
          datasetsGross.push(0)
          datasetsTax.push(0)
        }
    } 
  setUsersGross(datasetsGross)
  setUsersTax(datasetsTax)






  if (currentUsersExpense["undefined"]) {
    delete currentUsersExpense["undefined"]
  }
Object.keys(currentUsersExpense).map((key) => {
    labelsExpense.push(key)
    if (currentUsersExpense[key] && !currentUsersGross[key]) {
    labelsNet.push(key)
    }

    if (datasetsExpense.length < key) {
    for (var m = datasetsExpense.length; m < key - 1; m++) {
      datasetsExpense.push(0)
      // TO UNCOMMENT
    //  if (currentUsersExpense[key] && !currentUsersGross[key]) {
      datasetsNet.push(0)
   //   }
    }
    }
    datasetsExpense.push(currentUsersExpense[key].toFixed(2))
 // TO UNCOMMENT
   // if (currentUsersExpense[key] && !currentUsersGross[key]) {
    datasetsNet.push((currentUsersGross[key] - ((currentUsersGross[key] - (currentUsersGross[key] / 1.2)) + (currentUsersExpense[key] ? currentUsersExpense[key] : 0))).toFixed(2))
  //  }
    
  return { labelsExpense: key , datasetsExpense: currentUsersExpense[key] }
});
if (datasetsExpense.length < 12) {
    for (var m = datasetsExpense.length; m < 12; m++) {
      datasetsExpense.push(0)
      }
  } 
  if (datasetsNet.length < 12) {
    for (var n = datasetsNet.length; n < 12; n++) {
      datasetsNet.push(0)
      }
  } 
setUsersExpense(datasetsExpense)
setUsersNet(datasetsNet)


  }
}


  const data = {
	labels: usersLabel,
	datasets: [{
		data: usersData,
		backgroundColor: chartColor,
		hoverBackgroundColor: chartColor
	}]
};




const newData = {
    labels: monthLabel,
    datasets: [
      {
        label: creationLabel,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(14,46,223,0.4)',
        borderColor: 'rgba(14,46,223,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(14,46,223,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(14,46,223,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: usersCreation
      },
      {
        label: memberLabel,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(54,162,235,0.4)',
        borderColor: 'rgba(54,162,235,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(54,162,235,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(54,162,235,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: usersMember
      },
      {
        label: repositioningLabel,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(160,54,235,0.4)',
        borderColor: 'rgba(160,54,235,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(160,54,235,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(160,54,235,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: usersRepositioning
      }
    ]
  };




  const newDataIncome = {
    labels: monthLabel,
    datasets: [
      {
        label: grossLabel,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(0,63,92,0.4)',
        borderColor: 'rgba(0,63,92,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(0,63,92,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(0,63,92,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: usersGross
      },
      {
        label: expenseLabel,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(255,99,97,0.4)',
        borderColor: 'rgba(255,99,97,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(255,99,97,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(255,99,97,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: usersExpense
      },
      {
        label: taxLabel,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(121,106,237,0.4)',
        borderColor: 'rgba(121,106,237,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(121,106,237,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(121,106,237,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: usersTax
      },
      {
        label: netLabel,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(188,80,144,0.4)',
        borderColor: 'rgba(188,80,144,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(188,80,144,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(188,80,144,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: usersNet
      }
    ]
  };
  
  
   

      
      const showChart =
      
      
      <Fragment>
      
      <SponsorshipActionsAdmin />




<h1 className='large text-primary'>{t('AdminSponsorship.Status_statistics')}</h1>


  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 

<Doughnut data={data}
width={window.innerWidth}
height={window.innerHeight} />

<br></br><br></br>
<h1 className='large text-primary'>{t('AdminSponsorship.Evolution_statistics') + " " + moment().tz("Europe/London").format("YYYY-MM-DD").substring(0, 10).split("-")[0]}</h1>

<Line data={newData}  />

<br></br><br></br>
<h1 className='large text-primary'>{t('AdminSponsorship.Accounting_statistics') + " " + moment().tz("Europe/London").format("YYYY-MM-DD").substring(0, 10).split("-")[0]}</h1>

<Line data={newDataIncome}  />

</div>
</div> </Fragment>;




return loading && all_users === null ? (
  <Spinner />
) : (
  
  <Fragment>

  {showChart}


</Fragment>
);
};


ChartAdmin.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
  getAllTransactions: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  getSponsorships: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  transaction: state.transaction,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getAllUsers, getAllTransactions, getSponsorships }
)(ChartAdmin);


