import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
    <section className="container">
    <h1 className='large text-primary'>{t('TermsConditions.Title')}</h1>
  <details>
    <summary>I. {t('TermsConditions.Subtitle1')}</summary>
    <p>
    <div style={{marginLeft : "2em"}}>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph1')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph2')} < br/>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph3')} < br/>
    <strong>4. </strong>
      {t('TermsConditions.Paragraph4')}
      </div>
    </p>
  </details>

  <details>
    <summary>II. {t('TermsConditions.Subtitle2')}</summary>
    <p>
    {t('TermsConditions.Paragraph5')}<br />
    {t('TermsConditions.Paragraph6')}<br />
    {t('TermsConditions.Paragraph7')}
    </p>
  </details>

  <details>
    <summary>III. {t('TermsConditions.Subtitle3')}</summary>
    <p>
    {t('TermsConditions.Paragraph8')}<br />
    <div style={{marginLeft : "2em"}}>
    <strong>1. {t('TermsConditions.Paragraph9')}</strong>< br/>
      {t('TermsConditions.Paragraph10')} < br/>
      <strong>2. {t('TermsConditions.Paragraph11')}</strong>< br/>
      {t('TermsConditions.Paragraph12')} < br/>
    <strong>3. {t('TermsConditions.Paragraph13')}</strong>< br/>
      {t('TermsConditions.Paragraph14')} < br/>
    <strong>4. {t('TermsConditions.Paragraph15')}</strong>< br/>
      {t('TermsConditions.Paragraph16')} < br/>
    <strong>5. {t('TermsConditions.Paragraph17')}</strong>< br/>
      {t('TermsConditions.Paragraph18')}
      </div>
    </p>
  </details>

  <details>
    <summary>IV. {t('TermsConditions.Subtitle4')}</summary>
    <p>
    <div style={{marginLeft : "2em"}}>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph19')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph20')} < br/>
      {t('TermsConditions.Paragraph21')} < br/>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph22')} < br/>
    <strong>4. </strong>
      {t('TermsConditions.Paragraph23')} < br/>
    <strong>5. </strong>
      {t('TermsConditions.Paragraph24')} < br/>
      {t('TermsConditions.Paragraph25')} < br/>
    <strong>6. </strong>
      {t('TermsConditions.Paragraph26')} < br/>
    <strong>7. </strong>
      {t('TermsConditions.Paragraph27')} < br/>
      {t('TermsConditions.Paragraph28')} < br/>
    <strong>8. </strong>
      {t('TermsConditions.Paragraph29')} < br/>
      {t('TermsConditions.Paragraph30')} < br/>
    <strong>9. </strong>
      {t('TermsConditions.Paragraph31')} < br/>
      {t('TermsConditions.Paragraph32')} < br/>
    <strong>10. </strong>
      {t('TermsConditions.Paragraph33')} < br/>
      {t('TermsConditions.Paragraph34')} < br/>
    <strong>11. </strong>
      {t('TermsConditions.Paragraph35')} < br/>
      {t('TermsConditions.Paragraph36')} < br/>
      {t('TermsConditions.Paragraph37')} < br/>
    <strong>12. </strong>
      {t('TermsConditions.Paragraph38')}
      </div>
    </p>
  </details>

  <details>
    <summary>V. {t('TermsConditions.Subtitle5')}</summary>
    <p>
    <div style={{marginLeft : "2em"}}>
    <strong>1. {t('TermsConditions.Paragraph39')}</strong>< br/>
      {t('TermsConditions.Paragraph40')} < br/>
      <strong>2. {t('TermsConditions.Paragraph41')}</strong>< br/>
      {t('TermsConditions.Paragraph42')}
      </div>
    </p>
  </details>

  <details>
    <summary>VI. {t('TermsConditions.Subtitle6')}</summary>
    <p>
    1. {t('TermsConditions.Paragraph43')}<br />
    2. {t('TermsConditions.Paragraph44')}<br />
    {t('TermsConditions.Paragraph45')}
    </p>
  </details>

  <details>
    <summary>VII. {t('TermsConditions.Subtitle7')}</summary>
    <p>
    1. {t('TermsConditions.Paragraph46')}<br />
    2. {t('TermsConditions.Paragraph47')}<br />
    3. {t('TermsConditions.Paragraph48')}<br />
    4. {t('TermsConditions.Paragraph49')}<br />
    5. {t('TermsConditions.Paragraph50')}<br />
    6. {t('TermsConditions.Paragraph51')}<br />
    7. {t('TermsConditions.Paragraph52')}
    </p>
  </details>

  <details>
    <summary>VIII. {t('TermsConditions.Subtitle8')}</summary>
    <p>
    1. {t('TermsConditions.Paragraph53')}<br />
    2. {t('TermsConditions.Paragraph54')}
    </p>
  </details>

  <details>
    <summary>IX. {t('TermsConditions.Subtitle9')}</summary>
    <p>
    1. {t('TermsConditions.Paragraph55')}<br />
    2. {t('TermsConditions.Paragraph56')}<br />
    3. {t('TermsConditions.Paragraph57')}<br />
    4. {t('TermsConditions.Paragraph58')}
    </p>
  </details>

  <details>
    <summary>X. {t('TermsConditions.Subtitle10')}</summary>
    <p>
    1. {t('TermsConditions.Paragraph59')}<br />
    2. {t('TermsConditions.Paragraph60')}<br />
    3. {t('TermsConditions.Paragraph61')}<br />
    {t('TermsConditions.Paragraph62')}<br />
    4. {t('TermsConditions.Paragraph63')}<br />
    5. {t('TermsConditions.Paragraph64')}<br />
    <div style={{marginLeft : "2em"}}>
    <strong>a. </strong>
      {t('TermsConditions.Paragraph65')} < br/>
    <strong>b. </strong>
      {t('TermsConditions.Paragraph66')} < br/>
    <strong>c. </strong>
      {t('TermsConditions.Paragraph67')}
      </div>
    </p>
  </details>

  <details>
    <summary>XI. {t('TermsConditions.Subtitle11')}</summary>
    <p>
    1. {t('TermsConditions.Paragraph68')}<br />
    2. {t('TermsConditions.Paragraph69')}
    </p>
  </details>

  <details>
    <summary>XII. {t('TermsConditions.Subtitle12')}</summary>
    <p>
    1. {t('TermsConditions.Paragraph70')}<br />
    {t('TermsConditions.Paragraph71')}<br />
    {t('TermsConditions.Paragraph72')}<br />
    <div style={{marginLeft : "2em"}}>
    <strong>a. </strong>
      {t('TermsConditions.Paragraph73')} < br/>
    <strong>b. </strong>
      {t('TermsConditions.Paragraph74')} < br/>
    <strong>c. </strong>
      {t('TermsConditions.Paragraph75')} < br/>
    <strong>d. </strong>
      {t('TermsConditions.Paragraph76')}
      </div>
    2. {t('TermsConditions.Paragraph77')}<br />
    3. {t('TermsConditions.Paragraph78')}<br />
    4. {t('TermsConditions.Paragraph79')}
    </p>
  </details>

  <details>
    <summary>XIII. {t('TermsConditions.Subtitle13')}</summary>
    <p>
    {t('TermsConditions.Paragraph80')}
    </p>
  </details>

  <details>
    <summary>XIV. {t('TermsConditions.Subtitle14')}</summary>
    <p>
    1. {t('TermsConditions.Paragraph81')}<br />
    2. {t('TermsConditions.Paragraph82')}<br />
    3. {t('TermsConditions.Paragraph83')}
    </p>
  </details>

  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

PrivacyPolicy.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(PrivacyPolicy);
