import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GroupedPurchasesActions from './GroupedPurchasesActions';


const GroupedPurchases = ({ auth: { user, theme } }) => {

    const { t } = useTranslation();
    if (user && user.status === "C") {
        return <Redirect to="/dashboard" />;
      }

  return (
      <Fragment>
      <GroupedPurchasesActions />
      <section className="container">
        <h1 className='x-large text-primary'>
          <i className='fas fa-exclamation-triangle' /> {t('GroupedPurchases.Grouped_purchases')}
        </h1>
        <p className='large'>{t('NavBar.Wip')}</p>
        <div class="progress">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: "75%"}}></div>
  </div>
        </section>
      </Fragment>
    ) ;
}


GroupedPurchases.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(GroupedPurchases);
