import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import userImage from '../../img/user.png';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Conversation = ({ auth: { user, theme }, data, currentUser, online, sponsorship: { sponsorships }, chat: { chats } }) => {

    const { t } = useTranslation();


  const [userData, setUserData] = useState(null);


    useEffect(() => {
    const userId = data?.members?.find((id)=>id!==currentUser)
    const childWithId = sponsorships.find((child) => child.id === userId);
   if(childWithId) {
    setUserData(childWithId);
   }
   else {
    setUserData({"id": user.parent_id, "name": user.parent_username})
   }
      }, [sponsorships, currentUser, user, data]);

  return (
    <Fragment>
   <div className="follower conversation">
        <div>
          {online && <div className="online-dot"></div>}
          <img
            src={userImage}
            alt="User_image"
            className="followerImage"
            style={{ width: "50px", height: "50px" }}
          />
          <div className="name" style={{fontSize: '0.8rem'}}>
            <span>{userData?.name}</span> <br/>
            <span style={{color: online?"#28B463":""}}>{online? t('Messaging.Online') : t('Messaging.Offline')}</span>
          </div>
        </div>
      </div>
      <hr style={{ width: "85%", border: "0.1px solid #ececec" }} />
    </Fragment>
    ) ;
}


Conversation.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  sponsorship: state.sponsorship,
  chat: state.chat
});

export default connect(
  mapStateToProps,
  {  }
)(Conversation);
