import {  axios } from '../utils/axios';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  CHANGE_THEME,
  PLAY_MUSIC,
  SHOW_LOADING_API,
  HIDE_LOADING_API,
  ACCEPT_MEMBERSHIP
} from './types';

// Load User
export const loadUser = () => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });
  try {
    const res = await axios.get('/api/auth');

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });

    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  }
};

// Register User
export const register = ({ username, first_name, last_name, country, gender, date_of_birth, email, password, phone_number, address, additional_address, city, postal_code, attachmentType, imgToSendFront, imgToSendBack }) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
 // const body = JSON.stringify({ username, first_name, last_name, country, gender, date_of_birth, email, password });

 const body = new FormData();
 body.append("username", username);
 body.append("first_name", first_name);
 body.append("last_name", last_name);
 body.append("country", country);
 body.append("gender", gender);
 body.append("date_of_birth", date_of_birth);
 body.append("email", email);
 body.append("password", password);
 body.append("phone_number", phone_number);
 body.append("address", address);
 body.append("additional_address", additional_address);
 body.append("city", city);
 body.append("postal_code", postal_code);
 body.append("attachment_type", attachmentType);
 body.append("imgFront", imgToSendFront);
 body.append("imgBack", imgToSendBack);
 
  try {

    const res = await axios.post('/api/users', body, config);


    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
   /* else {
      dispatch(setAlert('server_39_user', 'danger'));
    }*/

    dispatch({
      type: REGISTER_FAIL
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  }
};

// Login User
export const login = (email, password, fromRepositioning = false) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, password, fromRepositioning });

  try {
    const res = await axios.post('/api/auth', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch(loadUser());
    
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });

    dispatch({
      type: HIDE_LOADING_API
    });
  }
};


// Forogt Password
export const forgotPassword = (email) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email });

  try {
    await axios.post('/api/users/forgot_password', body, config);

    dispatch({
      type: HIDE_LOADING_API
    });
    
    dispatch(setAlert('server_8_auth', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });

    dispatch({
      type: HIDE_LOADING_API
    });
  }
};



// Update Password
export const updatePassword = (password, repeat_password, token, history) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ password, repeat_password, token });

  try {
     await axios.post('/api/users/update_password', body, config);

     history.push('/login');
    dispatch({
      type: HIDE_LOADING_API
    });
    
    dispatch(setAlert('server_9_auth', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    history.push('/login');
    dispatch({
      type: LOGIN_FAIL
    });

    dispatch({
      type: HIDE_LOADING_API
    });
  }
};



// Change Theme
export const changeTheme = (theme) => async dispatch => {
  try {
    dispatch({ type: CHANGE_THEME, payload: theme});
  }
  catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

// Player Music
export const playMusic = (mute) => async dispatch => {
  try {
    dispatch({ type: PLAY_MUSIC, payload: mute});
  }
  catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

// Logout / Clear Profile
export const logout = () => async dispatch => {
  try {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({ type: ACCEPT_MEMBERSHIP,
      payload: null });
    await dispatch({ type: LOGOUT });
 //   window.location.reload(false);
  }
  catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};
