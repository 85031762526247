import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';
import { connect } from 'react-redux';
import { deleteExperience } from '../../actions/profile';
import { useTranslation } from 'react-i18next';

const Experience = ({ experience, deleteExperience }) => {
  const { t } = useTranslation();
  const experiences = experience.map(exp => (
    <tr key={exp._id}>
      <td>{exp.company}</td>
      <td className="hide-sm">{exp.title}</td>
      <td>
        <Moment format="YYYY/MM/DD">{moment.utc(exp.from)}</Moment> -{' '}
        {exp.to === null ? (
          " " +t('Dashboard.Now')
        ) : (
          <Moment format="YYYY/MM/DD">{moment.utc(exp.to)}</Moment>
        )}
      </td>
      <td>
        <button
          onClick={() => deleteExperience(exp._id)}
          className="btn btn-danger"
        >
          {t('Dashboard.Delete')}
        </button>
      </td>
    </tr>
  ));

  return (
    <Fragment>
      <h2 className="my-2">{t('Dashboard.Experience_credentials')}</h2>
      <table className="table">
        <thead>
          <tr>
            <th>{t('Dashboard.Company')}</th>
            <th className="hide-sm">{t('Dashboard.Title')}</th>
            <th className="hide-sm">{t('Dashboard.Years')}</th>
            <th />
          </tr>
        </thead>
        <tbody>{experiences}</tbody>
      </table>
    </Fragment>
  );
};

Experience.propTypes = {
  experience: PropTypes.array.isRequired,
  deleteExperience: PropTypes.func.isRequired
};

export default connect(
  null,
  { deleteExperience }
)(Experience);
