import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { getArticleById } from '../../actions/article';

import Spinner from '../layout/Spinner';
const ArticleDetail = ({
  auth: { user },
  article: { get_article },
  getArticleById,
  match
}) => {

  const [finalDateCreation, setFinalDateCreation] = useState("");
      
  useEffect(() => {
    getArticleById(match.params.id);

    
  }, [getArticleById, match.params.id]);



  const { t } = useTranslation();

  let history = useHistory();


  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
    else {
      history.push({
        pathname: '/dashboard'
      })
    }
  }, [t, history]);



  useEffect(() => {  
    if (get_article) {
    var dateCreation = get_article.date_creation
    if (dateCreation !== "") {
      dateCreation = get_article.date_creation.substring(0, 10).split("-")
      setFinalDateCreation(dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0]);
    
      if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
        setFinalDateCreation(dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0]);
      }
    }
  }
  }, [t, get_article]);



  return (!get_article ? (
    <Spinner />
  ) : (
    <Fragment>
          <Link to='/dashboard' className="btn btn-dark text-button-color mb-2">
        <i className='fas fa-graduation-cap text-primary' /> {t('ArticleDetail.Back')}
      </Link>
      <p className='lead'>
        <i className='fas fa-user' />{finalDateCreation}
  </p>
      <h3 className='large text-primary'>{get_article ? get_article.title : ""}</h3>

        <Fragment>
                  <div className="card-text ql-editor" dangerouslySetInnerHTML={{ __html: get_article ? get_article.content : "" }} />
        </Fragment>
    

    </Fragment>
  )
  );
};

ArticleDetail.propTypes = {
  auth: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  article: state.article
});

export default connect(
  mapStateToProps,
  { getArticleById }
)(ArticleDetail);
