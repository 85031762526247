import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
//import Moment from 'react-moment';
import { connect } from 'react-redux';
import { addLike, removeLike, deletePost } from '../../actions/post';
import { useTranslation } from 'react-i18next';

const PostItem = ({
  addLike,
  removeLike,
  deletePost,
  auth,
  post: { _id, text, username, avatar, user, likes, comments, date },
  showActions
}) =>  {
  const { t } = useTranslation();
  return (
  <div className='post bg-secondary p-1 my-1'>
    <div>
     {/*  <Link to={`/profile/${user}`}>*/}
        <img className='round-img' src={avatar} alt='' />
        <h4 style={{color: "white"}}>{username}</h4>
      {/*</Link>*/}
    </div>
    <div>
      <p className='my-1' style={{color: "white"}}>{text}</p>
      <p className='post-date'>
      {t('Post.Posted_on') + " " /*<Moment format='YYYY/MM/DD'>{date}</Moment>*/}
      {(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? date ? date.substring(0, 10).split("-")[2] + '/' + date.substring(0, 10).split("-")[1] + '/' + date.substring(0, 10).split("-")[0] : date.substring(0, 10).split("-")[2] + '/' + date.substring(0, 10).split("-")[1] + '/' + date.substring(0, 10).split("-")[0] : date ? date.substring(0, 10).split("-")[1] + '/' + date.substring(0, 10).split("-")[2] + '/' + date.substring(0, 10).split("-")[0] : date.substring(0, 10).split("-")[1] + '/' + date.substring(0, 10).split("-")[2] + '/' + date.substring(0, 10).split("-")[0]}
      </p>

      {showActions && (
        <Fragment>
          <button
            onClick={() => addLike(_id)}
            type='button'
            className='btn btn-light'
          >
            {t('Post.Like')}
            <i className='fas fa-thumbs-up' />{' '}
            <span>{likes.length > 0 && <span>{likes.length}</span>}</span>
          </button>
          <button
            onClick={() => removeLike(_id)}
            type='button'
            className='btn btn-light'
          >
            {t('Post.Unlike')}
            <i className='fas fa-thumbs-down' />
          </button>
          <Link to={`/community/${_id}`} className='btn btn-primary'>
          {t('Post.Discussion')}{' '}
            {comments.length > 0 && (
              <span className='comment-count'>{comments.length}</span>
            )}
          </Link>
          {!auth.loading && user === auth.user._id && (
            <button
              onClick={() => deletePost(_id)}
              type='button'
              className='btn btn-danger'
            >
              {t('Post.Delete')}
              <i className='fas fa-times' />
            </button>
          )}
        </Fragment>
      )}
    </div>
  </div>
)};

PostItem.defaultProps = {
  showActions: true
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  showActions: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { addLike, removeLike, deletePost }
)(PostItem);
