import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addPost } from '../../actions/post';
import { useTranslation } from 'react-i18next';

const PostForm = ({ addPost }) => {
  const [text, setText] = useState('');
  const { t } = useTranslation();
  return (
    <div className='post-form'>
      <div className='bg-primary p'>
        <h3 className="whiteTextOverride">{t('Post.Publish_content')}...</h3>
      </div>
      <form
        className='form my-1'
        onSubmit={e => {
          e.preventDefault();
          addPost({ text });
          setText('');
        }}
      >
        <textarea
          name='text'
          cols='30'
          rows='5'
          placeholder={t('Post.Create_post')}
          value={text}
          onChange={e => setText(e.target.value)}
          required
        />
        <input type='submit' className='btn btn-block btn-primary my-1' value={t('Post.Submit')} />
      </form>
    </div>
  );
};

PostForm.propTypes = {
  addPost: PropTypes.func.isRequired
};

export default connect(
  null,
  { addPost }
)(PostForm);
