import { v4 as uuidv4 } from 'uuid';
import { SET_ALERT, REMOVE_ALERT,
  SHOW_LOADING_API,
  HIDE_LOADING_API } from './types';

export const setAlert = (msg, alertType, timeout = 5000) => dispatch => {
  
  const id = uuidv4();
  dispatch({ type: REMOVE_ALERT, payload: id });
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id }
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};


// Show Loader
export const showLoader = () => dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });
};

// Show Loader
export const hideLoader = () => dispatch => {
  dispatch({
    type: HIDE_LOADING_API
  });
};