import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SponsorshipActionsDemo from './SponsorshipActionsDemo';
import { updateMonth } from '../../actions/sponsorship';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Pagination from '../layout/Pagination';
import { useTranslation } from 'react-i18next';

const DigitalWalletDemo = ({
  updateMonth,
  auth: { user, theme }, sponsorship: { month }
}) => {

  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");


  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(5);


  useEffect(() => {
    setCurrentPage(1)
  }, [month]);


  useEffect(() => {
    if (theme === 'light') {
    document.body.style = 'background: #b9d7f7';
    }
    else {
    document.body.style = 'background: #398DE7';
    }
   // document.body.style = 'background: #b9d7f7'; //#DCDCDC

    // returned function will be called on component unmount 
    return () => {
      
     // document.body.style = 'background: #D3D3D3'; //#DCDCDC
      document.body.style = null;
    }
  }, [theme]);

  const { t } = useTranslation();

  const dropDownSelected = event => {
    if (JSON.parse(event).index === "0") {
      updateMonth(0);
    }
    else if (JSON.parse(event).index === "1") {
      updateMonth(1);
    }
    else if (JSON.parse(event).index === "2") {
      updateMonth(2);
    }
    else if (JSON.parse(event).index === "3") {
      updateMonth(3);
    }
    else if (JSON.parse(event).index === "4") {
      updateMonth(4);
    }
    else if (JSON.parse(event).index === "5") {
      updateMonth(5);
    }
    else if (JSON.parse(event).index === "6") {
      updateMonth(6);
    }
    else if (JSON.parse(event).index === "7") {
      if (month !== 7 && month !== 8) {
        setModalTitle(t('DigitalWallet.Congratulations'));
        setModalBody(t('DigitalWallet.Full_matrix1'));
        setModalIsOpen(true);
      }
      updateMonth(7);
    }
    else if (JSON.parse(event).index === "8") {
      if (month !== 7 && month !== 8) {
        setModalTitle(t('DigitalWallet.Congratulations'));
        setModalBody(t('DigitalWallet.Full_matrix1'));
        setModalIsOpen(true);
      }
      updateMonth(8);
    }
  }

  var transactions = [];
  var wallet = 0;
  if (month === 1) {
    wallet = 200;
    transactions = [
      {
        affiliation_bonus_quantity: 2,
        amount: 200,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 200,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-08-05T22:43:43.379Z",
        date_creation: "2020-09-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20080005318",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: /*-436*/ 0,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 0,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-08-05T10:28:40.454Z",
        date_creation: "2020-08-05",
        description: "server_6_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 1,
        monthly_subscription_quantity: 0,
        new_id: "I20080002063",
        repositioning_costs_quantity: 0,
        title: "server_20_transaction",
        transaction_rib: "",
        transaction_type: 0,
        transaction_username: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      }
    ];
  }
  else if (month === 2) {
    wallet = 212;
    transactions = [
      {
        affiliation_bonus_quantity: 0,
        amount: 12,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 212,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-10-01T22:11:30.455Z",
        date_creation: "2020-10-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 2,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20100006218",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 2,
        amount: 200,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 200,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-09-01T22:43:43.379Z",
        date_creation: "2020-09-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20090005318",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: /*-436*/ 0,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 0,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-08-05T10:28:40.454Z",
        date_creation: "2020-08-05",
        description: "server_6_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 1,
        monthly_subscription_quantity: 0,
        new_id: "I20080002063",
        repositioning_costs_quantity: 0,
        title: "server_20_transaction",
        transaction_rib: "",
        transaction_type: 0,
        transaction_username: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      }
    ];
  }
  else if (month === 3) {
    wallet = 248;
    transactions = [
      {
        affiliation_bonus_quantity: 0,
        amount: 36,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 248,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-11-01T16:08:51.330Z",
        date_creation: "2020-11-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 6,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20110007118",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: 12,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 212,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-10-01T22:11:30.455Z",
        date_creation: "2020-10-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 2,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20100006218",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 2,
        amount: 200,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 200,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-09-01T22:43:43.379Z",
        date_creation: "2020-09-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20090005318",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: /*-436*/ 0,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 0,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-08-05T10:28:40.454Z",
        date_creation: "2020-08-05",
        description: "server_6_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 1,
        monthly_subscription_quantity: 0,
        new_id: "I20080002063",
        repositioning_costs_quantity: 0,
        title: "server_20_transaction",
        transaction_rib: "",
        transaction_type: 0,
        transaction_username: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      }
    ];
  }
  else if (month === 4) {
    wallet = 269.5;
    transactions = [
      {
        affiliation_bonus_quantity: 0,
        amount: -62.5,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 269.5,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-12-01T22:11:32.327Z",
        date_creation: "2020-12-01",
        description: "server_6_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 0,
        monthly_subscription_quantity: 1,
        new_id: "I20120003761",
        repositioning_costs_quantity: 0,
        title: "server_7_transaction",
        transaction_rib: "",
        transaction_type: 2,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: 84,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 332,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-12-01T22:11:32.221Z",
        date_creation: "2020-12-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 14,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20120008021",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: 36,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 248,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-11-01T16:08:51.330Z",
        date_creation: "2020-11-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 6,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20110007118",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: 12,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 212,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-10-01T22:11:30.455Z",
        date_creation: "2020-10-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 2,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20100006218",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 2,
        amount: 200,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 200,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-09-01T22:43:43.379Z",
        date_creation: "2020-09-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20090005318",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: /*-436*/ 0,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 0,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-08-05T10:28:40.454Z",
        date_creation: "2020-08-05",
        description: "server_6_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 1,
        monthly_subscription_quantity: 0,
        new_id: "I20080002063",
        repositioning_costs_quantity: 0,
        title: "server_20_transaction",
        transaction_rib: "",
        transaction_type: 0,
        transaction_username: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      }];
    }
  else if (month === 5) {
    wallet = 387;
    transactions = [
      {
        affiliation_bonus_quantity: 0,
        amount: -62.5,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 387,
        bonus_quantity: 0,
        country: "GB",
        date: "2021-01-01T22:11:32.327Z",
        date_creation: "2021-01-01",
        description: "server_6_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 0,
        monthly_subscription_quantity: 1,
        new_id: "I21010004761",
        repositioning_costs_quantity: 0,
        title: "server_7_transaction",
        transaction_rib: "",
        transaction_type: 2,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: 180,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 449.5,
        bonus_quantity: 0,
        country: "GB",
        date: "2021-01-01T22:11:32.221Z",
        date_creation: "2021-01-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 30,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P21010009021",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: -62.5,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 269.5,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-12-01T22:11:32.327Z",
        date_creation: "2020-12-01",
        description: "server_6_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 0,
        monthly_subscription_quantity: 1,
        new_id: "I20120003761",
        repositioning_costs_quantity: 0,
        title: "server_7_transaction",
        transaction_rib: "",
        transaction_type: 2,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: 84,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 332,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-12-01T22:11:32.221Z",
        date_creation: "2020-12-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 14,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20120008021",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: 36,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 248,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-11-01T16:08:51.330Z",
        date_creation: "2020-11-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 6,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20110007118",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: 12,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 212,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-10-01T22:11:30.455Z",
        date_creation: "2020-10-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 2,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20100006218",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 2,
        amount: 200,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 200,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-09-01T22:43:43.379Z",
        date_creation: "2020-09-01",
        description: "server_2_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 0,
        monthly_subscription_quantity: 0,
        new_id: "P20090005318",
        repositioning_costs_quantity: 0,
        title: "server_3_transaction",
        transaction_rib: "",
        transaction_type: 1,
        transaction_username: "",
        transfer_id: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      },
      {
        affiliation_bonus_quantity: 0,
        amount: /*-436*/ 0,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 0,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-08-05T10:28:40.454Z",
        date_creation: "2020-08-05",
        description: "server_6_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 1,
        monthly_subscription_quantity: 0,
        new_id: "I20080002063",
        repositioning_costs_quantity: 0,
        title: "server_20_transaction",
        transaction_rib: "",
        transaction_type: 0,
        transaction_username: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden",
        __v: 0
      }];
    }
    else if (month === 6) {
      wallet = 696.5;
      transactions = [
        {
          affiliation_bonus_quantity: 0,
          amount: -62.5,
          annual_subscription_quantity: 0,
          availability: 1,
          balance: 696.5,
          bonus_quantity: 0,
          country: "GB",
          date: "2021-02-01T22:11:32.327Z",
          date_creation: "2021-02-01",
          description: "server_6_transaction",
          matrix_commission_quantity: 0,
          membership_quantity: 0,
          monthly_subscription_quantity: 1,
          new_id: "I21020005761",
          repositioning_costs_quantity: 0,
          title: "server_7_transaction",
          transaction_rib: "",
          transaction_type: 2,
          transaction_username: "",
          transfer_id: "",
          user: "5f2a89d86ec416001719a34e",
          username: "Golden",
          __v: 0
        },
        {
          affiliation_bonus_quantity: 0,
          amount: 372,
          annual_subscription_quantity: 0,
          availability: 1,
          balance: 759,
          bonus_quantity: 0,
          country: "GB",
          date: "2021-02-01T22:11:32.221Z",
          date_creation: "2021-02-01",
          description: "server_2_transaction",
          matrix_commission_quantity: 62,
          membership_quantity: 0,
          monthly_subscription_quantity: 0,
          new_id: "P21020010021",
          repositioning_costs_quantity: 0,
          title: "server_3_transaction",
          transaction_rib: "",
          transaction_type: 1,
          transaction_username: "",
          transfer_id: "",
          user: "5f2a89d86ec416001719a34e",
          username: "Golden",
          __v: 0
        },
        {
          affiliation_bonus_quantity: 0,
          amount: -62.5,
          annual_subscription_quantity: 0,
          availability: 1,
          balance: 387,
          bonus_quantity: 0,
          country: "GB",
          date: "2021-01-01T22:11:32.327Z",
          date_creation: "2021-01-01",
          description: "server_6_transaction",
          matrix_commission_quantity: 0,
          membership_quantity: 0,
          monthly_subscription_quantity: 1,
          new_id: "I21010004761",
          repositioning_costs_quantity: 0,
          title: "server_7_transaction",
          transaction_rib: "",
          transaction_type: 2,
          transaction_username: "",
          transfer_id: "",
          user: "5f2a89d86ec416001719a34e",
          username: "Golden",
          __v: 0
        },
        {
          affiliation_bonus_quantity: 0,
          amount: 180,
          annual_subscription_quantity: 0,
          availability: 1,
          balance: 449.5,
          bonus_quantity: 0,
          country: "GB",
          date: "2021-01-01T22:11:32.221Z",
          date_creation: "2021-01-01",
          description: "server_2_transaction",
          matrix_commission_quantity: 30,
          membership_quantity: 0,
          monthly_subscription_quantity: 0,
          new_id: "P21010009021",
          repositioning_costs_quantity: 0,
          title: "server_3_transaction",
          transaction_rib: "",
          transaction_type: 1,
          transaction_username: "",
          transfer_id: "",
          user: "5f2a89d86ec416001719a34e",
          username: "Golden",
          __v: 0
        },
        {
          affiliation_bonus_quantity: 0,
          amount: -62.5,
          annual_subscription_quantity: 0,
          availability: 1,
          balance: 269.5,
          bonus_quantity: 0,
          country: "GB",
          date: "2020-12-01T22:11:32.327Z",
          date_creation: "2020-12-01",
          description: "server_6_transaction",
          matrix_commission_quantity: 0,
          membership_quantity: 0,
          monthly_subscription_quantity: 1,
          new_id: "I20120003761",
          repositioning_costs_quantity: 0,
          title: "server_7_transaction",
          transaction_rib: "",
          transaction_type: 2,
          transaction_username: "",
          transfer_id: "",
          user: "5f2a89d86ec416001719a34e",
          username: "Golden",
          __v: 0
        },
        {
          affiliation_bonus_quantity: 0,
          amount: 84,
          annual_subscription_quantity: 0,
          availability: 1,
          balance: 332,
          bonus_quantity: 0,
          country: "GB",
          date: "2020-12-01T22:11:32.221Z",
          date_creation: "2020-12-01",
          description: "server_2_transaction",
          matrix_commission_quantity: 14,
          membership_quantity: 0,
          monthly_subscription_quantity: 0,
          new_id: "P20120008021",
          repositioning_costs_quantity: 0,
          title: "server_3_transaction",
          transaction_rib: "",
          transaction_type: 1,
          transaction_username: "",
          transfer_id: "",
          user: "5f2a89d86ec416001719a34e",
          username: "Golden",
          __v: 0
        },
        {
          affiliation_bonus_quantity: 0,
          amount: 36,
          annual_subscription_quantity: 0,
          availability: 1,
          balance: 248,
          bonus_quantity: 0,
          country: "GB",
          date: "2020-11-01T16:08:51.330Z",
          date_creation: "2020-11-01",
          description: "server_2_transaction",
          matrix_commission_quantity: 6,
          membership_quantity: 0,
          monthly_subscription_quantity: 0,
          new_id: "P20110007118",
          repositioning_costs_quantity: 0,
          title: "server_3_transaction",
          transaction_rib: "",
          transaction_type: 1,
          transaction_username: "",
          transfer_id: "",
          user: "5f2a89d86ec416001719a34e",
          username: "Golden",
          __v: 0
        },
        {
          affiliation_bonus_quantity: 0,
          amount: 12,
          annual_subscription_quantity: 0,
          availability: 1,
          balance: 212,
          bonus_quantity: 0,
          country: "GB",
          date: "2020-10-01T22:11:30.455Z",
          date_creation: "2020-10-01",
          description: "server_2_transaction",
          matrix_commission_quantity: 2,
          membership_quantity: 0,
          monthly_subscription_quantity: 0,
          new_id: "P20100006218",
          repositioning_costs_quantity: 0,
          title: "server_3_transaction",
          transaction_rib: "",
          transaction_type: 1,
          transaction_username: "",
          transfer_id: "",
          user: "5f2a89d86ec416001719a34e",
          username: "Golden",
          __v: 0
        },
        {
          affiliation_bonus_quantity: 2,
          amount: 200,
          annual_subscription_quantity: 0,
          availability: 1,
          balance: 200,
          bonus_quantity: 0,
          country: "GB",
          date: "2020-09-01T22:43:43.379Z",
          date_creation: "2020-09-01",
          description: "server_2_transaction",
          matrix_commission_quantity: 0,
          membership_quantity: 0,
          monthly_subscription_quantity: 0,
          new_id: "P20090005318",
          repositioning_costs_quantity: 0,
          title: "server_3_transaction",
          transaction_rib: "",
          transaction_type: 1,
          transaction_username: "",
          transfer_id: "",
          user: "5f2a89d86ec416001719a34e",
          username: "Golden",
          __v: 0
        },
        {
          affiliation_bonus_quantity: 0,
          amount: /*-436*/ 0,
          annual_subscription_quantity: 0,
          availability: 1,
          balance: 0,
          bonus_quantity: 0,
          country: "GB",
          date: "2020-08-05T10:28:40.454Z",
          date_creation: "2020-08-05",
          description: "server_6_transaction",
          matrix_commission_quantity: 0,
          membership_quantity: 1,
          monthly_subscription_quantity: 0,
          new_id: "I20080002063",
          repositioning_costs_quantity: 0,
          title: "server_20_transaction",
          transaction_rib: "",
          transaction_type: 0,
          transaction_username: "",
          user: "5f2a89d86ec416001719a34e",
          username: "Golden",
          __v: 0
        }];
      }
      else if (month === 7) {
        wallet = 1390;
        transactions = [
          {
            affiliation_bonus_quantity: 0,
            amount: -62.5,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 1390,
            bonus_quantity: 0,
            country: "GB",
            date: "2021-03-01T22:11:32.327Z",
            date_creation: "2021-03-01",
            description: "server_6_transaction",
            matrix_commission_quantity: 0,
            membership_quantity: 0,
            monthly_subscription_quantity: 1,
            new_id: "I21030006761",
            repositioning_costs_quantity: 0,
            title: "server_7_transaction",
            transaction_rib: "",
            transaction_type: 2,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 0,
            amount: 756,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 1452.5,
            bonus_quantity: 0,
            country: "GB",
            date: "2021-03-01T22:11:32.221Z",
            date_creation: "2021-03-01",
            description: "server_2_transaction",
            matrix_commission_quantity: 126,
            membership_quantity: 0,
            monthly_subscription_quantity: 0,
            new_id: "P21030011021",
            repositioning_costs_quantity: 0,
            title: "server_3_transaction",
            transaction_rib: "",
            transaction_type: 1,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 0,
            amount: -62.5,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 696.5,
            bonus_quantity: 0,
            country: "GB",
            date: "2021-02-01T22:11:32.327Z",
            date_creation: "2021-02-01",
            description: "server_6_transaction",
            matrix_commission_quantity: 0,
            membership_quantity: 0,
            monthly_subscription_quantity: 1,
            new_id: "I21020005761",
            repositioning_costs_quantity: 0,
            title: "server_7_transaction",
            transaction_rib: "",
            transaction_type: 2,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 0,
            amount: 372,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 759,
            bonus_quantity: 0,
            country: "GB",
            date: "2021-02-01T22:11:32.221Z",
            date_creation: "2021-02-01",
            description: "server_2_transaction",
            matrix_commission_quantity: 62,
            membership_quantity: 0,
            monthly_subscription_quantity: 0,
            new_id: "P21020010021",
            repositioning_costs_quantity: 0,
            title: "server_3_transaction",
            transaction_rib: "",
            transaction_type: 1,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 0,
            amount: -62.5,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 387,
            bonus_quantity: 0,
            country: "GB",
            date: "2021-01-01T22:11:32.327Z",
            date_creation: "2021-01-01",
            description: "server_6_transaction",
            matrix_commission_quantity: 0,
            membership_quantity: 0,
            monthly_subscription_quantity: 1,
            new_id: "I21010004761",
            repositioning_costs_quantity: 0,
            title: "server_7_transaction",
            transaction_rib: "",
            transaction_type: 2,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 0,
            amount: 180,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 449.5,
            bonus_quantity: 0,
            country: "GB",
            date: "2021-01-01T22:11:32.221Z",
            date_creation: "2021-01-01",
            description: "server_2_transaction",
            matrix_commission_quantity: 30,
            membership_quantity: 0,
            monthly_subscription_quantity: 0,
            new_id: "P21010009021",
            repositioning_costs_quantity: 0,
            title: "server_3_transaction",
            transaction_rib: "",
            transaction_type: 1,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 0,
            amount: -62.5,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 269.5,
            bonus_quantity: 0,
            country: "GB",
            date: "2020-12-01T22:11:32.327Z",
            date_creation: "2020-12-01",
            description: "server_6_transaction",
            matrix_commission_quantity: 0,
            membership_quantity: 0,
            monthly_subscription_quantity: 1,
            new_id: "I20120003761",
            repositioning_costs_quantity: 0,
            title: "server_7_transaction",
            transaction_rib: "",
            transaction_type: 2,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 0,
            amount: 84,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 332,
            bonus_quantity: 0,
            country: "GB",
            date: "2020-12-01T22:11:32.221Z",
            date_creation: "2020-12-01",
            description: "server_2_transaction",
            matrix_commission_quantity: 14,
            membership_quantity: 0,
            monthly_subscription_quantity: 0,
            new_id: "P20120008021",
            repositioning_costs_quantity: 0,
            title: "server_3_transaction",
            transaction_rib: "",
            transaction_type: 1,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 0,
            amount: 36,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 248,
            bonus_quantity: 0,
            country: "GB",
            date: "2020-11-01T16:08:51.330Z",
            date_creation: "2020-11-01",
            description: "server_2_transaction",
            matrix_commission_quantity: 6,
            membership_quantity: 0,
            monthly_subscription_quantity: 0,
            new_id: "P20110007118",
            repositioning_costs_quantity: 0,
            title: "server_3_transaction",
            transaction_rib: "",
            transaction_type: 1,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 0,
            amount: 12,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 212,
            bonus_quantity: 0,
            country: "GB",
            date: "2020-10-01T22:11:30.455Z",
            date_creation: "2020-10-01",
            description: "server_2_transaction",
            matrix_commission_quantity: 2,
            membership_quantity: 0,
            monthly_subscription_quantity: 0,
            new_id: "P20100006218",
            repositioning_costs_quantity: 0,
            title: "server_3_transaction",
            transaction_rib: "",
            transaction_type: 1,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 2,
            amount: 200,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 200,
            bonus_quantity: 0,
            country: "GB",
            date: "2020-09-01T22:43:43.379Z",
            date_creation: "2020-09-01",
            description: "server_2_transaction",
            matrix_commission_quantity: 0,
            membership_quantity: 0,
            monthly_subscription_quantity: 0,
            new_id: "P20090005318",
            repositioning_costs_quantity: 0,
            title: "server_3_transaction",
            transaction_rib: "",
            transaction_type: 1,
            transaction_username: "",
            transfer_id: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          },
          {
            affiliation_bonus_quantity: 0,
            amount: /*-436*/ 0,
            annual_subscription_quantity: 0,
            availability: 1,
            balance: 0,
            bonus_quantity: 0,
            country: "GB",
            date: "2020-08-05T10:28:40.454Z",
            date_creation: "2020-08-05",
            description: "server_6_transaction",
            matrix_commission_quantity: 0,
            membership_quantity: 1,
            monthly_subscription_quantity: 0,
            new_id: "I20080002063",
            repositioning_costs_quantity: 0,
            title: "server_20_transaction",
            transaction_rib: "",
            transaction_type: 0,
            transaction_username: "",
            user: "5f2a89d86ec416001719a34e",
            username: "Golden",
            __v: 0
          }];
        }
        else if (month === 8) {
          wallet = 2851.5;
          transactions = [
            {
              affiliation_bonus_quantity: 0,
              amount: -62.5,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 2851.5,
              bonus_quantity: 0,
              country: "GB",
              date: "2021-04-01T22:11:32.327Z",
              date_creation: "2021-04-01",
              description: "server_6_transaction",
              matrix_commission_quantity: 0,
              membership_quantity: 0,
              monthly_subscription_quantity: 1,
              new_id: "I21040007761",
              repositioning_costs_quantity: 0,
              title: "server_7_transaction",
              transaction_rib: "",
              transaction_type: 2,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: 1524,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 2914,
              bonus_quantity: 0,
              country: "GB",
              date: "2021-04-01T22:11:32.221Z",
              date_creation: "2021-04-01",
              description: "server_2_transaction",
              matrix_commission_quantity: 254,
              membership_quantity: 0,
              monthly_subscription_quantity: 0,
              new_id: "P21040012021",
              repositioning_costs_quantity: 0,
              title: "server_3_transaction",
              transaction_rib: "",
              transaction_type: 1,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: -62.5,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 1390,
              bonus_quantity: 0,
              country: "GB",
              date: "2021-03-01T22:11:32.327Z",
              date_creation: "2021-03-01",
              description: "server_6_transaction",
              matrix_commission_quantity: 0,
              membership_quantity: 0,
              monthly_subscription_quantity: 1,
              new_id: "I21030006761",
              repositioning_costs_quantity: 0,
              title: "server_7_transaction",
              transaction_rib: "",
              transaction_type: 2,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: 756,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 1452.5,
              bonus_quantity: 0,
              country: "GB",
              date: "2021-03-01T22:11:32.221Z",
              date_creation: "2021-03-01",
              description: "server_2_transaction",
              matrix_commission_quantity: 126,
              membership_quantity: 0,
              monthly_subscription_quantity: 0,
              new_id: "P21030011021",
              repositioning_costs_quantity: 0,
              title: "server_3_transaction",
              transaction_rib: "",
              transaction_type: 1,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: -62.5,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 696.5,
              bonus_quantity: 0,
              country: "GB",
              date: "2021-02-01T22:11:32.327Z",
              date_creation: "2021-02-01",
              description: "server_6_transaction",
              matrix_commission_quantity: 0,
              membership_quantity: 0,
              monthly_subscription_quantity: 1,
              new_id: "I21020005761",
              repositioning_costs_quantity: 0,
              title: "server_7_transaction",
              transaction_rib: "",
              transaction_type: 2,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: 372,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 759,
              bonus_quantity: 0,
              country: "GB",
              date: "2021-02-01T22:11:32.221Z",
              date_creation: "2021-02-01",
              description: "server_2_transaction",
              matrix_commission_quantity: 62,
              membership_quantity: 0,
              monthly_subscription_quantity: 0,
              new_id: "P21020010021",
              repositioning_costs_quantity: 0,
              title: "server_3_transaction",
              transaction_rib: "",
              transaction_type: 1,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: -62.5,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 387,
              bonus_quantity: 0,
              country: "GB",
              date: "2021-01-01T22:11:32.327Z",
              date_creation: "2021-01-01",
              description: "server_6_transaction",
              matrix_commission_quantity: 0,
              membership_quantity: 0,
              monthly_subscription_quantity: 1,
              new_id: "I21010004761",
              repositioning_costs_quantity: 0,
              title: "server_7_transaction",
              transaction_rib: "",
              transaction_type: 2,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: 180,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 449.5,
              bonus_quantity: 0,
              country: "GB",
              date: "2021-01-01T22:11:32.221Z",
              date_creation: "2021-01-01",
              description: "server_2_transaction",
              matrix_commission_quantity: 30,
              membership_quantity: 0,
              monthly_subscription_quantity: 0,
              new_id: "P21010009021",
              repositioning_costs_quantity: 0,
              title: "server_3_transaction",
              transaction_rib: "",
              transaction_type: 1,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: -62.5,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 269.5,
              bonus_quantity: 0,
              country: "GB",
              date: "2020-12-01T22:11:32.327Z",
              date_creation: "2020-12-01",
              description: "server_6_transaction",
              matrix_commission_quantity: 0,
              membership_quantity: 0,
              monthly_subscription_quantity: 1,
              new_id: "I20120003761",
              repositioning_costs_quantity: 0,
              title: "server_7_transaction",
              transaction_rib: "",
              transaction_type: 2,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: 84,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 332,
              bonus_quantity: 0,
              country: "GB",
              date: "2020-12-01T22:11:32.221Z",
              date_creation: "2020-12-01",
              description: "server_2_transaction",
              matrix_commission_quantity: 14,
              membership_quantity: 0,
              monthly_subscription_quantity: 0,
              new_id: "P20120008021",
              repositioning_costs_quantity: 0,
              title: "server_3_transaction",
              transaction_rib: "",
              transaction_type: 1,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: 36,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 248,
              bonus_quantity: 0,
              country: "GB",
              date: "2020-11-01T16:08:51.330Z",
              date_creation: "2020-11-01",
              description: "server_2_transaction",
              matrix_commission_quantity: 6,
              membership_quantity: 0,
              monthly_subscription_quantity: 0,
              new_id: "P20110007118",
              repositioning_costs_quantity: 0,
              title: "server_3_transaction",
              transaction_rib: "",
              transaction_type: 1,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: 12,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 212,
              bonus_quantity: 0,
              country: "GB",
              date: "2020-10-01T22:11:30.455Z",
              date_creation: "2020-10-01",
              description: "server_2_transaction",
              matrix_commission_quantity: 2,
              membership_quantity: 0,
              monthly_subscription_quantity: 0,
              new_id: "P20100006218",
              repositioning_costs_quantity: 0,
              title: "server_3_transaction",
              transaction_rib: "",
              transaction_type: 1,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 2,
              amount: 200,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 200,
              bonus_quantity: 0,
              country: "GB",
              date: "2020-09-01T22:43:43.379Z",
              date_creation: "2020-09-01",
              description: "server_2_transaction",
              matrix_commission_quantity: 0,
              membership_quantity: 0,
              monthly_subscription_quantity: 0,
              new_id: "P20090005318",
              repositioning_costs_quantity: 0,
              title: "server_3_transaction",
              transaction_rib: "",
              transaction_type: 1,
              transaction_username: "",
              transfer_id: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            },
            {
              affiliation_bonus_quantity: 0,
              amount: /*-436*/ 0,
              annual_subscription_quantity: 0,
              availability: 1,
              balance: 0,
              bonus_quantity: 0,
              country: "GB",
              date: "2020-08-05T10:28:40.454Z",
              date_creation: "2020-08-05",
              description: "server_6_transaction",
              matrix_commission_quantity: 0,
              membership_quantity: 1,
              monthly_subscription_quantity: 0,
              new_id: "I20080002063",
              repositioning_costs_quantity: 0,
              title: "server_20_transaction",
              transaction_rib: "",
              transaction_type: 0,
              transaction_username: "",
              user: "5f2a89d86ec416001719a34e",
              username: "Golden",
              __v: 0
            }];
          }
  else {
    wallet = 0;
    transactions = [
      {
        affiliation_bonus_quantity: 0,
        amount: /*-436*/ 0,
        annual_subscription_quantity: 0,
        availability: 1,
        balance: 0,
        bonus_quantity: 0,
        country: "GB",
        date: "2020-08-05T10:28:40.454Z",
        date_creation: "2020-08-05",
        description: "server_6_transaction",
        matrix_commission_quantity: 0,
        membership_quantity: 1,
        monthly_subscription_quantity: 0,
        new_id: "I20080002063",
        repositioning_costs_quantity: 0,
        title: "server_20_transaction",
        transaction_rib: "",
        transaction_type: 0,
        transaction_username: "",
        user: "5f2a89d86ec416001719a34e",
        username: "Golden"
      }
    ];
  }

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;

  var currentTransactions = [];

  if (transactions.length > 0) {
    currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);
  }


  const paginate = pageNum => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage === Math.ceil(transactions.length / transactionsPerPage) ? currentPage : currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(Math.ceil(transactions.length / transactionsPerPage));





  const showTableRibs =


    <Fragment>



      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>

        <div className="scrollme">





          <table id="tbl" style={{ width: 1000 }} className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
            <thead className={theme === "light" ? "thead-dark" : "thead-light"} >
              <tr>
                <th scope="col">{t('DigitalWallet.Date')}</th>
                <th scope="col">{t('DigitalWallet.Bank')}</th>
                <th scope="col">{t('DigitalWallet.Iban')}</th>
                <th scope="col">{t('DigitalWallet.Actions')}</th>
              </tr>
            </thead>
            <tbody>



              <tr>
                <th colSpan={4}>{t('DigitalWallet.No_ribs')}</th>
              </tr>


            </tbody>
          </table>
        </div>
      </div>
    </Fragment>;












  const showTableTrasactions =


    <Fragment>


      <h1 className='large text-primary'>{t('DigitalWallet.Activities')}</h1>



      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>

        <div className="scrollme">





          <table id="tbl" style={{ width: 1000 }} className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
            <thead className={theme === "light" ? "thead-dark" : "thead-light"} >
              <tr>
                <th scope="col">{t('DigitalWallet.Ref')}</th>
                <th scope="col">{t('DigitalWallet.Date')}</th>
                <th scope="col">{t('DigitalWallet.Description')}</th>
                <th scope="col">{t('DigitalWallet.Amount')}</th>
                <th scope="col">{t('DigitalWallet.Balance')}</th>
                <th scope="col">{t('DigitalWallet.Action')}</th>
              </tr>
            </thead>
            <tbody>


              {
                transactions.length === 0 ?
                  <tr>
                    <th colSpan={6}>{t('DigitalWallet.No_activities')}</th>
                  </tr>
                  :
                  currentTransactions.map((get_transaction, index) => {
                    // console.log("sponsorships"+JSON.stringify(sponsorship))
                    var date = get_transaction.date_creation.substring(0, 10).split("-")
                    var finalDate = date[1] + '/' + date[2] + '/' + date[0];
                    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
                      finalDate = date[2] + '/' + date[1] + '/' + date[0];
                    }
                    return (
                      <tr key={index}>
                        <td>{get_transaction.new_id}</td>
                        <td>{finalDate}</td>
                        <td>{t(`Server.${get_transaction.title}`) + (/*get_transaction.title === "server_12_transaction" ? " " + ++penalityCounter : */get_transaction.transaction_rib !== "" ? " " + t('Server.server_21_transaction') + " " + get_transaction.transaction_rib : get_transaction.transaction_username !== "" ? " " + t('Server.server_13_transaction') + " " + get_transaction.transaction_username : "") + ((get_transaction.transaction_type === 4 || get_transaction.transaction_type === 5) ? " - (" + t('Server.server_22_transaction') + " ...)" : ((get_transaction.transaction_type === 8 || get_transaction.transaction_type === 10) ? " - (" + t('Server.server_23_transaction') + ")" : ""))}</td>
                        <td><font color={/*#03925e*/get_transaction.amount >= 0 ? "" : "red"}>{get_transaction.amount.toFixed(2) + "€"}</font></td>
                        <td>{get_transaction.balance.toFixed(2) + "€"}</td>
                        <td>
                          {get_transaction.transaction_type === 0 || get_transaction.transaction_type === 2 || get_transaction.transaction_type === 3 || get_transaction.transaction_type === 13 ?
                            <button type="button" className="btn btn-primary" onClick={() => consultInvoice()}>{t('DigitalWallet.Consult_invoice')}</button>
                            :
                            get_transaction.transaction_type === 1 ?
                              <button type="button" className="btn btn-primary" onClick={() => consultFile()}>{t('DigitalWallet.Consult_file')}</button>
                              :
                              t('DigitalWallet.No_action')
                          }
                        </td>
                      </tr>

                    );
                  })}

            </tbody>
          </table>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <div className="scrollme">
          <table>
            {transactions.length > 0 ? <Pagination usersPerPage={transactionsPerPage} totalUsers={transactions.length > 0 ? transactions.length : 0} paginate={paginate} nextPage={nextPage} prevPage={prevPage} firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} /> : null}
          </table>
        </div>
      </div>
    </Fragment>;
    
  const showModal =
    <Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
      <ModalHeader closeButton as="span">
        <ModalTitle as="h4">{modalTitle}</ModalTitle>
      </ModalHeader>
      <ModalBody as="section"
        style={{
          whiteSpace: "pre-line"
        }}>{modalBody}
      </ModalBody>
      <ModalFooter as="footer">

        <button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{t('DigitalWallet.Ok')}</button>

      </ModalFooter>
    </Modal>







  function addCard() {
    setModalTitle(t('DigitalWallet.Demonstration_mode'));
    setModalBody(t('DigitalWallet.Demo_add'));
    setModalIsOpen(true);
  }


  function consultInvoice() {
    setModalTitle(t('DigitalWallet.Demonstration_mode'));
    setModalBody(t('DigitalWallet.Demo_invoice'));
    setModalIsOpen(true);
  }

  function consultFile() {
    setModalTitle(t('DigitalWallet.Demonstration_mode'));
    setModalBody(t('DigitalWallet.Demo_file'));
    setModalIsOpen(true);
  }



  return (

    <Fragment>

      <SponsorshipActionsDemo />

      <select className="form-control" name="action" value={'{"index": "' + month + '"}'} onChange={e => dropDownSelected(e.target.value)}>
        <option value={'{"index": "0"}'}>{t('DigitalWallet.Month')} 0</option>
        <option value={'{"index": "1"}'}>{t('DigitalWallet.Month')} 1</option>
        <option value={'{"index": "2"}'}>{t('DigitalWallet.Month')} 2</option>
        <option value={'{"index": "3"}'}>{t('DigitalWallet.Month')} 3</option>
        <option value={'{"index": "4"}'}>{t('DigitalWallet.Month')} 4</option>
        <option value={'{"index": "5"}'}>{t('DigitalWallet.Month')} 5</option>
        <option value={'{"index": "6"}'}>{t('DigitalWallet.Month')} 6</option>
        <option value={'{"index": "7"}'}>{t('DigitalWallet.Month')} 7</option>
        <option value={'{"index": "8"}'}>{t('DigitalWallet.Month')} 8</option>
      </select>

      <br />

      <h1 className='large text-primary'>{t('DigitalWallet.Digital_wallet')}</h1>
      <p className='lead'>
        <i className='fas fa-user' /> {t('DigitalWallet.Current_balance') + " : "}
        <strong style={{ fontSize: "30px" }}>{wallet.toFixed(2) + "€"}</strong>
      </p>
      <button type="button" className="btn btn-primary" onClick={() => addCard()}>{t('DigitalWallet.Add_account')}</button>

      <br /><br />
      {showTableRibs}
      <br />
      {showTableTrasactions}
      {showModal}


    </Fragment>
  );

};

DigitalWalletDemo.propTypes = {
  auth: PropTypes.object.isRequired,
  updateMonth: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { updateMonth }
)(DigitalWalletDemo);