import {
  ADD_CHAT,
  CHAT_ERROR,
  GET_CHATS,
  FIND_CHAT,
  ADD_MESSAGE,
  MESSAGE_ERROR,
  GET_MESSAGES,
  } from '../actions/types';
  
  const initialState = {
    chats: [],
    chat: null,
    messages: [],
    message: null,
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case ADD_CHAT:
        return {
          ...state,
          chats: [payload, ...state.chats],
          loading: false
        };
      case GET_CHATS:
        return {
          ...state,
          chats: payload,
          loading: false
        };
        case FIND_CHAT:
          return {
            ...state,
            chat: payload,
            loading: false
          };
          case ADD_MESSAGE:
        return {
          ...state,
          messages: [payload, ...state.messages],
          loading: false
        };
      case GET_MESSAGES:
        return {
          ...state,
          messages: payload,
          loading: false
        };
        case CHAT_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
        case MESSAGE_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
      default:
        return state;
    }
  }
  