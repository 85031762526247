import {
    ADD_ARTICLE_SUCCESS,
    ARTICLES_SUCCESS,
    FIRST_ARTICLES_SUCCESS,
    ARTICLES_FAIL,
    ARTICLES_NEXT,
    ARTICLES_CURRENT,
    ALL_ARTICLES_SUCCESS,
    ALL_ARTICLES_FAIL,
    GET_ARTICLE_SUCCESS,
    GET_ARTICLE_FAIL
} from '../actions/types';



const initialState = {
    loading: true,
    add_article: null,
    articles_next: true,
    articles: [],
    
    error: {}
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case ADD_ARTICLE_SUCCESS:
            return {
                ...state,
                add_article: payload,
                loading: false
            };

            case ARTICLES_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                articles: payload
            };

            case FIRST_ARTICLES_SUCCESS:
                return {
                    ...state,
                    ...payload,
                    loading: false,
                    first_articles: payload
                };

            case ARTICLES_FAIL:
            return {
                ...state,
                error: payload,
                loading: false
            };

            case ARTICLES_NEXT:
                return {
                    ...state,
                    ...payload,
                    loading: false,
                    articles_next: payload
                };

                case ARTICLES_CURRENT:
                    return {
                        ...state,
                        ...payload,
                        loading: false,
                        articles_current: payload
                    };


                    case ALL_ARTICLES_SUCCESS:
                        return {
                            ...state,
                            ...payload,
                            loading: false,
                            all_articles: payload
                        };
                        case ALL_ARTICLES_FAIL:
                        return {
                            ...state,
                            error: payload,
                            loading: false
                        };
                        



            case GET_ARTICLE_SUCCESS:
                return {
                    ...state,
                    ...payload,
                    loading: false,
                    get_article: payload
                };
    
    
                case GET_ARTICLE_FAIL:
                return {
                    ...state,
                    error: payload,
                    loading: false
                };
                    
        default:
            return state;
    }
}