import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import { useTranslation } from 'react-i18next';

import { GlobalStyles, lightTheme, darkTheme } from '../../styles/globalStyles';
import { ThemeProvider } from 'styled-components';


//import './custom.scss';

const GlobalTheme = ({
  auth: { theme }
}) => {

  
  //const { t } = useTranslation();
  var themeMode = darkTheme
  if (theme === 'light') {
    themeMode = lightTheme
  }
 // const themeMode = theme === 'light' ? lightTheme : darkTheme;
  return (
    <ThemeProvider theme={themeMode}>
    
    <GlobalStyles />
    </ThemeProvider>
  );
};

GlobalTheme.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(GlobalTheme);
